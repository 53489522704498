/* @ngInject */
module.exports = function ($scope, $translate, $filter, BannerSvc, $q, $rootScope, EnlightedAPISvc, localizeFilter, EnlightedAPIErrorSvc, ViewOnly) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.apiEnum = undefined;
  vm.cachedEnumObj = undefined;
  vm.enumObj = undefined;
  vm.hasAsync = false;
  vm.infoForm = {};
  vm.isLoaded = false;
  vm.options = {};
  vm.options.editMode = true;
  vm.orgTypes = [];
  vm.availableOrgTypes = [];
  vm.selectedOrgTypes = [];

  vm.dirtyFlag = false;
  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops contact_type info.ctrl: activate ');

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });
    });
    $scope.$on('CONTACT_TYPE_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('ops contact_type info.ctrl: init', enumObj);
    EnlightedAPISvc.organization_type.getAll().then(function(rsp) {
      vm.orgTypeVals = _.map(rsp.enums, 'val');
      angular.forEach(rsp.enums, function(item) {
        vm.orgTypes.push({


          id: item.val,
          display: localizeFilter(item.names)
        });
      });
      vm.apiEnum = enumObj;
      vm.enumObj = createEnumDisplay(enumObj);
      vm.cachedEnumObj = angular.copy(vm.enumObj);
      setDualColSelect(vm.enumObj.org_types);
      vm.isLoaded = true;
    });

  }

  function createEnumDisplay(enumObj) {
    return {
      val: enumObj.val,
      dname: (enumObj.names) ? enumObj.names[''] : undefined,
      ddescription: (enumObj.descriptions) ? enumObj.descriptions[''] : undefined,
      org_types: enumObj.org_types,
      kinds: enumObj.kinds
    };
  }

  function setDualColSelect(orgTypes) {

    vm.selectedOrgTypes = [];
    vm.availableOrgTypes = [];

    angular.forEach(orgTypes, function (val) {
      vm.selectedOrgTypes.push({
        id: val,
        val: val,
        display: _.find(vm.orgTypes, function(o) {
           return o.id === val;
        }).display,
        selected: false
      });
    });


    angular.forEach(_.difference(vm.orgTypeVals, orgTypes), function (val) {
      vm.availableOrgTypes.push({
        id: val,
        val: val,
        display: _.find(vm.orgTypes, function(o) {
          return o.id === val;
        }).display,
        selected: false
      });
    });

    vm.options.selectedOrgTypes = vm.selectedOrgTypes;
    vm.options.availableOrgTypes = vm.availableOrgTypes;
  }

  function restore() {
    vm.dirtyFlag = false;
    vm.enumObj = angular.copy(vm.cachedEnumObj);
    setDualColSelect(vm.cachedEnumObj.org_types);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    vm.apiEnum = updateAPIEnum(vm.apiEnum, vm.enumObj);
    console.log('contact_type-info.ctrl: save - ', vm.apiEnum);

    vm.hasAsync = true;

    EnlightedAPISvc[vm.apiEnum.kind].update(vm.apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', {val: vm.apiEnum.val}),
          el: '#content'
        });

        vm.enumObj = createEnumDisplay(rsp.enum);
        vm.cachedEnumObj = angular.copy(vm.enumObj);

        vm.infoForm.$setPristine();

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', {val: vm.cachedEnumObj.val}), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.dname;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.ddescription;
    apiEnumObj.org_types = enumObj.org_types;
    apiEnumObj.kinds = enumObj.kinds;
    return apiEnumObj;
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("contact type form");
    } else {
      ViewOnly.delete("contact type form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("contact type form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("contact type form");
  });
};
