/* @ngInject */
module.exports = function ($scope, EnlightedAPISvc, ConfirmDialogSvc, $filter, EnlightedAPIEnumsSvc, $translate, MAXLENGTH) {

    var vm = this,

    I18N_KEYS = [
        'CONFIRM_ALLOW_3RDPARTY_IDP',
        'CONFIRM_DIALOG',
        'CONFIRM_SKIP_IDP'
    ],

    DICTIONARY = {},

    isInit = false;

    //============= MODELS ===========//
    vm.idpTypes = [{
        name: '',
        val: ''
    }];

    vm.thirdParty = [];

    //============= FUNCTIONS ============//
    vm.showConfirmDialog = showConfirmDialog;
    vm.skipAndFinish     = skipAndFinish;

    activate();

    //============ FN DEFINITIONS ==========//
    function activate() {

        console.log('idp_step.ctrl: activate');

        $scope.$on('CREATE_ORG_ACTIVATE_IDP', function () {
            init();
        });

        if ($scope.coBaseCtrl.selectedStep === 3) {
            init();
        }
    }

    function init() {
        console.log('idp_step.ctrl: init');
        vm.idpTypes = vm.idpTypes.concat(EnlightedAPIEnumsSvc.getIdpTypes());
        angular.forEach(I18N_KEYS, function (key) {
          DICTIONARY[key] = $filter('translate')(key);
        });

        console.log("allow third party idps?");
        console.log($scope.step.data.org.thirdparty_idps);

        if (isInit) {
            return;
        }

        EnlightedAPISvc.IdentityProvider.getAll()
            .then(function (result) {
                vm.thirdParty = filterIdps(result.objs);
                console.log("third party provider");
                console.log(vm.thirdParty);
                isInit = true;
            }, function () {
                // TODO: error handling
            });
    }

    function filterIdps(idpList) {
        var result = [];
        angular.forEach(idpList, function (idp) {
            if (typeof idp.org_owner === 'undefined') {
                result.push(idp);
            }
        });
        return result;
    }

    function showConfirmDialog(ev, allowed) {
        if (!allowed) {
            return;
        }

        var opts = {
          ariaLabel: DICTIONARY.CONFIRM_DIALOG,
          title: DICTIONARY.CONFIRM_DIALOG,
          message: DICTIONARY.CONFIRM_ALLOW_3RDPARTY_IDP
        };

        ConfirmDialogSvc.show(ev, opts)
          .catch(function () {
            $scope.step.data.org.thirdparty_idps = false;
          });
      }

    function skipAndFinish(ev) {
        if ($scope.idpForm.$dirty) {
            var opts = {
              ariaLabel: DICTIONARY.CONFIRM_DIALOG,
              title: DICTIONARY.CONFIRM_DIALOG,
              message: DICTIONARY.CONFIRM_SKIP_IDP
            };

            ConfirmDialogSvc.show(ev, opts)
              .then(function () {
                $scope.coBaseCtrl.finishWizard(true);
              });
        } else {
            $scope.coBaseCtrl.finishWizard(true);
        }
    }
};
