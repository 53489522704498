module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      entry        : '=',
      restrictions : '=',
      onCancel     : '&',
      onSave       : '&',
      options      : '='
    },
    templateUrl: 'app-container/components/global-settings/ops/regulator/details/wattage-entry-editor.template.html',
    controller: 'WattageEntryEditorDirCtrl as editorCtrl',
    compile: function (element, attrs, transclude) {
      attrs.restrictions = attrs.restrictions || [];
    }
  };
};
