/* @ngInject */
module.exports = function ($scope,localizeFilter,EnlightedAPIEnumsSvc, EnlightedAPISvc, MAXLENGTH) {
  var vm = this;

  //============= MODELS ============//
  vm.svgProps = {};
  vm.devices = EnlightedAPIEnumsSvc.getBasicDeviceTypes();
  

  //============= FUNCTIONS ============//
  activate();


  //============= FN DEFINITIONS ============//
  function activate() {
    $scope.$watch(function () {
      return $scope.infoForm;
    }, function (newVal, oldVal) {
      $scope.form = newVal;
    });

    EnlightedAPISvc.Session.getSession()
      .then(function (res) {
        vm.svgProps = res.properties;
      });
  }
};
