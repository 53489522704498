/* @ngInject */
module.exports = function ($scope, $filter, EnlightedAPIEnumsSvc, $q, EnlightedAPISvc, BannerSvc, $rootScope, $translate, EnlightedAPIErrorSvc) {
  var

  vm = this,

  isInit = false,

  orgId,

  I18N_KEYS = [
    'RESTORE_USER_SUCCESS',
    'UPDATE_USER_SUCCESS',
    'UPDATE_USER_FAILURE'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.apiUser    = undefined;
  vm.buildings  = [];
  vm.cachedUser = undefined;
  vm.countries  = [];
  vm.curUser    = undefined;
  vm.infoForm   = {};
  vm.isLoaded   = false;
  vm.locales    = [];
  vm.infoOpts   = { disableCountry: true };

  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('my-account-info.ctrl: activate');

    $scope.$on('USERS_INFORMATION_TAB_SELECTED', function (evt, args) {
      if ((args.op === 'SELECT') && (! isInit)) {
        init(args.user);
      }
    });
  }

  function init(user) {
    var promises = [];

    console.log('my-account-info.ctrl: init - ', user);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    orgId = user.org;
    vm.apiUser = angular.copy(user);

    promises.push(EnlightedAPIEnumsSvc.getEnum('locale'));
    promises.push(EnlightedAPIEnumsSvc.getEnum('country'));
    promises.push(EnlightedAPIEnumsSvc.getEnum('facility_type'));
    promises.push(EnlightedAPISvc.Facility.getAllByOrg(orgId));

    $q.all(promises)
      .then(function (resolutions) {
        vm.locales = processLocales(resolutions[0]);
        vm.countries = processCountries(resolutions[1]);
        
        var facilityTypeMap = _.keyBy(resolutions[2], function(facilityType) { return facilityType.val; });
        vm.buildings = processFacilities(resolutions[3].objs, facilityTypeMap);

        vm.curUser = createViewUser(vm.apiUser, vm.countries);
        vm.cachedUser = angular.copy(vm.curUser);
        vm.isLoaded = true;
      });
    
    isInit = true;
  }

  function createEmptyAddress() {
    return {
      lines: []
    };
  }

  function createViewUser(apiUser, countries) {
    var viewUser = {}, address;

    viewUser.firstname = apiUser.firstname;
    viewUser.lastname = apiUser.lastname;
    viewUser.office = apiUser.facility;
    viewUser.locale = apiUser.locale;

    address = (angular.isArray(apiUser.addresses)) ? apiUser.addresses[0] : createEmptyAddress();

    if (address) {
      viewUser.country = _.find(countries, { val: address.country });
    }

    viewUser.address = address;

    return viewUser;
  }

  function processCountries(enums) {
    var countries = [], dietCountry;
    angular.forEach(enums, function (country) {
      dietCountry = {
        val: country.val,
        display: $filter('localize')(country.names)
      };

      countries.push(dietCountry);
    });

    return countries;
  }

  function processFacilities(facilities, facilityTypes) {
    return _.filter(facilities, function (facility) {
      return facilityTypes[facility.type].floors;
    });
  }

  function processLocales(enums) {
    var locales = [], dietLocale;

    locales.push({});

    angular.forEach(enums, function (locale) {
      dietLocale = {
        val: locale.val,
        display: locale.lname
      };

      locales.push(dietLocale);
    });

    return locales;
  }

  function restore() {
    vm.curUser = angular.copy(vm.cachedUser);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_USER_SUCCESS
    });
  }

  function save() {
    vm.apiUser = updateAPIUser(vm.apiUser, vm.curUser);
    console.log('my-account-info.ctrl: save - ', vm.apiUser);

    EnlightedAPISvc.User.update(vm.apiUser)
      .then(function (userRsp) {
        angular.extend(vm.apiUser, userRsp.obj);
        vm.curUser = createViewUser(vm.apiUser, vm.countries);
        vm.cachedUser = angular.copy(vm.curUser);

        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          el: '#content',
          autoClose: true,
          content: DICTIONARY.UPDATE_USER_SUCCESS
        });

      vm.infoForm.$setPristine();
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.UPDATE_USER_FAILURE, error)
        });
      });
  }

  function updateAPIUser(apiUser, curUser) {
    apiUser.firstname = curUser.firstname;
    apiUser.lastname = curUser.lastname;
    apiUser.facility = curUser.office;

    apiUser.locale = curUser.locale || undefined;

    apiUser.addresses = apiUser.addresses || [];

    var address = curUser.address;
    address.country = curUser.country.val;
    address.type = 'work';

    apiUser.addresses[0] = address;

    return apiUser;
  }
};
