/* @ngInject */
module.exports = function ($scope, MAXLENGTH) {
  var

  vm = this;

  //============= MODELS ============//
  vm.NUMBER_REGEX = /^\d+$/;
  

  //============= FUNCTIONS ============//

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    $scope.$watch(function () {
      return $scope.infoForm;
    }, function (newVal, oldVal) {
      $scope.form = newVal;
    });
  }
};
