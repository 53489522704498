module.exports = function () {
  return {
    convertToAPILampConfig   : convertToAPILampConfig,
    convertToAPIWattages     : convertToAPIWattages,
    convertToDisplayWattages : convertToDisplayWattages,
    createDisplayLamps       : createDisplayLamps,
    createDisplayRegulator   : createDisplayRegulator,
    updateAPIRegulator       : updateAPIRegulator
  };

  function convertToAPILampConfig(dispConfig) {
    var apiConfig;

    apiConfig = angular.copy(dispConfig);

    // Parse the numbers
    apiConfig.voltage = parseInt(dispConfig.voltage);
    apiConfig.channels = parseInt(dispConfig.channels);
    apiConfig.factor = parseFloat(dispConfig.factor);
    apiConfig.num = parseInt(dispConfig.num);

    apiConfig.lamp = dispConfig.lamp.eid;
    apiConfig.lamp_manufacturer = dispConfig.lamp.manufacturer;
    apiConfig.lamp_part_number = dispConfig.lamp.part_number;

    apiConfig.wattages = convertToAPIWattages(dispConfig.wattages);

    return apiConfig;
  }

  function convertToAPIWattages(dispWattages) {
    var apiWattages = {};

    angular.forEach(dispWattages, function (w) {
      apiWattages[w.percentage] = parseFloat(w.wattage);
    });

    return apiWattages;
  }

  function convertToDisplayWattages(wattages) {
    var displayWattages = [], entry;

    angular.forEach(wattages, function (val, key) {
      entry = {
        percentage: parseFloat(key),
        wattage: val,
      };

      if (100 === parseFloat(key)) {
        entry.options = {
          disableDelete: true,
          disablePercentage: true
        };
      }

      displayWattages.push(entry);
    });

    return displayWattages;
  }

  function createDisplayLamps(apiLamps) {
    var displayLamps = [];

    angular.forEach(apiLamps, function (lamp) {
      displayLamps.push({
        eid: lamp.eid,
        display: lamp.parent + " " + lamp.val,
        manufacturer: lamp.parent,
        part_number: lamp.val
      });
    });

    return displayLamps;
  }

  function createDisplayRegulator(apiObj) {
    return {
      manufacturer: apiObj.parent,
      part_number : apiObj.val,
      dname : (apiObj.names) ? apiObj.names[''] : undefined,
      ddescription : (apiObj.descriptions) ? apiObj.descriptions[''] : undefined,

      channels : apiObj.channels,
      metering: apiObj.metering
    };
  }

  function updateAPIRegulator(apiObj, dispObj) {
    apiObj.parent = dispObj.manufacturer;
    apiObj.val = dispObj.part_number;

    apiObj.names = apiObj.names || {};
    apiObj.names[''] = dispObj.dname;

    apiObj.descriptions = apiObj.descriptions || {};
    apiObj.descriptions[''] = dispObj.ddescription;
    
    apiObj.channels = parseInt(dispObj.channels);
    apiObj.metering = (dispObj.metering !== undefined) ? dispObj.metering : false;

    return apiObj;
  }
};
