module.exports = (function() {
  'use strict';

  angular.module('enl-create-org', [
    'ngSanitize',
    'pascalprecht.translate',
    'ngMaterial',
    'ngMessages',
    'md-steppers',
    'ngAnimate',
    'ui.router',
    // 'angular-spinkit',
    // 'enl-org-templates',
    'ngEnlighted'])

    /* App Config */
    .config(require('./create-org-config'))

    /* Register Controllers */
    .controller('CreateOrgCtrl', require('./create-org.ctrl'))
    .controller('OrganizationStepCtrl', require('./organization/organization_step.ctrl'))
    .controller('IdpStepCtrl', require('./idp/idp_step.ctrl'))
    .controller('AdminStepCtrl', require('./administrator/administrator_step.ctrl'))
    .controller('OrganizationTypesStepCtrl', require('./organization/organization_types_step.ctrl'))
    .controller('AppsStepCtrl', require('./organization/applications_step.ctrl'));

    /* Register Services */

    /* Register Filters */

    /* Register Directives */
})();

