/* @ngInject */
module.exports = function ($stateParams, $scope, $state, $rootScope, ConfirmDialogSvc, EnlightedAPISvc, $filter, BannerSvc, $translate, $mdDialog, EnlightedAPIErrorSvc) {

  var vm = this,
    dongleId;

  //============= MODELS ============//
  vm.curDongle = undefined;
  vm.isLoaded = false;
  vm.doglNoOrg = false;
  vm.originalDongle = undefined;
  vm.dongleTitleName = '';
  vm.tabIndex = 0;
  vm.breadcrumbLabel = undefined;
  $scope.dongleId = undefined;
  //============= FUNCTIONS ============//
  vm.onSelectInfoTab = onSelectInfoTab;
  vm.onSelectNotesTab = onSelectNotesTab;
  vm.showConfirmDelete = showConfirmDelete;
  vm.deleteDongle = deleteDongle;
  vm.changeStatus = changeStatus;
  vm.changeOwner = changeOwner;
  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('dongle-detail.ctrl: activiate');
    init();
  }

  function init() {
    console.log('dongle-detail.ctrl: initialized');
    dongleId = $stateParams.dongleId;
    $scope.dongleId = $stateParams.dongleId;
    console.log('current dongle id' + dongleId);

    EnlightedAPISvc.Dongle.get(dongleId, false, true).then(function (dongleRsp) {
      vm.originalDongle = dongleRsp.obj;
      vm.curDongle = angular.copy(dongleRsp.obj);


      vm.dongleTitleName = vm.originalDongle.mac.pcode + ' ' + $filter('formatMAC')(vm.originalDongle.mac.address);
      vm.dongleName = vm.dongleTitleName;
      vm.breadcrumbLabel = vm.dongleTitleName;
      vm.isLoaded = true;


      //disable note if there is no org owner
      if(!dongleRsp.obj.org_owner){
         vm.doglNoOrg = true;
      }
    });

  }

  function broadcastSelection(tabname) {
    $scope.$broadcast('DONGLES_' + tabname + '_TAB_SELECTED', {dongle: vm.curDongle});
  }

  function deleteDongle() {
    console.log('deleting dongle');

    EnlightedAPISvc.Dongle.delete(vm.curDongle.gid)
      .then(function() {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('DELETE_DONGLE_SUCCESS', { donglename: vm.dongleName }),
          el:'#content'
        });
        $state.go('dongles.list');
      }, function(error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('DELETE_DONGLE_FAILURE'), error),
          el:'#content'
        });
      });
  }

  function onSelectInfoTab() {
    broadcastSelection('INFORMATION');
  }

  function onSelectNotesTab() {
    var notesObject = angular.copy(vm.curDongle);
    $scope.$broadcast('NOTES_TAB_SELECTED', {object: notesObject});
  }

  function showConfirmDelete(ev) {
    var

      objectType = $translate.instant('DONGLE_OBJECT_TYPE'),

      opts = {
        ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
        title: $translate.instant('CONFIRM_DELETE'),
        message: $translate.instant('CONFIRM_DELETE_MESSAGE', {objectType: objectType})
      };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        deleteDongle();
      });
  }

  function changeStatus(event) {
    $mdDialog.show({
      locals: {
        // pass dongle vm to md-dialog
        dongleVm: vm.curDongle
      },
      clickOutsideToClose: true,
      controller: 'DoglChstatsCtrl',
      controllerAs: 'ctrl',
      focusOnOpen: false,
      targetEvent: event,
      templateUrl: 'app-container/components/global-settings/dongle/chstats/change-state-dialog.tpl.html'
    }).then(function () {
      init();
    });
  }

  function changeOwner(event) {
    $mdDialog.show({
      locals: {
        // pass dongle vm to md-dialog
        dongleVm: vm.curDongle
      },
      clickOutsideToClose: true,
      controller: 'DoglChownCtrl',
      controllerAs: 'ctrl',
      focusOnOpen: false,
      targetEvent: event,
      templateUrl: 'app-container/components/global-settings/dongle/chown/change-owner-dialog.tpl.html'
    }).then(function () {
      init();
    });
  }
};
