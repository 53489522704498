/* @ngInject */
module.exports = function() {
  return {
    restrict: 'E',
    scope: {
      items       : '=',
      options     : '=',
      onItemClick : '&',
      addFn       : '&',
      deleteFn    : '&',
      kind        : '@'
    },
    templateUrl: 'app-container/components/global-settings/ops/regulator/list/regulator-list-table.template.html',
    controller: 'RegulatorListDirCtrl as listDirCtrl'
  };
};
