/* @ngInject */
module.exports = function ($scope, EnlightedAPISvc, $translate, $q, $state) {
  var

  vm = this,

  I18N_KEYS = [
    'DT_PAGE',
    'DT_ROWS_PER_PAGE',
    'DT_OF'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.isLoaded = false;
  vm.enums = [];
  vm.filter = {};
  vm.query = {};

  //============= FUNCTIONS ============//
  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    var promises = [];

    console.log('settings list.ctrl: activate');

    angular.forEach(I18N_KEYS, function (key) {
      promises.push($translate(key));
    });

    $q.all(promises)
      .then(function (translations) {
        angular.forEach(translations, function (translation, index) {
          DICTIONARY[I18N_KEYS[index]] = translation;
        });

        return $q.when();
      })
      .finally(function () {
        var enumList, displayList = [];

        vm.query = {
          order: 'name',
          limit: 15,
          page: 1,
          label: {
            page: DICTIONARY.DT_PAGE,
            rowsPerPage: DICTIONARY.DT_ROWS_PER_PAGE,
            of: DICTIONARY.DT_OF
          }
        };

        enumList = EnlightedAPI.Enums.getAll('system');

        angular.forEach(enumList, function (enumType) {
          displayList.push({
            val: enumType,
            name: $translate.instant(enumType)
          });
        });

        vm.enums = displayList;

        vm.isLoaded = true;
      });
  }
};
