/* @ngInject */
module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      obj : '=',
      form    : '=',
      mlist : '=',
      plist : '=',
      refresh : '='
    },

    templateUrl: 'app-container/components/global-settings/mdevice/directive/mdevice-form.tpl.html',
    controller: 'MdeviceInfoDirCtrl as InfoCtrl'
  };
};
