/* @ngInject */
module.exports = function ($scope, $translate, $q, ConfirmDialogSvc, EnlightedAPISvc) {
  var

    vm = this,

    I18N_KEYS = [
      'DT_PAGE',
      'DT_ROWS_PER_PAGE',
      'DT_OF'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.filter     = {};
  vm.query      = {};
  vm.selected   = [];
  vm.tableTitle = undefined;


  //============= FUNCTIONS ============//
  vm.addEnum     = addEnum;
  vm.deleteEnums = deleteEnums;
  vm.onItemClick = onItemClick;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    var promises = [];

    vm.query = {
      order: 'name',
      limit: 15,
      page: 1
    };

    vm.tableTitle = $scope.options.title;

    angular.forEach(I18N_KEYS, function (key) {
      promises.push($translate(key));
    });

    $q.all(promises)
      .then(function (translations) {
        angular.forEach(translations, function (translation, index) {
          DICTIONARY[I18N_KEYS[index]] = translation;
        });

        return $q.when();
      })
      .finally(function () {
        vm.query.label = {
          page: DICTIONARY.DT_PAGE,
          rowsPerPage: DICTIONARY.DT_ROWS_PER_PAGE,
          of: DICTIONARY.DT_OF
        };
      });
  }

  function addEnum() {
    console.log('contact_type-list.directive.ctrl: addEnum');
    if (typeof $scope.addFn() === 'function') {
      $scope.addFn()();
    }
  }

  function deleteEnums(selected, evt) {
    console.log('contact_type-list.directive.ctrl: deleteEnums');
    if (typeof $scope.deleteFn() === 'function') {
      $scope.deleteFn()(selected, evt)
        .then(function () {
          vm.selected = [];
        });
    }
  }

  function onItemClick(enumObj) {
    if (typeof $scope.onItemClick() === 'function') {
      $scope.onItemClick()(enumObj);
    }
  }
};
