module.exports = (function() {
  'use strict';

  // These are provided via NPM because they play nicer with browserify to expose the library as a global
  var vendorList = require('./vendor-list');

  for (var i = 0; i < vendorList.length; i++) {
    require(vendorList[i]);
  }

  require('../templates/templates');
  require('../components/create-org/create-org.module');
  require('../components/global-settings/global-settings.module');
  require('../components/my-account/my-account.module');

  window.d3 = require('d3');
  window.c3 = require('c3');

  angular.module('enlighted.app', [
    'ui.router',
    'ui.bootstrap',
    'ngSanitize',
    'pascalprecht.translate',
    'ngMaterial',
    'ngFileSaver',
    'ngEnlighted',
    'enl-container-templates',
    'lfNgMdFileInput',
    'angular-sortable-view',
    'enl-create-org',
    'enl-global-settings',
    'ncy-angular-breadcrumb',
    'enl-my-account',
    'enl-app-components'
    ])

    /* App Config */
    .config(require('./app-config'))

    /* Register Controllers */
    .controller('DashboardController', require('../components/dashboard/dashboard.ctrl'))
    .controller('AppViewController', require('../components/appview/appview.ctrl'))
    .controller('BaseAppMainController', require('../components/base/base.ctrl'))
    .controller('UpdateToastCtrl', require('../components/base/update-toast.ctrl'))

    /* Register Services */
    .factory('ContainerNavigationSvc', require('../components/base/container-navigation.svc'))
    .factory('EnlUpdateSvc', require('../components/base/update.svc'))

    /* Register Directives */
    .directive('angledNavbar', require('../components/header/angled-navbar.directive'));
})();
