/* @ngInject */
module.exports = function($scope,EnlightedAPIEnumsSvc,EnlightedAPISvc,$timeout, MAXLENGTH) {
  var vm = this;

  //============= MODELS ============//
  

  $timeout(function(){
    vm.units = EnlightedAPIEnumsSvc.getMeasuringUnit().sort();
  },0);
  EnlightedAPISvc.manufacturer.getAll().then(function(res){
    vm.manufacturers = res.enums;
  });
  EnlightedAPISvc.lamp_type.getAll().then(function(res){
    vm.lamp_types = res.enums;
  });

  //============= FUNCTIONS ============//

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    $scope.$watch(function () {
      return $scope.lampInfoForm;
    }, function (newVal, oldVal) {
      $scope.form = newVal;
    });
  }
};
