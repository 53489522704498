/* @ngInject */
module.exports = function ($state) {
  var

  APPS = {
    ORG_MGMT: 'org_mgmt'
  };

  return {
    APPS          : APPS,
    goToDashboard : goToDashboard,
    goToApp       : goToApp
  };

  function goToApp(orgId, locale, appName, opts) {
    $state.go('appview', {
      appId : appName,
      orgId : orgId,
      locale: locale,
      opts  : opts
    });
  }

  function goToDashboard(orgId, locale) {
    $state.go('dashboard', {
      orgId: orgId,
      locale: locale
    });
  }
};
