/* @ngInject */
module.exports = function($scope, $rootScope, $state, $stateParams, localizeFilter, $http, $q, ContainerNavigationSvc, $timeout, EnlightedAPISvc, EnlUpdateSvc, $document) {

  var

  vm = this,

  menuTemplate = [],

  hasRegisteredCb = false,

  i18n = {},

  needsUpdate;

  //============= MODELS ============//
  vm.iframe = null;
  vm.isLoaded = false;
  vm.app = null;

  //============= FUNCTIONS ============//
  vm.updateApp = updateApp;

  activate();

  //============= FN DEFINITIONS ============//

  function activate() {
    console.log('appview.ctrl: activate - stateParams', $stateParams);

    needsUpdate = EnlUpdateSvc.appNeedUpdate($stateParams.appId);
    console.log('appview.ctrl: activate - does app need update, ' + needsUpdate);

    if (!hasRegisteredCb) {
      EnlightedAPISvc.Mailman.register('appInit', handleAppInit);
      hasRegisteredCb = true;
    }

    if ($scope.init && $state.is('appview')) {
      updateApp();
    }

    $scope.$watch(function () {
      return $scope.init;
    }, function (newVal, oldVal) {
      if (newVal) {
        init();
      }
    });

    $scope.$on('LANGUAGE_UPDATE', function(evt, args) {
      if (vm.iframe && vm.app) {
        EnlightedAPISvc.Mailman.sendLanguageUpdate(args.langCode, vm.app.ui_url);
      }
    });

    $scope.$on('ORGANIZATION_UPDATE', function () {
      if (vm.iframe && vm.app) {
        EnlightedAPISvc.Mailman.sendOrganizationUpdate($scope.$parent.currentOrg.gid, vm.app.ui_url);
      }
    });
  }

  function init() {
    console.log('appview.ctrl: init');

    if ($stateParams.orgId) {
      console.log('appview.ctrl: init - orgId query param found, setting current org');
      $scope.setCurrentOrg($stateParams.orgId);
    }

    console.log('appview.ctrl: init - app id=' + $stateParams.appId);
    updateApp();
  }

  function getIframe() {
    return document.getElementById('app-view-iframe');
  }

  function updateApp() {
    vm.app = getOrgAppByValue($stateParams.appId, $scope.currentApps);

    // If the app being accessed is not provisioned for this org, redirect to dashboard
    if (!vm.app) {
      ContainerNavigationSvc.goToDashboard($scope.currentOrg.gid, $scope.selectedLanguage.val);
    }

    getAppMenu(vm.app.ui_url).finally(function () {
      $scope.currentApp.title = localizeFilter(vm.app.names);
      //Hardcoded for now. Need to populate menu items based on menu json for this app
      $scope.title = localizeFilter(vm.app.names);
      vm.iframe = getIframe();
      vm.iframe.src = buildAppUrl(vm.app.ui_url);
    });
  }

  function buildAppUrl(url) {
    if (window.enl && window.enl.config) {
      if (url[url.length - 1] !== '/') {
        url = url.concat('/');
      }

      url = url.concat('?');
      var first = true;
      angular.forEach(window.enl.config, function (value, key) {
        if (!first) {
          url = url.concat('&');
        }

        url = url.concat(key, '=', value);
        first = false;
      });
    }

    var character = (url.indexOf('?') > -1) ? '&' : '?';
    url = url.concat(character, 'origin=', encodeURIComponent(window.location.origin));

    url = url.concat('&', 'orgId=', $scope.currentOrg.gid)
      .concat('&', 'locale=', $scope.selectedLanguage.val);

    return url;
  }

  function getOrgAppByValue(appTypeVal, apps) {
    var foundApp;
    angular.forEach(apps, function(app, index) {
      if (app.val === appTypeVal) {
        foundApp = app;
        return;
      }
    });

    return foundApp;
  }

  function handleAppInit() {
    console.log('appview.ctrl: handleAppInit, needsUpdate - ' + needsUpdate);

    if (!needsUpdate) {
      $timeout(function () {
        vm.isLoaded = true;
      }, 1000);
    } else {
      console.log('appview.ctrl: handleAppInit - reloading iframe');
      $timeout(function () {
        try {
          vm.iframe.contentWindow.location.reload(true);
        } catch (e) {
          console.error(e, 'Error attempting to force reload the iframe');
          vm.isLoaded = true;
          $scope.$apply();
        }
      }, 0);
      needsUpdate = false;
      console.log('appview.ctrl: handleAppInit - updating local storage');
      EnlUpdateSvc.updateApp($stateParams.appId, !needsUpdate);
    }
  }

  function translateMenu(menuList, translationTable) {
    var translatedMenu = [], currMenu = {};

    if (!translationTable) {
      return translatedMenu;
    }

    for (var i = 0; i < menuList.length; i++) {
      currMenu = {};
      currMenu.title = translationTable[menuList[i].title] || menuList[i].title;
      currMenu.action = menuList[i].action;

      if (menuList[i].menus && menuList[i].menus.length > 0) {
        translateMenu(menuList[i].menus, translationTable);
      }

      translatedMenu.push(currMenu);
    }

    return translatedMenu;
  }

  function getAppMenu(appUrl) {
    var
    menuUrl = appUrl + '/menu.json',
    deferred = $q.defer();

    $http.get(menuUrl).then(function (rsp) {
      console.log('menu.json rsp');
      console.dir(rsp);
      if (rsp.status === 200) {
        // $scope.$parent.menus = rsp.data.menus;
        menuTemplate = rsp.data.menus;

        // Get the translations
        i18n = rsp.data.i18n;

        // Render menus in language
        console.log('selectedLanguage is ' + $scope.selectedLanguage.val);
        console.dir($scope.selectedLanguage);
        console.dir(i18n);
        $scope.$parent.menus = translateMenu(menuTemplate, i18n[$scope.selectedLanguage.val]);
      }
      deferred.resolve();
    }, function () {
      $scope.$parent.menus = [];
      deferred.reject();
    });

    return deferred.promise;
  }
};
