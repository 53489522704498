/* @ngInject */
module.exports = function ($scope, EnlightedAPIEnumsSvc, $q, RegulatorSvc) {
  var

  vm = this;

  //============= MODELS ============//
  vm.infoForm             = {};
  vm.manufacturers        = undefined;
  vm.newBallast            = undefined;

  //============= FUNCTIONS ============//
  vm.nextStep = nextStep;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    $scope.$on('CREATE_BALLAST_ACTIVATE_INFO', init);

    if ($scope.createCtrl.selectedStep === 0) {
      init();
    }
  }

  function init() {
    var promises = [];

    promises.push(EnlightedAPIEnumsSvc.getEnum('manufacturer'));


    $q.all(promises)
      .then(function (resolutions) {
        vm.manufacturers = resolutions[0];

      }, function (error) {
        console.log('info_step.ctrl: error fetching data', error);
      });
  }

  function nextStep() {
    console.log('info_step.ctrl: nextStep -', vm.newBallast);

    RegulatorSvc.updateAPIRegulator($scope.step.data.ballast, vm.newBallast);

    console.log('info_step.ctrl: nextStep -', $scope.step.data);

    $scope.createCtrl.submitCurrentStep($scope.step);
  }
};
