/* @ngInject */
module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      enlEnum : '=',
      form    : '=',
      options : '=',
      enlMode : '@'
    },
    templateUrl: 'app-container/components/global-settings/ops/product-code/details/info-form.tpl.html',
    controller: 'ProductCodeInfoFormDirCtrl as enumInfoCtrl'
  };
};
