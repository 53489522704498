/* @ngInject */
module.exports = function (mdeviceVm, $mdDialog, $scope, $q, $translate, EnlightedAPISvc, EnlightedAPIEnumsSvc, ConfirmDialogSvc, BannerSvc, $rootScope,$timeout, EnlightedAPIErrorSvc) {

  var vm = this,
      I18N_KEYS = [
        'UPDATE_MDEVICE_SUCCESS',
        'UPDATE_MDEVICE_FAILURE'
      ],
      DICTIONARY = {};

  //============= MODELS ============//
  $timeout(function(){
    vm.mdeviceStates = EnlightedAPIEnumsSvc.getMdeviceStates();
  },0);
  vm.selectedState = undefined;
  vm.cancel = $mdDialog.cancel;
  vm.curmdevice = mdeviceVm;
  vm.changeState = changeState;
  vm.selState = mdeviceVm.state;
  //============= FUNCTIONS ============//

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('change-state.ctrl: activiate');
    console.log(vm.curmdevice);
    init();
  }

  function init() {
    console.log('change-state.ctrl: initialized');
    console.log(vm.curmdevice);
    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });

    });

  }

  function changeState() {
    var orgsel = vm.curmdevice.state; 
    vm.curmdevice.state = vm.selState;
    
    console.log('change-state.ctrl: save - ', vm.curmdevice);

    EnlightedAPISvc.ManufacturedDevice.update(vm.curmdevice)
      .then(function (mdeviceRsp) {
        console.log(mdeviceRsp);
        
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: DICTIONARY.UPDATE_MDEVICE_SUCCESS,
          el:'#content'
        });
        $mdDialog.hide();

      }, function (error) {
        vm.curmdevice.state = orgsel;
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.UPDATE_MDEVICE_FAILURE, error),
          el:'#content'
        });
        $mdDialog.cancel();
      });
  }
};
