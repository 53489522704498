/* @ngInject */
module.exports = function ($scope, $stateParams, $q, $translate, EnlightedAPIEnumsSvc, EnlightedAPISvc, BannerSvc, $rootScope, $state, ConfirmDialogSvc, localizeFilter, EnlightedAPIErrorSvc) {
  var

    vm = this,

    I18N_KEYS = [
      'CONFIRM_CANCEL_DIALOG',
      'CONFIRM_CANCEL',
      'CONFIRM_CANCEL_CREATE_MSG'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.newEnum  = {};
  vm.newEnum.kinds = [];
  vm.newEnum.org_types = [];
  vm.infoForm = {};
  vm.isLoaded = false;
  vm.options  = {};
  vm.options.editMode = false;
  vm.kind = undefined;

  vm.orgTypes = [];
  vm.availableOrgTypes = [];
  vm.selectedOrgTypes = [];
  //============= FUNCTIONS ============//
  vm.save              = save;
  vm.showConfirmCancel = showConfirmCancel;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops contact_type create.ctrl: activate ', $stateParams);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    $scope.enumType = vm.kind = 'contact_type';

    EnlightedAPISvc.organization_type.getAll().then(function(rsp) {
      vm.orgTypeVals = _.map(rsp.enums, 'val');
      angular.forEach(rsp.enums, function(item) {
        vm.orgTypes.push({
          id: item.val,
          display: localizeFilter(item.names)
        });
      });

      setDualColSelect();
      vm.isLoaded = true;
    });

  }

  function save() {
    var apiEnum = {};

    apiEnum = updateAPIEnum(apiEnum, vm.newEnum);


    console.log('Contact_Type-create.ctrl: save -', apiEnum);

    EnlightedAPISvc[vm.kind].create(apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('CREATE_SUCCESS', { val: vm.newEnum.val }),
          el: '#content'
        });

        $state.go('^.list', {enumId: vm.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_FAILURE', { val: vm.newEnum.val }), error),
          el: '#content'
        });
      });
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        $state.go('^.list');
      });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.dname;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.ddescription;
    apiEnumObj.org_types = enumObj.org_types;
    apiEnumObj.kinds = enumObj.kinds;
    apiEnumObj.kind = 'contact_type';
    return apiEnumObj;

  }

  function setDualColSelect(orgTypes) {

    vm.selectedOrgTypes = [];
    vm.availableOrgTypes = [];

    angular.forEach(orgTypes, function (val) {
      vm.selectedOrgTypes.push({
        id: val,
        val: val,
        display: _.find(vm.orgTypes, function(o) {
          return o.id === val;
        }).display,
        selected: false
      });
    });


    angular.forEach(_.difference(vm.orgTypeVals, orgTypes), function (val) {
      vm.availableOrgTypes.push({
        id: val,
        val: val,
        display: _.find(vm.orgTypes, function(o) {
          return o.id === val;
        }).display,
        selected: false
      });
    });

    vm.options.selectedOrgTypes = vm.selectedOrgTypes;
    vm.options.availableOrgTypes = vm.availableOrgTypes;
  }
};
