/* @ngInject */
module.exports = function ($scope, $filter, EnlightedAPIEnumsSvc, $q, EnlightedAPISvc, BannerSvc, $rootScope, $translate, $state, IdpSvc, EnlightedAPIErrorSvc, ViewOnly) {
  var

    vm = this,

    idpId,

    I18N_KEYS = [
      'RESTORE_IDP_SUCCESS',
      'UPDATE_IDP_SUCCESS',
      'UPDATE_IDP_FAILURE'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  // View Model
  vm.curIdp = undefined;
  vm.originalIdp = undefined;

  // API Model
  vm.idpAPIModel = undefined;
  vm.infoForm = {};
  vm.idpTypes = [];
  vm.providers = [];
  vm.orgs = [];
  vm.isLoaded = false;

  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('idp-info.ctrl: activate');

    $scope.$on('IDPS_INFORMATION_TAB_SELECTED', function (evt, args) {
      init(args.idp);
    });
  }

  function init(idp) {
    console.log('idp-info.ctrl: init - ', idp);
    vm.idpTypes = EnlightedAPIEnumsSvc.getIdpTypes();
    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });
    });

    EnlightedAPISvc.IdentityProvider.getAll().then(function(idpRsp) {
      vm.curIdp = angular.copy(idp);
      vm.originalIdp = angular.copy(vm.curIdp);
      vm.idpAPIModel = angular.copy(vm.curIdp);

      vm.providers = filterIdps(idpRsp.objs);
      return EnlightedAPISvc.Organization.getAll();
    }).then(function(orgsRsp) {
      var orgs = [{}];
      vm.orgs = orgs.concat(orgsRsp.objs);

      vm.isLoaded = true;
    });
  }

  function filterIdps(idpList) {
    var result = [];
    angular.forEach(idpList, function (idp) {
      if (typeof idp.org_owner === 'undefined') {
        result.push(idp);
      }
    });
    return result;
  }

  function restore() {
    vm.curIdp = angular.copy(vm.originalIdp);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_IDP_SUCCESS,
      el: '#content'
    });
  }


  function save() {
    vm.idpAPIModel = IdpSvc.prepareIdpAPIModel(vm.idpAPIModel, vm.curIdp);
    if (vm.idpAPIModel.org_owner == "") {
      vm.idpAPIModel.org_owner = null;
    }
    console.log('idp-info.ctrl: save - ', vm.idpAPIModel);

    EnlightedAPISvc.IdentityProvider.update(vm.idpAPIModel)
      .then(function (idpRsp) {
        vm.curIdp = idpRsp.obj;
        vm.originalIdp = angular.copy(idpRsp.obj);

        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: DICTIONARY.UPDATE_IDP_SUCCESS,
          el: '#content'
        });
        vm.infoForm.$setPristine();

      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.UPDATE_IDP_FAILURE, error),
          el: '#content'
        });
      });
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("identity providers form");
    } else {
      ViewOnly.delete("identity providers form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("identity providers form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("identity providers form");
  });
};
