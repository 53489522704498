/* @ngInject */
module.exports = function ($scope, EnlightedAPISvc, EnlightedAPIEnumsSvc, MAXLENGTH) {
  var vm = this;

  //============= MODELS ============//
  vm.svgProps = {};
  vm.units = EnlightedAPIEnumsSvc.getMeasuringUnit();


  //============= FUNCTIONS ============//
  vm.autogenerateSVG        = autogenerateSVG;
  vm.isAutogenerateDisabled = isAutogenerateDisabled;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('gang-box-info-form.directive.ctrl : options');
    console.log($scope.options);

    $scope.$watch(function () {
      return $scope.infoForm;
    }, function (newVal, oldVal) {
      $scope.form = newVal;
    });

    EnlightedAPISvc.Session.getSession()
      .then(function (res) {
        vm.svgProps = res.properties;
      });
  }

  function autogenerateSVG() {
    if (!$scope.kind && !EnlightedAPISvc.hasOwnProperty($scope.kind)) {
      return;
    }

    EnlightedAPISvc[$scope.kind].generateDefaultSVG($scope.enlEnum.width.width,
      $scope.enlEnum.height.width, 'IN', true)
      .then(function (rsp) {
        if (rsp && angular.isString(rsp)) {
          $scope.enlEnum.svg = rsp;
          $scope.infoForm.$setDirty();
        }
      })
      .catch(function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('SVG_AUTOGENERATE_ERROR'), error),
          el: '#content'
        });
      });
  }

  function isAutogenerateDisabled() {
    var result = (!angular.isUndefined($scope.enlEnum) && !angular.isUndefined($scope.enlEnum.width) && !angular.isUndefined($scope.enlEnum.width.width) &&
      !angular.isUndefined($scope.enlEnum.width.units) && !angular.isUndefined($scope.enlEnum.height) && !angular.isUndefined($scope.enlEnum.height.width) &&
      !angular.isUndefined($scope.enlEnum.height.units));

    return !result;
  }
};
