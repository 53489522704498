/* @ngInject */
module.exports = function ($scope, EnlightedAPISvc, EnlightedAPIEnumsSvc, $q, BannerSvc, $rootScope, $translate, RegulatorSvc, localizeFilter, ConfirmDialogSvc, EnlightedAPIErrorSvc) {
  var

  vm = this;

  //============= MODELS ============//
  vm.apiBallast           = undefined;
  vm.cachedConfigurations = [];
  vm.configurations       = [];
  vm.hasAsync             = false;
  vm.isLoaded             = false;
  vm.lamps                = [];
  vm.options              = {};

  //============= FUNCTIONS ============//
  vm.onConfigChange   = onConfigChange;

  activate();

  //============= FN DEFINITIONS ============//

  function activate() {
    $scope.$on('BALLAST_LAMP_CONFIGS_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    var promises = [];

    vm.isLoaded = false;

    promises.push(EnlightedAPISvc.ballast.getById(enumObj.eid));
    promises.push(EnlightedAPIEnumsSvc.getEnum('lamp'));

    $q.all(promises)
      .then(function (resolutions) {
        vm.apiBallast = resolutions[0].enum;
        vm.lamps = RegulatorSvc.createDisplayLamps(resolutions[1].filter(function (lamp) { return !lamp.led; }));
        vm.configurations = createDisplayConfigs(vm.apiBallast.lamps, resolutions[1]);
        vm.cachedConfigurations = angular.copy(vm.apiBallast.lamps);
        vm.options.noWattages = vm.apiBallast.metering;

        vm.isLoaded = true;
      }, function (error) {
        console.log('ballast-lamp-configs.ctrl: error fetching data', error);
      });
  }

  function createDisplayConfigs(apiConfigurations, lamps) {
    var displayConfigurations = [];

    angular.forEach(apiConfigurations, function (config) {
      var lamp = _.find(lamps, {eid: config.lamp}), xconfig;

      xconfig = angular.extend(config, {
        lamp_manufacturer: lamp.parent,
        lamp_part_number: lamp.val
      });

      displayConfigurations.push(xconfig);
    });

    return displayConfigurations;
  }

  function getFailureMessage(eventtype, affectedConfig) {
    return $translate.instant(eventtype.toUpperCase() + '_LAMP_CONFIG_FAILURE', { lamp: affectedConfig.lamp_manufacturer + ' ' + affectedConfig.lamp_part_number});
  }

  function getSuccessMessage(eventtype, affectedConfig) {
    return $translate.instant(eventtype.toUpperCase() + '_LAMP_CONFIG_SUCCESS', { lamp: affectedConfig.lamp_manufacturer + ' ' +  affectedConfig.lamp_part_number});
  }

  function onConfigChange(configurations, eventtype, affectedConfig) {
    console.log('ballast-lamp-configs.ctrl: onConfigChange', configurations, eventtype, affectedConfig);

    vm.apiBallast.lamps = configurations;

    EnlightedAPISvc.ballast.update(vm.apiBallast)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: getSuccessMessage(eventtype, affectedConfig),
          el: '#content'
        });

        vm.cachedConfigurations = angular.copy(vm.configurations);
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(getFailureMessage(eventtype, affectedConfig), error),
          el: '#content'
        });

        vm.configurations = angular.copy(vm.cachedConfigurations);
      });
  }

  // $scope.$watch(function () {
  //   return vm.infoForm.$dirty;
  // }, function (newval, oldval) {
  //   if (newval) {
  //     ViewOnly.set("ballast lamp configuration form");
  //   } else {
  //     ViewOnly.delete("ballast lamp configuration form");
  //   }
  // });
  //
  // $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
  //   if (ViewOnly.exists("ballast lamp configuration form")) {
  //     restore();
  //   }
  // });
  //
  // $scope.$on("$destroy", function () {
  //   ViewOnly.delete("ballast lamp configuration form");
  // });
};
