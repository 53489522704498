/* @ngInject */
module.exports = function ($scope, $filter, $rootScope, EnlightedAPISvc, NotesWidgetSvc, BannerSvc, ConfirmDialogSvc, $q, EnlightedAPIErrorSvc) {
  var

  vm = this,

  I18N_KEYS = [
    'CREATE_NOTE_SUCCESS',
    'CREATE_NOTE_FAILURE',
    'UPDATE_NOTE_SUCCESS',
    'UPDATE_NOTE_FAILURE',
    'NOTE_DELETE_SUCCESS',
    'NOTE_DELETE_FAILED',
    'CONFIRM_DELETE',
    'CONFIRM_DELETE_ARIA_LABEL',
    'NOTE_OBJECT_TYPE'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.curObject   = {};
  vm.notes       = [];
  vm.sessionUser = null;

  //============= FUNCTIONS ============//
  vm.save              = save;
  vm.showDeleteConfirm = showDeleteConfirm;
  vm.deleteNote        = deleteNote;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('notes.ctrl: activate(global)');

    $scope.$on('NOTES_TAB_SELECTED', function (evt, args) {
      init(args.object);
    });

    angular.forEach(I18N_KEYS, function (key) {
      DICTIONARY[key] = $filter('translate')(key);
    });
  }

  function getNotes() {
    console.log(vm.curObject);
    NotesWidgetSvc.getViewNotes(vm.curObject.gid, vm.curObject.org_owner).then(function (notes) {
      vm.notes = notes;
    });
  }

  function getSessionUser() {
    var deferred = $q.defer();

    EnlightedAPISvc.Session.getSession().then(function (rsp) {
      vm.sessionUser = rsp;
      deferred.resolve();
    }, function () {
      deferred.reject();
    });

    return deferred.promise;
  }

  function handleCreateNoteSuccess(rsp) {
    BannerSvc.show({
      type: 'success',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.CREATE_NOTE_SUCCESS,
      el: '#content'
    });

    // refetch notes
    getNotes();
  }

  function handleCreateNoteError(errRsp) {
    BannerSvc.show({
      type: 'error',
      scope: $rootScope,
      autoClose: false,
      content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.CREATE_NOTE_FAILURE, error),
      el: '#content'
    });
  }

  function handleUpdateNoteSuccess(rsp) {
    BannerSvc.show({
      type: 'success',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.UPDATE_NOTE_SUCCESS,
      el: '#content'
    });

    // refetch notes
    getNotes();
  }

  function handleUpdateNoteError(errRsp) {
    BannerSvc.show({
      type: 'error',
      scope: $rootScope,
      autoClose: false,
      content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.UPDATE_NOTE_FAILURE, error),
      el: '#content'
    });
  }

  function init(object) {
    vm.curObject = object;
    getSessionUser().finally(getNotes);
  }

  function save(currNote) {
    var note;

    console.log(currNote);


    if (currNote.gid) {
      console.log('update!');

      note = NotesWidgetSvc.getModelNote(currNote.gid);
      note.subject = currNote.subject;
      note.content = currNote.content;

      EnlightedAPISvc.Note.update(note).then(handleUpdateNoteSuccess, handleUpdateNoteError);
    } else {
      console.log('create new!');
      var curgidinfo = EnlightedAPI.GIDDecoder.decodeGID(vm.curObject.org_owner);
      console.log(vm.curObject);
      note = {
        kind: 'note',
        org: curgidinfo.org,
        obj: vm.curObject.gid,
        type: 'user',
        subject: currNote.subject,
        content: currNote.content,
        parent: currNote.parent
      };

      EnlightedAPISvc.Note.create(note).then(handleCreateNoteSuccess, handleCreateNoteError);
    }
  }

  function deleteNote(gid) {
    console.log(gid);
    console.log(vm.notes);

    EnlightedAPISvc.Note.delete(gid).then(function (rsp) {
      BannerSvc.show({
        scope: $rootScope,
        type: 'success',
        autoClose: true,
        content: DICTIONARY.NOTE_DELETE_SUCCESS,
        el: '#content'
      });

      vm.notes.splice(_.findIndex(vm.notes,function(o){ return o.gid == gid;}));

    }, function (error) {
      BannerSvc.show({
        scope: $rootScope,
        type: 'error',
        autoClose: false,
        content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.NOTE_DELETE_FAILED, error),
        el: '#content'
      });
    });
  }

  function showDeleteConfirm(gid, ev) {
    var

    objectType = DICTIONARY.NOTE_OBJECT_TYPE,

    opts = {
      ariaLabel: DICTIONARY.CONFIRM_DELETE_ARIA_LABEL,
      title: DICTIONARY.CONFIRM_DELETE,
      message: $filter('translate')('CONFIRM_DELETE_MESSAGE', {objectType: objectType})
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        vm.deleteNote(gid);
      });
  }
};
