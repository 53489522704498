var fakeAppData = require('../../../scripts/fake-app-data');

/* @ngInject */
module.exports = function ($scope, EnlightedAPIEnumsSvc, $filter) {
  var vm = this,

  isInit = false,

  preselectedApps = [
    'facility_mgmt',
    'project_mgmt',
    'org_mgmt'
  ];

  //============= MODELS ============//
  vm.apps = [];

  //============= FUNCTIONS ============//
  vm.appClicked = appClicked;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('applications_step.ctrl: activate');

    $scope.$on('CREATE_ORG_ACTIVATE_APPLICATIONS', function () {
      init();
    });

    if ($scope.coBaseCtrl.selectedStep === 1) {
      init();
    }
  }

  function appClicked(app) {

    console.log('applications_step.ctrl: appClicked ', $scope.step.data.org.apps);
    if (app.selected) {
      $scope.step.data.org.apps.push(app.id);
    } else {
      _.remove($scope.step.data.org.apps, function (appId) { return appId === app.id; });
    }
    console.log('applications_step.ctrl: appClicked after ', $scope.step.data.org.apps);
  }

  function init() {
    if (isInit) {
      return;
    }

    console.log('applications_step.ctrl: init');

    EnlightedAPIEnumsSvc.getEnum('app_type')
      .then(function (apps) {
        if (enl.bootstrap.getQueryParam('debug') === 'true') {
          vm.apps = massageApps(fakeAppData.enums);
        } else {
          vm.apps = massageApps(apps);
        }

        isInit = true;
      }, function () {
        // TODO: what to do when failed to get apps
      });
  }

  function massageApps(appsList) {
    var newList = [], newApp, isPreselected;

    angular.forEach(appsList, function (app) {
      isPreselected = _.includes(preselectedApps, app.val);

      newApp = {
        id: app.val,
        display: $filter('localize')(app.names),
        selected: isPreselected,
        locked: isPreselected
      };

      newList.push(newApp);

      if (isPreselected) {
        $scope.step.data.org.apps.push(newApp.id);
      }
    });

    return newList;
  }
};
