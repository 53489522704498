/* @ngInject */
module.exports = function ($scope, $translate, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, RegulatorSvc, ConfirmDialogSvc) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE',
      'CONFIRM_CANCEL',
      'CONFIRM_CANCEL',
      'CONFIRM_CANCEL_LAMP_CONFIG',
      'CONFIRM_DELETE_ARIA_LABEL',
      'CONFIRM_DELETE',
      'CONFIRM_DELETE_LAMP_CONFIG_MESSAGE_NO_ARGS'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.origEnumObj   = undefined;
  vm.enumObj       = undefined;
  vm.hasAsync      = false;
  vm.infoForm      = {};
  vm.isLoaded      = false;
  vm.wrapper       = {};
  vm.mode          = "list";
  var maxPowerWatch = "";

  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;
  vm.doEdit  = doEdit;
  vm.doAdd   = doAdd;
  vm.showCancelConfirm = showCancelConfirm;
  vm.showDeleteConfirm = showDeleteConfirm;
  vm.onWattagesChange = onWattagesChange;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops lamp config wattage.ctrl: activate ');
    $scope.$on('LAMP_CONFIG_WATTAGES_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function onWattagesChange(wattages, type, newObject) {

  }

  function showDeleteConfirm(config, index, ev) {
    var

    opts = {
      ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
      title: $translate.instant('CONFIRM_DELETE'),
      message: $translate.instant('CONFIRM_DELETE_LAMP_CONFIG_MESSAGE_NO_ARGS')
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        save(true, config);
      });
  }

  function watchMP () {
    maxPowerWatch = $scope.$watch("lampCfgWattageCtrl.curConfig.maxpower", function (newVal, oldVal) {
      if (typeof newVal === "number" && newVal > 0) {
        var hasHundred = containsHundred(vm.curConfig.wattages);
        if (!hasHundred) {
          vm.curConfig.wattages.push({
            percentage: 100,
            wattage: newVal,
            options: {
              disablePercentage: true,
              disableDelete: true,
              disableEdit: true
            }
          });
        } else {
          var index = containsHundred(vm.curConfig.wattages, true);
          if (index > -1) {
            vm.curConfig.wattages[index].wattage = newVal;
          }
        }
      }
    }, true);
  }

  function containsHundred(arr, getIndex) {
    var found = (getIndex ? -1 : false);
    angular.forEach(arr, function (obj, ind) {
      if (parseInt(obj.percentage) === 100) {
        found = (getIndex ? ind : true);
      }
    });
    return found;
  }

  function doEdit(wattage, voltage, $index) {
    vm.curConfig = {};
    vm.curConfig.wattages = RegulatorSvc.convertToDisplayWattages(wattage);
    vm.curConfig.wattageForm = {};
    vm.curConfig.origVoltage = voltage;
    angular.forEach(vm.curConfig.wattages, function (wattage) {
      if (parseInt(wattage.percentage) === 100) {
        vm.curConfig.voltage = voltage;
        vm.curConfig.maxpower = wattage.wattage;
      }
    });
    vm.mode = 'form';
    watchMP();
  }

  function doAdd() {
    vm.curConfig = {};
    vm.curConfig.wattages = [];
    vm.curConfig.origVoltage = -9999;
    vm.curConfig.wattageForm = {};
    vm.mode = 'form';
    watchMP();
  }

  function init(enumObj) {
    console.log('ops lamp config info.ctrl: init', enumObj);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    vm.enumObj = enumObj;

    vm.origEnumObj = angular.copy(enumObj);

    vm.enumObj.wattageMassaged = RegulatorSvc.convertToDisplayWattages(vm.enumObj.wattages);
    vm.isLoaded = true;

    createChartedValues();

    //console.log(vm.enumObj.wattageMassaged);
  }

  function createChartedValues() {
    angular.forEach(vm.enumObj.wattageMassaged, function (wattage, index) {
        vm.enumObj.wattageMassaged[index].chartedValues = [];
        angular.forEach(wattage.wattage, function (value, keys) {
          vm.enumObj.wattageMassaged[index].chartedValues.push({
            percentage: parseFloat(keys),
            wattage: value
          });
        });
    });
  }

  function restore() {
    console.log("RESTORE vm.enumObj WITH vm.origEnumObj ", "OBJECT MODEL: ", vm.enumObj, "ORIGINAL OBJECT:", vm.origEnumObj);
    vm.enumObj = angular.copy(vm.origEnumObj);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save(saveFromDelete, config) {

    vm.hasAsync = true;
    vm.apiEnum = (saveFromDelete ? removeWattage(vm.enumObj, config) : updateAPIEnum(vm.enumObj, vm.curConfig));

    console.log("DATA SENT:", vm.apiEnum);

    EnlightedAPISvc.lampcfg.update(vm.apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        console.log("RESPONSE AFTER SAVE", rsp.enum, vm.apiEnum);
        vm.apiEnum = rsp.enum;
        vm.enumObj = rsp.enum;

        vm.enumObj.wattageMassaged = RegulatorSvc.convertToDisplayWattages(vm.enumObj.wattages);

        vm.origEnumObj = angular.copy(vm.enumObj);

        vm.isLoaded = true;

        vm.mode = 'list';
        if (typeof maxPowerWatch === "function") {
          maxPowerWatch();
        }

        createChartedValues();

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.origEnumObj.val }), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  function removeWattage(enumObj, config) {
    if (enumObj.wattages.hasOwnProperty(config.percentage)) {
      delete enumObj.wattages[config.percentage];
    }
    return enumObj;
  }

  function updateAPIEnum(enumObj, curConfig) {
    var wattageListAsObject = {};
    angular.forEach(curConfig.wattages, function (w, index) {
      wattageListAsObject[w.percentage] = w.wattage;
    });

    if (enumObj.wattages.hasOwnProperty(curConfig.origVoltage)) {
      delete enumObj.wattages[curConfig.origVoltage];
    }

    enumObj.wattages[curConfig.voltage] = wattageListAsObject;

    return enumObj;
  }

  function showCancelConfirm(ev) {
    var

    opts = {
      ariaLabel: $translate.instant('CONFIRM_CANCEL'),
      title: $translate.instant('CONFIRM_CANCEL'),
      message: $translate.instant('CONFIRM_CANCEL_LAMP_CONFIG')
    };

    if (!vm.wattageForm.$pristine) {
      ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        vm.mode = 'list';
        maxPowerWatch();
      });
    } else {
      vm.mode = 'list';
      maxPowerWatch();
    }
  }
};
