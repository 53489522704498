/* @ngInject */
module.exports = function($scope, EnlightedAPIEnumsSvc, $filter, $q, EnlightedAPISvc) {
  var vm = this,

  I18N_KEYS = [
    'NO_ORGS_TO_DISPLAY',
    'SELECTED_PARTNERS',
    'AVAILABLE_PARTNERS'
  ],

  DICTIONARY = {},

  isInit = false;

  //============= MODELS ============//
  vm.availableOrgs = [];
  vm.dcmsOptions   = {};
  vm.orgtypes      = [];
  vm.orgs          = [];
  vm.selectedOrgs  = [];
  vm.lasttype      = undefined;
  vm.type          = undefined;

  //============= FUNCTIONS ============//
  vm.changeTypeSelection = changeTypeSelection;
  vm.submitCurrentStep = submitCurrentStep;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('organization_types_step.ctrl: activate');

    angular.forEach(I18N_KEYS, function (key) {
      DICTIONARY[key] = $filter('translate')(key);
    });

    $scope.$on('CREATE_ORG_ACTIVATE_ORGANIZATIONS', function () {
      init();
    });

    if ($scope.coBaseCtrl.selectedStep === 2) {
      init();
    }
  }

  function init() {
    if (isInit) {
      return;
    }

    console.log('organization_types_step.ctrl: init');

    EnlightedAPIEnumsSvc.getEnum('organization_type')
      .then(function (orgtypes) {
        var diettypes = [], type;

        angular.forEach(orgtypes, function (orgtype) {
          type = {
            val: orgtype.val,
            display: $filter('localize')(orgtype.names)
          };

          diettypes.push(type);
        });

        vm.orgtypes = diettypes;
        vm.type = diettypes[0].val;
        vm.lasttype = vm.type;

        isInit = true;

        return $q.resolve();
      }, function () { $q.reject(); })
      .finally(function () {
        // Get all organizations
        EnlightedAPISvc.Organization.getAll()
          .then(function (rsp) {
            // Massage the orgs array to fit the dual-col-multiselect data
            angular.forEach(rsp.objs, function (org) {
              vm.orgs.push({
                id: org.gid,
                display: org.name,
                types: org.types,
                selected: false
              });
            });

            vm.orgs = _.sortBy(vm.orgs, function (org) { return org.display; });

            vm.availableOrgs = filterByOrgType(vm.orgs, vm.type);

            vm.dcmsOptions = {
              columns: [{
                title: DICTIONARY.AVAILABLE_PARTNERS,
                list: vm.availableOrgs,
                emptyListText: DICTIONARY.NO_ORGS_TO_DISPLAY
              }, {
                title: DICTIONARY.SELECTED_PARTNERS,
                list: vm.selectedOrgs,
                emptyListText: DICTIONARY.NO_ORGS_TO_DISPLAY
              }]
            };
          }, function (error) {
            // TODO: what happens when can't get all orgs?
          });
      });
  }

  function changeTypeSelection(orgtype) {
    var availableOrgs;

    // Save selected orgs to $scope.step.data.org.orgs
    storeSelectedOrgs(vm.selectedOrgs, vm.lasttype);
    // selectedOrgIds = _.map(vm.selectedOrgs, 'id');
    // $scope.step.data.org.orgs[vm.lasttype] = selectedOrgIds;

    // Update reference to lasttype
    vm.lasttype = orgtype;

    // Check the step data for any orgs already selected for this orgtype
    vm.selectedOrgs = _.filter(vm.orgs, function (org) {
      if (!$scope.step.data.org.orgs || !$scope.step.data.org.orgs[orgtype]) {
        return false;
      }

      return _.includes($scope.step.data.org.orgs[orgtype], org.id);
    });

    resetSelections(vm.selectedOrgs);

    console.log('organization_types_step.ctrl: selectedOrgs ', vm.selectedOrgs);

    // Refilter available orgs based on orgtype and the orgs already selected
    availableOrgs = _.filter(vm.orgs, function (org) {
      return _.includes(org.types, orgtype);
    });

    resetSelections(availableOrgs);

    vm.availableOrgs = _.difference(availableOrgs, vm.selectedOrgs);
    console.log('organization_types_step.ctrl: availableOrgs ', vm.availableOrgs);

    vm.dcmsOptions.columns[0].list = vm.availableOrgs;
    vm.dcmsOptions.columns[1].list = vm.selectedOrgs;
  }

  function filterByOrgType(allorgs, orgtype) {
    return _.filter(allorgs, function(org) {
      return _.includes(org.types, orgtype);
    });
  }

  function resetSelections(orgs) {
    angular.forEach(orgs, function (org) { org.selected = false; });
  }

  function storeSelectedOrgs(selectedOrgs, orgtype) {
    var selectedOrgIds = _.map(selectedOrgs, 'id');
    $scope.step.data.org.orgs[orgtype] = selectedOrgIds;
  }

  function submitCurrentStep(step) {
    storeSelectedOrgs(vm.selectedOrgs, vm.lasttype);
    $scope.coBaseCtrl.submitCurrentStep(step);
  }
};
