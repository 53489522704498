/* @ngInject */
module.exports = function ($scope, $translate, $filter, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIEnumsSvc, EnlightedAPIErrorSvc, ViewOnly) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.apiEnum = undefined;
  vm.cachedEnumObj = undefined;
  vm.enumObj = undefined;
  vm.hasAsync = false;
  vm.infoForm = {};
  vm.isLoaded = false;
  vm.options = {};
  vm.allKinds = [];
  vm.availableObjKinds = [];
  vm.selectedObjKinds = [];
  vm.curkinds = [];
  vm.dirtyFlag = false;
  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops reason info.ctrl: activate ');

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });
    });
    $scope.$on('REASON_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('ops reason info.ctrl: init', enumObj);
    resetDualColSelect(enumObj.kinds);
    vm.apiEnum = enumObj;
    vm.enumObj = createEnumDisplay(enumObj);
    vm.cachedEnumObj = angular.copy(vm.enumObj);
    vm.isLoaded = true;
  }

  function createEnumDisplay(enumObj) {
    return {
      val: enumObj.val,
      dname: (enumObj.names) ? enumObj.names[''] : undefined,
      ddescription: (enumObj.descriptions) ? enumObj.descriptions[''] : undefined,
      subject: enumObj.subject,
      content: enumObj.content,
      kinds: enumObj.kinds
    };
  }

  function resetDualColSelect(objKinds) {
    vm.curkinds = [];
    vm.selectedObjKinds = [];
    vm.availableObjKinds = [];
    angular.forEach(objKinds, function(val) {
      vm.curkinds.push(val.toUpperCase());
    });
    angular.forEach(vm.curkinds, function (val) {
      vm.selectedObjKinds.push({
        id: val.toLowerCase(),
        val: val.toLowerCase(),
        name: $filter('translate')(val),
        display: $filter('translate')(val),
        selected: false,
        disable: true
      });
    });
    vm.allKinds = EnlightedAPIEnumsSvc.getReasonObjectKinds();
    angular.forEach(_.difference(vm.allKinds, vm.curkinds), function (val) {
      vm.availableObjKinds.push({
        id: val.toLowerCase(),
        val: val.toLowerCase(),
        name: $filter('translate')(val),
        display: $filter('translate')(val),
        selected: false
      });
    });

    vm.options.selectedObjKinds = vm.selectedObjKinds;
    vm.options.availableObjKinds = vm.availableObjKinds;
  }

  function restore() {
    vm.dirtyFlag = false;
    vm.enumObj = angular.copy(vm.cachedEnumObj);
    resetDualColSelect(vm.cachedEnumObj.kinds);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    vm.apiEnum = updateAPIEnum(vm.apiEnum, vm.enumObj);
    console.log('Reason-info.ctrl: save - ', vm.apiEnum);

    vm.hasAsync = true;

    EnlightedAPISvc[vm.apiEnum.kind].update(vm.apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', {val: vm.apiEnum.val}),
          el: '#content'
        });

        vm.enumObj = createEnumDisplay(rsp.enum);
        vm.cachedEnumObj = angular.copy(vm.enumObj);

        vm.infoForm.$setPristine();

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', {val: vm.cachedEnumObj.val}), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.dname;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.ddescription;
    apiEnumObj.subject = enumObj.subject;
    apiEnumObj.content = enumObj.content;
    apiEnumObj.kinds = enumObj.kinds;
    return apiEnumObj;
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("reason code form");
    } else {
      ViewOnly.delete("reason code form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("reason code form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("reason code form");
  });
};
