/* @ngInject */
module.exports = function ($scope, $q, $stateParams, $translate, EnlightedAPISvc, BannerSvc, $rootScope, $state, ConfirmDialogSvc, EnlightedAPIErrorSvc) {
  var

  vm = this,

  I18N_KEYS = [
    'CONFIRM_CANCEL_DIALOG',
    'CONFIRM_CANCEL',
    'CONFIRM_CANCEL_CREATE_MSG'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.enumObj  = {};
  vm.baseObj  = {};
  vm.infoForm = {};
  vm.isLoaded = false;
  vm.kind     = undefined;
  vm.options  = {};

  //============= FUNCTIONS ============//
  vm.save              = save;
  vm.showConfirmCancel = showConfirmCancel;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('system address_type create.ctrl: activate ', $stateParams);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    $scope.enumType = vm.kind = "address_type";

    vm.enumObj = {};

    $translate('ENUM_VAL_DISPLAY_' + vm.kind.toUpperCase())
      .then(function (translation) {
        vm.options.valDisplay = translation;
        vm.isLoaded = true;
      });
  }

  function save() {
    console.log(vm.enumObj);

    vm.baseObj = updateAPIEnum(vm.baseObj, vm.enumObj);
    vm.baseObj.kind = vm.kind;

    console.log('basic-create.ctrl: save -', vm.baseObj);

    EnlightedAPISvc[vm.kind].create(vm.baseObj)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('CREATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        $state.go('^.list', {enumId: vm.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_FAILURE', { val: vm.enumObj.val }), error),
          el: '#content'
        });
      });
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        $state.go('^.list');
      });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.dname;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.ddescription || "";
    apiEnumObj.kinds = [];
    angular.forEach(enumObj.kinds, function (kind, index) {
      if (typeof kind.label === "string") {
        apiEnumObj.kinds.push(kind.label);
      }
    });

    return apiEnumObj;
  }
};
