/* @ngInject */
module.exports = function ($scope, $q, $translate, EnlightedAPISvc, EnlightedAPIEnumsSvc, ConfirmDialogSvc, BannerSvc, $rootScope, $filter, $state, EnlightedAPIErrorSvc) {
  var vm = this,

    I18N_KEYS = [
      'IDENTITY_PROVIDERS',
      'DT_PAGE',
      'DT_ROWS_PER_PAGE',
      'DT_OF',
      'IDP_OBJECT_TYPE',
      'IDP_OBJECT_TYPE_PLURAL',
      'CONFIRM_DELETE',
      'CONFIRM_DELETE_ARIA_LABEL',
      'NONE'
    ],
    DICTIONARY = {};

  //============= MODELS ============//
  vm.idps = [];
  vm.isLoaded = false;
  vm.selected = [];
  vm.filter = {};
  vm.query = {};

  //============= FUNCTIONS ============//
  vm.createIdp = createIdp;
  vm.deleteIdps = deleteIdps;
  vm.showDeleteConfirm = showDeleteConfirm;

  activiate();

  //============= FN DEFINITIONS ============//

  function activiate() {
    console.log('idps.list.ctrl: activiate');

    init();
  }

  function init() {
    console.log('idps.list.ctrl: init');

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });
    });
    vm.query = {
      order: 'name',
      limit: 15,
      page: 1,
      label: {
        page: DICTIONARY.DT_PAGE,
        rowsPerPage: DICTIONARY.DT_ROWS_PER_PAGE,
        of: DICTIONARY.DT_OF
      }
    };


    EnlightedAPISvc.IdentityProvider.getAll(false, true)
      .then(function(idpRsp) {
        console.log('idp-list:ctrl idp response');
        console.log(idpRsp);
        var tableIdPs = [];
        angular.forEach(idpRsp.objs, function(idp) {
          tableIdPs.push({
            id: idp.gid,
            name: idp.name,
            domain: idp.domain,
            type: idp.kind.toUpperCase(),
            orgOwner: idp.references.org_owner ? idp.references.org_owner.name : $translate.instant('NONE'),
            provider: idp.references.provider ? idp.references.provider.name : ''
          });

        });

        vm.idps = tableIdPs;
        vm.isLoaded = true;

      });
  }


  function createIdp() {
    $state.go('settings.idps.create');
  }

  function deleteIdps(selectedItems) {
    var promises = [];
    console.log(selectedItems);
    console.log('idps-list.ctrl: deleting' + selectedItems);
    angular.forEach(selectedItems, function (item) {
      promises.push(EnlightedAPISvc.IdentityProvider.delete(item.id));
    });

    $q.all(promises).then(function () {
      // remove deleted idps from idp list
      vm.idps = _.difference(vm.idps, selectedItems);

      BannerSvc.show({
        scope: $rootScope,
        type: 'success',
        autoClose: true,
        content: $translate.instant('DELETE_SUCCESS_PLURAL', {objects: DICTIONARY.IDP_OBJECT_TYPE_PLURAL}),
        el: '#content'
      });

      vm.selected = [];
    }, function () {
      BannerSvc.show({
        scope: $rootScope,
        type: 'error',
        autoClose: false,
        content: $translate.instant('DELETE_FAILED_PLURAL', {objects: DICTIONARY.IDP_OBJECT_TYPE_PLURAL}),
        el: '#content'
      });
      vm.selected = [];
    });
  }

  function showDeleteConfirm(selected, ev) {
    var opts = {
        ariaLabel: DICTIONARY.CONFIRM_DELETE_ARIA_LABEL,
        title: DICTIONARY.CONFIRM_DELETE,
        message: (selected.length > 1) ?
          $translate.instant('CONFIRM_DELETE_MESSAGE_LIST_PLURAL') :
          $translate.instant('CONFIRM_DELETE_MESSAGE_LIST')
      };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        deleteIdps(vm.selected);
      });
  }


};
