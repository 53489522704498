/* @ngInject */
module.exports = function ($scope, $translate, ConfirmDialogSvc) {
  var

  vm = this;

  //============= MODELS ============//
  vm.editEntry    = undefined;
  vm.mode         = 'readonly';
  vm.restrictions = [];

  //============= FUNCTIONS ============//
  vm.doEdit            = doEdit;
  vm.onSave            = onSave;
  vm.showDeleteConfirm = showDeleteConfirm;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
  }

  function doEdit() {
    vm.mode = 'edit';
    vm.editEntry = angular.copy($scope.entry);
    vm.restrictions = $scope.restrictions.filter(function (o) { return o !== vm.editEntry.percentage; });
  }

  function onSave(entry) {
    console.log('wattage-entry.directive.ctrl: onSave - ', entry);
    vm.mode = 'readonly';

    if (typeof $scope.enlOnChange() === 'function') {
      $scope.enlOnChange()(entry, $scope.index);
    }
  }

  function showDeleteConfirm(ev) {
    var

    opts = {
      ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
      title: $translate.instant('CONFIRM_DELETE'),
      message: $translate.instant('CONFIRM_DELETE_WATTAGE_ENTRY_MESSAGE')
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        if (typeof $scope.delFn() === 'function') {
          $scope.delFn()($scope.entry, $scope.index);
        }
      });
  }
};
