/* @ngInject */
module.exports = function ($scope,localizeFilter,EnlightedAPIEnumsSvc, MAXLENGTH) {
  var vm = this;

  $scope.editMode = false;
  

  //============= MODELS ============//

  //============= FUNCTIONS ============//
  activate();


  //============= FN DEFINITIONS ============//
  function activate() {

    $scope.$watch(function () {
      return $scope.infoForm;
    }, function (newVal, oldVal) {
      $scope.form = newVal;
    });
    setupCB();



    $scope.$watch(function(){
      return $scope.person_cb;
    },function(){
      var temp = chkKindsPerson();
      if($scope.person_cb === true){
        if(temp === -1){
          $scope.obj.kinds.push("person");
        }
      }else{
        if(temp !== -1){
          $scope.obj.kinds.splice(temp,1);
        }
      }
    });

    $scope.$watch(function(){
      return $scope.facility_cb;
    },function(){
      var temp = chkKindsFacility();
      if($scope.facility_cb === true){
        if(temp === -1){
          $scope.obj.kinds.push("facility");
        }
      }else{
        if(temp !== -1){
          $scope.obj.kinds.splice(temp,1);
        }
      }
    });


    $scope.$watch(function(){
      return $scope.obj.kinds;
    },function(){
      setupCB();
    });


  }


  function chkKindsPerson(){
    return $scope.obj.kinds.indexOf("person");
  }
  function chkKindsFacility(){
    return $scope.obj.kinds.indexOf("facility");
  }

  function setupCB(){
    if(chkKindsPerson() !== -1){
      $scope.person_cb = true;
      $scope.person_disable = true;
      $scope.editMode = true;
    }else{
      $scope.person_cb = false;
      $scope.person_disable = false;
    }

    if(chkKindsFacility() !== -1){
      $scope.facility_cb = true;
      $scope.facility_disable = true;
      $scope.editMode = true;
    }else{
      $scope.facility_cb = false;
      $scope.facility_disable = false;
    }
  }
};
