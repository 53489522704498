/* @ngInject */
module.exports = function ($scope, EnlightedAPISvc, EnlightedAPIEnumsSvc, $q, BannerSvc, $rootScope, $translate, RegulatorSvc, EnlightedAPIErrorSvc, ViewOnly) {
  var

  vm = this,

  I18N_KEYS = [
    'RESTORE_SUCCESS'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.apiDriver            = undefined;
  vm.cachedDriver         = undefined;
  vm.curDriver            = {};
  vm.hasAsync             = false;
  vm.infoForm             = {};
  vm.isLoaded             = false;
  vm.manufacturers        = [];


  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;

  activate();

  //============= FN DEFINITIONS ============//

  function activate() {
    $scope.$on('DRIVER_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    var promises = [];

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    promises.push(EnlightedAPISvc.driver.getById(enumObj.eid));
    promises.push(EnlightedAPIEnumsSvc.getEnum('manufacturer'));


    $q.all(promises)
      .then(function (resolutions) {
        vm.apiDriver = resolutions[0].enum;
        vm.curDriver = RegulatorSvc.createDisplayRegulator(vm.apiDriver);
        vm.cachedDriver = angular.copy(vm.curDriver);

        vm.manufacturers = resolutions[1];

        vm.isLoaded = true;
      }, function (error) {
        console.log('driver-info.ctrl: error fetching data', error);
      });
  }

  function restore() {
    vm.curDriver = angular.copy(vm.cachedDriver);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    RegulatorSvc.updateAPIRegulator(vm.apiDriver, vm.curDriver);

    vm.hasAsync = true;

    EnlightedAPISvc.driver.update(vm.apiDriver)
      .then(function (rsp) {
        vm.apiDriver = rsp.enum;
        vm.curDriver = RegulatorSvc.createDisplayRegulator(vm.apiDriver);
        vm.cachedDriver = angular.copy(vm.curDriver);
        vm.infoForm.$setPristine();

        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', {val: vm.apiDriver.val}),
          el: '#content'
        });

        vm.hasAsync = false;
      }, function(error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', {val: vm.apiDriver.val}), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("driver form");
    } else {
      ViewOnly.delete("driver form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("driver form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("driver form");
  });
};
