/* @ngInject */
module.exports = function () {
  return {
    restrict: 'A',
    require: 'ngModel',

    link: function(scope, elem, attrs, ctrl) {
      ctrl.$validators.validid = function (modelValue, viewValue) {
        var regex = /^[A-Za-z][A-Za-z0-9_]*$/;

        if(typeof viewValue == 'undefined'){
          return true;
        }else if(viewValue.length !== 0 && viewValue.match(regex)){
          return true;
        }else{
          return false;
        }

      };
    }
  };
};
