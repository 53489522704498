/* @ngInject */
module.exports = function ($scope, $translate, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, ViewOnly) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.origEnumObj   = undefined;
  vm.enumObj       = undefined;
  vm.hasAsync      = false;
  vm.infoForm      = {};
  vm.isLoaded      = false;
  vm.wrapper       = {};
  vm.manufacturers = [];

  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops lamp config info.ctrl: activate ');
    console.log("LANGUAGE: ", $rootScope);
    $scope.$on('LAMP_CONFIG_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('ops lamp config info.ctrl: init', enumObj);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    EnlightedAPI.manufacturer.getAll().then(function (resp) {
      vm.manufacturers = [];
      angular.forEach(resp.enums, function (e) {
        vm.manufacturers.push(e);
      });
    });

    vm.apiEnum = enumObj;
    // transform to have app_type obj model
    vm.enumObj = createEnumDisplay(enumObj);
    // copy original object to retain information for restore
    vm.origEnumObj = angular.copy(vm.enumObj);
    vm.isLoaded = true;


  }

  function createEnumDisplay(enumObj) {
    return {
      manufacturer : enumObj.parent,
      val : enumObj.val,
      name : enumObj.names[''],
      description : (enumObj.descriptions) ? enumObj.descriptions[''] : undefined,
      controlchannels : enumObj.channels,
      factor : enumObj.factor,
      initiallummens : enumObj.initial_lumens,
      designlummens : enumObj.design_lumens,
      avginstreg : enumObj.life_ins_start,
      avgprogreg : enumObj.life_prog_start,
      coloridx : enumObj.cri,
      colortemp : enumObj.color_temp,
      color : enumObj.color,
      metering : enumObj.metering,
      eid : enumObj.eid
    };
  }

  function restore() {
    console.log("RESTORE vm.enumObj WITH vm.origEnumObj ", "OBJECT MODEL: ", vm.enumObj, "ORIGINAL OBJECT:", vm.origEnumObj);
    vm.enumObj = angular.copy(vm.origEnumObj);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    console.log('lamp config-info.ctrl: save - ', vm.enumObj);

    vm.hasAsync = true;
    vm.apiEnum = updateAPIEnum(vm.apiEnum, vm.enumObj);

    console.log("DATA SENT:", vm.apiEnum);

    EnlightedAPISvc.lampcfg.update(vm.apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        console.log("RESPONSE AFTER SAVE", rsp.enum, vm.apiEnum);
        vm.apiEnum = rsp.enum;

        vm.enumObj = createEnumDisplay(rsp.enum);
        // copy original object to retain information for restore
        vm.origEnumObj = angular.copy(vm.enumObj);

        vm.infoForm.$setPristine();

        vm.isLoaded = true;

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.origEnumObj.val }), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  function updateAPIEnum(apiEnum, enumObj) {
    apiEnum.eid = enumObj.eid;
    apiEnum.parent = enumObj.manufacturer;
    apiEnum.val = enumObj.val;
    apiEnum.descriptions = apiEnum.descriptions || {};
    apiEnum.descriptions[''] = enumObj.description || "";
    apiEnum.names = enumObj.names || {};
    apiEnum.names[''] = enumObj.name || "";
    apiEnum.channels = enumObj.controlchannels;
    apiEnum.factor = enumObj.factor;
    apiEnum.initial_lumens = enumObj.initiallummens;
    apiEnum.design_lumens = enumObj.designlummens;
    apiEnum.life_ins_start = enumObj.avginstreg;
    apiEnum.life_prog_start = enumObj.avgprogreg;
    apiEnum.cri = enumObj.coloridx;
    apiEnum.color_temp = enumObj.colortemp;
    apiEnum.color = enumObj.color;
    apiEnum.metering = enumObj.metering;
    apiEnum.parent = enumObj.manufacturer;

    return apiEnum;
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("lamp configuration form");
    } else {
      ViewOnly.delete("lamp configuration form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("lamp configuration form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("lamp configuration form");
  });
};
