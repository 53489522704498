/* @ngInject */
module.exports = function ($scope, MAXLENGTH) {
  var vm = this;

  //============= MODELS ============//
  

  //============= FUNCTIONS ============//

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('product-code-info-form.directive.ctrl : options');
    console.log($scope.options);

    $scope.$watch(function () {
      return $scope.infoForm;
    }, function (newVal, oldVal) {
      $scope.form = newVal;
    });


  }
};
