/* @ngInject */
module.exports = function ($scope) {
  var

  vm = this;

  //============= MODELS ============//
  vm.entryPercentages = [];
  vm.mode             = 'button';
  vm.newEntry         = {};

  //============= FUNCTIONS ============//
  vm.cancelCreate   = cancelCreate;
  vm.createNewEntry = createNewEntry;
  vm.doDelete       = doDelete;
  vm.onEntryChange  = onEntryChange;
  vm.showEditor     = showEditor;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {

    $scope.$watch(function () { return $scope.entries.length; }, function (newVal, oldVal) {
      console.log('wattage-widget.directive.ctrl: watch ', newVal, oldVal, $scope.entries);
      vm.entryPercentages = updateRestrictions($scope.entries);
    });
  }

  function cancelCreate() {
    vm.mode = 'button';
  }

  function createNewEntry(entry) {
    console.log('wattage-widget.directive.ctrl: createNewEntry - ', entry);
    $scope.entries.push(entry);
    vm.mode = 'button';
    notifyChange($scope.entries, 'add', entry);
  }

  function doDelete(entry, index) {
    console.log('wattage-widget.directive.ctrl: doDelete - ', entry, index);
    $scope.entries.splice(index, 1);
    notifyChange($scope.entries, 'delete', entry);
  }

  function notifyChange(entries, type, entry) {
    vm.entryPercentages = updateRestrictions(entries);
    if ($scope.enlOnChange && typeof $scope.enlOnChange() === 'function') {
      $scope.enlOnChange()(entries, type, entry);
    }
  }

  function onEntryChange(newEntry, index) {
    console.log('wattage-widget.directive.ctrl: onEntryChange - ', newEntry, index);
    $scope.entries[index] = newEntry;
    notifyChange($scope.entries, 'update', newEntry);
  }

  function showEditor() {
    vm.mode = 'editor';
    vm.newEntry = {};
  }

  function updateRestrictions(entries) {
    var restrictions = [];

    // Update the entryPercentages array
    if (angular.isArray(entries)) {
      restrictions = entries.map(function (entry) {
        return entry.percentage;
      });
    }

    return restrictions;
  }
};
