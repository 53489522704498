var fakeOrgData = require('../../scripts/fake-org-data');

function filterApps(appsList, currentOrg) {
  var filteredApps = [];

  angular.forEach(currentOrg.apps, function(allowedAppName, index) {
    angular.forEach(appsList.enums, function(app, index) {
      if (app.val === allowedAppName) {
        filteredApps.push(app);
      }
    });
  });

  return filteredApps;
}

/* @ngInject */
module.exports = function($scope, $state, $stateParams, $translate, $q, EnlightedAPISvc, $http, EnlightedAPIEnumsSvc, $rootScope, ContainerNavigationSvc, EnlUpdateSvc, $window, MAXLENGTH, NUMBER_LIMITS, REGEX_LIB, ViewOnly, ConfirmDialogSvc) {
  console.log('baseapp initialize');

  //============= MODELS ============//
  $rootScope.MAXLENGTH = MAXLENGTH;
  $rootScope.NUMBER_LIMITS = NUMBER_LIMITS;
  $rootScope.REGEX_LIB = REGEX_LIB;

  $scope.orgs = [];
  $scope.apps = [];
  $scope.locales = [];
  $scope.currentOrg = {};
  $scope.currentApps = [];
  $scope.currentApp = {};
  $scope.headerOptions = {
    showOrgDropdown: true,
    showAppsDropdown: true
  };
  $scope.init = false;
  // TODO: would need to fetch supported languages from somewhere
  $scope.languages = [{
    label: 'ENGLISH_LABEL',
    langKey: 'en'
  }, {
    label: 'SPANISH_LABEL',
    langKey: 'es'
  }];
  $scope.selectedLanguage = $scope.languages[0].langKey;
  $rootScope.global_language = $scope.selectedLanguage;
  $rootScope.version = '';

  $scope.applicationUrl = 'http://www.enlightedinc.com';
  $scope.affixed = 'top';
  $scope.search = {
    show: true,
    terms: ''
  };
  $scope.inverse = true;
  $scope.menus = []; // end menus

  $scope.item = '';
  $scope.styling = 'Inverse';
  $scope.searchDisplay = 'Visible';
  $scope.apps = [];

  //============= FUNCTIONS ============//
  $scope.setCurrentOrg = setCurrentOrg;

  activate();

  //============= FN DEFINITIONS ============//
  function setCurrentOrg(orgId) {
    console.log('base.ctrl: setCurrentOrg - ', orgId);
    var newCurOrg = _.find($scope.orgs.objs, { gid: orgId });
    if (newCurOrg) {
      updateCurrentOrg(newCurOrg);
    } else {
      updateCurrentOrg($scope.orgs.objs[0]);
    }
  }

  function updateCurrentOrg(newCurOrg) {
    $scope.currentOrg = newCurOrg;
    $scope.currentApps = filterApps($scope.apps, newCurOrg);
    EnlightedAPI.Mailman.sendOrganizationUpdate($scope.currentOrg.gid);
  }

  $scope.changeLanguage = function(language) {
    console.log('changing language');
    $scope.selectedLanguage = language;
    // lookup language based on label in $scope.languages
    // for (var i=0; i<$scope.languages.length; i++) {
    //   if ($scope.languages[i].langKey === language) {
    //     $translate.use($scope.languages[i].langKey);
    //     break;
    //   }
    // }
    var langKey = language.val || 'en';

    $translate.use(langKey);
    // TODO: need to update the server preference for this language change?

    $rootScope.global_language = langKey;
    $scope.$broadcast('LANGUAGE_UPDATE', { langCode: langKey });
  };

  $scope.openApp = function(app) {
    ContainerNavigationSvc.goToApp($scope.currentOrg.gid, $scope.selectedLanguage.val, app.val);
  };

  $scope.populateLocales = function(orgId) {
    var deferred = $q.defer(), loadLocale;

    EnlightedAPISvc.locale.getAll().then(function(data, textStatus, jqXHR) {
        var locales = [];
        angular.forEach(data.enums, function(locale, index) {
          var skinnyLocale = {};
          skinnyLocale.val = locale.val;
          skinnyLocale.label = EnlightedAPI.Localization.getLocalizedValue(locale.names, locale.val);
          locales.push(skinnyLocale);
        });

        $scope.locales = _.sortBy(locales, function (locale) { return locale.label; });

        loadLocale = $stateParams.locale || 'en';

        // Get the locale to load
        for (var i = 0; i < $scope.locales.length; i++) {
          if ($scope.locales[i].val === loadLocale) {
            $rootScope.global_language = $scope.locales[i].val;
            $scope.selectedLanguage = $scope.locales[i];
            $translate.use(loadLocale);
            break;
          }
        }

        deferred.resolve();
    });

    return deferred.promise;
  };

  $scope.searchfn = function() {
    alert('Attempting search on: "' + $scope.search.terms + '"');
  }; // searchfn

  $scope.navfn = function(action) {
    var iframe = document.getElementById('app-view-iframe');
    iframe.src = action;
  }; // end navfn

  $scope.changeOrg = function(org) {
    updateCurrentOrg(org);
    ContainerNavigationSvc.goToDashboard(org.gid, $scope.selectedLanguage.val);
    console.log('Changed org: New Current Org: ' + JSON.stringify(org));
  };

  $scope.toggleStyling = function() {
    $scope.inverse = !$scope.inverse;
    if (angular.equals($scope.inverse, true))
      $scope.styling = 'Inverse';
    else
      $scope.styling = 'Default';
  }; // end toggleStyling

  $scope.toggleSearchForm = function() {
    $scope.search.show = !$scope.search.show;
    if (angular.equals($scope.search.show, true))
      $scope.searchDisplay = 'Visible';
    else
      $scope.searchDisplay = 'Hidden';
  }; // end toggleSearchForm

  $scope.addMenu = function() {
    $scope.menus.push({
      title: "Added On The Fly!",
      action: "default"
    });
  }; // end test

  $scope.toggleAffixed = function() {
    switch ($scope.affixed) {
      case 'top':
        $scope.affixed = 'bottom';
        break;
      case 'bottom':
        $scope.affixed = 'none';
        break;
      case 'none':
        $scope.affixed = 'top';
        break;
    }
  }; // end toggleAffixed
  //End Directive related hardcodes

  // check for unsaved items
  $rootScope.$on('$stateChangeStart', function (evt, toState, stateParams) {
    if (ViewOnly.oneDirty()) {
      evt.preventDefault();
      var dirtyNames = ViewOnly.getDirtyNames();
      var opts = {
        ariaLabel: $translate.instant('CONFIRM_CHANGES_LOSE_ARIA_LABEL'),
        title: $translate.instant('CONFIRM_CHANGES_LOSE'),
        message: $translate.instant('CHANGES_LOSE', {names: dirtyNames.join(" and ")})
      };

      ConfirmDialogSvc.show(evt, opts).then(function () {
        ViewOnly.deleteAll();
        $state.go(toState, stateParams);
        // nothing should happen since you`re exiting the route
      });
    }
  });

  function checkForAppIcons(apps) {
    angular.forEach(apps, function (app) {
      console.log('checking icon for ' + app.val);
      console.dir(app);
      $http.get(app.ui_url + '/app-icon.svg').then(function (rsp) {
        console.log('icon exists for ' + app.val);
        app.hasIcon = true;
      }, function (error) {
        console.log('icon does not exist for ' + app.val);
        console.dir(error);
        app.hasIcon = false;
      });
    });
  }

  function handleViewChangeRequest(reqData) {
    console.log('base.ctrl: handleViewChangeRequest - ', reqData);

    if (reqData) {
      if (reqData.view === EnlightedAPI.Mailman.views.DASHBOARD) {
        ContainerNavigationSvc.goToDashboard((reqData) ? reqData.orgId : null, $scope.selectedLanguage.val);
      }
    }
  }

  function init() {
    EnlightedAPI.Mailman.register('viewChangeRequest', handleViewChangeRequest);

    EnlightedAPISvc.Session.getSession()
      .then(function(data, textStatus, jqXHR) {
        $scope.user = data;
        $rootScope.user = data.user.user;
        console.log('logged in!');
        $('.wrapper').show();

        EnlightedAPISvc.app_type.getAll().then(function(data, textStatus, jqXHR) {
          console.log('all apps');
          console.dir(data);

          EnlUpdateSvc.checkForUpdate(data.enums);

          if ($window.enl.bootstrap.getQueryParam('debug') === 'true') {
            $scope.apps = window.appData;
          } else {
            $scope.apps = data;
          }

          checkForAppIcons($scope.apps.enums);
          EnlightedAPIEnumsSvc.setEnum('app_type', angular.copy($scope.apps.enums));

          EnlightedAPISvc.Organization.getAll().then(function(data, textStatus, jqXHR) {
            console.log('all orgs');
            console.dir(data);

            // sort the orgs alphabetically
            var sortedOrgs = _.sortBy(data.objs, function (org) { return org.name; });
            data.objs = sortedOrgs;

           // $scope.orgs = fakeOrgData; // fake org data for now
            $scope.orgs = data;
            $scope.currentOrg = $scope.orgs.objs[0];
            EnlightedAPI.Mailman.sendOrganizationUpdate($scope.currentOrg.gid);
            $scope.populateLocales($scope.currentOrg.id).then(function () {
              $scope.currentApps = filterApps($scope.apps, $scope.currentOrg);
              $scope.init = true;
              console.log('broadcast bootstrap complete');
              $scope.$broadcast('BOOTSTRAP_COMPLETE');
            });
          });
        });
        //EnlightedAPI.app_type.getAllByOrg(gid)
      });

      $rootScope.version = $window.enl.config.version;
  }

  function activate() {
    console.log('base.ctrl: activate');

    EnlightedAPISvc.Config.initialize($window.enl.config).then(init);

    $rootScope.$on('REFRESH_ORG_LIST', function () {
      console.log('base.ctrl: REFRESH_ORG_LIST');
      EnlightedAPISvc.Organization.getAll()
        .then(function (orgsRsp) {
          var sortedOrgs = _.sortBy(orgsRsp.objs, function (org) { return org.name; });
          orgsRsp.objs = sortedOrgs;
          $scope.orgs = orgsRsp;
          return $q.when();
        }, function (error) {
          return $q.reject();
        })
        .finally(function () {
          $scope.$broadcast('ORG_LIST_REFRESHED', { orgList: $scope.orgs });
        });
    });
  }
};
