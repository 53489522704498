/* @ngInject */
module.exports = function ($scope,EnlightedAPISvc,EnlightedAPIEnumsSvc,$filter,$q, MAXLENGTH) {
  var vm = this;

  //============= MODELS ============//
  $scope.pcdisabled = true;
  

  //============= FUNCTIONS ============//
  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    setupWatches();
  }

  function setupWatches(){

    $scope.$watch(function () {
      return $scope.infoForm;
    }, function (newVal, oldVal) {
      $scope.form = newVal;
    });

    $scope.$watch(function(){return $scope.obj.manufacturer;},function(){
      if($scope.obj.manufacturer){
        $scope.pcdisabled = false;
      }
      //watch manufacture for product code dropdown change
      $scope.refresh();
    });
  }





};
