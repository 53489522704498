module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      entry        : '=',
      index        : '=',
      enlOnChange  : '&',
      delFn        : '&',
      options      : '=',
      restrictions : '='
    },
    templateUrl: 'app-container/components/global-settings/ops/regulator/details/wattage-entry.template.html',
    controller: 'WattageEntryDirCtrl as entryCtrl'
  };
};
