/* @ngInject */
module.exports = function ($scope, $q, $state, $stateParams, EnlightedAPISvc, localizeFilter, ConfirmDialogSvc, $translate, BannerSvc, $rootScope, EnlightedAPIErrorSvc, ViewOnly) {
  var

  vm = this;

  //============= MODELS ============//
  vm.curDriver = undefined;
  vm.enumVal   = undefined;
  vm.isLoaded  = false;
  vm.tabIndex  = 0;

  //============= FUNCTIONS ============//
  vm.onSelectDescriptionsTab = undefined;
  vm.onSelectInfoTab         = onSelectInfoTab;
  vm.onSelectLampConfigsTab  = onSelectLampConfigsTab;
  vm.onSelectNamesTab        = undefined;
  vm.showConfirmDelete       = showConfirmDelete;
  vm.saveFn                  = saveFn;
  vm.checkViewOnly = checkViewOnly;

  activate();

  //============= FN DEFINITIONS ============//
  function checkViewOnly(ev) {
    if (ViewOnly.oneDirty()) {
      var dirtyNames = ViewOnly.getDirtyNames();
      var opts = {
        ariaLabel: $translate.instant('CONFIRM_CHANGES_LOSE_ARIA_LABEL'),
        title: $translate.instant('CONFIRM_CHANGES_LOSE'),
        message: $translate.instant('CHANGES_LOSE', {names: dirtyNames.join(" and ")})
      };

      ConfirmDialogSvc.show(ev, opts).then(function () {
        // deleteOrg();
        vm.globalsettingseditmode = false;
        $scope.$broadcast("RESET_FORM_VIEW_ONLY");
      });
    } else {
      vm.globalsettingseditmode = false;
    }
  }

  function activate() {
    console.log('driver-details.ctrl: activate ', $stateParams);

    EnlightedAPISvc.driver.getById($stateParams.enumEid)
      .then(function (enumRsp) {
        vm.curDriver = enumRsp.enum;
        $scope.enumVal = vm.enumVal = vm.curDriver.val;
        vm.isLoaded = true;
      }, function (error) {
        // TODO: what to show in case of error?
        vm.isLoaded = true;
      });
  }

  function saveFn() {
    var deferred = $q.defer();
    console.log(vm.curDriver);
    EnlightedAPISvc.driver.update(vm.curDriver)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.curDriver.val }),
          el: '#content'
        });

        console.log("RESPONSE AFTER SAVE", rsp.enum, vm.curDriver);
        vm.curDriver = rsp.enum;

        deferred.resolve("success");

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.curDriver.val }), error),
          el: '#content'
        });

        deferred.resolve("error");

        vm.hasAsync = false;
      });

      return deferred.promise;
  }

  function deleteEnum() {
    EnlightedAPISvc.driver.deleteById(vm.curDriver.val)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('DELETE_SUCCESS', { val: vm.curDriver.val }),
          el: '#content'
        });
        $state.go('^.list', {enumId: vm.curDriver.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('DELETE_FAILURE', { val: vm.curDriver.val }), error),
          el: '#content'
        });
      });
  }

  function onSelectInfoTab() {
    $scope.$broadcast('DRIVER_INFO_TAB_SELECTED', {enumObj: vm.curDriver});
  }

  function onSelectLampConfigsTab() {
    $scope.$broadcast('DRIVER_LAMP_CONFIGS_TAB_SELECTED', {enumObj: vm.curDriver});
  }

  function showConfirmDelete(ev) {
    var

    opts = {
      ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
      title: $translate.instant('CONFIRM_DELETE'),
      message: $translate.instant('CONFIRM_DELETE_MESSAGE', {objName: localizeFilter(vm.curDriver.names)})
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        deleteEnum();
      });
  }
};
