/* @ngInject */
module.exports = function ($scope, $translate, EnlightedAPIEnumsSvc, ConfirmDialogSvc, $q, $filter, MAXLENGTH, EnlightedAPISvc) {
  var vm = this,

  isInit = false;

  //============= MODELS ============//
  vm.countries = [];
  vm.countryLabel = $filter('translate')('COUNTRY') + ' *';
  vm.noCache = true;
  vm.searchText = '';
  vm.selectedItem = undefined;
  vm.validateEmail = validateEmail;
  vm.rolemaptypelist = [];
  vm.rolestaken = [];
  vm.makingRequest = false;
  $scope.validEmailRegEx = '';


  //============= FUNCTIONS ============//
  vm.querySearch = querySearch;
  vm.selectedItemChange = selectedItemChange;
  vm.goToTreeView = goToTreeView;
  vm.addToAccessRole = addToAccessRole;
  vm.editRoleMapType = editRoleMapType;
  vm.roleConfigConfirmCancel = roleConfigConfirmCancel;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('administrator_step.ctrl: activate');

    $scope.$on('CREATE_ORG_ACTIVATE_ADMIN', function () {
      init();
    });

    if ($scope.coBaseCtrl.selectedStep === 2) {
      init();
    }
  }

  function init() {
    console.log('administrator_step.ctrl: init');

    if ($scope.coBaseCtrl.stepData[3].data.idp.domain) {
      $scope.validEmailRegEx = '^[A-Za-z0-9._%+-]+@' + $scope.coBaseCtrl.stepData[3].data.idp.domain + '$';
    }

    if (isInit) {
      return;
    }

    isInit = true;

    EnlightedAPIEnumsSvc.getEnum('country').then(function (enums) {
      var countries = [], dietCountry;
      angular.forEach(enums, function (country) {
        dietCountry = {
          val: country.val,
          display: $filter('localize')(country.names)
        };

        countries.push(dietCountry);
      });

      vm.countries = countries;

      vm.isLoaded = true;
    }, function () {
      vm.isLoaded = true;
    });
  }

  function createFilterFor(query) {
    var lowercaseQuery = angular.lowercase(query);
    return function filterFn(country) {
        return (angular.lowercase(country.display).indexOf(lowercaseQuery) !== -1);
    };
  }

  function querySearch(query) {
    var results = query ? vm.countries.filter(createFilterFor(query)) : vm.countries;
    return results;
  }

  function selectedItemChange(country) {
    $scope.step.data.user.country = (country) ? country.val : undefined;
  }

  function validateEmail() {
    return $scope.regex;
  }

  function addToAccessRole() {
    $scope.step.data.user.roles = $scope.step.data.user.roles || {};
    $scope.step.data.user.roles.roles = $scope.step.data.user.roles.roles || {};
    $scope.$broadcast("$B_GET_SELECTED_IDS");
  }

  function editRoleMapType(role, ev) {
    vm.edit = {};
    vm.edit.idlist = role.ids;
    vm.edit.roletype = role.orig.val;
    vm.rolestaken = vm.rolemaptypelist.map(function (r) {
      if (r.val !== role.val) {
        return r.val;
      }
    });
    vm.addRole = true;
  }

  function deleteRoleMapType(role, ev, i) {
    var opts = {
        ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
        title: $translate.instant('CONFIRM_DELETE'),
        message: $translate.instant('CONFIRM_DELETE_MESSAGE', {objName: localizeFilter(role.names)})
      };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        vm.rolemaptypelist.splice(i, 1);
        if (vm.rolemaptypelist.length === 0) {
          vm.isSavable = false;
        }
      });
  }

  $scope.$on("$E_GET_SELECTED_IDS", function (evt, data) {
    vm.isLoaded = false;
    vm.addRole = false;
    var ids = data.ids;
    var role = data.roletype;

    EnlightedAPISvc.role.getById(role).then(function (resp) {
      var roletoadd = {ids: ids, hasRestrictions: (ids.length === 0), names: resp.enum.names, orig: resp.enum, val: resp.enum.val};

      var overwrite = false;
      vm.rolemaptypelist.forEach(function (role, index) {
        if (role.orig.val === resp.enum.val) {
          overwrite = true;
          vm.rolemaptypelist[index] = roletoadd;
        }
      });

      if (!overwrite) {
        vm.rolemaptypelist.push(roletoadd);
      }

      $scope.step.data.user.roles.roles = updateApiEnum($scope.step.data.user.roles.roles, vm.rolemaptypelist);

      vm.isSavable = true;
      vm.isLoaded = true;
    });
  });

  function setRolemapType(type) {
    allroles.roles[type] = {};
  }

  // function save() {
  //   var obj = updateApiEnum(vm.user.roles.roles, vm.rolemaptypelist, vm.type);
  //   vm.isLoaded = false;
  //   vm.user.roles.roles = obj;

  function updateApiEnum(roles, rolemaplist) {
    var obj = roles;
    angular.forEach(rolemaplist, function (role) {
      obj[role.val] = { ids : role.ids };
    });
    console.log(obj);
    return obj;
  }

  function goToTreeView() {
    vm.edit = undefined;
    vm.rolestaken = vm.rolemaptypelist.map(function (r) {
      return r.val;
    });
    vm.addRole = true;
  }

  function roleConfigConfirmCancel(ev) {
    var opts = {
      ariaLabel: $translate.instant("CONFIRM_CANCEL_DIALOG"),
      title: $translate.instant("CONFIRM_CANCEL"),
      message: $translate.instant("CONFIRM_CANCEL_CREATE_MSG")
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        vm.addRole = false;
      });
  }


};
