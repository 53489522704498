/* @ngInject */
module.exports = function ($scope, $translate, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, ViewOnly, $timeout) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.apiEnum       = undefined;
  vm.cachedEnumObj = undefined;
  vm.enumObj       = {};
  vm.hasAsync      = false;
  vm.infoForm      = {};
  vm.isLoaded      = false;
  vm.options       = {};

  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops gang-box info.ctrl: activate ');

    $scope.$on('GANG_BOX_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('ops gang-box info.ctrl: init', enumObj);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    vm.apiEnum = enumObj;
    vm.enumObj = createEnumDisplay(enumObj);
    vm.cachedEnumObj = angular.copy(vm.enumObj);

    $timeout(function () {
      vm.isLoaded = true;
    }, 1000);
  }

  function createEnumDisplay(enumObj) {
    return {
      val: enumObj.val,
      dname: (enumObj.names) ? enumObj.names[''] : undefined,
      ddescription: (enumObj.descriptions) ? enumObj.descriptions[''] : undefined,
      max_outlets: enumObj.max_outlets,
      svg: enumObj.svg,
      width: enumObj.width,
      height: enumObj.height
    };
  }

  function restore() {
    vm.enumObj = angular.copy(vm.cachedEnumObj);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    vm.apiEnum = updateAPIEnum(vm.apiEnum, vm.enumObj);
    console.log('GangBox-info.ctrl: save - ', vm.apiEnum);

    vm.hasAsync = true;

    EnlightedAPISvc[vm.apiEnum.kind].update(vm.apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.apiEnum.val }),
          el: '#content'
        });

        vm.enumObj = createEnumDisplay(rsp.enum);
        vm.cachedEnumObj = angular.copy(vm.enumObj);

        vm.infoForm.$setPristine();

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.cachedEnumObj.val }), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.dname;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.ddescription;
    apiEnumObj.parent ='';
    apiEnumObj.svg = enumObj.svg;
    apiEnumObj.width = enumObj.width;
    apiEnumObj.height = enumObj.height;
    apiEnumObj.max_outlets = enumObj.max_outlets;

    return apiEnumObj;
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("gang box form");
    } else {
      ViewOnly.delete("gang box form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("gang box form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("gang box form");
  });
};
