/* @ngInject */
module.exports = function($scope, $element, $attrs, $sce, EnlightedPermissionsSvc, $state) {
  var

  vm = this;

  //=== Scope/Attributes Defaults ===//

  //=== View functions ===//
  vm.getAppIconUrl = getAppIconUrl;
  vm.goMyAccount = goMyAccount;
  vm.hasCreateOrgPermission = hasCreateOrgPermission;
  vm.hasSettingPermission = hasSettingPermission;
  vm.hasDropdownMenu = hasDropdownMenu;
  vm.hasMenus = hasMenus;
  vm.isDivider = isDivider;
  vm.navAction = navAction;
  vm.navfn = $attrs.navfn || navfn;
  vm.noop = noop;
  vm.logout = logout;

  //=== Methods ===//
  // if no parent function was passed to directive for navfn, then create one to emit an event
  function navfn(action) {
    if (angular.isObject(action))
      $scope.$emit('nav.menu', action);
    else
      $scope.$emit('nav.menu', {
        'action': action
      });
  } // end navfn

  function noop() {
    angular.noop();
  } // end noop

  function navAction(action) {
    vm.navfn({
      'action': action
    });
  } // end navAction

  /**
   * Has Menus
   * Checks to see if there were menus passed in for the navbar.
   * @result  boolean
   */
  function hasMenus() {
    return (angular.isDefined($attrs.menus));
  }

  /**
   * Has Dropdown Menu
   * Check to see if navbar item should have a dropdown menu
   * @param  object  menu
   * @result  boolean
   */
  function hasDropdownMenu(menu) {
    return (angular.isDefined(menu.menu) && angular.isArray(menu.menu));
  } // end hasDropdownMenu

  /**
   * Is Divider
   * Check to see if dropdown menu item is to be a menu divider.
   * @param  object  item
   * @result  boolean
   */
  function isDivider(item) {
    return (angular.isDefined(item.divider) && angular.equals(item.divider, true));
  } // end isDivider

  function logout() {
    EnlightedAPI.Session.signoff().then(function () {
      window.location = window.location.origin;
    }, function (error) {
      console.log('Unable to sign out ', error);
    });
  }

  function getAppIconUrl(app) {
    return $sce.trustAsResourceUrl(app.ui_url + '/app-icon.svg');
  }

  function goMyAccount() {
    $state.go('myaccount');
  }

  function hasCreateOrgPermission() {
    if (angular.isDefined($scope.user)) {
      return EnlightedPermissionsSvc.hasCreateOrgPermission($scope.user);
    }
  }

  function hasSettingPermission() {
    if (angular.isDefined($scope.user)) {
      return EnlightedPermissionsSvc.hasSettingPermission($scope.user);
    }
  }
};
