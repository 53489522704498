/* @ngInject */
module.exports = function ($scope, $translate, ConfirmDialogSvc, localizeFilter, AutoCompleteSvc, RegulatorSvc, MAXLENGTH) {
  var

  vm = this,

  I18N_KEYS = [
    'ADD_LAMP_CONFIG',
    'EDIT_LAMP_CONFIG'
  ],

  DICTIONARY = {},

  unregisterWatchFn;

  //============= MODELS ============//
  vm.configForm     = undefined;
  vm.curConfig      = {};
  vm.curConfigTitle = undefined;
  vm.DECIMAL_REGEX  = /^\d+\.\d+$/;
  vm.mode           = 'list';
  vm.NUMBER_REGEX   = /^\d+$/;
  vm.searchText     = '';
  

  //============= FUNCTIONS ============//
  vm.doAdd             = doAdd;
  vm.doEdit            = doEdit;
  vm.getChannelKey     = getChannelKey;
  vm.onWattagesChange  = onWattagesChange;
  vm.querySearch       = querySearch;
  vm.save              = save;
  vm.showCancelConfirm = showCancelConfirm;
  vm.showDeleteConfirm = showDeleteConfirm;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    $scope.$watch(function () {
      return vm.mode;
    }, function (newVal, oldVal) {
      if (newVal !== undefined) {
        if ($scope.enlOnViewChange && typeof $scope.enlOnViewChange() === 'function') {
          $scope.enlOnViewChange()(newVal);
        }
      }
    });
  }

  function getChannelKey(numChannels) {
    if (numChannels === 1) {
      return 'CONTROL_CHANNELS_SINGLE';
    } else {
      return 'CONTROL_CHANNELS_PLURAL';
    }
  }

  function deleteConfiguration(config, index) {
    $scope.configurations.splice(index, 1);
    console.log($scope.configurations);

    notifyChange($scope.configurations, 'delete', config);
  }

  function doAdd() {
    console.log('regulator-lamp-configs: doAdd');
    vm.mode = 'form';
    vm.curConfigTitle = DICTIONARY.ADD_LAMP_CONFIG;
    vm.curConfig = { wattages: [], factor: 1 };
    vm.searchText = '';

    watchMaxPower();
  }

  function doDelete(config, index, ev) {
    $scope.delFn()(config, index, ev);
  }

  function doEdit(config, index) {
    console.log('regulator-lamp-configs: doEdit', config, index);

    vm.curConfig = angular.extend({ index: index }, config);
    vm.curConfig.lamp = {
      eid: config.lamp,
      display: config.lamp_manufacturer + ' ' + config.lamp_part_number,
      manufacturer: config.lamp_manufacturer,
      part_number: config.lamp_part_number
    };

    for (var key in config.wattages) {
      if (key.indexOf('100' === 0)) {
        vm.curConfig.x_maxPower = config.wattages[key];
      }
    }

    vm.curConfig.wattages = RegulatorSvc.convertToDisplayWattages(config.wattages);

    vm.curConfigTitle = DICTIONARY.EDIT_LAMP_CONFIG;
    vm.mode = 'form';
    vm.searchText = '';

    watchMaxPower();
  }

  function notifyChange(configurations, eventType, affectedConfig) {
    if (typeof $scope.enlOnChange() === 'function') {
      $scope.enlOnChange()(configurations, eventType, affectedConfig);
    }
  }

  function onWattagesChange(wattages, eventType, affectedWattage) {
    console.log('regulator-lamp-configs.directive.ctrl: onWattagesChange - ', wattages, eventType, affectedWattage);
    if (affectedWattage && parseFloat(affectedWattage.percentage) === 100 ) {
      vm.curConfig.x_maxPower = affectedWattage.wattage;
    }
    vm.configForm.$setDirty();
  }

  function querySearch(query) {
    return AutoCompleteSvc.querySearch(query, $scope.lamps);
  }

  function save() {
    var apiConfig = RegulatorSvc.convertToAPILampConfig(vm.curConfig);

    if (vm.curConfig.index !== undefined) {
      $scope.configurations[vm.curConfig.index] = apiConfig;
    } else {
      $scope.configurations.push(apiConfig);
    }

    notifyChange($scope.configurations, (!!vm.curConfig.index) ? 'add' : 'edit', apiConfig);

    vm.mode = 'list';
    stopMaxPowerWatch();
  }

  function showCancelConfirm(ev) {
    var

    opts = {
      ariaLabel: $translate.instant('CONFIRM_CANCEL'),
      title: $translate.instant('CONFIRM_CANCEL'),
      message: $translate.instant('CONFIRM_CANCEL_LAMP_CONFIG')
    };

    if (!vm.configForm.$pristine) {
      ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        vm.mode = 'list';
        stopMaxPowerWatch();
      });
    } else {
      vm.mode = 'list';
      stopMaxPowerWatch();
    }
  }

  function showDeleteConfirm(config, index, ev) {
    var

    opts = {
      ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
      title: $translate.instant('CONFIRM_DELETE'),
      message: $translate.instant('CONFIRM_DELETE_LAMP_CONFIG_MESSAGE', {lamp_manufacturer: config.lamp_manufacturer, lamp_part_number: config.lamp_part_number })
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        deleteConfiguration(config, index);
      });
  }

  function watchMaxPower() {
    unregisterWatchFn = $scope.$watch(function () {
      return vm.curConfig.x_maxPower;
    }, function (newVal, oldVal) {
      console.log('regulator-lamp-configs.directive.ctrl: $watch -', newVal, oldVal);
      if (newVal !== undefined) {
        var found100 = false;
        if (vm.curConfig && vm.curConfig.wattages) {
          angular.forEach(vm.curConfig.wattages, function (w) {
            if (100 === parseFloat(w.percentage)) {
              w.wattage = newVal;
              found100 = true;
            }
          });

          if (!found100) {
            vm.curConfig.wattages.push({
              percentage: 100.0,
              wattage: newVal,
              options: {
                disablePercentage: true,
                disableDelete: true
              }
            });
          }
        } else {
          vm.curConfig.wattages = [{
            percentage: 100.0,
            wattage: newVal,
            options: {
              disablePercentage: true,
              disableDelete: true
            }
          }];
        }
      }
    });
  }

  function stopMaxPowerWatch() {
    console.log('regulator-lamp-configs.directive.ctrl: stopMaxPowerWatch');
    unregisterWatchFn();
  }
};
