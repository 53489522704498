/* @ngInject */
module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      obj : '=',
      form : '=',
      enlMode : '@'
    },
    templateUrl: 'app-container/components/global-settings/ops/device_type/directive/device_type-form.tpl.html',
    controller: 'DeviceTypeInfoDirCtrl as infoCtrl'
  };
};
