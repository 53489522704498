/* @ngInject */
module.exports = function ($scope, $stateParams, $q, $filter, $translate, EnlightedAPIEnumsSvc, EnlightedAPISvc, BannerSvc, $rootScope, $state, ConfirmDialogSvc, EnlightedAPIErrorSvc) {
  var

    vm = this,

    I18N_KEYS = [
      'CONFIRM_CANCEL_DIALOG',
      'CONFIRM_CANCEL',
      'CONFIRM_CANCEL_CREATE_MSG'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.newEnum  = undefined;
  vm.infoForm = {};
  vm.isLoaded = false;
  vm.options  = {};
  vm.kind = undefined;
  vm.objKinds = [];
  vm.selectedObjKinds = [];
  vm.availableObjKinds = [];
  //============= FUNCTIONS ============//
  vm.save              = save;
  vm.showConfirmCancel = showConfirmCancel;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops reason create.ctrl: activate ', $stateParams);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    $scope.enumType = vm.kind = 'reason';
    vm.objKinds = EnlightedAPIEnumsSvc.getObjectKinds();
    setDualColSelect(vm.objKinds);
    vm.isLoaded = true;

  }

  function save() {
    var apiEnum = {};

    apiEnum = updateAPIEnum(apiEnum, vm.newEnum);


    console.log('Reason-create.ctrl: save -', apiEnum);

    EnlightedAPISvc[vm.kind].create(apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('CREATE_SUCCESS', { val: vm.newEnum.val }),
          el: '#content'
        });

        $state.go('^.list', {enumId: vm.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_FAILURE', { val: vm.newEnum.val }), error),
          el: '#content'
        });
      });
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        $state.go('^.list');
      });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.dname;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.ddescription;
    apiEnumObj.subject = enumObj.subject;
    apiEnumObj.content = enumObj.content;
    apiEnumObj.kinds = enumObj.kinds;
    apiEnumObj.kind = 'reason';
    return apiEnumObj;

  }

  function setDualColSelect(objKinds) {
    vm.allKindsVal = [];
    angular.forEach(objKinds, function(val) {
      vm.allKindsVal.push(val.toUpperCase());
    });
    angular.forEach(vm.allKindsVal, function (val) {
      vm.availableObjKinds.push({
        id: val.toLowerCase(),
        val: val.toLowerCase(),
        name: $filter('translate')(val),
        display: $filter('translate')(val),
        selected: false
      });
    });
    console.log('available list');
    console.log(vm.availableObjKinds);
    vm.options.selectedObjKinds = vm.selectedObjKinds;
    vm.options.availableObjKinds = vm.availableObjKinds;
  }
};
