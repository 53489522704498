module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      entries     : '=',
      enlOnChange : '&'
    },
    templateUrl: 'app-container/components/global-settings/ops/regulator/details/wattage-widget.template.html',
    controller: 'WattageWidgetDirCtrl as wattagesCtrl'
  };
};
