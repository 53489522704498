/* @ngInject */
module.exports = function ($scope, $translate, ConfirmDialogSvc, $state, $timeout, $q, EnlightedAPISvc, BannerSvc, $rootScope, EnlightedAPIErrorSvc) {
  var

  vm = this,

  I18N_KEYS = [
    'INFORMATION',
    'LAMP_CONFIGURATIONS',
    'CONFIRM_CANCEL_DIALOG',
    'CONFIRM_CANCEL',
    'CONFIRM_CANCEL_CREATE_MSG'
  ],

  DICTIONARY = {},

  WIZARD_STEPS = {
    INFORMATION         : 0,
    LAMP_CONFIGURATIONS : 1
  };

  //============= MODELS ============//
  vm.NUMBER_REGEX = /^[\d]+$/;
  vm.selectedStep = 0;
  vm.stepProgress = 1;
  vm.maxStep      = 5;
  vm.showBusyText = false;
  vm.stepData     = [];

  //============= FUNCTIONS ============//
  vm.finishWizard      = finishWizard;
  vm.goPreviousStep    = goPreviousStep;
  vm.showConfirmCancel = showConfirmCancel;
  vm.submitCurrentStep = submitCurrentStep;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    var promises = [];

    angular.forEach(I18N_KEYS, function (key) {
      promises.push($translate(key));
    });

    $q.all(promises)
      .then(function (translations) {
        angular.forEach(translations, function (translation, index) {
          DICTIONARY[I18N_KEYS[index]] = translation;
        });

        return $q.when();
      })
      .then(function () {
        watchSelectedStep();

        initSteps();

        $scope.$broadcast('CREATE_DRIVER_ACTIVATE_INFO');
      });
  }

  function finishWizard() {
    // merge the step data together to get the API driver enum to send to the server
    var apiDriver = {};
    angular.forEach(vm.stepData, function (step) {
      angular.extend(apiDriver, step.data.driver);
    });

    apiDriver.kind = 'driver';

    console.log('driver-create.ctrl: finishWizard -', apiDriver);

    EnlightedAPISvc.driver.create(apiDriver)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('CREATE_DRIVER_SUCCESS', {name: apiDriver.parent + ' ' + apiDriver.val}),
          el: '#content'
        });

        $state.go('^.list');
      }, function (error) {
        console.log('driver-create.ctrl: finishWizard - ', error);
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_DRIVER_FAILURE', {name: apiDriver.parent + ' ' + apiDriver.val}), error),
          el: '#content'
        });
      });
  }

  function goPreviousStep(step) {
    vm.selectedStep = vm.selectedStep - 1;
  }

  function initSteps() {
    vm.selectedStep = 0;
    vm.stepProgress = 1;
    vm.stepData = [{
      step: WIZARD_STEPS.INFORMATION,
      label: DICTIONARY.INFORMATION,
      completed: false,
      optional: false,
      data: {
        driver: {}
      },
      templateUrl: 'app-container/components/global-settings/ops/driver/create/info_step.html'
    }, {
      step: WIZARD_STEPS.LAMP_CONFIGURATIONS,
      label: DICTIONARY.LAMP_CONFIGURATIONS,
      completed: false,
      optional: true,
      data: {
        driver: {
          lamps: []
        }
      },
      templateUrl: 'app-container/components/global-settings/ops/driver/create/lamp_configs_step.html'
    }];
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function() {
        $state.go('^.list');
      });
  }

  function submitCurrentStep(step) {
    step.completed = true;
    if (vm.selectedStep >= vm.maxStep) {
      return;
    }
    //do not increment vm.stepProgress when submitting from previously completed step
    if (vm.selectedStep === vm.stepProgress - 1) {
      vm.stepProgress = vm.stepProgress + 1;
    }

    $timeout(function() {
      vm.selectedStep = vm.selectedStep + 1;
    }, 0);
  }

  function watchSelectedStep() {
    $scope.$watch(function() {
      return vm.selectedStep;
    }, function(newVal, oldVal) {
      var broadcastEvt = 'CREATE_DRIVER_ACTIVATE_INFO';

      if (newVal === WIZARD_STEPS.LAMP_CONFIGURATIONS) {
        broadcastEvt = 'CREATE_DRIVER_ACTIVATE_LAMP_CONFIGURATIONS';
      }

      $scope.$broadcast(broadcastEvt);
    });
  }
};
