/* @ngInject */
module.exports = function ($scope, $state) {
  var

  vm = this,

  SETTINGS_LENGTH = "settings.".length;

  //============= MODELS ============//
  vm.menuItems = [];
  //============= FUNCTIONS ============//
  vm.isCurrentState = isCurrentState;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('menu.ctrl: activate');

    vm.menuItems = [{
      icon: 'language',
      label: 'SYSTEM_CONFIG',
      toState: 'settings.system.list'
    }, {
      icon: 'widgets',
      label: 'OPS_CONFIG',
      toState: 'settings.ops.list'
    }, {
      icon: 'fingerprint',
      label: 'IDPS',
      toState: 'settings.idps.list'
    }, {
      icon: 'settings_remote',
      label: 'DONGLES',
      toState: 'settings.dongles.list'
    }, {
      icon: 'devices_other',
      label: 'MANUFACTURED DEVICES',
      toState: 'settings.mdevice.list'
    }];
  }

  function isCurrentState(state) {
    var stateRootState = (state.indexOf('.', SETTINGS_LENGTH) !== -1) ? state.substr(0, state.indexOf('.', SETTINGS_LENGTH)) : state;
    var currentRootState = ($state.current.name.indexOf('.', SETTINGS_LENGTH) !== -1) ? $state.current.name.substr(0, $state.current.name.indexOf('.', SETTINGS_LENGTH)) : $state.current.name;
    return stateRootState === currentRootState;
  }
};
