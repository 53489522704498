/* @ngInject */
module.exports = function ($scope, $timeout, $interval, $sce, $q, $stateParams, $translate, EnlightedAPISvc, localizeFilter, BannerSvc, $rootScope, $state, ConfirmDialogSvc, EnlightedAPIErrorSvc, EnlightedAPIEnumsSvc) {
  var vm = this,

  I18N_KEYS = [
    'CONFIRM_CANCEL_DIALOG',
    'CONFIRM_CANCEL',
    'CONFIRM_CANCEL_CREATE_MSG',
    'CONFIRM_DELETE_ARIA_LABEL',
    'CONFIRM_DELETE',
    'CONFIRM_DELETE_LAMP_CONFIG_MESSAGE_NO_ARGS',
    'CONFIRM_DELETE_SENSOR_MESSAGE_NO_ARGS'
  ],

  DICTIONARY = {},

  watchSVG,
  watchCOORDS,
  $svgwrapper,
  recalcInterval,
  isFirstEntry = true,
  counter = 0,
  svghtml;

  vm.initialLeft = 0;
  vm.initialTop = 0;
  vm.sensorsFormDirty = true;

  //============= MODELS ============//
  vm.selectedStep = 0;
  vm.enumObj  = {};
  vm.baseObj  = {};

  vm.informationForm = {};
  vm.lampConfigForm = {};
  vm.sensorsForm = {};
  vm.steps = createSteps();

  vm.isLoaded = false;

  vm.kind     = "luminaire";
  vm.options  = {};
  vm.manufacturers = [];
  vm.lampcfgtypes = [];
  vm.lampcfg = [];
  vm.productCodes = [];
  vm.backupSensor = [];

  //============= FUNCTIONS ============//
  vm.save              = save;
  vm.showConfirmCancel = showConfirmCancel;
  vm.cancelLampCfg = cancelLampCfg;
  vm.showDeleteConfirm = showDeleteConfirm;

  vm.addLampCfg = addLampCfg;
  vm.editView = editView;
  vm.doEdit = doEdit;

  vm.nextStep = nextStep;
  vm.previousStep = previousStep;
  vm.compareConfigs = compareConfigs;

  vm.isEdit = false;

  activate();

  //============= FN DEFINITIONS ============//

  $scope.$on("addSensorEvt", function (e, data) {
    vm.backupSensor.push(data.data);
  });

  $scope.$on("removeSensorEvt", function (e, data) {
    vm.backupSensor.splice(data.index, 1);
  });

  function activate() {
    console.log('ops luminaire create.ctrl: activate ', $stateParams);

    getManufacturers();
    getLampCfg();
    getLampCfgType();

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    $scope.enumType = vm.kind = "luminaire";

    vm.enumObj = {};
    vm.enumObj.height = {};
    vm.enumObj.height.units = "FT";

    $translate('ENUM_VAL_DISPLAY_' + vm.kind.toUpperCase()).then(function (translation) {
      vm.options.valDisplay = translation;
      vm.isLoaded = true;
    });
  }

  function save() {
    vm.baseObj = updateAPIEnum(vm.baseObj, vm.enumObj);

    console.log('basic-create.ctrl: save -', vm.baseObj);

    EnlightedAPISvc[vm.kind].create(vm.baseObj)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('CREATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        $state.go('^.list', {enumId: vm.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_FAILURE', { val: vm.enumObj.val }), error),
          el: '#content'
        });
      });
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts).then(function () {
      $state.go('^.list');
    });
  }

  function showDeleteConfirm(key, ev) {
    var opts = {
      ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
      title: $translate.instant('CONFIRM_DELETE'),
      message: $translate.instant('CONFIRM_DELETE_LAMP_CONFIG_MESSAGE_NO_ARGS')
    };

    ConfirmDialogSvc.show(ev, opts).then(function () {
      if (typeof vm.enumObj.lamp_cfgs === "object" && vm.enumObj.lamp_cfgs.hasOwnProperty(key)) {
        $rootScope.$broadcast("deleteLampCfg", key);
        delete vm.enumObj.lamp_cfgs[key];
      }
    });
  }

  function cancelLampCfg(ev) {
    vm.lampcfg_mode = "list";
  }

  function compareConfigs() {
    if (typeof vm.enumObj.lamp_cfgs === "undefined") {
      return 'false';
    }
    var comparison = angular.equals(vm.enumObj.lamp_cfgs, {});
    return (comparison ? 'false' : 'true');
  }

  function doEdit(lampcfg, key) {
    vm.enumObj.lampcfg.partnumber = lampcfg;
    vm.enumObj.lampcfg.type = key;
    vm.lampcfg_mode = "edit";
  }

  function editView() {
    vm.lampcfg_mode = "edit";
  }

  function addLampCfg(key, val) {
    vm.enumObj.lamp_cfgs = vm.enumObj.lamp_cfgs || {};
    vm.enumObj.lamp_cfgs[vm.enumObj.lampcfg.type] = vm.enumObj.lampcfg.partnumber;
    vm.lampConfigForm.$setPristine();
    vm.lampcfg_mode = "list";
    vm.enumObj.lampcfg.type = vm.enumObj.lampcfg.partnumber = undefined;
    $scope.$broadcast("checkLampsAgain");
  }

  function getLampCfg() {
    EnlightedAPI.lampcfg.getAll().then(function (resp) {
      angular.forEach(resp.enums, function (e) {
        vm.lampcfg.push(e);
      });
    });
  }

  function getLampCfgType() {
    EnlightedAPI.lampcfg_type.getAll().then(function (resp) {
      angular.forEach(resp.enums, function (e) {
        vm.lampcfgtypes.push(e);
      });
    });
  }

  function getManufacturers() {
    EnlightedAPI.manufacturer.getAll().then(function (resp) {
      angular.forEach(resp.enums, function (e) {
        vm.manufacturers.push(e);
      });
    });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.name;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.description || "";
    apiEnumObj.parent = enumObj.manufacturer;
    apiEnumObj.lamp_cfgs = enumObj.lamp_cfgs;
    apiEnumObj.height = enumObj.height;
    apiEnumObj.width = enumObj.width;
    apiEnumObj.height.units = apiEnumObj.height.units.replace(/(\s|\n|\r)/g, "");
    // apiEnumObj.width.units = apiEnumObj.height.units;
    // apiEnumObj.svg_dimensions = {};
    // apiEnumObj.svg_dimensions.width = enumObj.width.width;
    // apiEnumObj.svg_dimensions.height = enumObj.height.width;
    apiEnumObj.sensors = enumObj.sensorArray;
    apiEnumObj.svg = enumObj.svg;
    //apiEnumObj.svg_dimensions = {width: apiEnumObj.width.width, height: apiEnumObj.height.width};
    apiEnumObj.kind = vm.kind;

    return apiEnumObj;
  }

  function nextStep() {
    vm.selectedStep++;
    if (vm.selectedStep === 1) {
      vm.lampcfg_mode = "list";
      vm.steps.step1.completed = true;
    } else if (vm.selectedStep === 2) {
      vm.steps.step2.completed = true;
    }
  }

  function previousStep() {
    vm.selectedStep--;
    if (vm.selectedStep === 1) {
      vm.steps.step2.completed = false;
    } else if (vm.selectedStep === 2) {
      vm.steps.step3.completed = false;
    }
  }

  function createSteps() {
    return {
      step1: {
        completed: false,
        disabled: false
      },
      step2: {
        completed: false,
        disabled: true
      },
      step3: {
        completed: false,
        disabled: true
      }
    };
  }
};
