module.exports = (function() {
  'use strict';

  angular.module('enl-global-settings', [
    'ngSanitize',
    'pascalprecht.translate',
    'ngMaterial',
    'ngMessages',
    'ngAnimate',
    'ui.router',
    'md.data.table',
    'ncy-angular-breadcrumb',
    'angular-spinkit',
    'ngEnlighted'])

    /* Register Controllers */
    .controller('MenuCtrl', require('./menu/menu.ctrl'))
    .controller('SystemListCtrl', require('./system/list/system-list.ctrl'))
    .controller('OpsListCtrl', require('./ops/list/ops-list.ctrl'))
    .controller('DoglListCtrl', require('./dongle/list/dogl-list.ctrl'))
    .controller('DoglDetailCtrl', require('./dongle/detail/dogl-detail.ctrl'))
    .controller('DoglInfoCtrl', require('./dongle/detail/info/dogl-info.ctrl'))
    .controller('DoglChownCtrl', require('./dongle/chown/change-owner.ctrl'))
    .controller('DoglChstatsCtrl', require('./dongle/chstats/change-state.ctrl'))


    .controller('NotesCtrl', require('./notes/notes.ctrl'))
    .controller('IdpListCtrl', require('./idp/list/idp-list.ctrl'))
    .controller('IdpDetailsCtrl', require('./idp/details/idp-details.ctrl'))
    .controller('IdPInfoCtrl', require('./idp/details/idp-info.ctrl'))
    .controller('BasicListCtrl', require('./ops/basic/list/basic-list.ctrl'))
    .controller('BasicDetailsCtrl', require('./ops/basic/details/basic-details.ctrl'))
    .controller('BasicEnumInfoCtrl', require('./ops/basic/details/basic-info.ctrl'))
    .controller('BasicCreateCtrl', require('./ops/basic/create/basic-create.ctrl'))
    .controller('IdpCreateCtrl', require('./idp/create/create-idp.ctrl'))


    //----------------------------SYSTEM--------------------------------
    // enum system facility_type
    .controller('FacilityTypeListCtrl',require('./system/facility_type/list/list.ctrl'))
    .controller('FacilityTypeDetailCtrl',require('./system/facility_type/details/details.ctrl'))
    .controller('FacilityTypeInfoCtrl',require('./system/facility_type/details/info.ctrl'))
    .controller('FacilityTypeCreateCtrl',require('./system/facility_type/create/create.ctrl'))

    // enum system layer_type
    .controller('LayerTypeListCtrl',require('./system/layer_type/list/list.ctrl'))
    .controller('LayerTypeDetailCtrl',require('./system/layer_type/details/details.ctrl'))
    .controller('LayerTypeInfoCtrl',require('./system/layer_type/details/info.ctrl'))
    .controller('LayerTypeCreateCtrl',require('./system/layer_type/create/create.ctrl'))

    // enum system app_type
    .controller('AppTypeListCtrl',require('./system/app_type/list/list.ctrl'))
    .controller('AppTypeDetailCtrl',require('./system/app_type/details/details.ctrl'))
    .controller('AppTypeInfoCtrl',require('./system/app_type/details/info.ctrl'))
    .controller('AppTypeCreateCtrl',require('./system/app_type/create/create.ctrl'))

    // enum system address_type
    .controller('AddressTypeListCtrl',require('./system/address_type/list/list.ctrl'))
    .controller('AddressTypeDetailCtrl',require('./system/address_type/details/details.ctrl'))
    .controller('AddressTypeInfoCtrl',require('./system/address_type/details/info.ctrl'))
    .controller('AddressTypeCreateCtrl',require('./system/address_type/create/create.ctrl'))

    // enum system address_type
    .controller('RoleTypeListCtrl',require('./system/role/list/list.ctrl'))
    .controller('RoleTypeDetailCtrl',require('./system/role/details/details.ctrl'))
    .controller('RoleTypeInfoCtrl',require('./system/role/details/info.ctrl'))
    .controller('RoleTypeCreateCtrl',require('./system/role/create/create.ctrl'))

    // enum system task_type
    .controller('TaskTypeListCtrl',require('./system/task_type/list/list.ctrl'))
    .controller('TaskTypeDetailCtrl',require('./system/task_type/details/details.ctrl'))
    .controller('TaskTypeInfoCtrl',require('./system/task_type/details/info.ctrl'))
    .controller('TaskTypeCreateCtrl',require('./system/task_type/create/create.ctrl'))

    // enum phone type controllers
    .controller('PhoneTypeListCtrl', require('./system/phone_type/list/phone_type-list.ctrl'))
    .controller('PhoneTypeDetailCtrl', require('./system/phone_type/details/phone_type-details.ctrl'))
    .controller('PhoneTypeInfoCtrl', require('./system/phone_type/details/phone_type-info.ctrl'))
    .controller('PhoneTypeInfoDirCtrl', require('./system/phone_type/directive/phone_type-form.directive.ctrl'))
    .controller('PhoneTypeCreateCtrl', require('./system/phone_type/create/create.ctrl'))



    //---------------------------OPERATION ------------------------------
    // enum lamp controllers
    .controller('LampListCtrl',require('./ops/lamp/list/lamp-list.ctrl'))
    .controller('LampDetailCtrl',require('./ops/lamp/details/lamp-details.ctrl'))
    .controller('LampInfoCtrl', require('./ops/lamp/details/lamp-info.ctrl'))
    .controller('LampInfoDirCtrl', require('./ops/lamp/directive/lamp-form.directive.ctrl'))
    .controller('LampCreateCtrl', require('./ops/lamp/create/create.ctrl'))

    // enum lamp cfg
    .controller('LampConfigListCtrl',require('./ops/lampcfg/list/list.ctrl'))
    .controller('LampConfigDetailCtrl',require('./ops/lampcfg/details/details.ctrl'))
    .controller('LampConfigInfoCtrl', require('./ops/lampcfg/details/info.ctrl'))
    .controller('LampConfigWattageCtrl', require('./ops/lampcfg/details/wattage.ctrl'))
    .controller('LampConfigCreateCtrl', require('./ops/lampcfg/create/create.ctrl'))

    // enum luminaire
    .controller('LuminaireListCtrl', require('./ops/luminaire/list/list.ctrl'))
    .controller('LuminaireDetailCtrl', require('./ops/luminaire/details/details.ctrl'))
    .controller('LuminaireInfoCtrl', require('./ops/luminaire/details/info.ctrl'))
    // .controller('LuminaireLampcfgCtrl', require('./ops/luminaire/details/lampcfg.ctrl'))
    .controller('LuminaireSensorsCtrl', require('./ops/luminaire/details/lamp_tab.ctrl'))
    .controller('LuminaireCreateCtrl', require('./ops/luminaire/create/create.ctrl'))

    // enum device type controllers
    .controller('DeviceTypeListCtrl', require('./ops/device_type/list/device_type-list.ctrl'))
    .controller('DeviceTypeDetailCtrl', require('./ops/device_type/details/device_type-details.ctrl'))
    .controller('DeviceTypeInfoCtrl', require('./ops/device_type/details/device_type-info.ctrl'))
    .controller('DeviceTypeInfoDirCtrl', require('./ops/device_type/directive/device_type-form.directive.ctrl'))
    .controller('DeviceTypeCreateCtrl', require('./ops/device_type/create/create.ctrl'))

    .controller('MdeviceListCtrl', require('./mdevice/list/mdevice-list.ctrl'))
    .controller('MdeviceCreateCtrl', require('./mdevice/create/create-mdevice.ctrl'))
    .controller('MdeviceInfoDirCtrl', require('./mdevice/directive/mdevice-form.directive.ctrl'))
    .controller('MultiMdeviceInfoDirCtrl', require('./mdevice/directive/multi-mdevice-form.directive.ctrl'))
    .controller('MdeviceDetailCtrl', require('./mdevice/detail/mdevice-detail.ctrl'))
    .controller('MdeviceChstatsCtrl', require('./mdevice/chstats/change-state.ctrl'))

    // enum control unit controllers
    .controller('ControlUnitListCtrl', require('./ops/control-unit/list/list.ctrl'))
    .controller('ControlUnitDetailsCtrl', require('./ops/control-unit/details/details.ctrl'))
    .controller('ControlUnitInfoCtrl', require('./ops/control-unit/details/info.ctrl'))
    .controller('ControlUnitCreateCtrl', require('./ops/control-unit/create/create.ctrl'))
    .controller('ControlUnitInfoFormDirCtrl', require('./ops/control-unit/details/info-form.directive.ctrl'))
    .controller('ControlUnitListDirCtrl', require('./ops/control-unit/list/control-unit-list.directive.ctrl'))

    // enum driver controllers
    .controller('DriverListCtrl', require('./ops/driver/list/driver-list.ctrl'))
    .controller('RegulatorListDirCtrl', require('./ops/regulator/list/regulator-list-table.directive.ctrl'))
    .controller('BallastListCtrl', require('./ops/ballast/list/ballast-list.ctrl'))
    .controller('DriverDetailsCtrl', require('./ops/driver/details/driver-details.ctrl'))
    .controller('RegulatorDetailsDirCtrl', require('./ops/regulator/details/regulator-info-form.directive.ctrl'))
    .controller('DriverInfoCtrl', require('./ops/driver/details/driver-info.ctrl'))
    .controller('DriverLampConfigsCtrl', require('./ops/driver/details/driver-lamp-configs.ctrl'))
    .controller('LampConfigsDirCtrl', require('./ops/regulator/details/regulator-lamp-configs.directive.ctrl'))
    .controller('RegulatorChartCtrl', require('./ops/regulator/details/chart/chart.directive.ctrl'))
    .controller('WattageEntryEditorDirCtrl', require('./ops/regulator/details/wattage-entry-editor.directive.ctrl'))
    .controller('WattageEntryDirCtrl', require('./ops/regulator/details/wattage-entry.directive.ctrl'))
    .controller('WattageWidgetDirCtrl', require('./ops/regulator/details/wattage-widget.directive.ctrl'))
    .controller('CreateDriverCtrl', require('./ops/driver/create/driver-create.ctrl'))
    .controller('CreateDriverInfoCtrl', require('./ops/driver/create/info_step.ctrl'))
    .controller('CreateDriverLampConfigsCtrl', require('./ops/driver/create/lamp_configs_step.ctrl'))
    .controller('BallastDetailsCtrl', require('./ops/ballast/details/ballast-details.ctrl'))
    .controller('BallastInfoCtrl', require('./ops/ballast/details/ballast-info.ctrl'))
    .controller('BallastLampConfigsCtrl', require('./ops/ballast/details/ballast-lamp-configs.ctrl'))
    .controller('CreateBallastCtrl', require('./ops/ballast/create/ballast-create.ctrl'))
    .controller('CreateBallastInfoCtrl', require('./ops/ballast/create/info_step.ctrl'))
    .controller('CreateBallastLampConfigsCtrl', require('./ops/ballast/create/lamp_configs_step.ctrl'))

    // enum gang-box
    .controller('GangBoxListCtrl', require('./ops/gang-box/list/list.ctrl'))
    .controller('GangBoxListDirCtrl', require('./ops/gang-box/list/gang-box-list.directive.ctrl'))
    .controller('GangBoxDetailsCtrl', require('./ops/gang-box/details/details.ctrl'))
    .controller('GangBoxInfoCtrl', require('./ops/gang-box/details/info.ctrl'))
    .controller('GangBoxInfoFormDirCtrl', require('./ops/gang-box/details/info-form.directive.ctrl'))
    .controller('GangBoxCreateCtrl', require('./ops/gang-box/create/create.ctrl'))

    // enum light-enclosure
    .controller('LightEnclosureListCtrl', require('./ops/light-enclosure/list/list.ctrl'))
    .controller('LightEnclosureDetailsCtrl', require('./ops/light-enclosure/details/details.ctrl'))
    .controller('LightEnclosureInfoCtrl', require('./ops/light-enclosure/details/info.ctrl'))
    .controller('LightEnclosureCreateCtrl', require('./ops/light-enclosure/create/create.ctrl'))

    // enum reason
    .controller('ReasonListCtrl', require('./ops/reason/list/list.ctrl'))
    .controller('ReasonListDirCtrl', require('./ops/reason/list/reason-list.directive.ctrl'))
    .controller('ReasonDetailsCtrl', require('./ops/reason/details/details.ctrl'))
    .controller('ReasonInfoCtrl', require('./ops/reason/details/info.ctrl'))
    .controller('ReasonCreateCtrl', require('./ops/reason/create/create.ctrl'))

    // enum contact_type
    .controller('ContactTypeListCtrl', require('./ops/contact-type/list/list.ctrl'))
    .controller('ContactTypeListDirCtrl', require('./ops/contact-type/list/contact-type-list.directive.ctrl'))
    .controller('ContactTypeDetailsCtrl', require('./ops/contact-type/details/details.ctrl'))
    .controller('ContactTypeInfoCtrl', require('./ops/contact-type/details/info.ctrl'))
    .controller('ContactTypeCreateCtrl', require('./ops/contact-type/create/create.ctrl'))

     // enum product_code
    .controller('ProductCodeListCtrl', require('./ops/product-code/list/list.ctrl'))
    .controller('ProductCodeListDirCtrl', require('./ops/product-code/list/pcode-list.directive.ctrl'))
    .controller('ProductCodeDetailsCtrl', require('./ops/product-code/details/details.ctrl'))
    .controller('ProductCodeInfoCtrl', require('./ops/product-code/details/info.ctrl'))
    .controller('ProductCodeCertCtrl', require('./ops/product-code/details/cert.ctrl'))
    .controller('ProductCodeInfoFormDirCtrl', require('./ops/product-code/details/info-form.directive.ctrl'))
    .controller('PCodeChStateCtrl', require('./ops/product-code/details/change-state.ctrl'))
    .controller('PCodeReqCertCtrl', require('./ops/product-code/details/req-cert.ctrl'))
    .controller('ProductCodeCreateCtrl', require('./ops/product-code/create/create.ctrl'))
    .controller('PCodeRolesCtrl', require('./ops/product-code/details/roles.ctrl'))
    .controller('PCodeFirmwareCtrl', require('./ops/product-code/details/firmware.ctrl'))
    /* Register Services */
    .factory('RegulatorSvc', require('./ops/regulator/regulator.svc'))

    /* Register Filters */

    /* Register Directives */
    .directive('enlControlUnitForm', require('./ops/control-unit/details/info-form.directive'))
    .directive('enlControlUnitFormViewOnly', require('./ops/control-unit/details/view-only/info-form.directive'))
    .directive('enlControlUnitTable', require('./ops/control-unit/list/control-unit-list.directive'))
    .directive('enlLampInfoForm', require('./ops/lamp/directive/lamp-form.directive'))
    .directive('enlLampInfoFormViewOnly', require('./ops/lamp/directive/view-only/lamp-form.directive'))
    .directive('enlDeviceTypeInfoForm', require('./ops/device_type/directive/device_type-form.directive'))
    .directive('enlDeviceTypeInfoFormViewOnly', require('./ops/device_type/directive/view-only/device_type-form.directive'))
    .directive('enlPhoneTypeInfoForm', require('./system/phone_type/directive/phone_type-form.directive'))
    .directive('enlPhoneTypeInfoFormViewOnly', require('./system/phone_type/directive/view-only/phone_type-form.directive'))
    .directive('enlRegulatorDataTable', require('./ops/regulator/list/regulator-list-table.directive'))
    .directive('enlRegulatorInfoForm', require('./ops/regulator/details/regulator-info-form.directive'))
    .directive('enlRegulatorInfoFormViewOnly', require('./ops/regulator/details/view-only/regulator-info-form.directive'))
    .directive('enlLampConfigWidget', require('./ops/regulator/details/regulator-lamp-configs.directive'))
    .directive('enlLampConfigWidgetViewOnly', require('./ops/regulator/details/view-only/regulator-lamp-configs.directive'))
    .directive('enlGangBoxTable', require('./ops/gang-box/list/gang-box-list.directive'))
    .directive('enlChart', require('./ops/regulator/details/chart/chart.directive'))
    .directive('enlWattageEntryEditor', require('./ops/regulator/details/wattage-entry-editor.directive'))
    .directive('enlGangBoxForm', require('./ops/gang-box/details/info-form.directive'))
    .directive('enlGangBoxFormViewOnly', require('./ops/gang-box/details/view-only/info-form.directive'))
    .directive('enlWattageEntry', require('./ops/regulator/details/wattage-entry.directive'))
    .directive('enlWattageWidget', require('./ops/regulator/details/wattage-widget.directive'))
    .directive('enlReasonTable', require('./ops/reason/list/reason-list.directive'))
    .directive('enlContactTypeTable', require('./ops/contact-type/list/contact-type-list.directive'))
    .directive('enlRestrict', require('./ops/regulator/details/conflict.directive'))
    .directive('enlAlphaNumericId', require('./ops/alphanumericid_check/val_check.directive'))
    .directive('enlMdeviceInfoForm', require('./mdevice/directive/mdevice-form.directive'))
    .directive('enlProductCode', require('./ops/product-code/list/pcode-list.directive'))
    .directive('enlProductCodeForm', require('./ops/product-code/details/info-form.directive'))
    .directive('enlProductCodeFormViewOnly', require('./ops/product-code/details/view-only/info-form.directive'))
    .directive('enlMultiMdeviceInfoForm', require('./mdevice/directive/multi-mdevice-form.directive'))
    ;
})();
