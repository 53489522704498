/* @ngInject */
module.exports = function ($scope, ConfirmDialogSvc, EnlightedAPISvc, EnlightedAPIEnumsSvc, $q, $filter, $state, BannerSvc, $rootScope, EnlightedAPIErrorSvc) {
  var vm = this,

    I18N_KEYS = [
      'CREATE_MDEVICE_SUCCESS',
      'CREATE_MDEVICE_FAILURE',
      'CONFIRM_CANCEL_DIALOG',
      'CONFIRM_CANCEL_CREATE_MSG',
      'CONFIRM_CANCEL',
      'MDEVICE_SINGLE_CREATE_TITLE',
      'MDEVICE_MULTI_CREATE_TITLE'
    ],
    isInit = false,

    DICTIONARY = {};

  //============= MODELS ============//
  vm.createToggle = {};
  vm.mdeviceObj = {};
  vm.infoForm = {};
  vm.batchForm = {};
  vm.batchObj = {};
  vm.manufacturers = [];
  vm.filteredManufacturers = [];
  vm.product_codes_sd = [];   //product code list for single device creation
  vm.product_codes_md = [];   //product code list for single device creation
  vm.setProductCodesDropdown_sd = setProductCodesDropdown_sd;
  vm.setProductCodesDropdown_md = setProductCodesDropdown_md;
  //============= FUNCTIONS ============//
  vm.showConfirmCancel = showConfirmCancel;
  vm.saveSingle = saveSingle;
  vm.saveBatch = saveBatch;
  vm.createMode = createMode;
  //single mdevice = 0, multiple = 1
  vm.mode = 0;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('create_mdevice.ctrl: activate');

    var promises = [
      EnlightedAPISvc.manufacturer.getAll(),
      EnlightedAPISvc.product_code.getAll()
    ];

    $q.all(promises).then(function(res){

      jQuery.each(res[0].enums,function(idx1){
        vm.manufacturers.push(res[0].enums[idx1]);
        vm.manufacturers[vm.manufacturers.length-1].children_ui = [];


        jQuery.each(res[1].enums,function(idx2){
          if(vm.manufacturers[vm.manufacturers.length-1].val === res[1].enums[idx2].parent ){
            vm.manufacturers[vm.manufacturers.length-1].children_ui.push(res[1].enums[idx2]);
          }
        });

      });

      //filtering to the orgs that have product codes
      jQuery.each(vm.manufacturers,function(idx){
        if(vm.manufacturers[idx].children_ui.length !== 0){
          vm.filteredManufacturers.push(vm.manufacturers[idx]);
        }
      });


      setProductCodesDropdown_sd();
      setProductCodesDropdown_md();

      console.log('create_mdevice.ctrl: activate');

      angular.forEach(I18N_KEYS, function (key) {
        DICTIONARY[key] = $filter('translate')(key);
      });

      vm.createToggle.single = {
        display: DICTIONARY.MDEVICE_SINGLE_CREATE_TITLE,
        selected: true,
        hideIcon: true
      };

      vm.createToggle.multiple = {
        display: DICTIONARY.MDEVICE_MULTI_CREATE_TITLE,
        selected: false,
        hideIcon: true
      };
    });
  }

  function setProductCodesDropdown_sd(){
    var temp =  _.find(vm.filteredManufacturers, function(obj){
      return obj.val == vm.mdeviceObj.manufacturer;
    });

    if(temp){
      vm.product_codes_sd = angular.copy(temp.children_ui);
    }else{
      vm.product_codes_sd = [];
    }
  }
  function setProductCodesDropdown_md(){
    var temp =  _.find(vm.filteredManufacturers, function(obj){
      return obj.val == vm.batchObj.manufacturer;
    });

    if(temp){
      vm.product_codes_md = angular.copy(temp.children_ui);
    }else{
      vm.product_codes_md = [];
    }
  }



  function createMode(mode){
    vm.mode = mode;

    if (mode === 0) {
      vm.createToggle.single.selected = true;
      vm.createToggle.multiple.selected = false;
    } else {
      vm.createToggle.single.selected = false;
      vm.createToggle.multiple.selected = true;
    }
  }



  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        $state.go('settings.mdevice.list');
      });
  }

  function saveSingle() {
    var ajaxObj = {};

    ajaxObj.state = 'MANUFACTURING';
    ajaxObj.kind = 'mdevice';
    ajaxObj.mac = {};
    ajaxObj.mac.pcode = vm.mdeviceObj.manufacturer + ':' + vm.mdeviceObj.pcode;
    ajaxObj.mac.address = vm.mdeviceObj.macaddr;


    EnlightedAPISvc.ManufacturedDevice.create(ajaxObj)
      .then(function (mdeviceRsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: DICTIONARY.CREATE_MDEVICE_SUCCESS,
          el: '#content'
        });
        $state.go('settings.mdevice.list');

      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.CREATE_MDEVICE_FAILURE, error),
          el: '#content'
        });
      });
  }


    function saveBatch() {
    /*
    var ajaxObj = {};

    ajaxObj.state = 'MANUFACTURING';
    ajaxObj.kind = 'mdevice';
    ajaxObj.mac = {};
    ajaxObj.mac.pcode = vm.batchObj.manufacturer + ':' + vm.batchObj.pcode;
    ajaxObj.mac.address = vm.batchObj.macaddr;
    */

    var macstart = vm.batchObj.macaddr_start.split(":").join("");
    var macend = vm.batchObj.macaddr_end.split(":").join("");
    console.log("yoyoyo");
    console.log(vm.batchObj);



    EnlightedAPISvc.ManufacturedDevice.bulkCreate(vm.batchObj.manufacturer + ':' + vm.batchObj.pcode,macstart,macend)
      .then(function (mdeviceRsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: DICTIONARY.CREATE_MDEVICE_SUCCESS,
          el: '#content'
        });
        $state.go('settings.mdevice.list');

      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.CREATE_MDEVICE_FAILURE, error),
          el: '#content'
        });
      });
  }
};
