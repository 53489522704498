/* @ngInject */
module.exports = function ($scope, $translate, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, localizeFilter, ViewOnly) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE',
      'AVAIL_SUBTYPES',
      'SEL_SUBTYPES',
      'NO_SUBTYPES'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.origEnumObj = undefined;
  vm.enumObj       = undefined;
  vm.hasAsync      = false;
  vm.infoForm      = {};
  vm.isLoaded      = false;
  vm.wrapper       = {};

  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;
  vm.handleSelectionChange = handleSelectionChange;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('system facility-type info.ctrl: activate ');

    $scope.$on('FACILITY_TYPE_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('system facility-type info.ctrl: init', enumObj);

    var promises = [];

    angular.forEach(I18N_KEYS, function (key) {
      promises.push($translate(key));
    });

    $q.all(promises).then(function (translations) {
      angular.forEach(translations, function (translate, index) {
        DICTIONARY[I18N_KEYS[index]] = translate;
      });

      createEnumDisplay(enumObj).then(function (okViewObject) {
        vm.enumObj = okViewObject;
        // copy original object to retain information for restore
        vm.origEnumObj = angular.copy(vm.enumObj);
        vm.isLoaded = true;
      });

    });

    vm.apiEnum = enumObj;
    // transform to have facility_type obj model
    vm.enumObj = {};

  }

  function createEnumDisplay(enumObj) {
    var deferred = $q.defer();
    createOptionsForMultiSelect(enumObj.subtypes).then(function (okOptions) {
      deferred.resolve({
        val: enumObj.val,
        dname: (enumObj.names) ? enumObj.names[''] : undefined,
        ddescription: (enumObj.descriptions) ? enumObj.descriptions[''] : undefined,
        subtypes: okOptions,
        physical: enumObj.physical,
        floors: enumObj.floors,
        eid: enumObj.eid
      });
    });
    return deferred.promise;
  }

  function collectSubtypes(enums) {
    var subtypesList = [];
    angular.forEach(enums, function (item, index) {
      subtypesList.push(localizeFilter(item.names));
    });
    return subtypesList;
  }

  function setColumns(columns, subtypes) {
    // if there is any subtype assigned then map it to columns[1]
    if (subtypes.length) {
      columns[1].list = subtypes.map(function (subtype, index) {
        return {
          display: subtype.capitalize(),
          id: index,
          disable: true
        };
      });
      columns[0].list = vm.subtypesFiltered.objDiff(columns[1].list, "display");
    }
    // else just set column 0 to types collect and column1 empty
    else {
      columns[0].list = vm.subtypesFiltered;
      columns[1].list = [];
    }
    return columns;
  }

  function createOptionsForMultiSelect(subtypes) {
    var deferred = $q.defer();
    var subtypesPromise = getAllSubTypes();
    var wrapper = {};
    var columns = [{},{}];
    vm.subtypesFiltered = [];

    // columns "config" for title and emptyListText
    columns[0].title = DICTIONARY.AVAIL_SUBTYPES;
    columns[1].title = DICTIONARY.SEL_SUBTYPES;
    columns[0].emptyListText = columns[1].emptyListText = DICTIONARY.NO_SUBTYPES;

    subtypesPromise.then(function (resp) {
      var subtypesList = collectSubtypes(resp.enums);

      // sort to have them display correctly
      subtypesList.sort(function (a, b) {
        return a > b;
      });

      // filter repeated items and map them to the correct object type
      vm.subtypesFiltered = subtypesList.map(function (sub, index) {
        return {
          display: sub,
          id: index
        };
      });
      // add columns to object wrapper
      wrapper.columns = setColumns(columns, subtypes);

      console.log("WRAPPER LOG:", wrapper);
      deferred.resolve(wrapper);
    }, function (err) {
      deferred.resolve({});
    });

    return deferred.promise;
  }

  function getAllSubTypes () {
    return EnlightedAPI.facility_type.getAll();
  }

  function restore() {
    //console.log("RESTORE vm.enumObj WITH vm.origEnumObj ", "OBJECT MODEL: ", vm.enumObj, "ORIGINAL OBJECT:", vm.origEnumObj);
    vm.enumObj = angular.copy(vm.origEnumObj);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    console.log('facility-type-info.ctrl: save - ', vm.enumObj);

    vm.hasAsync = true;
    vm.apiEnum = updateAPIEnum(vm.apiEnum, vm.enumObj);

    EnlightedAPISvc.facility_type.update(vm.apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        vm.apiEnum = rsp.enum;
        createEnumDisplay(vm.apiEnum).then(function (okViewObject) {
          vm.enumObj = okViewObject;
          // copy original object to retain information for restore
          vm.origEnumObj = angular.copy(vm.enumObj);
        });

        // not sure why this was here, don`t want to set pristine if update was done
        vm.infoForm.$setPristine();

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.origEnumObj.val }), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  function updateAPIEnum(apiEnum, enumObj) {
    console.log(apiEnum);
    apiEnum.val = enumObj.val;
    apiEnum.names[''] = enumObj.dname;
    apiEnum.descriptions = apiEnum.descriptions || {};
    apiEnum.descriptions[''] = enumObj.ddescription || "";
    apiEnum.physical = enumObj.physical;
    apiEnum.eid = enumObj.eid;

    return apiEnum;
  }

  function updateSubtypes(apiEnum, listUpdated) {
    apiEnum.subtypes = listUpdated.map(function (listItem) {
      return listItem.display;
    });
    apiEnum.floors = (apiEnum.subtypes.length > 0) ? false : true;
  }

  function handleSelectionChange(listA, listB) {
    // set dirty on list update
    vm.infoForm.$setDirty();
    updateSubtypes(vm.apiEnum, listB);
  }

  /* helper */
  Array.prototype.objDiff = function(arr, prop) {
    var bIds = {};
    arr.forEach(function(obj){
        bIds[obj[prop]] = obj;
    });

    // Return all elements in A, unless in B
    return this.filter(function(obj){
        return !(obj[prop] in bIds);
    });
  };

  String.prototype.capitalize = function () {
    return this[0].toUpperCase() + this.slice(1);
  };

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("facility type form");
    } else {
      ViewOnly.delete("facility type form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("facility type form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("facility type form");
  });
};
