/* @ngInject */
module.exports = function ($scope, $state, $translate, BannerSvc) {
  var

  vm = this;

  activate();

  function activate() {
    //console.log('settings.ctrl: activate');

    // Change the header
    $translate('GLOBAL_SETTINGS')
      .then(function (translation) {
        $scope.currentApp.title =  translation;
      });
    $scope.headerOptions.showOrgDropdown = false;
    $scope.headerOptions.showAppsDropdown = false;

    if ($state.is('settings')) {
      $state.go('settings.system.list');
    }

    // Clear any banners on ui route state change
    $scope.$on('$stateChangeSuccess', function () {
      if (BannerSvc.isError()) {
        BannerSvc.clear();
      }
    });
  }
};
