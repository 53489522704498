/* @ngInject */
module.exports = function (dongleVm, $mdDialog, $scope, $q, $translate, EnlightedAPISvc, EnlightedAPIEnumsSvc, ConfirmDialogSvc, BannerSvc, $rootScope, $timeout, EnlightedAPIErrorSvc) {

  var vm = this,
      I18N_KEYS = [
        'UPDATE_DONGLE_SUCCESS',
        'UPDATE_DONGLE_FAILURE'
      ],
      DICTIONARY = {};

  //============= MODELS ============//
  $timeout(function(){
    vm.dongleStates = EnlightedAPIEnumsSvc.getDoglStates();
  },0);
  vm.selectedState = undefined;
  //============= FUNCTIONS ============//
  vm.cancel = $mdDialog.cancel;
  vm.curDongle = dongleVm;
  vm.changeState = changeState;
  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('change-state.ctrl: activiate');
    console.log(vm.curDongle);
    init();
  }

  function init() {
    console.log('change-state.ctrl: initialized');

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });
    });

  }

  function changeState(dongeAPIModel) {

    console.log('change-state.ctrl: save - ', dongeAPIModel);

    EnlightedAPISvc.Dongle.update(dongeAPIModel)
      .then(function (dongleRsp) {

        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: DICTIONARY.UPDATE_DONGLE_SUCCESS,
          el:'#content'
        });
        $mdDialog.hide();

      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.UPDATE_DONGLE_FAILURE, error),
          el:'#content'
        });
        $mdDialog.cancel();
      });
  }
};
