/* @ngInject */
module.exports = function ($scope, EnlightedAPISvc, EnlightedAPIEnumsSvc, localizeFilter, $q, $translate, $state) {
  var

  vm = this;

  //============= MODELS ============//
  vm.enums         = [];
  vm.isLoaded      = false;
  vm.manufacturers = [];
  vm.options       = { title: 'driver' };

  //============= FUNCTIONS ============//

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    if ($scope.init) {
      init();
    }

    $scope.$watch(function () {
      return $scope.init;
    }, function (newVal, oldVal) {
      if (newVal === true) {
        init();
      }
    });
  }

  function init() {
    var promises = [];

    promises.push(EnlightedAPISvc.driver.getAll());
    promises.push(EnlightedAPIEnumsSvc.getEnum('manufacturer'));

    $q.all(promises)
      .then(function (resolutions) {
        var
        drivers = resolutions[0].enums,
        manufacturers = resolutions[1],
        translations = resolutions[2],
        displayEnums = [];

        angular.forEach(drivers, function (d) {
          var manufacturerObj = _.find(manufacturers, { val: d.parent });

          displayEnums.push({
            eid          : d.eid,
            part_number  : d.val,
            name         : localizeFilter(d.names),
            manufacturer : manufacturerObj ? localizeFilter(manufacturerObj.names) : '',
            channels     : d.channels
          });
        });

        vm.enums = displayEnums;

        return $q.when();
      }, function (errors) {
        console.log('driver-list.ctrl: activate errors', errors);
        // TODO: what should we do if unable to fetch?
        return $q.reject();
      })
      .finally(function () {
        vm.isLoaded = true;
      });
  }
};
