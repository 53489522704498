/* @ngInject */
module.exports = function ($scope, $translate, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, ViewOnly, $timeout) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.origEnumObj   = undefined;
  vm.enumObj       = undefined;
  vm.hasAsync      = false;
  vm.infoForm      = {};
  vm.isLoaded      = false;
  vm.wrapper       = {};
  vm.manufacturers = [];

  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;

  activate();

  // $timeout(function () {
  //   vm.showform = true;
  // }, 1000);

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops luminaire info.ctrl: activate ');
    $scope.$on('LUMINAIRE_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('ops luminaire info.ctrl: init', enumObj, vm, $scope);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    vm.enumObj = {};
    getSVGProps();

    getManufacturers().then(function () {
      vm.apiEnum = enumObj;
      // transform to have app_type obj model
      vm.enumObj = createEnumDisplay(enumObj);
      // copy original object to retain information for restore
      vm.origEnumObj = angular.copy(vm.enumObj);
      vm.isLoaded = true;
    });
  }

  function getSVGProps() {
    EnlightedAPISvc.Session.getSession().then(function (res) {
      vm.enumObj.svgProps = res.properties;
    });
  }

  function getManufacturers() {
    var deferred = $q.defer();
    EnlightedAPI.manufacturer.getAll().then(function (resp) {
      vm.manufacturers = [];
      angular.forEach(resp.enums, function (e) {
        vm.manufacturers.push(e);
      });

      deferred.resolve();
    });

    return deferred.promise;
  }

  function createEnumDisplay(enumObj) {
    return {
      manufacturer : enumObj.parent,
      val : enumObj.val,
      name : enumObj.names[''],
      description : (enumObj.descriptions) ? enumObj.descriptions[''] : undefined,
      svg : enumObj.svg,
      width : enumObj.width,
      height : enumObj.height,
      eid : enumObj.eid
    };
  }

  function restore() {
    console.log("RESTORE vm.enumObj WITH vm.origEnumObj ", "OBJECT MODEL: ", vm.enumObj, "ORIGINAL OBJECT:", vm.origEnumObj);
    vm.enumObj = angular.copy(vm.origEnumObj);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    console.log('luminaire-info.ctrl: save - ', vm.enumObj);

    vm.hasAsync = true;
    vm.apiEnum = updateAPIEnum(vm.apiEnum, vm.enumObj);

    console.log("DATA SENT:", vm.apiEnum);

    EnlightedAPISvc.lampcfg.update(vm.apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        console.log("RESPONSE AFTER SAVE", rsp.enum, vm.apiEnum);
        vm.apiEnum = rsp.enum;

        vm.enumObj = createEnumDisplay(rsp.enum);
        // copy original object to retain information for restore
        vm.origEnumObj = angular.copy(vm.enumObj);

        vm.infoForm.$setPristine();

        vm.isLoaded = true;

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.origEnumObj.val }), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  function updateAPIEnum(apiEnum, enumObj) {
    apiEnum.eid = enumObj.eid;
    apiEnum.val = enumObj.val;
    apiEnum.descriptions = apiEnum.descriptions || {};
    apiEnum.descriptions[''] = enumObj.description || "";
    apiEnum.names = enumObj.names || {};
    apiEnum.names[''] = enumObj.name || "";
    apiEnum.svg = enumObj.svg;
    apiEnum.width = enumObj.width;
    apiEnum.height = enumObj.height;
    apiEnum.width.units = apiEnum.height.units;

    return apiEnum;
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("luminaire form changes");
    } else {
      ViewOnly.delete("luminaire form changes");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("luminaire form changes")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("luminaire form changes");
  });
};
