/* @ngInject */
module.exports = function (dongleVm, $mdDialog, $scope, $q, $translate, EnlightedAPISvc, EnlightedAPIEnumsSvc, ConfirmDialogSvc, BannerSvc, $rootScope, $filter, EnlightedAPIErrorSvc) {

  var vm = this,
      orgGid,
      I18N_KEYS = [
        'UPDATE_DONGLE_SUCCESS',
        'UPDATE_DONGLE_FAILURE'
      ],
      DICTIONARY = {};
  //============= MODELS ============//


  //============= FUNCTIONS ============//
  vm.cancel = $mdDialog.cancel;
  vm.curDongle = dongleVm;
  vm.changeOwner = changeOwner;
  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('change-owner.ctrl: activate');
    console.log(vm.curDongle);

    init();
  }

  function init() {
    console.log('change-owner.ctrl: initialized');



    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });
    });


    getAllOrgInfo().then(handleSuccess(), handleErr());

  }

  function getAllOrgInfo() {
    return EnlightedAPISvc.Organization.getAll(false, false);
  }

  function handleSuccess() {
    return function(orgListRsp) {
      vm.orgs = orgListRsp.objs;
      vm.orgs.sort();
    };
  }

  function handleErr() {
    return function(error) {
      return $q.reject(error);
    };
  }

  function changeOwner(dongeAPIModel) {

    console.log('change-ownership.ctrl: save - ', dongeAPIModel);

    EnlightedAPISvc.Dongle.update(dongeAPIModel)
      .then(function (dongleRsp) {

        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: DICTIONARY.UPDATE_DONGLE_SUCCESS,
          el:'#content'
        });

        $mdDialog.hide();
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.UPDATE_DONGLE_FAILURE, error),
          el:'#content'
        });
        $mdDialog.cancel();
      });

  }
};
