/* @ngInject */
module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      obj : '=',
      form    : '=',
      enlMode : '@'
    },
    templateUrl: 'app-container/components/global-settings/ops/lamp/directive/lamp-form.template.html',
    controller: 'LampInfoDirCtrl as lampFormCtrl'
  };
};
