/* @ngInject */
module.exports = function ($scope, $q, $translate, EnlightedAPISvc, EnlightedAPIEnumsSvc, ConfirmDialogSvc, BannerSvc, $rootScope, $filter, EnlightedAPIErrorSvc) {
  var vm = this,
    orgGid,
    I18N_KEYS = [
      'DONGLES',
      'DT_PAGE',
      'DT_ROWS_PER_PAGE',
      'DT_OF',
      'DONGLE_DELETE_SUCCESS_PLURAL',
      'DONGLE_DELETE_FAILED_PLURAL',
      'CONFIRM_DELETE',
      'CONFIRM_DELETE_ARIA_LABEL',
      'DONGLE_OBJECT_TYPE_PLURAL',
      'DONGLE_OBJECT_TYPE'
    ],
    DICTIONARY = {};

  //============= MODELS ============//
  vm.isLoaded = false;
  vm.filter = {};
  vm.query = {};
  vm.selected = [];
  vm.dongles = [];
  vm.lastUser = undefined;
  vm.owner = undefined;

  //============= FUNCTIONS ============//
  vm.deleteDongles = deleteDongles;
  vm.showDeleteConfirm = showDeleteConfirm;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('dongles.list.ctrl: activate');

    init();
  }

  function init() {
    console.log('dongle-list.ctrl: init');
    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });
    });

    orgGid = $rootScope.orgId;

    vm.query = {
      order: 'address',
      limit: 15,
      page: 1,
      label: {
        page: DICTIONARY.DT_PAGE,
        rowsPerPage: DICTIONARY.DT_ROWS_PER_PAGE,
        of: DICTIONARY.DT_OF
      }
    };
    EnlightedAPISvc.Dongle.getAll(false, true)
      .then(handleGetDonglesSucc(), handleGetDonglesErr());
  }


  function handleGetDonglesSucc() {
    return function (donglesRsp) {
      var tableDongles = [];

      angular.forEach(donglesRsp.objs, function (dongle) {

        var lastUserName = '';
        var orgOwnerName = '';
        var orgOwnerGid = '';
        var lu = '';

        if (dongle.org_owner) {
          orgOwnerGid = dongle.org_owner;
        }

        if (dongle.references && dongle.references.org_owner) {
          orgOwnerName = dongle.references.org_owner.name;
          console.log(orgOwnerName);
        }

        if (dongle.references && dongle.references.last_user) {
          lastUserName = dongle.references.last_user.name;
        }
        if (dongle.last_used) {
          lu = new Date(dongle.last_used).toLocaleString();
        }

        tableDongles.push({
          id: dongle.gid,
          address: $filter('formatMAC')(dongle.mac.address),
          model: dongle.mac.pcode,
          state: dongle.state,
          last_user: dongle.last_user,
          last_used: lu,
          org_owner: orgOwnerGid,
          last_user_name: lastUserName,
          owner_name: orgOwnerName
        });

      });
      vm.dongles = tableDongles;
      vm.isLoaded = true;
    };
  }

  function handleGetDonglesErr() {
    return function (err) {
      return $q.reject(err);
    };
  }

  function deleteDongles(selected) {
    var promises = [];
    angular.forEach(selected, function (dongle) {
      promises.push(EnlightedAPISvc.Dongle.delete(dongle.id));
    });

    $q.all(promises).then(function () {
      // remove deleted dongles form dongle lsit
      vm.dongles = _.difference(vm.dongles, selected);
      BannerSvc.show({
        scope: $rootScope,
        type: 'success',
        autoClose: true,
        content: $translate.instant('DELETE_SUCCESS_PLURAL', {objects: DICTIONARY.IDP_OBJECT_TYPE_PLURAL})
      });
      vm.selected = [];
    }, function (error) {
      BannerSvc.show({
        scope: $rootScope,
        type: 'error',
        autoClose: false,
        content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('DELETE_FAILED_PLURAL', {objects: DICTIONARY.IDP_OBJECT_TYPE_PLURAL}), error)
      });
      vm.selected = [];
    });
  }

  function showDeleteConfirm(selected, ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_DELETE_ARIA_LABEL,
      title: DICTIONARY.CONFIRM_DELETE,
      message: (selected.length > 1) ?
        $translate.instant('CONFIRM_DELETE_MESSAGE_LIST_PLURAL') :
        $translate.instant('CONFIRM_DELETE_MESSAGE_LIST')
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        vm.deleteDongles(vm.selected);
      });
  }

};
