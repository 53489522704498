/* @ngInject */
module.exports = function ($scope) {
  var

  vm = this;

  //============= MODELS ============//
  vm.DECIMAL_REGEX = /^\d+\.\d+$/;

  //============= FUNCTIONS ============//
  vm.cancel            = cancel;
  vm.save              = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
  }

  function cancel() {
    if (typeof $scope.onCancel() === 'function') {
      $scope.onCancel()();
    }
  }

  function save() {
    if (typeof $scope.onSave() === 'function') {
      $scope.onSave()(angular.copy($scope.entry));
    }
  }
};
