(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(factory);
    } else if (typeof exports === 'object') {
        // Node, CommonJS-like
        module.exports = factory();
    } else {
        // Browser globals (root is window)
        root.appData = factory();
    }
}(this, function () {
    var appData = {
        "code": {
            "val": 200008,
            "message": "ENUMS_RETRIEVED"
        },
        "kind": "app_type",
        "enums": [{
            "kind": "app_type",
            "val": "device_mgmt",
            "modified": 1448472500839,
            "modified_user": "AANhcGkABmdsb2JhbBOBQAAd0hGygICAgICAgIATgUAAHdIRsoCAgICAgICA",
            "trans_id": "13814000-1dd2-11b2-8080-808080808080",
            "names": {
                "": "Device Management"
            },
            "version": "1.0",
            "release": "development",
            "ui_url": "http://localhost:8001/"
        }, {
            "kind": "app_type",
            "val": "facility_mgmt",
            "modified": 1448472500839,
            "modified_user": "AANhcGkABmdsb2JhbBOBQAAd0hGygICAgICAgIATgUAAHdIRsoCAgICAgICA",
            "trans_id": "13814000-1dd2-11b2-8080-808080808080",
            "names": {
                "": "Facility Management"
            },
            "version": "1.0",
            "release": "published",
            "ui_url": "http://localhost:9000/"
        }, {
            "kind": "app_type",
            "val": "lightning_mgmt",
            "modified": 1448472500839,
            "modified_user": "AANhcGkABmdsb2JhbBOBQAAd0hGygICAgICAgIATgUAAHdIRsoCAgICAgICA",
            "trans_id": "13814000-1dd2-11b2-8080-808080808080",
            "names": {
                "": "Lighting Management"
            },
            "version": "1.0",
            "release": "development",
            "ui_url": "http://getbootstrap.com/"
        }, {
            "kind": "app_type",
            "val": "project_mgmt",
            "modified": 1448472500839,
            "modified_user": "AANhcGkABmdsb2JhbBOBQAAd0hGygICAgICAgIATgUAAHdIRsoCAgICAgICA",
            "trans_id": "13814000-1dd2-11b2-8080-808080808080",
            "names": {
                "": "Project Management"
            },
            "version": "1.0",
            "release": "published",
            "ui_url": "http://localhost:3000"
        }, {
            "kind": "app_type",
            "val": "sysadmin",
            "modified": 1448472500839,
            "modified_user": "AANhcGkABmdsb2JhbBOBQAAd0hGygICAgICAgIATgUAAHdIRsoCAgICAgICA",
            "trans_id": "13814000-1dd2-11b2-8080-808080808080",
            "names": {
                "": "System Administration"
            },
            "version": "1.0",
            "release": "published",
            "ui_url": "https://api.enlightedinc.co/js/api.jquery.js"
        }, {
            "kind": "app_type",
            "val": "test",
            "modified": 1448472500839,
            "modified_user": "AANhcGkABmdsb2JhbBOBQAAd0hGygICAgICAgIATgUAAHdIRsoCAgICAgICA",
            "trans_id": "13814000-1dd2-11b2-8080-808080808080",
            "names": {
                "": "Test App"
            },
            "version": "1.0",
            "release": "development",
            "ui_url": "https://design.google.com/"
        }, {
            "kind": "app_type",
            "val": "org_mgmt",
            "modified": 1448472500839,
            "modified_user": "AANhcGkABmdsb2JhbBOBQAAd0hGygICAgICAgIATgUAAHdIRsoCAgICAgICA",
            "trans_id": "13814000-1dd2-11b2-8080-808080808080",
            "names": {
                "": "Organization Management"
            },
            "version": "1.0",
            "release": "development",
            "ui_url": "http://localhost:4000"
        }]
    };

    //    exposed public method
    return appData;
}));
