/* @ngInject */
module.exports = function ($scope, ConfirmDialogSvc, EnlightedAPISvc, EnlightedAPIEnumsSvc, $q, $filter, $state, BannerSvc, $rootScope, IdpSvc, EnlightedAPIErrorSvc, $translate) {
  var vm = this,

    orgGid,

    I18N_KEYS = [
      'CREATE_IDP_SUCCESS',
      'CREATE_IDP_FAILURE',
      'CONFIRM_CANCEL_DIALOG',
      'CONFIRM_CANCEL_CREATE_MSG',
      'CONFIRM_CANCEL'
    ],
    isInitialized = false,

    DICTIONARY = {};

  //============= MODELS ============//
  vm.idpAPIModel = {};
  vm.idpInfoForm = {};
  vm.providers = [];
  vm.idp = {};
  vm.idpTypes = [];
  vm.orgs = [];

  //============= FUNCTIONS ============//
  vm.showConfirmCancel = showConfirmCancel;
  vm.save = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('create_idp.ctrl: activate');
    init();
  }

  function init() {
    console.log('create_idp.ctrl: init');
    orgGid = $rootScope.orgGid;
    vm.idpTypes = EnlightedAPIEnumsSvc.getIdpTypes();
    vm.idp.windows_only = true;

    angular.forEach(I18N_KEYS, function (key) {
      DICTIONARY[key] = $filter('translate')(key);
    });

    if (isInitialized) {
      return;
    }
    EnlightedAPISvc.IdentityProvider.getAll()
      .then(function (result) {
        vm.providers = filterIdps(result.objs);
        return EnlightedAPISvc.Organization.getAll();
      }, function () {
        // TODO: error handling
      }).then(function (orgsRsp) {
        vm.orgs = [{}].concat(orgsRsp.objs);
        isInitialized = true;
    }, function () {
      // TODO: error handling
    });
  }

  function filterIdps(idpList) {
    var result = [];
    angular.forEach(idpList, function (idp) {
      if (typeof idp.org_owner === 'undefined') {
        result.push(idp);
      }
    });
    return result;
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        $state.go('settings.idps.list');
      });
  }

  function save() {
    vm.idpAPIModel = IdpSvc.prepareIdpAPIModel(vm.idpAPIModel, vm.idp);
    if (vm.idpAPIModel.org_owner == "") {
      vm.idpAPIModel.org_owner = null;
    }
    console.log('create-idp.ctrl: save - ', vm.idpAPIModel);

    EnlightedAPISvc.IdentityProvider.create(vm.idpAPIModel)
      .then(function (idpRsp) {

        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: DICTIONARY.CREATE_IDP_SUCCESS,
          el: '#content'
        });
        $state.go('settings.idps.list');

      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_IDP_FAILURE', { name: vm.idpAPIModel.name}), error),
          el: '#content'
        });
      });
  }
};
