/* @ngInject */
module.exports = function ($scope, $translate, FileSaver, Blob, $filter, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, $window) {

  var vm = this,
  I18N_KEYS = [
    'AVAILABLE_OBJECT_KINDS',
    'NO_OBJECT_KINDS_TO_DISPLAY',
    'SELECTED_OBJECT_KINDS',
    'CONFIRM_DELETE_ARIA_LABEL',
    'CONFIRM_DELETE',
    'CONFIRM_DELETE_MESSAGE_LIST'
  ],

  DICTIONARY = {};

  //============= FUNCTIONS ============//
  vm.addFirmware = addFirmware;
  vm.deleteFirmwareVersion = deleteFirmwareVersion;
  vm.downloadFirmware = downloadFirmware;
  vm.editFirmware = editFirmware;
  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops product_code firmwares.ctrl: activate');

    angular.forEach(I18N_KEYS, function (key) {
      DICTIONARY[key] = $filter('translate')(key);
    });

    $scope.$on('PRODUCT_CODE_FIRMWARES_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('ops product_code firmwares.ctrl: init', enumObj);
    // use firmware from references or empty array
    vm.firmwareList = [];
    if (typeof enumObj.references.firmwares !== "undefined") {
      angular.forEach(enumObj.references.firmwares, function (obj, key) {
        obj.trans_version = EnlightedAPI.VersionCodec.getVersionString(obj.version);
        obj.trans_min_version = EnlightedAPI.VersionCodec.getVersionString(obj.min_version);
        obj.user_name = obj.references.modified_user.user.name;
        obj.identifier = key;
        vm.firmwareList.push(obj);
      });
    }
    console.log(vm.firmwareList);
    // product code GID
    vm.enumObj = enumObj;
    vm.productEid = enumObj.eid;
    // page loaded bool
    vm.isLoaded = true;
  }

  function editFirmware(firmware, identifier) {
    vm.isLoaded = false;

    var updated_firmware = angular.copy(vm.enumObj.references.firmwares[identifier]);
    updated_firmware.min_version = firmware.min_version;
    try {
      delete updated_firmware.references;
    } catch (err) {
      console.log("cant delete references");
      vm.isLoaded = true;
    }
    // console.log(updated_firmware);
    EnlightedAPI.Firmware.update(updated_firmware).then(function (resp) {
      BannerSvc.show({
        type: 'success',
        scope: $rootScope,
        autoClose: true,
        content: $translate.instant('UPDATE_SUCCESS', {val: "firmware for " + updated_firmware.val}),
        el: '#content'
      });

      EnlightedAPI.Firmware.get(vm.productEid, resp.obj.version).then(function (resp) {
        var firmware = resp.obj;
        var version = firmware.version;
        angular.forEach(vm.firmwareList, function (item, index) {
          if (item.version === version) {
            firmware.trans_version = EnlightedAPI.VersionCodec.getVersionString(firmware.version);
            firmware.trans_min_version = EnlightedAPI.VersionCodec.getVersionString(firmware.min_version);
            firmware.identifier = EnlightedAPI.VersionCodec.getVersionString(firmware.version);
            vm.firmwareList[index] = firmware;
            getUserName(firmware.modified_user, index);
          }
        });

        vm.isLoaded = true;
      }, function (error) {
        console.log("error fetching firmware");
        vm.isLoaded = true;
      });

    }, function (error) {
      BannerSvc.show({
        type: 'error',
        scope: $rootScope,
        autoClose: false,
        content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', {val: "firmware for " + firmware.val}), error),
        el: '#content'
      });

      vm.isLoaded = true;
    });
  }

  function addFirmware(firmware, file) {
    vm.isLoaded = false;
    firmware.pcode = vm.productEid;
    firmware.val = vm.enumObj.val;
    firmware.parent = vm.enumObj.references.parent.val;
    console.log("firmware in view", firmware, file);
    EnlightedAPI.Firmware.createMultipart(firmware, file).then(function (resp) {
      BannerSvc.show({
        type: 'success',
        scope: $rootScope,
        autoClose: true,
        content: $translate.instant('CREATE_SUCCESS', {val: "firmware for " + firmware.val}),
        el: '#content'
      });

      getFirmwares().then(function () {
        vm.isLoaded = true;
      });

    }, function (error) {
      BannerSvc.show({
        type: 'error',
        scope: $rootScope,
        autoClose: false,
        content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_FAILURE', {val: "firmware for " + firmware.val}), error),
        el: '#content'
      });
      vm.isLoaded = true;
    });
  }

  function downloadFirmware(pcode, version) {
    console.log("firmware in view", pcode);
    var uri;

    if ($window.enl && $window.enl.config) {
      uri = $window.enl.config.protocol + '://' + $window.enl.config.host;
    }

    window.open(uri + "/firmware/" + pcode + "/" + version + "/download");
  }

  function deleteFirmwareVersion(pcode, id) {
    vm.isLoaded = false;
    console.log("firmware in view", pcode, id);
    EnlightedAPI.Firmware.delete(pcode, id).then(function (resp) {
      console.log(resp);
      BannerSvc.show({
        type: 'success',
        scope: $rootScope,
        autoClose: true,
        content: $translate.instant('DELETE_SUCCESS', {val: "firmware for " + resp.obj.val}),
        el: '#content'
      });
      getFirmwares().then(function () {
        vm.isLoaded = true;
      });
    }, function (error) {
      BannerSvc.show({
        type: 'error',
        scope: $rootScope,
        autoClose: false,
        content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('DELETE_FAILURE', {val: "firmware for " + pcode}), error),
        el: '#content'
      });
      vm.isLoaded = true;
    });
  }

  function getFirmwares() {
    var deferred = $q.defer();

    EnlightedAPI.Firmware.getAll(vm.productEid).then(function (enums) {
      if (enums.objs.length) {
        vm.firmwareList = [];
        angular.forEach(enums.objs, function (firmware, index) {
          firmware.trans_version = EnlightedAPI.VersionCodec.getVersionString(firmware.version);
          firmware.trans_min_version = EnlightedAPI.VersionCodec.getVersionString(firmware.min_version);
          firmware.identifier = EnlightedAPI.VersionCodec.getVersionString(firmware.version);
          vm.firmwareList.push(firmware);
          getUserName(firmware.modified_user, index);
        });
      } else {
        vm.firmwareList = [];
      }
      deferred.resolve();
    });

    return deferred.promise;
  }

  function getUserName(id, index) {
    EnlightedAPI.User.get(id).then(function (resp) {
      vm.firmwareList[index].user_name = resp.obj.name;
    });
  }
};
