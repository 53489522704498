/* @ngInject */
module.exports = function ($scope, EnlightedAPISvc, $stateParams, UserUtilsSvc, ConfirmDialogSvc, $translate, $state, BannerSvc, $rootScope, EnlightedAPIErrorSvc, $timeout) {
  var

  vm = this,

  userId;

  //============= MODELS ============//
  vm.breadcrumbLabel = undefined;
  vm.curUser = undefined;
  vm.cachedUser = undefined;
  vm.isLoaded = false;
  vm.tabIndex = 0;
  vm.userName = undefined;

  //============= FUNCTIONS ============//
  vm.onSelectContactInfoTab = onSelectContactInfoTab;
  vm.onSelectInfoTab        = onSelectInfoTab;
  vm.onSelectNotesTab       = onSelectNotesTab;
  vm.showConfirmDelete      = showConfirmDelete;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('users-details.ctrl: activate');

    // Change the header
    $translate('MY_ACCOUNT')
      .then(function (translation) {
        $scope.currentApp.title =  translation;
      });
    $scope.headerOptions.showOrgDropdown = false;
    $scope.headerOptions.showAppsDropdown = false;

    waitForUserId();
  }

  function init() {
    console.log('users-details.ctrl: init', $stateParams);

    userId = $rootScope.user.gid;

    EnlightedAPISvc.User.get(userId, false, true)
      .then(function (userRsp) {
        vm.cachedUser = userRsp.obj;
        vm.curUser = angular.copy(userRsp.obj);
        vm.userName = UserUtilsSvc.generateUIName(vm.cachedUser);
        vm.breadcrumbLabel = vm.userName;

        vm.isLoaded = true;
      });
  }

  function broadcastSelection(tabname, params) {
    const broadcastParams = angular.extend({user: vm.curUser}, params || {});
    $scope.$broadcast('USERS_' + tabname + '_TAB_SELECTED', broadcastParams );
  }

  function deleteUser() {
    EnlightedAPISvc.User.delete(vm.curUser.gid)
      .then(function () {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('DELETE_USER_SUCCESS', { username: vm.userName })
        });
        $state.go('users.list');
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('DELETE_USER_FAILURE'), error)
        });
      });
  }

  function onSelectContactInfoTab() {
    broadcastSelection('CONTACT_INFO');
  }

  function onSelectInfoTab() {
    broadcastSelection('INFORMATION', {op: 'SELECT'});
  }

  function onSelectNotesTab() {
    var notesObject = angular.copy(vm.curUser);
    $scope.$broadcast('NOTES_TAB_SELECTED', {object: notesObject});
  }

  function showConfirmDelete(ev) {
    var

    objectType = $translate.instant('USER_OBJECT_TYPE'),

    opts = {
      ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
      title: $translate.instant('CONFIRM_DELETE'),
      message: $translate.instant('CONFIRM_DELETE_MESSAGE', {objectType: objectType})
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        deleteUser();
      });
  }

  function waitForUserId() {
    if (!$rootScope.user) {
      $timeout(function () {
        waitForUserId();
      }, 300);
    } else {
      init();
    }
  }
};
