/* @ngInject */
module.exports = function ($scope, $q, $stateParams, $translate, EnlightedAPISvc, BannerSvc, $rootScope, $state, ConfirmDialogSvc, EnlightedAPIErrorSvc, MAXLENGTH) {
  var

  vm = this,

  I18N_KEYS = [
    'CONFIRM_CANCEL_DIALOG',
    'CONFIRM_CANCEL',
    'CONFIRM_CANCEL_CREATE_MSG'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.enumObj  = {};
  vm.baseObj  = {};
  vm.infoForm = {};
  vm.isLoaded = false;
  vm.kind     = undefined;
  vm.curConfig = {};
  vm.curConfig.origVoltage = -9999;
  vm.curConfig.wattages = [];
  vm.options  = {};
  vm.manufacturers = [];
  vm.step1 = {
    disabled: false,
    completed: false
  };

  vm.step2 = {
    disabled: true,
    completed: false
  };

  //============= FUNCTIONS ============//
  vm.save              = save;
  vm.showConfirmCancel = showConfirmCancel;
  vm.onWattagesChange = onWattagesChange;
  vm.nextStep = nextStep;
  vm.goPreviousStep = goPreviousStep;
  var maxPowerWatch = "";

  

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops lampcfg create.ctrl: activate ', $stateParams);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    EnlightedAPI.manufacturer.getAll().then(function (resp) {
      angular.forEach(resp.enums, function (e) {
        vm.manufacturers.push(e);
      });
    });

    $scope.enumType = vm.kind = "lampcfg";

    vm.enumObj = {};

    $translate('ENUM_VAL_DISPLAY_' + vm.kind.toUpperCase())
      .then(function (translation) {
        vm.options.valDisplay = translation;
        vm.isLoaded = true;
      });
  }

  function nextStep() {
    vm.step1.completed = true;
    vm.selectedStep = 1;
    vm.step2.disabled = false;
    watchMP();
  }

  function goPreviousStep() {
    vm.step1.completed = false;
    vm.selectedStep = 0;
    maxPowerWatch();
  }

  function onWattagesChange(wattages, type, newObject) {

  }

  function save() {
    console.log(vm.enumObj);

    vm.baseObj = updateAPIEnum(vm.baseObj, vm.enumObj);
    vm.baseObj = createWattagesForEnum(vm.baseObj, vm.curConfig);

    maxPowerWatch();

    console.log('basic-create.ctrl: save -', vm.baseObj);

    EnlightedAPISvc[vm.kind].create(vm.baseObj)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('CREATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        $state.go('^.list', {enumId: vm.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_FAILURE', { val: vm.enumObj.val }), error),
          el: '#content'
        });
      });
  }

  function watchMP () {
    maxPowerWatch = $scope.$watch("createCtrl.curConfig.maxpower", function (newVal, oldVal) {
      if (typeof newVal === "number" && newVal > 0) {
        var hasHundred = containsHundred(vm.curConfig.wattages);
        if (!hasHundred) {
          vm.curConfig.wattages.push({
            percentage: 100,
            wattage: newVal,
            options: {
              disablePercentage: true,
              disableDelete: true,
              disableEdit: true
            }
          });
        } else {
          var index = containsHundred(vm.curConfig.wattages, true);
          if (index > -1) {
            vm.curConfig.wattages[index].wattage = newVal;
          }
        }
      }
    }, true);
  }

  function containsHundred(arr, getIndex) {
    var found = (getIndex ? -1 : false);
    angular.forEach(arr, function (obj, ind) {
      if (parseInt(obj.percentage) === 100) {
        found = (getIndex ? ind : true);
      }
    });
    return found;
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        $state.go('^.list');
      });
  }

  function createWattagesForEnum(enumObj, curConfig) {
    var wattageListAsObject = {};
    angular.forEach(curConfig.wattages, function (w, index) {
      wattageListAsObject[w.percentage] = w.wattage;
    });

    if (typeof enumObj.wattages !== "undefined" && enumObj.wattages.hasOwnProperty(curConfig.origVoltage)) {
      delete enumObj.wattages[curConfig.origVoltage];
    }

    enumObj.wattages[curConfig.voltage] = wattageListAsObject;

    return enumObj;
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.val;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.description || "";
    apiEnumObj.channels = enumObj.controlchannels;
    apiEnumObj.factor = enumObj.factor;
    apiEnumObj.initial_lumens = enumObj.initiallummens;
    apiEnumObj.design_lumens = enumObj.designlummens;
    apiEnumObj.life_ins_start = enumObj.avginstreg;
    apiEnumObj.life_prog_start = enumObj.avgprogreg;
    apiEnumObj.cri = enumObj.coloridx;
    apiEnumObj.color_temp = enumObj.colortemp;
    apiEnumObj.color = enumObj.color;
    apiEnumObj.metering = enumObj.metering;
    apiEnumObj.wattages = enumObj.wattages || {};
    apiEnumObj.parent = enumObj.manufacturer;
    apiEnumObj.kind = vm.kind;

    return apiEnumObj;
  }
};
