/* @ngInject */
module.exports = function ($scope, $q, $stateParams, $translate, EnlightedAPISvc, BannerSvc, $rootScope, $state, ConfirmDialogSvc, EnlightedAPIErrorSvc) {
  var

  vm = this,

  I18N_KEYS = [
    'CONFIRM_CANCEL_DIALOG',
    'CONFIRM_CANCEL',
    'CONFIRM_CANCEL_CREATE_MSG',
    'AVAIL_SUBTYPES',
    'SEL_SUBTYPES',
    'NO_SUBTYPES'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.newEnum  = undefined;
  vm.baseObj  = {};
  vm.infoForm = {};
  vm.isLoaded = false;
  vm.kind     = undefined;
  vm.options  = {};

  //============= FUNCTIONS ============//
  vm.save              = save;
  vm.showConfirmCancel = showConfirmCancel;
  vm.handleSelectionChange = handleSelectionChange;
  vm.checkToUpdateMultiSelect = checkToUpdateMultiSelect;

  //=============== VARS ==============//
  var created = false,
      createdIndex = -1,
      createdColumn = 0;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('system basic create.ctrl: activate ', $stateParams);

    $scope.enumType = vm.kind = "facility_type";

    vm.newEnum = {};

    var promises = [];

    angular.forEach(I18N_KEYS, function (key) {
      promises.push($translate(key));
    });

    $q.all(promises).then(function (translations) {
      angular.forEach(translations, function (translate, index) {
        DICTIONARY[I18N_KEYS[index]] = translate;
      });

      getAllSubTypes().then(function (enums) {
        var subtypes = collectSubtypes(enums);
        var wrapper = createEmptyMultiSelectStructure();
        wrapper.columns[0].list = massageSubtypes(subtypes);
        vm.newEnum.subtypes = wrapper;
        vm.newEnum.physical = false;

        console.log(vm.newEnum);
      }, function (empty) {

      });
    });


    $translate('ENUM_VAL_DISPLAY_' + vm.kind.toUpperCase())
      .then(function (translation) {
        vm.options.valDisplay = translation;
        vm.isLoaded = true;
      });
  }

  function save() {
    console.log(vm.newEnum);

    vm.baseObj = updateAPIEnum(vm.baseObj, vm.newEnum);
    vm.baseObj.kind = vm.kind;

    console.log('basic-create.ctrl: save -', vm.baseObj);

    EnlightedAPISvc[vm.kind].create(vm.baseObj)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('CREATE_SUCCESS', { val: vm.newEnum.val }),
          el: '#content'
        });

        $state.go('^.list', {enumId: vm.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_FAILURE', { val: vm.newEnum.val }), error),
          el: '#content'
        });
      });
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        $state.go('^.list');
      });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.dname;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.ddescription || "";
    apiEnumObj.physical = enumObj.physical;
    apiEnumObj.floors = (typeof apiEnumObj.subtypes !== "undefined" ? (apiEnumObj.subtypes.length ? false : true) : true);

    return apiEnumObj;
  }

  function updateSubtypes(apiEnum, listUpdated) {
    if (listUpdated.length) {
      apiEnum.subtypes = listUpdated.map(function (listItem) {
        return listItem.display;
      });
      apiEnum.floors = (apiEnum.subtypes.length > 0) ? false : true;
    } else {
      apiEnum.subtypes = undefined;
    }
  }

  function handleSelectionChange(listA, listB) {
    // set dirty on list update
    vm.infoForm.$setDirty();
    updateCreatedColumn(listA, 0);
    updateCreatedColumn(listB, 1);
    updateSubtypes(vm.baseObj, listB);
  }

  function updateCreatedColumn(listA, col) {
    angular.forEach(listA, function (subtype, index) {
      if (subtype.hasOwnProperty("justCreated")) {
        createdColumn = col;
        createdIndex = index;
      }
    });
  }

  function createEmptyMultiSelectStructure () {
    var wrapper = {};
    wrapper.columns = [{},{}];
    // columns "config" for title and emptyListText
    wrapper.columns[0].title = DICTIONARY.AVAIL_SUBTYPES;
    wrapper.columns[1].title = DICTIONARY.SEL_SUBTYPES;
    wrapper.columns[0].emptyListText = wrapper.columns[1].emptyListText = DICTIONARY.NO_SUBTYPES;
    wrapper.columns[0].list = wrapper.columns[1].list = [];
    return wrapper;
  }

  function collectSubtypes(enums) {
    var subtypesList = [];
    angular.forEach(enums, function (item, index) {
      subtypesList.push(item.names['']);
    });
    return subtypesList;
  }

  function massageSubtypes(subtypesList) {
    return subtypesList.sort(function (a, b) {
      return a > b;
    }).map(function (sub, index) {
      return {
        display: sub,
        id: index
      };
    });
  }

  function getAllSubTypes() {
    var deferred = $q.defer();
    EnlightedAPI.facility_type.getAll().then(function (ok) {
      deferred.resolve(ok.enums);
    }, function (err) {
      deferred.resolve({});
    });
    return deferred.promise;
  }

  function checkToUpdateMultiSelect() {
    var val = vm.newEnum.val;
    var name = vm.newEnum.dname;
    if (created && (val === undefined || name === undefined)) {
      //console.log("FIND");
      var subtypeindex = lookUpForNewSubtype(0);
      if (subtypeindex.index === -1) {
        return false;
      } else {
        vm.newEnum.subtypes.columns[subtypeindex.col].list.splice(subtypeindex.index, 1);
        created = false;
      }
    } else if (val !== undefined && name !== undefined) {
      if (!created) {
        //console.log("CREATE");
        created = true;
        createdIndex = vm.newEnum.subtypes.columns[createdColumn].list.length;
        vm.newEnum.subtypes.columns[createdColumn].list.push({
          display: name,
          id: createdIndex,
          justCreated: true
        });
      } else {
        /*console.log("UPDATE");
        console.log("CREATED COLUMN", createdColumn);*/
        vm.newEnum.subtypes.columns[createdColumn].list[createdIndex].display = name;
      }
    }
  }

  function lookUpForNewSubtype (col) {
    var columns = col;
    var index = -1;
    if (col < 2) {
      angular.forEach(vm.newEnum.subtypes.columns[columns].list, function (subtype, ind) {
        if (subtype.hasOwnProperty("justCreated")) {
          index = ind;
        }
      });
      if (index === -1) {
        return lookUpForNewSubtype(col + 1);
      } else {
        return {
          col: columns,
          index: index
        };
      }
    } else {
      return {
        col: columns,
        index: index
      };
    }
  }
};
