/* @ngInject */
module.exports = function ($scope,EnlightedAPISvc,EnlightedAPIEnumsSvc,$timeout,$q, MAXLENGTH) {
  var vm = this;

  //============= MODELS ============//
  $scope.pcdisabled = true;
  

  //to resolve issue that div is not translated during startup
  $timeout(function(){
    $scope.pclist = $scope.plist;
  },0);


  //============= FUNCTIONS ============//
  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    setupWatches();
  }

  function setupWatches(){

    $scope.$watch(function () {
      return $scope.infoForm;
    }, function (newVal, oldVal) {
      $scope.form = newVal;
    });

    $scope.$watch(function(){return $scope.obj.manufacturer;},function(){
      if($scope.obj.manufacturer){
        $scope.pcdisabled = false;
      }
      //watch manufacture for product code dropdown change
      $scope.refresh();

    });


  }





};
