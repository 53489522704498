/* @ngInject */
module.exports = function ($scope, $translate, ConfirmDialogSvc, FileSaver, Blob, $filter, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIEnumsSvc) {

  var vm = this;

  //============= FUNCTIONS ============//

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops product_code roles.ctrl: activate');

    $scope.$on('PRODUCT_CODE_ROLES_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('ops product_code roles.ctrl: init', enumObj);

    vm.enumObj = enumObj;
    vm.isLoaded = true;
  }
};