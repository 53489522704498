/* @ngInject */
module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      enlEnum : '=',
      form    : '=',
      options : '=',
      kind    : '@',
      enlMode : '@'
    },
    templateUrl: 'app-container/components/global-settings/ops/gang-box/details/info-form.tpl.html',
    controller: 'GangBoxInfoFormDirCtrl as enumInfoCtrl'
  };
};
