/* @ngInject */
module.exports = function ($scope, RegulatorSvc, EnlightedAPISvc) {
  var

  vm = this;

  //============= MODELS ============//
  vm.configurations = [];
  vm.lamps          = [];
  vm.mode           = 'list';
  vm.options        = undefined;

  //============= FUNCTIONS ============//
  vm.finish         = finish;
  vm.onViewChange   = onViewChange;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    $scope.$on('CREATE_DRIVER_ACTIVATE_LAMP_CONFIGURATIONS', init);
  }

  function init() {
    EnlightedAPISvc.lamp.getAll()
      .then(function (lampsRsp) {
        vm.lamps = RegulatorSvc.createDisplayLamps(lampsRsp.enums.filter(function (lamp) { return lamp.led; }));
        vm.options = { noWattages: $scope.createCtrl.stepData[0].data.driver.metering };
      }, function (error) {
        console.log('lamp_configs_step.ctrl: init - unable to load lamps');
      });
  }

  function finish() {
    $scope.step.data.driver.lamps = vm.configurations;
    console.log('lamps-configs_step.ctrl: finish - ', $scope.step.data);
    $scope.createCtrl.finishWizard();
  }

  function onViewChange(newView) {
    console.log('lamp_configs_step.ctrl: onViewChange -', newView);
    vm.mode = newView;
  }
};
