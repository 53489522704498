(function(window, document, undefined) {
'use strict';

// Source: index.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/index.html',
    '<html>\n' +
    '\n' +
    '<head>\n' +
    '    <title>Enlighted Inc.</title>\n' +
    '    <link rel="stylesheet" href="css/enlighted-container.css">\n' +
    '    <meta http-equiv="content-type" content="text/html; charset=UTF8">\n' +
    '</head>\n' +
    '\n' +
    '<body>\n' +
    '    <div class="wrapper" style="display: none" ng-controller="BaseAppMainController">\n' +
    '        <div id="header" ui-view="header"></div>\n' +
    '        <div id="content" ui-view="content"></div>\n' +
    '        <div id="footer" ui-view="footer"></div>\n' +
    '    </div>\n' +
    '    <!-- Remove once the app data is legit -->\n' +
    '    <script src="js/fake-app-data.js" async></script>\n' +
    '    <script src="js/config.js"></script>\n' +
    '    <script src="js/enl-bootstrap.js"></script>\n' +
    '    <script src="js/vendor.js"></script>\n' +
    '    <script src="js/app.js"></script>\n' +
    '    <script>\n' +
    '        var bootstrapOpts = Object.assign({}, enl.config);\n' +
    '        bootstrapOpts.cb = function () {\n' +
    '            angular.bootstrap(document, [\'enlighted.app\'], {\n' +
    '                strictDi: true\n' +
    '            });\n' +
    '        }\n' +
    '\n' +
    '        enl.bootstrap.init(bootstrapOpts);\n' +
    '    </script>\n' +
    '</body>\n' +
    '\n' +
    '</html>\n' +
    '');
}]);
})();

// Source: components/apps/apps.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/apps/apps.html',
    '<div class="container-fluid">\n' +
    '    <div class="page-title">\n' +
    '        <h1>Enlighted Applications</h1>\n' +
    '        <hr/>\n' +
    '    </div>\n' +
    '    <div class="app-list-container">\n' +
    '        <div class="row">\n' +
    '            <div class="app-list-card col-md-6" ng-repeat="app in apps.enums">\n' +
    '                <div class="app-list-card-title">\n' +
    '                    <div class="app-list-card-title-icon">                \n' +
    '                        <i class="fa fa-camera-retro fa-4x"></i>\n' +
    '                    </div>\n' +
    '                    <div class="app-list-card-title-name">\n' +
    '                        <h3>{{app.names[\'\']}}</h3>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="app-list-card-description">\n' +
    '                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eu pellentesque dui. Phasellus malesuada tempus tortor, a sollicitudin lacus imperdiet vel. Ut feugiat eget turpis eu semper. Sed convallis sit amet eros in sodales. Fusce in arcu ac purus vehicula fermentum. Phasellus laoreet, elit eget semper convallis, velit nunc sodales orci, interdum molestie turpis est sed nibh. Nam a lobortis ligula. Sed pellentesque fringilla commodo. Aliquam interdum lacus quis felis vulputate vehicula. Sed quis felis luctus, venenatis enim nec, ullamcorper ex. Duis purus urna, aliquam ut sollicitudin vel, sagittis nec turpis. Nulla ante lectus, molestie nec arcu at, consequat tristique ex.</p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

// Source: components/base/update-toast.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/base/update-toast.tpl.html',
    '<md-toast class="enl-update-toast">\n' +
    '  <span flex>{{ \'UPDATE_VERSION_TEXT\' | translate }}</span>\n' +
    '  <md-button ng-click="reload()">\n' +
    '    {{ \'UPDATE_RELOAD\' | translate }}\n' +
    '  </md-button>\n' +
    '  <md-button class="md-icon-button" ng-click="close()">\n' +
    '    <md-icon aria-label="{{ \'CLOSE_DIALOG\' | translate }}">close</md-icon>\n' +
    '  </md-button>\n' +
    '</md-toast>\n' +
    '');
}]);
})();

// Source: components/create-org/wizard.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/create-org/wizard.html',
    '<md-content layout-padding>\n' +
    '  <div ng-if="coBaseCtrl.showSuccess">\n' +
    '    <div class="create-org-success" layout="column" layout-align="center center">\n' +
    '      <div>\n' +
    '        <p class="create-org-success-msg" translate="CREATE_ORG_SUCCESS_MSG" translate-values="{ name: coBaseCtrl.stepData[0].data.org.name}"></p>\n' +
    '      </div>\n' +
    '      <div>\n' +
    '        <md-icon>check_circle</md-icon>\n' +
    '      </div>\n' +
    '      <div layout="row">\n' +
    '        <div><md-button class="md-raised md-accent" ng-click="coBaseCtrl.initWizard()">{{ \'CREATE_ANOTHER\' | translate }}</md-button></div>\n' +
    '        <div><md-button ng-href="" ng-click="coBaseCtrl.goToOrg()" target="_blank" translate="GO_TO_ORG" translate-values="{ name: coBaseCtrl.stepData[0].data.org.name}"></md-button></div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-if="!coBaseCtrl.showSuccess">\n' +
    '    <md-steppers md-dynamic-height md-stretch-steppers="always" md-selected="coBaseCtrl.selectedStep" md-busy="coBaseCtrl.showBusyText">\n' +
    '      <md-step ng-repeat="step in coBaseCtrl.stepData track by $index" label="{{step.label}}" md-complete="step.completed" ng-disabled="coBaseCtrl.stepProgress < $index+1">\n' +
    '        <md-content ng-include="step.templateUrl"> </md-content>\n' +
    '      </md-step>\n' +
    '    </md-steppers>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/dashboard/dashboard.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/dashboard/dashboard.html',
    '<md-content class="dashboard-content" layout-padding>\n' +
    '<div layout="row" layout-wrap layout-align="space-around">\n' +
    '  <md-card style="width: 480px; height: 400px" ng-repeat="app in currentApps" ng-click="openApp(app)">\n' +
    '  <md-card-content>\n' +
    '  <div style="position: relative">\n' +
    '    <h3>{{ app.names | localize }}</h3>\n' +
    '    <!-- iframe src should be replaced by \'mini\' url of app -->\n' +
    '    <iframe style="width: 100%; height: 310px; border: none" src="{{ dashboardCtrl.getMiniUrl(app) }}"></iframe>\n' +
    '    <div style="z-index: 99; position: absolute; width: 100%; height: 310px; top: 36px"></div>\n' +
    '  </div>\n' +
    '  </md-card-content>\n' +
    '  </md-card>\n' +
    '</div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/appview/appview.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/appview/appview.html',
    '<div class="app-container">\n' +
    '	<div>\n' +
    '    <div layout="row" layout-align="center center" class="enl-fill enl-whiteout" ng-show="!appviewCtrl.isLoaded">\n' +
    '    </div>\n' +
    '    <div layout="row" layout-align="center center" class="enl-fill enl-loader" ng-if="!appviewCtrl.isLoaded">\n' +
    '      <double-bounce-spinner></double-bounce-spinner>\n' +
    '    </div>\n' +
    '		<iframe id="app-view-iframe" class="app-iframe" frameborder="0" src="about:blank"></iframe>\n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/footer/footer.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/footer/footer.html',
    '<md-toolbar class="footer">\n' +
    '  <div class="md-toolbar-tools">\n' +
    '    <md-button style="text-transform: none" ng-href="http://www.enlightedinc.com">{{ \'ABOUT_ENLIGHTED\' | translate }}</md-button>\n' +
    '    <span class="footer-version">{{\'VERSION\' | translate}} {{version}}</span>\n' +
    '    <span flex></span>\n' +
    '    <md-icon style="margin-right: 1rem;">language</md-icon>\n' +
    '    <md-menu class="language-menu">\n' +
    '      {{ \'CHOOSE_LANGUAGE\' | translate }}:&nbsp;<md-button class="enl-no-transform" ng-click="$mdOpenMenu(ev)">{{selectedLanguage.label}}</md-button>\n' +
    '      <md-menu-content width="4">\n' +
    '        <md-menu-item ng-repeat=\'locale in locales\'>\n' +
    '          <md-button aria-label="{{locale.label}}" class="md-primary" ng-click=\'changeLanguage(locale)\'>{{ locale.label }}</md-button>\n' +
    '        </md-menu-item>\n' +
    '      </md-menu-content>\n' +
    '    </md-menu>\n' +
    '  <div>\n' +
    '</md-toolbar>\n' +
    '');
}]);
})();

// Source: components/global-settings/index.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/index.html',
    '<div id="wrapper" layout="row" layout-align="none stretch"  style="min-height: calc(100vh - 110px);">\n' +
    '  <div id="menu" ng-include="\'app-container/components/global-settings/menu/menu.html\'"></div>\n' +
    '  <div flex class="content" ui-view style="min-height: 100%;"></div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/header/header.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/header/header.html',
    '<angled-navbar menus=\'menus\' user=\'user\' navfn=\'navfn(action)\' apps=\'currentApps\' orgs=\'orgs\' change-org=\'changeOrg(org)\' current-org=\'currentOrg\' open-app=\'openApp(app)\' show-title=\'currentApp.title\' show-opts=\'headerOptions\' locale=\'selectedLanguage\'></angled-navbar>\n' +
    '');
}]);
})();

// Source: components/header/navbar.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/header/navbar.html',
    '<md-content>\n' +
    '    <md-toolbar layout="row">\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <md-button class="md-icon-button" aria-label="Enlighted">\n' +
    '                <span> <img class=\'logo\' src=\'images/enlighted.png\' style="width: 25px;"></img> </span>\n' +
    '            </md-button>\n' +
    '            <h2 ng-if="showTitle">{{showTitle}}</h2>\n' +
    '            <md-button ng-repeat="menu in menus" ng-if="!navbarCtrl.hasDropdownMenu(menu)" aria-label="{{menu.title}}" ng-click="navbarCtrl.navAction(menu.action)">\n' +
    '                <h2 aria-label="{{menu.title}}"> <span> {{menu.title}} </span></h2>\n' +
    '            </md-button>\n' +
    '            <md-menu md-position-mode="target target" md-offset="0 59" ng-repeat="menu in menus" ng-if="navbarCtrl.hasDropdownMenu(menu)">\n' +
    '                <md-button aria-label="{{menu.title}}" ng-click="$mdOpenMenu($event)">\n' +
    '                    <h2 aria-label="{{menu.title}}"> <span> {{menu.title}} </span></h2>\n' +
    '                </md-button>\n' +
    '                <md-menu-content width="4">\n' +
    '                    <md-menu-item ng-repeat="item in menu.menu">\n' +
    '                        <md-button ng-if="!navbarCtrl.isDivider(item)" ng-click="navbarCtrl.navAction(item.action)">\n' +
    '                            <h5 aria-label="{{item.title}}"> <span> {{item.title}} </span></h5>\n' +
    '                        </md-button>\n' +
    '                        <md-menu-divider ng-if="navbarCtrl.isDivider(item)"></md-menu-divider>\n' +
    '                    </md-menu-item>\n' +
    '                </md-menu-content>\n' +
    '            </md-menu>\n' +
    '            <span flex=""></span>\n' +
    '            <md-menu md-position-mode="target-right target" md-offset="0 60" style="margin-left:20px;" ng-if="showOpts.showOrgDropdown">\n' +
    '                <md-button class="enl-no-transform" ng-click="$mdOpenMenu($event)">\n' +
    '                    <h4 aria-label="{{currentOrg.name}}"> <span> {{currentOrg.name}} </span></h4>\n' +
    '                </md-button>\n' +
    '                <md-menu-content width="4">\n' +
    '                    <md-menu-item ng-repeat="org in orgs.objs" ng-if="org.id !== currentOrg.id">\n' +
    '                        <md-button ng-click="changeOrg({org:org})">\n' +
    '                            <h4 aria-label="{{org.name}}"> <span> {{org.name}} </span></h4>\n' +
    '                        </md-button>\n' +
    '                    </md-menu-item>\n' +
    '                </md-menu-content>\n' +
    '            </md-menu>\n' +
    '            <md-button class="md-icon-button md-accent" aria-label="\'CREATE_ORGANIZATION | translate\'" ng-if="navbarCtrl.hasCreateOrgPermission() && showOpts.showOrgDropdown" ui-sref=\'create-org({locale: locale.val})\'>\n' +
    '                <md-tooltip>\n' +
    '                        {{ \'CREATE_ORGANIZATION\' | translate }}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon md-font-library="material-icons" class="md-accent">add_circle</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-menu ng-if="showOpts.showAppsDropdown" md-position-mode="target-right target" md-offset="0 66">\n' +
    '                <md-button aria-label="Applications" class="md-icon-button md-accent" ng-click="$mdOpenMenu($event)">\n' +
    '                    <md-tooltip>\n' +
    '                        {{ \'APPS\' | translate }}\n' +
    '                    </md-tooltip>\n' +
    '                    <md-icon md-menu-origin="" md-font-library="material-icons" class="md-accent">apps</md-icon>\n' +
    '                </md-button>\n' +
    '                <md-menu-content width="4">\n' +
    '                    <md-menu-item ng-repeat="app in apps">\n' +
    '                        <md-button ng-click="openApp({app:app})">\n' +
    '                            <img style="width: 24px; margin-right: 16px;" ng-src="{{navbarCtrl.getAppIconUrl(app)}}" ng-if="app.hasIcon"></img>\n' +
    '                            <md-icon ng-if="!app.hasIcon" md-font-library="material-icons" md-menu-align-target="">group_work</md-icon>\n' +
    '                            {{app.names | localize}}\n' +
    '                        </md-button>\n' +
    '                    </md-menu-item>\n' +
    '                    <!-- Commenting out the link to view the marketing page that describes the different Enlighted apps available -->\n' +
    '                    <!-- <md-menu-divider></md-menu-divider>\n' +
    '                    <md-menu-item>\n' +
    '                        <md-button style="text-align:center;width:100%;" ui-sref="apps({ orgId: currentOrg.gid, locale: locale.val })">\n' +
    '                            <span style="text-align:center;width:100%;"> {{ \'MORE_APPS\' | translate }} </span>\n' +
    '                        </md-button>\n' +
    '                    </md-menu-item> -->\n' +
    '                </md-menu-content>\n' +
    '            </md-menu>\n' +
    '            <md-button class="md-icon-button md-accent" aria-label="Favorite" ui-sref="dashboard({orgId: currentOrg.gid, locale: locale.val })">\n' +
    '                <md-tooltip>\n' +
    '                        {{ \'DASHBOARD\' | translate }}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon md-font-library="material-icons" class="md-accent">home</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-menu md-position-mode="target-right target" md-offset="0 52">\n' +
    '                <md-button aria-label="User Account" class="md-icon-button md-accent" ng-click="$mdOpenMenu($event)">\n' +
    '                    <md-tooltip>\n' +
    '                        {{ \'ACCOUNT\' | translate }}\n' +
    '                    </md-tooltip>\n' +
    '                    <md-icon md-menu-origin="" md-font-library="material-icons" class="md-accent">account_box</md-icon>\n' +
    '                </md-button>\n' +
    '                <md-menu-content width="4">\n' +
    '                    <md-menu-item>\n' +
    '                        <md-button>\n' +
    '                            <h5 aria-label="User\'s Name" ng-click="$mdOpenMenu($event)"> <span> {{user.name}} </span></h5>\n' +
    '                        </md-button>\n' +
    '                    </md-menu-item>\n' +
    '                    <md-menu-item>\n' +
    '                        <md-button>\n' +
    '                            <h5 aria-label="User\'s Email" ng-click="$mdOpenMenu($event)"> <span> {{user.email}} </span></h5>\n' +
    '                        </md-button>\n' +
    '                    </md-menu-item>\n' +
    '                    <md-menu-divider></md-menu-divider>\n' +
    '\n' +
    '                    <md-menu-item>\n' +
    '                        <md-button class="md-accent md-hue-3" ng-click="navbarCtrl.goMyAccount()">\n' +
    '                            <h5 aria-label="User\'s Account"> <span> MY ACCOUNT</span></h5>\n' +
    '                        </md-button>\n' +
    '                    </md-menu-item>\n' +
    '\n' +
    '                    <md-menu-item>\n' +
    '                        <md-button class="md-accent md-hue-3" ng-click="navbarCtrl.logout()">\n' +
    '                            <h5 aria-label="Logout"> <span> LOGOUT </span></h5>\n' +
    '                        </md-button>\n' +
    '                    </md-menu-item>\n' +
    '                </md-menu-content>\n' +
    '            </md-menu>\n' +
    '            <md-button ng-show="navbarCtrl.hasSettingPermission()" class="md-icon-button md-accent" aria-label="Favorite" ui-sref="settings.system.list({locale: locale.val})" style="margin-right:15px;">\n' +
    '                <md-tooltip>\n' +
    '                        {{ \'GLOBAL_SETTINGS\' | translate }}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon md-font-library="material-icons" class="md-accent">settings</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/my-account/account.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/my-account/account.html',
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>{{detailsCtrl.userName}}</h3>\n' +
    '      </div>\n' +
    '      <div flex></div>\n' +
    '      <!-- <md-button class="md-fab md-mini md-primary" aria-label="{{\'USER_DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'USER_DELETE\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon>delete</md-icon>\n' +
    '      </md-button> -->\n' +
    '    </div>\n' +
    '    <!-- Tabs -->\n' +
    '    <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '      <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '        <ng-include src="\'app-container/components/my-account/info.html\'"></ng-include>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'CONTACT_INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectContactInfoTab()">\n' +
    '        <ng-include src="\'app-container/components/my-account/my-account-contact-info.html\'"></ng-include>\n' +
    '      </md-tab>\n' +
    '      <!-- <md-tab label="{{ \'NOTES\' | translate }}" md-on-select="detailsCtrl.onSelectNotesTab()">\n' +
    '        <ng-include src="\'app-container/components/my-account/notes.html\'"></ng-include>\n' +
    '      </md-tab> -->\n' +
    '    </md-tabs>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/my-account/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/my-account/info.html',
    '<div ng-controller="MyAccountInfoCtrl as infoCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-user-info-form\n' +
    '      user="infoCtrl.curUser"\n' +
    '      offices="infoCtrl.buildings"\n' +
    '      locales="infoCtrl.locales"\n' +
    '      countries="infoCtrl.countries"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      options="infoCtrl.infoOpts">\n' +
    '    </enl-user-info-form>\n' +
    '    <div layout="row">\n' +
    '      <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '        <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '        <md-icon style="color: white;">check</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '        <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '        <md-icon style="color: white;">refresh</md-icon>\n' +
    '      </md-button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/my-account/my-account-contact-info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/my-account/my-account-contact-info.html',
    '<div ng-controller="MyAccountContactCtrl as contactCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!contactCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="contactCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '\n' +
    '    <!-- Emails -->\n' +
    '    <enl-email-contact-widget\n' +
    '      emails="contactCtrl.apiUser.emails"\n' +
    '      enl-on-change="contactCtrl.emailsChanged">\n' +
    '    </enl-email-contact-widget>\n' +
    '\n' +
    '    <!-- Phone Numbers -->\n' +
    '    <div style="padding-top: 1rem;">\n' +
    '      <enl-phone-contact-widget\n' +
    '        phonenums="contactCtrl.apiUser.phones"\n' +
    '        phone-types="contactCtrl.phone_types"\n' +
    '        enl-on-change="contactCtrl.phonesChanged">\n' +
    '      </enl-phone-contact-widget>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/create-org/administrator/administrator_step.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/create-org/administrator/administrator_step.html',
    '<div ng-controller="AdminStepCtrl as adminCtrl">\n' +
    '  <form name="adminForm">\n' +
    '    <md-content layout-padding ng-if="!adminCtrl.addRole">\n' +
    '      <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '          <!-- First name -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'FIRST_NAME\' | translate}}</label>\n' +
    '            <input required type="text" name="firstname" ng-model="step.data.user.fname" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '            <div ng-messages="adminForm.firstname.$error" role="alert" md-auto-hide="false" ng-show="adminForm.firstname.$touched && adminForm.firstname.$invalid">\n' +
    '              <div ng-message="required">{{\'FIRSTNAME_REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '          <!-- Last name -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'LAST_NAME\' | translate}}</label>\n' +
    '            <input required type="text" name="lastname" ng-model="step.data.user.lname" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '            <div ng-messages="adminForm.lastname.$error" role="alert" md-auto-hide="false" ng-show="adminForm.lastname.$touched && adminForm.lastname.$invalid">\n' +
    '              <div ng-message="required">{{\'LASTNAME_REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '          <!-- Email -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'EMAIL\' | translate}}</label>\n' +
    '            <input type="email" required name="email" ng-model="step.data.user.email" ng-pattern="validEmailRegEx" ng-maxlength="$root.MAXLENGTH.EMAIL">\n' +
    '            <div ng-messages="adminForm.email.$error" role="alert" md-auto-hide="false" ng-show="adminForm.email.$touched && adminForm.email.$invalid">\n' +
    '              <div ng-message="required">{{\'EMAIL_REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="email">{{\'VALID_EMAIL_REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="pattern">{{\'EMAIL_DOMAIN_MATCH_ERROR\' | translate }}</div>\n' +
    '              <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: MAXLENGTH.EMAIL }"></span></div>\n' +
    '            </div>\n' +
    '\n' +
    '          </md-input-container>\n' +
    '        </div>\n' +
    '        <!-- Country -->\n' +
    '        <md-autocomplete\n' +
    '            class="enl-md-autocomplete"\n' +
    '            md-autocomplete-force-select\n' +
    '            required="true"\n' +
    '            md-select-on-match="true"\n' +
    '            md-input-name="country_autocomplete"\n' +
    '            md-search-text="adminCtrl.searchText"\n' +
    '            md-no-cache="adminCtrl.noCache"\n' +
    '            md-selected-item="adminCtrl.selectedItem"\n' +
    '            md-search-text="adminCtrl.searchText"\n' +
    '            md-selected-item-change="adminCtrl.selectedItemChange(country)"\n' +
    '            md-min-length="0"\n' +
    '            md-items="country in adminCtrl.querySearch(adminCtrl.searchText)"\n' +
    '            md-item-text="country.display"\n' +
    '            ng-attr-md-floating-label="{{adminCtrl.countryLabel}}">\n' +
    '            <md-item-template>\n' +
    '                <span md-highlight-text="adminCtrl.searchText" md-highlight-flags="^i">{{ country.display }}</span>\n' +
    '            </md-item-template>\n' +
    '            <md-not-found>\n' +
    '                {{ \'AUTOCOMPLETE_NO_MATCHES\' | translate }}\n' +
    '            </md-not-found>\n' +
    '            <div ng-messages="adminForm.country_autocomplete.$error" role="alert" ng-if="adminForm.country_autocomplete.$touched">\n' +
    '                <div ng-message="required">{{ \'COUNTRY_REQUIRED_ERROR\' | translate }}</div>\n' +
    '            </div>\n' +
    '        </md-autocomplete>\n' +
    '      </div>\n' +
    '    </md-content>\n' +
    '    <p style="padding-left: 1.6rem;" enl-required-field-note ng-if="!adminCtrl.addRole"></p>\n' +
    '\n' +
    '    <!-- UNCOMMENT WHEN ROLE RESTRICTIONS WORK -->\n' +
    '    <!-- <div class="create-org-wrapper-for-role" ng-include="\'app-container/components/create-org/administrator/administrator_step.role.tpl.html\'"></div> -->\n' +
    '\n' +
    '  </form>\n' +
    '  <md-step-actions layout="row" ng-if="!adminCtrl.addRole">\n' +
    '    <div flex layout="row">\n' +
    '      <md-button class="md-primary md-raised" ng-click="coBaseCtrl.goPreviousStep(step)">{{\'PREVIOUS_STEP\' | translate}}\n' +
    '      </md-button>\n' +
    '      <span flex></span>\n' +
    '      <md-button class="md-primary" ng-click="coBaseCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '      <!-- UNCOMMENT WHEN ROLE RESTRICTIONS WORK -->\n' +
    '      <!-- <md-button class="md-accent md-raised" ng-disabled="adminForm.$invalid || adminCtrl.rolemaptypelist.length == 0" ng-click="coBaseCtrl.finishWizard()">{{\'FINISH\' | translate }}</md-button> -->\n' +
    '      <md-button class="md-accent md-raised" ng-disabled="adminForm.$invalid" ng-click="coBaseCtrl.finishWizard()">{{\'FINISH\' | translate }}</md-button>\n' +
    '    </div>\n' +
    '  </md-step-actions>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/create-org/administrator/administrator_step.role.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/create-org/administrator/administrator_step.role.tpl.html',
    '<div layout="row" layout-align="center center" style="height:30%;" ng-if="!adminCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="adminCtrl.isLoaded" style="padding: 1.6rem;" ng-class="{\'roles-full-screen\': adminCtrl.addRole}">\n' +
    '	<div ng-if="!adminCtrl.addRole">\n' +
    '		<div style="margin-bottom: 1.5rem;">\n' +
    '			<md-button class="md-accent md-raised" ng-click="adminCtrl.goToTreeView(null);">{{"ADD_NEW" | translate}}</md-button>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-if="adminCtrl.rolemaptypelist.length">\n' +
    '			<md-list style="padding: 0px 8px;margin-bottom: 1.5rem;">\n' +
    '				<enl-list-item ng-repeat="role in adminCtrl.rolemaptypelist track by $index" avatar-icon="\'circle\'" edit-fn="adminCtrl.editRoleMapType(role, $index, $event);" del-fn="adminCtrl.deleteRoleMapType(role, $event, $index);">\n' +
    '					<h3>{{role.names | localize}}</h3>\n' +
    '					<p ng-if="role.hasRestrictions"><span class="warn-chip"><md-icon>warning</md-icon><span>{{"NO_RESTRICTIONS" | translate}}</span></span></p>\n' +
    '					<md-divider ng-if="!$last"></md-divider>\n' +
    '				</enl-list-item>\n' +
    '			</md-list>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div ng-if="adminCtrl.addRole">\n' +
    '    <div layout="row" layout-align="space-between center">\n' +
    '      <h3 style="margin-top: 8px;">{{"ADD_ROLE_CONFIGURATION" | translate}}</h3>\n' +
    '      <md-button class="md-icon-button md-no-ink" style="margin-right: 0;padding-right: 0;" aria-label="{{ \'CANCEL\' | translate }}" ng-click="adminCtrl.roleConfigConfirmCancel($event)">\n' +
    '        <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '        <md-icon>close</md-icon>\n' +
    '      </md-button>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-role-configuration-dir form="adminCtrl.form" type="\'create\'" edit="adminCtrl.edit" getuserrestriction="\'yes\'" isonrequest="adminCtrl.makingRequest" rolestaken="adminCtrl.rolestaken"></enl-role-configuration-dir>\n' +
    '    </div>\n' +
    '    <div layout="row">\n' +
    '      <md-button class="md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="adminCtrl.addToAccessRole()" ng-disabled="adminCtrl.form.$invalid || adminCtrl.makingRequest">\n' +
    '        <md-tooltip>{{ \'ADD\' | translate }}</md-tooltip>\n' +
    '        {{"ADD" | translate}}\n' +
    '      </md-button>\n' +
    '      <md-button class="md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="adminCtrl.roleConfigConfirmCancel($event)">\n' +
    '        <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '        {{"CANCEL" | translate}}\n' +
    '      </md-button>\n' +
    '    </div>\n' +
    '	</div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/create-org/idp/idp_step.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/create-org/idp/idp_step.html',
    '<div ng-controller="IdpStepCtrl as idpCtrl">\n' +
    '    <form name="idpForm">\n' +
    '        <md-content layout-padding>\n' +
    '            <div>\n' +
    '                <p translate="IDP_HELP_TEXT"></p>\n' +
    '            </div>\n' +
    '            <div>\n' +
    '                <div style="display: inline-block" layout="column">\n' +
    '                    <md-switch ng-model="step.data.org.thirdparty_idps"\n' +
    '                               ng-change="idpCtrl.showConfirmDialog($event, step.data.org.thirdparty_idps)">\n' +
    '                        {{\'ALLOW_THIRDPARTY_IDPS\' | translate}}\n' +
    '                    </md-switch>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="enl-form-container">\n' +
    '\n' +
    '                <md-input-container class="md-block md-accent" flex\n' +
    '                                    ng-class="{ \'md-undefined-select\': !step.data.idp.type }">\n' +
    '                    <label>{{\'TYPE\' | translate}}</label>\n' +
    '                    <md-select name="type" class="md-accent" ng-model="step.data.idp.type"\n' +
    '                               ng-click="idpForm.$setDirty()">\n' +
    '                        <md-option ng-repeat="type in idpCtrl.idpTypes track by $index" value="{{type.val}}">\n' +
    '                            {{type.name}}\n' +
    '                        </md-option>\n' +
    '                    </md-select>\n' +
    '                </md-input-container>\n' +
    '\n' +
    '                <div layout="row" class="enl-form-field-container">\n' +
    '\n' +
    '                    <md-input-container class="md-block md-accent" flex ng-if="!!step.data.idp.type">\n' +
    '                        <label enl-required-field>{{ \'NAME\' | translate }}</label>\n' +
    '                        <input type="text" required name="idpName" ng-model="step.data.idp.name" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                        <div ng-messages="idpForm.idpName.$error" role="alert" md-auto-hide="false" ng-show="idpForm.idpName.$touched && idpForm.idpName.$invalid">\n' +
    '                            <div ng-message="required">{{ "NAME_REQUIRED_ERROR" | translate}}</div>\n' +
    '                            <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container class="md-block md-accent" flex ng-if="!!step.data.idp.type">\n' +
    '                        <label enl-required-field>{{ \'DOMAIN\' | translate }}</label>\n' +
    '                        <input type="text" required name="domain" ng-model="step.data.idp.domain"\n' +
    '                               ng-pattern=/^([a-zA-Z0-9]([a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,6}$/ ng-maxlength="$root.MAXLENGTH.DOMAIN"/>\n' +
    '                        <div ng-messages="idpForm.domain.$error" role="alert" md-auto-hide="false" ng-show="idpForm.domain.$touched && idpForm.domain.$invalid">\n' +
    '                            <div ng-message="required">{{ "DOMAIN_REQUIRED_ERROR" | translate}}</div>\n' +
    '                            <div ng-message="pattern">{{ "DOMAIN_INVALID_ERROR" | translate}}</div>\n' +
    '                            <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.DOMAIN }"></span></div>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '\n' +
    '                <div layout="row" class="enl-form-field-container" ng-if="step.data.idp.type == \'saml\'">\n' +
    '                    <md-input-container class="md-block md-accent" flex>\n' +
    '                        <label enl-required-field>{{ \'URL\' | translate }}</label>\n' +
    '                        <input type="text" required name="idpUrl" ng-model="step.data.idp.url"\n' +
    '                               ng-pattern=/^(https)://.*$/ ng-maxlength="$root.MAXLENGTH.URL"/>\n' +
    '                        <div ng-messages="idpForm.idpUrl.$error" role="alert" md-auto-hide="false" ng-show="idpForm.idpUrl.$touched && idpForm.idpUrl.$invalid">\n' +
    '                            <div ng-message="required">{{ "URL_REQUIRED_ERROR" | translate}}</div>\n' +
    '                            <div ng-message="pattern">{{ "URL_INVALID_ERROR" | translate}}</div>\n' +
    '                            <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.URL }"></span></div>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '\n' +
    '                <div layout="row" class="enl-form-field-container" ng-if="step.data.idp.type == \'saml\'">\n' +
    '                    <md-input-container class="md-block md-accent" flex>\n' +
    '                        <label enl-required-field>{{ \'PUBLIC_CERTIFICATE\' | translate }}</label>\n' +
    '                        <textarea required name="cert" rows="5"\n' +
    '                                  ng-model="step.data.idp.pcertificate" ng-pattern=/^-----BEGIN\\sCERTIFICATE-----(.*)-----END\\sCERTIFICATE-----$/\n' +
    '                                  md-select-on-focus\n' +
    '                                  md-no-autogrow></textarea>\n' +
    '                        <div ng-messages="idpForm.cert.$error" role="alert" md-auto-hide="false" ng-show="idpForm.cert.$touched && idpForm.cert.$invalid">\n' +
    '                            <div ng-message="required">{{ "PUBLIC_CERTIFICATE_REQUIRED_ERROR" | translate}}</div>\n' +
    '                            <div ng-message="pattern">{{ "PUBLIC_CERTIFICATE_INVALID_ERROR" | translate}}</div>\n' +
    '                            <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '\n' +
    '                <div layout="row" class="enl-form-field-container" ng-if="step.data.idp.type == \'alias\'">\n' +
    '                    <md-input-container class="md-block md-accent" flex>\n' +
    '                        <label enl-required-field>{{ \'THIRD_PARTY_IDENTITY_PROVIDER\' | translate }}</label>\n' +
    '                        <md-select required name="providerSelect" class="md-accent" ng-model="step.data.idp.provider"\n' +
    '                                   ng-click="idpForm.providerSelect.$setDirty()">\n' +
    '                            <md-option ng-repeat="provider in idpCtrl.thirdParty track by $index"\n' +
    '                                       value="{{provider.gid}}">\n' +
    '                                {{provider.name}}\n' +
    '                            </md-option>\n' +
    '                        </md-select>\n' +
    '                        <div ng-messages="idpForm.providerSelect.$error" role="alert">\n' +
    '                            <div ng-message="required">{{ "THIRD_PARTY_IDENTITY_PROVIDER_REQUIRED_ERROR" | translate}}</div>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="enl-form-field-container" ng-if="step.data.idp.type === \'azure\'">\n' +
    '                    <!-- Tenant ID -->\n' +
    '                    <div layout="row" class="enl-form-field-container">\n' +
    '                        <md-input-container class="md-block md-accent" flex>\n' +
    '                            <label>{{\'TENANT_ID\' | translate }}</label>\n' +
    '                            <input type="text" name="tenant" ng-model="step.data.idp.tenant" ng-maxlength="$root.MAXLENGTH.TEXT" />\n' +
    '                            <div ng-messages="idpForm.tenant.$error" role="alert">\n' +
    '                                <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                            </div>\n' +
    '                        </md-input-container>\n' +
    '                    </div>\n' +
    '                    <!-- Client ID -->\n' +
    '                    <div layout="row" class="enl-form-field-container">\n' +
    '                        <md-input-container class="md-block md-accent" flex>\n' +
    '                            <label enl-required-field>{{\'CLIENT_ID\' | translate }}</label>\n' +
    '                            <input required type="text" name="client" ng-model="step.data.idp.client" ng-maxlength="$root.MAXLENGTH.TEXT" />\n' +
    '                            <div ng-messages="idpForm.client.$error" role="alert" md-auto-hide="false" ng-show="idpForm.client.$touched && idpForm.client.$invalid">\n' +
    '                                <div ng-message="required">{{ \'CLIENT_ID_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                                <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                            </div>\n' +
    '                        </md-input-container>\n' +
    '                    </div>\n' +
    '                    <!-- Client Secret -->\n' +
    '                    <div layout="row" class="enl-form-field-container">\n' +
    '                        <md-input-container class="md-block md-accent" flex>\n' +
    '                            <label enl-required-field>{{\'CLIENT_SECRET\' | translate }}</label>\n' +
    '                            <input required type="text" name="client_secret" ng-model="step.data.idp.client_secret" ng-maxlength="$root.MAXLENGTH.TEXT" />\n' +
    '\n' +
    '                            <div ng-messages="idpForm.client_secret.$error" role="alert" md-auto-hide="false" ng-show="idpForm.client_secret.$touched && idpForm.client_secret.$invalid">\n' +
    '                                <div ng-message="required">{{ \'CLIENT_SECRET_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                                <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                            </div>\n' +
    '\n' +
    '                        </md-input-container>\n' +
    '                    </div>\n' +
    '\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </md-content>\n' +
    '\n' +
    '        <p style="padding-left: 1.6rem;" enl-required-field-note ng-if="!!step.data.idp.type"></p>\n' +
    '\n' +
    '        <md-step-actions layout="row">\n' +
    '            <div flex layout="row">\n' +
    '                <md-button class="md-primary md-raised" ng-click="coBaseCtrl.goPreviousStep(step)">{{\'PREVIOUS_STEP\' |\n' +
    '                    translate}}\n' +
    '                </md-button>\n' +
    '                <span flex></span>\n' +
    '                <md-button class="md-primary" ng-click="coBaseCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' |\n' +
    '                    translate\n' +
    '                    }}\n' +
    '                </md-button>\n' +
    '                <md-button class="md-accent" ng-click="idpCtrl.skipAndFinish($event)">{{\'SKIP_AND_FINISH\' |\n' +
    '                    translate\n' +
    '                    }}\n' +
    '                </md-button>\n' +
    '                <md-button class="md-accent md-raised"\n' +
    '                           ng-disabled="(step.data.org.thirdparty_idps === false && (idpForm.$invalid || !step.data.idp.type)) || (step.data.org.thirdparty_idps && idpForm.$invalid)"\n' +
    '                           ng-click="coBaseCtrl.submitCurrentStep(step)">{{\'NEXT_STEP\' | translate}}\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        </md-step-actions>\n' +
    '\n' +
    '    </form>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/create-org/organization/applications_step.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/create-org/organization/applications_step.html',
    '<div ng-controller="AppsStepCtrl as appsCtrl">\n' +
    '  <p translate="CREATE_ORG_CHOOSE_APPS_HELP_TEXT" style="padding-bottom: 2rem;"></p>\n' +
    '  <form name="appsForm">\n' +
    '    <div>\n' +
    '      <div class="tile-container">\n' +
    '        <enl-tile ng-repeat="app in appsCtrl.apps track by app.id" tile-model="app" tile-clicked="appsCtrl.appClicked"></enl-tile>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div style="clear: both;"></div>\n' +
    '    <md-step-actions layout="row">\n' +
    '      <div flex layout="row">\n' +
    '        <md-button class="md-primary md-raised" ng-click="coBaseCtrl.goPreviousStep(step)">{{\'PREVIOUS_STEP\' | translate}}\n' +
    '        </md-button>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-primary" ng-click="coBaseCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '        <md-button class="md-accent md-raised" ng-disabled="appsForm.$invalid" ng-click="coBaseCtrl.submitCurrentStep(step)">{{\'NEXT_STEP\' | translate }}</md-button>\n' +
    '      </div>\n' +
    '    </md-step-actions>\n' +
    '  </form>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/create-org/organization/organization_step.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/create-org/organization/organization_step.html',
    '<div ng-controller="OrganizationStepCtrl as orgCtrl">\n' +
    '  <enl-org-info org="step.data.org" locales="orgCtrl.locales" types="orgCtrl.orgtypes" form="orgCtrl.orgInfoForm" options="orgCtrl.orgInfoOpts"></enl-org-info>\n' +
    '  <md-step-actions layout="row">\n' +
    '    <div flex layout="row" layout-align="end top">\n' +
    '        <md-button class="md-primary" ng-click="coBaseCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '        <md-button class="md-accent md-raised" ng-disabled="orgCtrl.orgInfoForm.$invalid" ng-click="coBaseCtrl.submitCurrentStep(step)">{{\'NEXT_STEP\' | translate }}</md-button>\n' +
    '    </div>\n' +
    '  </md-step-actions>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/create-org/organization/organization_types_step.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/create-org/organization/organization_types_step.html',
    '<div style="padding: 16px;" ng-controller="OrganizationTypesStepCtrl as otsCtrl">\n' +
    '  <form name="orgsForm">\n' +
    '    <p translate="CREATE_ORG_CHOOSE_ORGS_HELP_TEXT" style="padding-bottom: 2rem;"></p>\n' +
    '    <md-input-container class="md-block md-accent" style="width: 20rem;">\n' +
    '      <label>{{\'TYPE\' | translate}}</label>\n' +
    '      <md-select name="type" class="md-accent" ng-model="otsCtrl.type" ng-click="orgsForm.type.$setDirty()" ng-change="otsCtrl.changeTypeSelection(otsCtrl.type)">\n' +
    '          <md-option ng-repeat="type in otsCtrl.orgtypes track by type.val" value="{{type.val}}">\n' +
    '              {{type.display}}\n' +
    '          </md-option>\n' +
    '      </md-select>\n' +
    '    </md-input-container>\n' +
    '\n' +
    '    <!-- Dual Column Multiselect for available and selected orgs -->\n' +
    '    <div style="padding-bottom: 3rem;">\n' +
    '      <enl-dual-col-multiselect enl-options="otsCtrl.dcmsOptions"></enl-dual-col-multiselect>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Steppers controls -->\n' +
    '    <md-step-actions layout="row">\n' +
    '      <div flex layout="row">\n' +
    '        <md-button class="md-primary md-raised" ng-click="coBaseCtrl.goPreviousStep(step)">{{\'PREVIOUS_STEP\' | translate}}\n' +
    '        </md-button>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-primary" ng-click="coBaseCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '        <md-button class="md-accent md-raised" ng-disabled="orgsForm.$invalid" ng-click="otsCtrl.submitCurrentStep(step)">{{\'NEXT_STEP\' | translate }}</md-button>\n' +
    '      </div>\n' +
    '    </md-step-actions>\n' +
    '  </form>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/menu/menu.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/menu/menu.html',
    '<div layout="column" ng-controller="MenuCtrl as menuCtrl">\n' +
    '  <div class="menu-item" layout="column" layout-align="center center" tabindex="0" role="button" ng-repeat="menu in menuCtrl.menuItems" ui-sref="{{menu.toState}}" ng-class="{\'menu-selected\' : menuCtrl.isCurrentState(menu.toState)}">\n' +
    '    <md-icon>{{menu.icon}}</md-icon>\n' +
    '    <p class="menu-label">{{menu.label | translate}}</p>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/notes/notes.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/notes/notes.html',
    '<div ng-controller="NotesCtrl as notesCtrl">\n' +
    '    <enl-notes-widget notes="notesCtrl.notes" user="notesCtrl.sessionUser.user" save-fn="notesCtrl.save" delete-fn="notesCtrl.showDeleteConfirm"></enl-notes-widget>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/dongle/chown/change-owner-dialog.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/dongle/chown/change-owner-dialog.tpl.html',
    '<md-dialog flex="30" aria-label="{{ \'CHANGE_OWNERSHIP\' | translate}}">\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>{{ \'CHANGE_OWNERSHIP\' | translate}}</h2>\n' +
    '            <span flex></span>\n' +
    '            <md-button class="md-icon-button" ng-click="ctrl.cancel()">\n' +
    '                <i class="material-icons">clear</i>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content layout="column" selected="selectedIndex" style="max-width:800px;max-height:610px;">\n' +
    '        <md-content class="md-padding">\n' +
    '            <form name="changeOwner.form" ng-submit="$event.preventDefault()">\n' +
    '                <div layout="column" class="center center">\n' +
    '                    <md-input-container>\n' +
    '                        <label>{{ \'ORGANIZATION\' | translate }}</label>\n' +
    '                        <md-select  name="owner" class="md-accent" ng-model="ctrl.curDongle.org_owner"  style="min-width: 200px;" ng-click="changeOwner.form.owner.$setDirty()">\n' +
    '                            <md-option ng-repeat="org in ctrl.orgs track by $index" value="{{ org.gid }}">\n' +
    '                                {{ org.name }}\n' +
    '                            </md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </md-content>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <md-button class="md-mini md-fab md-accent" aria-label="save"\n' +
    '                   ng-click="ctrl.changeOwner(ctrl.curDongle)">\n' +
    '            <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-mini md-fab md-primary" aria-label="cancel" ng-click="ctrl.cancel()">\n' +
    '            <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">clear</md-icon>\n' +
    '        </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '');
}]);
})();

// Source: components/global-settings/dongle/detail/detail.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/dongle/detail/detail.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="doglCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{doglCtrl.dongleTitleName}}</h3>\n' +
    '            </div>\n' +
    '            <div class="enl-vertical-separator"></div>\n' +
    '            <div>\n' +
    '                <div layout="column" class="enl-labelled-text-container" ng-focus-model focus-model="doglCtrl.stateFocus">\n' +
    '                    <label class="enl-floating-label">{{\'STATUS\' | translate}}</label>\n' +
    '                    <span>{{ doglCtrl.curDongle.state | translate }}</span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-primary" ng-disabled="doglCtrl.curDongle.state == \'MANUFACTURING\'" aria-label="{{\'DONGLE_DELETE\' | translate}}" ng-click="doglCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DONGLE_DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon style="color: #FFFFFF;">delete</md-icon>\n' +
    '            </md-button>\n' +
    '\n' +
    '            <md-button class="md-fab md-mini md-accent" ng-disabled="doglCtrl.curDongle.state == \'MANUFACTURING\'" aria-label="{{\'DONGLE_CHANGE_OWNERSHIP\' | translate}}" ng-click="doglCtrl.changeOwner($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DONGLE_CHANGE_OWNERSHIP\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon style="color: #FFFFFF;">supervisor_account</md-icon>\n' +
    '            </md-button>\n' +
    '\n' +
    '            <md-button class="md-fab md-mini md-accent" ng-disabled="doglCtrl.curDongle.state == \'MANUFACTURING\'" aria-label="{{\'DONGLE_CHANGE_STATUS\' | translate}}" ng-click="doglCtrl.changeStatus($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DONGLE_CHANGE_STATUS\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon style="color: #FFFFFF;">exit_to_app</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="doglCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="doglCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/dongle/detail/info/dogl-info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab ng-disabled="doglCtrl.doglNoOrg" label="{{ \'NOTES\' | translate }}" md-on-select="doglCtrl.onSelectNotesTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/notes/notes.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/dongle/chstats/change-state-dialog.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/dongle/chstats/change-state-dialog.tpl.html',
    '<md-dialog flex="30" aria-label="{{ \'CHANGE_STATE\' | translate}}">\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>{{ \'CHANGE_STATE\' | translate}}</h2>\n' +
    '            <span flex></span>\n' +
    '            <md-button class="md-icon-button" ng-click="ctrl.cancel()">\n' +
    '                <i class="material-icons">clear</i>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content layout="column" selected="selectedIndex" style="max-width:800px;max-height:610px;">\n' +
    '        <md-content class="md-padding">\n' +
    '            <form name="stateTransition.form" ng-submit="$event.preventDefault()">\n' +
    '                <div layout="column" class="center center">\n' +
    '                    <md-input-container>\n' +
    '                        <label>{{ "STATE" | translate }}</label>\n' +
    '                        <md-select  name="state" class="md-accent" ng-model="ctrl.curDongle.state"  style="min-width: 200px;" ng-click="stateTransition.form.state.$setDirty()">\n' +
    '                            <md-option ng-repeat="state in ctrl.dongleStates track by $index" value="{{ state }}">\n' +
    '                                {{ state | translate }}\n' +
    '                            </md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </md-content>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <md-button class="md-mini md-fab md-accent" aria-label="save"\n' +
    '                   ng-click="ctrl.changeState(ctrl.curDongle)">\n' +
    '            <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-mini md-fab md-primary" aria-label="cancel" ng-click="ctrl.cancel()">\n' +
    '            <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">clear</md-icon>\n' +
    '        </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '');
}]);
})();

// Source: components/global-settings/dongle/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/dongle/list/list.html',
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!doglCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="doglCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-data-table table-title="{{\'DONGLES\' | translate}}" selected="doglCtrl.selected" filter-text="doglCtrl.filter.search"\n' +
    '                        delete-fn="doglCtrl.showDeleteConfirm(doglCtrl.selected, $event)" hide-add="true">\n' +
    '            <md-table-container>\n' +
    '                <table md-table md-row-select="true" ng-model="doglCtrl.selected" md-progress="promise">\n' +
    '                    <thead md-head md-order="doglCtrl.query.order">\n' +
    '                    <tr md-row>\n' +
    '                        <th md-column md-order-by="address"><span>{{\'ADDRESS\' | translate}}</span></th>\n' +
    '                        <th md-column md-order-by="model"><span>{{\'PCODE\' | translate}}</span></th>\n' +
    '                        <th md-column md-order-by="state"><span>{{\'STATE\' | translate}}</span></th>\n' +
    '                        <th md-column md-order-by="last_user"><span>{{\'LAST_USER\' | translate}}</span></th>\n' +
    '                        <th md-column md-order-by="last_used"><span>{{\'LAST_USED\' | translate}}</span></th>\n' +
    '                        <th md-column md-order-by="org_owner"><span>{{\'ORG_OWNER\' | translate}}</span></th>\n' +
    '                    </tr>\n' +
    '                    </thead>\n' +
    '                    <tbody md-body>\n' +
    '                    <tr md-row md-select="dongle" md-on-select="logItem" md-auto-select="options.autoSelect"\n' +
    '                        ng-disabled="dongle.state === \'MANUFACTURING\'"\n' +
    '                        ng-repeat="dongle in (filteredItems = (doglCtrl.dongles | filterBy:[\'address\', \'model\', \'state\', \'last_user\', \'last_used\', \'org_owner\']:doglCtrl.filter.search | orderBy: doglCtrl.query.order | limitTo: doglCtrl.query.limit : (doglCtrl.query.page -1) * doglCtrl.query.limit))">\n' +
    '                        <td md-cell><span><a ng-class="{disabled:  dongle.state === \'MANUFACTURING\'}"  style="text-decoration: underline;cursor:pointer;"  ui-sref="settings.dongles.detail({dongleId: dongle.id})">{{ dongle.address }}</a></span></td>\n' +
    '                        <td md-cell>{{ dongle.model }}</td>\n' +
    '                        <td md-cell>{{ dongle.state | translate}}</td>\n' +
    '                        <td md-cell>{{ dongle.last_user_name }}</td>\n' +
    '                        <td md-cell>{{ dongle.last_used }}</td>\n' +
    '                        <td md-cell>{{ dongle.owner_name }}</td>\n' +
    '                    </tr>\n' +
    '                    <tr md-row ng-if="!filteredItems.length">\n' +
    '                        <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '                    </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '            </md-table-container>\n' +
    '            <md-table-pagination md-limit="doglCtrl.query.limit" md-page="doglCtrl.query.page" md-total="{{ doglCtrl.dongles.length }}"></md-table-pagination>\n' +
    '        </enl-data-table>\n' +
    '</md-content>');
}]);
})();

// Source: components/global-settings/idp/create/create-idp.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/idp/create/create-idp.tpl.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div style="padding: 1.6rem;">\n' +
    '    <enl-idp-info-form idp="newIdpCtrl.idp" types="newIdpCtrl.idpTypes" providers="newIdpCtrl.providers" orgs="newIdpCtrl.orgs"\n' +
    '                  form="newIdpCtrl.idpInfoForm"></enl-idp-info-form>\n' +
    '    <div layout="row" style="padding: 0.8rem;">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}"\n' +
    '                   ng-click="newIdpCtrl.save()"\n' +
    '                   ng-disabled="newIdpCtrl.idpInfoForm.$invalid || newIdpCtrl.idpInfoForm.$pristine">\n' +
    '            <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}"\n' +
    '                   ng-click="newIdpCtrl.showConfirmCancel($event)">\n' +
    '            <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">clear</md-icon>\n' +
    '        </md-button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/idp/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/idp/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="idpDetailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{idpDetailsCtrl.curIdp.name}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!idpDetailsCtrl.globalsettingseditmode" ng-click="idpDetailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="idpDetailsCtrl.globalsettingseditmode" ng-click="idpDetailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'IDP_DELETE\' | translate}}" ng-click="idpDetailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'IDP_DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="idpDetailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="idpDetailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/idp/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NOTES\' | translate }}" md-on-select="idpDetailsCtrl.onSelectNotesTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/notes/notes.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/idp/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/idp/details/info.html',
    '<div ng-controller="IdPInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '        <enl-idp-info-form ng-if="idpDetailsCtrl.globalsettingseditmode"\n' +
    '                idp="infoCtrl.curIdp"\n' +
    '                types="infoCtrl.idpTypes"\n' +
    '                providers="infoCtrl.providers"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                 orgs="infoCtrl.orgs">\n' +
    '        </enl-idp-info-form>\n' +
    '\n' +
    '        <enl-idp-info-form-view-only view-only ng-if="!idpDetailsCtrl.globalsettingseditmode"\n' +
    '                idp="infoCtrl.curIdp"\n' +
    '                types="infoCtrl.idpTypes"\n' +
    '                providers="infoCtrl.providers"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                 orgs="infoCtrl.orgs">\n' +
    '        </enl-idp-info-form-view-only>\n' +
    '        <div layout="row" ng-if="idpDetailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/idp/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/idp/list/list.html',
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!idpListCtrl.isLoaded">\n' +
    '    <wave-spinner></wave-spinner>\n' +
    '</div>\n' +
    '<md-content ng-if="idpListCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-data-table table-title="{{\'IDENTITY_PROVIDERS\' | translate}}" selected="idpListCtrl.selected"\n' +
    '                    filter-text="idpListCtrl.filter.search"\n' +
    '                    add-fn="idpListCtrl.createIdp"\n' +
    '                    delete-fn="idpListCtrl.showDeleteConfirm(idpListCtrl.selected, $event)">\n' +
    '        <md-table-container>\n' +
    '            <table md-table md-row-select="true" ng-model="idpListCtrl.selected" md-progress="promise">\n' +
    '                <thead md-head md-order="idpListCtrl.query.order">\n' +
    '                <tr md-row>\n' +
    '                    <th md-column md-order-by="name"><span>{{\'NAME\' | translate}}</span></th>\n' +
    '                    <th md-column md-order-by="domain"><span>{{\'DOMAIN\' | translate}}</span></th>\n' +
    '                    <th md-column md-order-by="type"><span>{{\'TYPE\' | translate}}</span></th>\n' +
    '                    <th md-column md-order-by="orgOwner"><span>{{\'OWNER\' | translate}}</span></th>\n' +
    '                </tr>\n' +
    '                </thead>\n' +
    '                <tbody md-body>\n' +
    '                <tr md-row md-select="idp" md-on-select="logItem" md-auto-select="options.autoSelect"\n' +
    '                    ng-repeat="idp in (filteredItems = (idpListCtrl.idps | filterBy: [\'name\', \'domain\', \'type\', \'orgOwner\']: idpListCtrl.filter.search | orderBy: idpListCtrl.query.order | limitTo: idpListCtrl.query.limit : (idpListCtrl.query.page -1) * idpListCtrl.query.limit))">\n' +
    '                    <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ui-sref="settings.idps.idp({idpId: idp.id})">{{idp.name}}</a></span></td>\n' +
    '                    <td md-cell>{{ idp.domain }}</td>\n' +
    '                    <td md-cell><span>{{ idp.type | translate }}</span> <span\n' +
    '                            ng-if="idp.provider">({{ idp.provider}})</span></td>\n' +
    '                    <td md-cell>{{ idp.orgOwner }}</td>\n' +
    '                </tr>\n' +
    '                <tr md-row ng-if="!filteredItems.length">\n' +
    '                    <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '                </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </md-table-container>\n' +
    '        <md-table-pagination md-limit="idpListCtrl.query.limit" md-page="idpListCtrl.query.page"\n' +
    '                             md-total="{{idpListCtrl.idps.length}}"></md-table-pagination>\n' +
    '    </enl-data-table>\n' +
    '\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/mdevice/chstats/change-state-dialog.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/mdevice/chstats/change-state-dialog.tpl.html',
    '<md-dialog flex="30" aria-label="{{ \'CHANGE_STATE\' | translate}}">\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>{{ \'CHANGE_STATE\' | translate}}</h2>\n' +
    '            <span flex></span>\n' +
    '            <md-button class="md-icon-button" ng-click="ctrl.cancel()">\n' +
    '                <i class="material-icons">clear</i>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content layout="column" selected="selectedIndex" style="max-width:800px;max-height:610px;">\n' +
    '        <md-content class="md-padding">\n' +
    '            <form name="stateTransition.form" ng-submit="$event.preventDefault()">\n' +
    '                <div layout="column" class="center center">\n' +
    '                    <md-input-container>\n' +
    '                        <label>{{ "STATE" | translate }}</label>\n' +
    '                        <md-select  name="state" class="md-accent" ng-model="ctrl.selState"  style="min-width: 200px;" ng-click="stateTransition.form.state.$setDirty()">\n' +
    '                            <md-option ng-repeat="state in ctrl.mdeviceStates track by $index" value="{{ state }}">\n' +
    '                                {{ state | translate }}\n' +
    '                            </md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </md-content>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <md-button class="md-mini md-fab md-accent" aria-label="save"\n' +
    '                   ng-click="ctrl.changeState(ctrl.curmdevice)">\n' +
    '            <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-mini md-fab md-primary" aria-label="cancel" ng-click="ctrl.cancel()">\n' +
    '            <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">clear</md-icon>\n' +
    '        </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '');
}]);
})();

// Source: components/global-settings/mdevice/detail/detail.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/mdevice/detail/detail.html',
    '<div id="breadcrumb" class="well" ncy-breadcrumb></div>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="mdeviceCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{mdeviceCtrl.mdeviceTitleName}}</h3>\n' +
    '            </div>\n' +
    '            <div class="enl-vertical-separator"></div>\n' +
    '            <div layout="row" flex layout-align="start center">\n' +
    '                <div layout="column" class="enl-labelled-text-container" style=\'width:50%\'>\n' +
    '                    <label class="enl-floating-label">{{\'STATE\' | translate}}</label>\n' +
    '                    <span>{{ mdeviceCtrl.curmdevice.state | translate }}</span>\n' +
    '                </div>\n' +
    '                <div layout="column" class="enl-labelled-text-container" style=\'width:50%\'>\n' +
    '                    <label class="enl-floating-label">{{\'SERIAL_NUMBER\' | translate}}</label>\n' +
    '                    <span>{{ mdeviceCtrl.curmdevice.serial }}</span>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'MDEVICE_DELETE\' | translate}}" ng-click="mdeviceCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon style="color: #FFFFFF;">delete</md-icon>\n' +
    '            </md-button>\n' +
    '\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{\'MDEVICE_CHANGE_STATUS\' | translate}}" ng-click="mdeviceCtrl.changeStatus($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'MDEVICE_CHANGE_STATUS\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon style="color: #FFFFFF;">exit_to_app</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '\n' +
    '        <enl-mdevice-info-form\n' +
    '            obj="mdeviceCtrl.formObj" \n' +
    '            form="mdeviceCtrl.infoForm"\n' +
    '            mlist="mdeviceCtrl.filteredManufacturers"\n' +
    '            plist="mdeviceCtrl.product_codes"\n' +
    '            refresh="mdeviceCtrl.setProductCodesDropdown">\n' +
    '        </enl-mdevice-info-form>\n' +
    '\n' +
    '        <div layout="column" class="enl-labelled-text-container" style="margin-top:50px">\n' +
    '            <label class="enl-floating-label">{{\'LAST_SIGNON\' | translate}}</label>\n' +
    '            <span>{{ mdeviceCtrl.lastSignon }}</span>\n' +
    '        </div>\n' +
    '\n' +
    '        <div layout="row" layout-align="start center">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="mdeviceCtrl.save()" ng-disabled="mdeviceCtrl.infoForm.$invalid || mdeviceCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="mdeviceCtrl.restore()" ng-disabled="mdeviceCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="mdeviceCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/mdevice/create/create-mdevice.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/mdevice/create/create-mdevice.tpl.html',
    '<div id="breadcrumb" class="well" ncy-breadcrumb></div>\n' +
    '\n' +
    '<div style="padding: 2rem;">\n' +
    '    <md-content>\n' +
    '      <div>\n' +
    '          <div flex-xs="" layout="column">\n' +
    '            <span class="md-headline">{{\'MDEVICE_CARD_TITLE\' | translate }}</span>\n' +
    '            <span style=\'margin: 15px; margin-left:0px;\' class="md-subhead">{{\'MDEVICE_CARD_SUBTITLE\' | translate }}</span>\n' +
    '            <div id="mdevice-create-option">\n' +
    '              <enl-tile\n' +
    '                tile-model=\'createCtrl.createToggle.single\'\n' +
    '                tile-clicked=\'createCtrl.createMode(0)\'>\n' +
    '              </enl-tile>\n' +
    '              <enl-tile\n' +
    '                tile-model=\'createCtrl.createToggle.multiple\'\n' +
    '                tile-clicked=\'createCtrl.createMode(1)\'>\n' +
    '              </enl-tile>\n' +
    '            </div>\n' +
    '            <div class="animate-switch-container" ng-switch on="createCtrl.mode">\n' +
    '              <div class="animate-switch" ng-switch-when="0">\n' +
    '                <enl-mdevice-info-form\n' +
    '                  obj="createCtrl.mdeviceObj"\n' +
    '                  form="createCtrl.infoForm"\n' +
    '                  mlist="createCtrl.filteredManufacturers"\n' +
    '                  plist="createCtrl.product_codes_sd"\n' +
    '                  refresh="createCtrl.setProductCodesDropdown_sd">\n' +
    '                </enl-mdevice-info-form>\n' +
    '                <div layout="row" style="padding: 0.8rem;">\n' +
    '                    <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}"\n' +
    '                            ng-click="createCtrl.saveSingle()"\n' +
    '                            ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '                        <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                        <md-icon style="color: white;">check</md-icon>\n' +
    '                    </md-button>\n' +
    '                    <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}"\n' +
    '                            ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '                        <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '                        <md-icon style="color: white;">clear</md-icon>\n' +
    '                    </md-button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '              <div class="animate-switch" ng-switch-when="1">\n' +
    '                <enl-multi-mdevice-info-form\n' +
    '                  obj="createCtrl.batchObj"\n' +
    '                  form="createCtrl.batchForm"\n' +
    '                  mlist="createCtrl.filteredManufacturers"\n' +
    '                  plist="createCtrl.product_codes_md"\n' +
    '                  refresh="createCtrl.setProductCodesDropdown_md">\n' +
    '                </enl-multi-mdevice-info-form>\n' +
    '\n' +
    '\n' +
    '                <div layout="row" style="padding: 0.8rem;">\n' +
    '                    <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}"\n' +
    '                            ng-click="createCtrl.saveBatch()"\n' +
    '                            ng-disabled="createCtrl.batchForm.$invalid || createCtrl.batchForm.$pristine">\n' +
    '                        <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                        <md-icon style="color: white;">check</md-icon>\n' +
    '                    </md-button>\n' +
    '                    <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}"\n' +
    '                            ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '                        <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '                        <md-icon style="color: white;">clear</md-icon>\n' +
    '                    </md-button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '    </md-content>\n' +
    '\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/mdevice/directive/mdevice-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/mdevice/directive/mdevice-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <md-input-container style="width:50%">\n' +
    '                <label enl-required-field>{{ "MANUFACTURER" | translate }}</label>\n' +
    '                <md-select required name="manufacturer" class="md-accent" ng-model="obj.manufacturer"  style="min-width: 200px;" ng-click="infoForm.manufacturer.$setDirty();">\n' +
    '                    <md-option ng-repeat="manufacturer in mlist track by $index" value="{{ manufacturer.val }}">\n' +
    '                        {{ manufacturer.names | localize }}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </md-input-container>\n' +
    '            <md-input-container style="width:50%">\n' +
    '                <label enl-required-field>{{ "PCODE" | translate }}</label>\n' +
    '                <md-select  name="pcode" ng-disabled="pcdisabled" class="md-accent" ng-model="obj.pcode"  style="min-width: 200px;" ng-click="infoForm.pcode.$setDirty()">\n' +
    '                    <md-option ng-repeat="pcode in plist track by $index" value="{{ pcode.val }}">\n' +
    '                        {{ pcode.names | localize }} ( {{pcode.val}} )\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'MAC_ADDRESS\' | translate}}</label>\n' +
    '                <input required type="text" name="macaddress" ng-model="obj.macaddr" ng-pattern="/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.macaddress.$error" role="alert" md-auto-hide="false" ng-show="infoForm.macaddress.$touched && infoForm.macaddress.$invalid">\n' +
    '                    <div ng-message="required"> {{\'MAC_ADDRESS_REQUIRED_ERROR\' | translate }} </div>\n' +
    '                    <div ng-message="pattern"> {{\'MAC_ADDRESS_INVALID_ERROR\' | translate }} </div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <p enl-required-field-note></p>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/mdevice/directive/multi-mdevice-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/mdevice/directive/multi-mdevice-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <md-input-container style="width:50%">\n' +
    '                <label enl-required-field>{{ "MANUFACTURER" | translate }}</label>\n' +
    '                <md-select required name="manufacturer" class="md-accent" ng-model="obj.manufacturer"  style="min-width: 200px;" ng-click="infoForm.manufacturer.$setDirty()">\n' +
    '                    <md-option ng-repeat="manufacturer in mlist track by $index" value="{{ manufacturer.val }}">\n' +
    '                        {{ manufacturer.names | localize }}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </md-input-container>\n' +
    '            <md-input-container style="width:50%">\n' +
    '                <label enl-required-field>{{ \'PCODE\' | translate }}</label>\n' +
    '                <md-select required name="pcode" class="md-accent" ng-disabled="pcdisabled" ng-model="obj.pcode"  style="min-width: 200px;" ng-click="infoForm.pcode.$setDirty()">\n' +
    '                    <md-option ng-repeat="pcode in plist track by $index" value="{{ pcode.val }}">\n' +
    '                        {{ pcode.names | localize }} ( {{pcode.val}} )\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'STARTING_MAC_ADDRESS\' | translate}}</label>\n' +
    '                <input required type="text" name="macaddress_start" ng-model="obj.macaddr_start" ng-pattern="/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.macaddress_start.$error" role="alert" md-auto-hide="false" ng-show="infoForm.macaddress_start.$touched && infoForm.macaddress_start.$invalid">\n' +
    '                    <div ng-message="required"> {{\'MAC_ADDRESS_REQUIRED_ERROR\' | translate }} </div>\n' +
    '                    <div ng-message="pattern"> {{\'MAC_ADDRESS_INVALID_ERROR\' | translate }} </div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'ENDING_MAC_ADDRESS\' | translate}}</label>\n' +
    '                <input required type="text" name="macaddress_end" ng-model="obj.macaddr_end" ng-pattern="/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.macaddress_end.$error" role="alert" md-auto-hide="false" ng-show="infoForm.macaddress_end.$touched && infoForm.macaddress_end.$invalid">\n' +
    '                    <div ng-message="required"> {{\'MAC_ADDRESS_REQUIRED_ERROR\' | translate }} </div>\n' +
    '                    <div ng-message="pattern"> {{\'MAC_ADDRESS_INVALID_ERROR\' | translate }} </div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <p enl-required-field-note></p>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/mdevice/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/mdevice/list/list.html',
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-data-table table-title="{{\'MANUFACTURED_DEVICES\' | translate}}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createMdevices" delete-fn="listCtrl.showDeleteConfirm(listCtrl.selected, $event)">\n' +
    '        <md-table-container>\n' +
    '            <table md-table md-row-select="true" ng-model="listCtrl.selected" md-progress="promise">\n' +
    '                <thead md-head md-order="listCtrl.query.order">\n' +
    '                <tr md-row>\n' +
    '                    <th md-column md-order-by="macaddress"><span>{{\'MAC_ADDRESS\' | translate}}</span></th>\n' +
    '                    <th md-column md-order-by="manu"><span>{{\'MANUFACTURER\' | translate}}</span></th>\n' +
    '                    <th md-column md-order-by="pcode"><span>{{\'PCODE\' | translate}}</span></th>\n' +
    '                    <th md-column md-order-by="serial"><span>{{\'SERIAL_NUMBER\' | translate}}</span></th>\n' +
    '                    <th md-column md-order-by="state"><span>{{\'STATE\' | translate}}</span></th>\n' +
    '                </tr>\n' +
    '                </thead>\n' +
    '                <tbody md-body>\n' +
    '                <tr md-row md-select="mdevice" md-on-select="logItem" md-auto-select="options.autoSelect"\n' +
    '                    ng-repeat="mdevice in (filteredItems = (listCtrl.md_grid | filterBy:[\'macaddress\', \'manu\', \'pcode\',  \'serial\', \'state\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '                    <td md-cell><span><a style="text-decoration: underline;cursor:pointer;"  ui-sref="settings.mdevice.detail({mdeviceId: mdevice.gid})">{{ mdevice.macaddress }}</a></span></td>\n' +
    '                     <td md-cell>{{ mdevice.manu | localize }}</td>\n' +
    '                    <td md-cell>{{ mdevice.pcode | localize }}</td>\n' +
    '                    <td md-cell>{{ mdevice.serial }}</td>\n' +
    '                    <td md-cell>{{ mdevice.state | translate}}</td>\n' +
    '                </tr>\n' +
    '                <tr md-row ng-if="!filteredItems.length">\n' +
    '                    <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '                </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </md-table-container>\n' +
    '        <md-table-pagination md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{ listCtrl.md_grid.length }}"></md-table-pagination>\n' +
    '    </enl-data-table>\n' +
    '</md-content>');
}]);
})();

// Source: components/global-settings/ops/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/list/list.html',
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!opsCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="opsCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ \'OPS_CONFIGURATIONS\' | translate}}" filter-text="opsCtrl.filter.search" hide-add="\'true\'">\n' +
    '    <md-table-container>\n' +
    '      <table md-table ng-model="opsCtrl.enums" md-progress="promise">\n' +
    '        <thead md-head md-order="opsCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'NAME\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (opsCtrl.enums | filter: {\'name\': opsCtrl.filter.search} | orderBy: opsCtrl.query.order | limitTo: opsCtrl.query.limit : (opsCtrl.query.page -1) * opsCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" href="#/settings/ops/{{enum.val}}">{{enum.name | translate}}</a></span> </td>\n' +
    '        </tr>\n' +
    '\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="opsCtrl.query.label" md-limit="opsCtrl.query.limit" md-page="opsCtrl.query.page" md-total="{{opsCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/list/list.html',
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!sysCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="sysCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ \'SYSTEM_CONFIGURATIONS\' | translate}}" selected="sysCtrl.selected" filter-text="sysCtrl.filter.search" add-fn="sysCtrl.createUser" hide-add="\'true\'">\n' +
    '    <md-table-container>\n' +
    '      <table md-table ng-model="sysCtrl.enums" md-progress="promise">\n' +
    '        <thead md-head md-order="sysCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'NAME\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (sysCtrl.enums | filter: {\'name\': sysCtrl.filter.search} | orderBy: sysCtrl.query.order | limitTo: sysCtrl.query.limit : (sysCtrl.query.page -1) * sysCtrl.query.limit))">\n' +
    '          <td md-cell>\n' +
    '            <span>\n' +
    '              <a style="text-decoration: underline;cursor:pointer;" href="#/settings/system/{{enum.val}}">{{enum.name}}</a>\n' +
    '            </span>\n' +
    '          </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="sysCtrl.query.label" md-limit="sysCtrl.query.limit" md-page="sysCtrl.query.page" md-total="{{sysCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/dongle/detail/info/dogl-info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/dongle/detail/info/dogl-info.html',
    '<div ng-controller="DoglInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded">\n' +
    '        <wave-spinner></wave-spinner>\n' +
    '    </div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '        <div class="labelled-container">\n' +
    '            <label class="floating-label">{{ "STATUS" | translate }}</label>\n' +
    '            <div class="labelled-content">\n' +
    '                <span>{{ infoCtrl.curDongle.state | translate }}</span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="labelled-container">\n' +
    '            <label class="floating-label">{{ "LAST_USED" | translate }}</label>\n' +
    '            <div class="labelled-content">\n' +
    '                <span>{{ infoCtrl.curDongle.last_used | date : \'medium\' }}</span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="labelled-container">\n' +
    '            <label class="floating-label">{{ "LAST_USER" | translate }}</label>\n' +
    '            <div class="labelled-content">\n' +
    '                <span>{{ infoCtrl.lastUserName }}</span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="labelled-container">\n' +
    '            <label class="floating-label">{{ "ORGANIZATION" | translate }}</label>\n' +
    '            <div class="labelled-content">\n' +
    '                <span>{{ infoCtrl.curDongle.references.org_owner.name }}</span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="tasks-list-title" flex>\n' +
    '            <h4 translate="TASKS_TITLE"></h4>\n' +
    '        </div>\n' +
    '        <md-divider></md-divider>\n' +
    '        <md-list style="padding: 0px 8px;">\n' +
    '            <div ng-repeat="task in infoCtrl.tasks">\n' +
    '                <enl-list-item item="task" avatar-icon="\'description\'" no-edit="true" no-del="true">\n' +
    '                    <p>{{ task.name }}</p>\n' +
    '                    <h4>{{ task.job }}</h4>\n' +
    '                </enl-list-item>\n' +
    '            </div>\n' +
    '        </md-list>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/ballast/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/ballast/create/create.html',
    '<!--<div id="breadcrumb" class="well" ncy-breadcrumb></div>-->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content layout-padding>\n' +
    '  <md-steppers md-dynamic-height md-stretch-steppers="always" md-selected="createCtrl.selectedStep" md-busy="createCtrl.showBusyText">\n' +
    '    <md-step ng-repeat="step in createCtrl.stepData track by $index" label="{{step.label}}" md-complete="step.completed" ng-disabled="createCtrl.stepProgress < $index+1">\n' +
    '      <md-content ng-include="step.templateUrl"> </md-content>\n' +
    '    </md-step>\n' +
    '  </md-steppers>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/ballast/create/info_step.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/ballast/create/info_step.html',
    '<div ng-controller="CreateBallastInfoCtrl as infoCtrl" style="padding: 0.8rem;">\n' +
    '  <p>{{ \'NEW_BALLAST_INFO_HELP_TEXT\' | translate }}</p>\n' +
    '  <div>\n' +
    '    <enl-regulator-info-form\n' +
    '      enl-enum="infoCtrl.newBallast"\n' +
    '      manufacturers="infoCtrl.manufacturers"\n' +
    '      electrical-interfaces="infoCtrl.electricalInterfaces"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      enl-mode="\'create\'">\n' +
    '    </enl-regulator-info-form>\n' +
    '  </div>\n' +
    '  <md-step-actions layout="row">\n' +
    '    <div flex layout="row" layout-align="end top">\n' +
    '        <md-button class="md-primary" ng-click="createCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '        <md-button class="md-accent md-raised" ng-disabled="infoCtrl.infoForm.$invalid" ng-click="infoCtrl.nextStep()">{{\'NEXT_STEP\' | translate }}</md-button>\n' +
    '    </div>\n' +
    '  </md-step-actions>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/ballast/create/lamp_configs_step.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/ballast/create/lamp_configs_step.html',
    '<div ng-controller="CreateBallastLampConfigsCtrl as configsCtrl" style="padding: 0.8rem;">\n' +
    '  <div>\n' +
    '    <p ng-hide="configsCtrl.mode === \'form\'">{{ \'NEW_BALLAST_LAMP_CONFIGS_HELP_TEXT\' | translate }}</p>\n' +
    '    <enl-lamp-config-widget\n' +
    '      configurations="configsCtrl.configurations"\n' +
    '      lamps="configsCtrl.lamps"\n' +
    '      enl-on-view-change="configsCtrl.onViewChange"\n' +
    '      options="configsCtrl.options">\n' +
    '    </enl-lamp-config-widget>\n' +
    '  </div>\n' +
    '  <md-step-actions layout="row" ng-hide="configsCtrl.mode === \'form\'">\n' +
    '    <div flex layout="row" layout-align="end top">\n' +
    '      <md-button class="md-primary" ng-click="createCtrl.goPreviousStep(step)">{{\'PREVIOUS_STEP\' | translate}}</md-button>\n' +
    '      <span flex></span>\n' +
    '      <md-button class="md-primary" ng-click="createCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '      <md-button class="md-accent md-raised" ng-click="configsCtrl.finish()">{{\'FINISH\' | translate }}</md-button>\n' +
    '    </div>\n' +
    '  </md-step-actions>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/ballast/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/ballast/details/details.html',
    '<!--<div id="breadcrumb" class="well" ncy-breadcrumb></div>-->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>{{detailsCtrl.curBallast.val}}</h3>\n' +
    '      </div>\n' +
    '      <div class="enl-vertical-separator"></div>\n' +
    '      <div layout="row" flex layout-align="start center">\n' +
    '        <div layout="column" class="enl-labelled-text-container" style=\'width:100%\'>\n' +
    '          <label class="enl-floating-label">{{\'CONFIGURATION_KEY\' | translate}}</label>\n' +
    '          <span>{{ detailsCtrl.curBallast.parent }}:{{ detailsCtrl.curBallast.val }} </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'EDIT\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon style="color: white;">edit</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'VIEW\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'DELETE\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon>delete</md-icon>\n' +
    '      </md-button>\n' +
    '    </div>\n' +
    '    <!-- Tabs -->\n' +
    '    <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '      <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '        <ng-include src="\'app-container/components/global-settings/ops/ballast/details/info.html\'"></ng-include>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'LAMP_CONFIGURATIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectLampConfigsTab()">\n' +
    '        <ng-include src="\'app-container/components/global-settings/ops/ballast/details/lamp-configs.html\'"></ng-include>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '        <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.names"\n' +
    '          title-table="\'Name\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '          type="\'names\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir>\n' +
    '\n' +
    '        <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.names"\n' +
    '          title-table="\'Name\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '          type="\'names\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir-view-only>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '        <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.descriptions"\n' +
    '          title-table="\'Descriptions\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '          type="\'descriptions\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir>\n' +
    '\n' +
    '        <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.descriptions"\n' +
    '          title-table="\'Descriptions\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '          type="\'descriptions\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir-view-only>\n' +
    '      </md-tab>\n' +
    '    </md-tabs>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/ballast/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/ballast/details/info.html',
    '<div ng-controller="BallastInfoCtrl as infoCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '    <enl-regulator-info-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '      enl-enum="infoCtrl.curBallast"\n' +
    '      manufacturers="infoCtrl.manufacturers"\n' +
    '      electrical-interfaces="infoCtrl.electricalInterfaces"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      >\n' +
    '    </enl-regulator-info-form>\n' +
    '\n' +
    '    <enl-regulator-info-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '      enl-enum="infoCtrl.curBallast"\n' +
    '      manufacturers="infoCtrl.manufacturers"\n' +
    '      electrical-interfaces="infoCtrl.electricalInterfaces"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      >\n' +
    '    </enl-regulator-info-form-view-only>\n' +
    '    <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '      <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '        <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '        <md-icon style="color: white;">check</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '        <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '        <md-icon style="color: white;">refresh</md-icon>\n' +
    '      </md-button>\n' +
    '      <div ng-show="infoCtrl.hasAsync">\n' +
    '        <circle-spinner></circle-spinner>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/ballast/details/lamp-configs.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/ballast/details/lamp-configs.html',
    '<div ng-controller="BallastLampConfigsCtrl as configsCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!configsCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="configsCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '    <enl-lamp-config-widget ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '      configurations="configsCtrl.configurations"\n' +
    '      lamps="configsCtrl.lamps"\n' +
    '      enl-on-change="configsCtrl.onConfigChange"\n' +
    '      options="configsCtrl.options">\n' +
    '    </enl-lamp-config-widget>\n' +
    '\n' +
    '    <enl-lamp-config-widget-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '      configurations="configsCtrl.configurations"\n' +
    '      lamps="configsCtrl.lamps"\n' +
    '      enl-on-change="configsCtrl.onConfigChange"\n' +
    '      options="configsCtrl.options">\n' +
    '    </enl-lamp-config-widget-view-only>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/ballast/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/ballast/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-regulator-data-table\n' +
    '    items="listCtrl.enums"\n' +
    '    options="listCtrl.options"\n' +
    '    kind=\'ballast\'>\n' +
    '  </enl-regulator-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/basic/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/basic/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{createCtrl.kind | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-basic-enum-form\n' +
    '        enl-enum="createCtrl.newEnum"\n' +
    '        form="createCtrl.infoForm"\n' +
    '        options="createCtrl.options">\n' +
    '      </enl-basic-enum-form>\n' +
    '\n' +
    '      <div layout="row">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/basic/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/basic/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '      </div>\n' +
    '      <div flex></div>\n' +
    '      <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'EDIT\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon style="color: white;">edit</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'VIEW\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'DELETE\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon>delete</md-icon>\n' +
    '      </md-button>\n' +
    '    </div>\n' +
    '    <!-- Tabs -->\n' +
    '    <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '      <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '        <ng-include src="\'app-container/components/global-settings/ops/basic/details/info.html\'"></ng-include>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '        <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.names"\n' +
    '          title-table="\'Name\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '          type="\'names\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir>\n' +
    '\n' +
    '        <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.names"\n' +
    '          title-table="\'Name\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '          type="\'names\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir-view-only>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '        <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.descriptions"\n' +
    '          title-table="\'Descriptions\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '          type="\'descriptions\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir>\n' +
    '\n' +
    '        <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.descriptions"\n' +
    '          title-table="\'Descriptions\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '          type="\'descriptions\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir-view-only>\n' +
    '      </md-tab>\n' +
    '    </md-tabs>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/basic/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/basic/details/info.html',
    '<div ng-controller="BasicEnumInfoCtrl as infoCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '    <enl-basic-enum-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '      enl-enum="infoCtrl.enumObj"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      options="infoCtrl.options"\n' +
    '      enl-mode=\'edit\'>\n' +
    '    </enl-basic-enum-form>\n' +
    '\n' +
    '    <enl-basic-enum-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '      enl-enum="infoCtrl.enumObj"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      options="infoCtrl.options"\n' +
    '      enl-mode=\'edit\'>\n' +
    '    </enl-basic-enum-form-view-only>\n' +
    '\n' +
    '    <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '      <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '        <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '        <md-icon style="color: white;">check</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '        <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '        <md-icon style="color: white;">refresh</md-icon>\n' +
    '      </md-button>\n' +
    '      <div ng-show="infoCtrl.hasAsync">\n' +
    '        <circle-spinner></circle-spinner>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/basic/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/basic/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-basic-enum-table\n' +
    '    items="listCtrl.enums"\n' +
    '    options="listCtrl.options"\n' +
    '    on-item-click="listCtrl.viewEnumDetails"\n' +
    '    add-fn="listCtrl.createEnum"\n' +
    '    delete-fn="listCtrl.showDeleteConfirm">\n' +
    '  </enl-basic-enum-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/contact-type/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/contact-type/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>Create New {{\'contact_type\' | translate}}</h3>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div>\n' +
    '            <enl-contact-type-form\n' +
    '                    enl-enum="createCtrl.newEnum"\n' +
    '                    form="createCtrl.infoForm"\n' +
    '                    options="createCtrl.options">\n' +
    '            </enl-contact-type-form>\n' +
    '\n' +
    '            <div layout="row">\n' +
    '                <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '                    <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">check</md-icon>\n' +
    '                </md-button>\n' +
    '                <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '                    <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">close</md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/contact-type/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/contact-type/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/ops/contact-type/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/contact-type/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/contact-type/details/info.html',
    '<div ng-controller="ContactTypeInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '        <enl-contact-type-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                enl-enum="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                options="infoCtrl.options"\n' +
    '                enl-mode="edit">\n' +
    '        </enl-contact-type-form>\n' +
    '\n' +
    '        <enl-contact-type-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                enl-enum="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                options="infoCtrl.options"\n' +
    '                enl-mode="edit">\n' +
    '        </enl-contact-type-form-view-only>\n' +
    '\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()"\n' +
    '                       ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/contact-type/list/contact-type-list.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/contact-type/list/contact-type-list.tpl.html',
    '<enl-data-table table-title="{{ listCtrl.tableTitle | translate }}" selected="listCtrl.selected"\n' +
    '                filter-text="listCtrl.filter.search" add-fn="listCtrl.addEnum"\n' +
    '                delete-fn="listCtrl.deleteEnums(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '        <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '            <thead md-head md-order="listCtrl.query.order">\n' +
    '            <tr md-row>\n' +
    '                <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '                <th md-column md-order-by="val"><span>{{\'TYPE\' | translate }}</span></th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody md-body>\n' +
    '            <tr md-row md-select="enum"\n' +
    '                ng-repeat="enum in (filteredItems = (items | filterBy: [\'name\', \'val\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '                <td md-cell><span><a style="text-decoration: underline;cursor:pointer;"\n' +
    '                                     ng-click="listCtrl.onItemClick(enum)">{{enum.name}}</a></span></td>\n' +
    '                <td md-cell><span>{{enum.val}}</span></td></tr>\n' +
    '\n' +
    '            <tr md-row ng-if="!filteredItems.length">\n' +
    '                <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </md-table-container>\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page"\n' +
    '                         md-total="{{items.length}}"></md-table-pagination>\n' +
    '</enl-data-table>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/contact-type/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/contact-type/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-contact-type-table\n' +
    '            items="listCtrl.enums"\n' +
    '            options="listCtrl.options"\n' +
    '            on-item-click="listCtrl.viewEnumDetails"\n' +
    '            add-fn="listCtrl.createEnum"\n' +
    '            delete-fn="listCtrl.showDeleteConfirm">\n' +
    '    </enl-contact-type-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/control-unit/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/control-unit/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>Create New {{\'control_unit\' | translate}}</h3>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div>\n' +
    '            <enl-control-unit-form\n' +
    '                    enl-enum="createCtrl.newEnum"\n' +
    '                    form="createCtrl.infoForm"\n' +
    '                    options="createCtrl.options">\n' +
    '            </enl-control-unit-form>\n' +
    '\n' +
    '            <div layout="row">\n' +
    '                <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '                    <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">check</md-icon>\n' +
    '                </md-button>\n' +
    '                <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '                    <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">close</md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/control-unit/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/control-unit/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div class="enl-vertical-separator"></div>\n' +
    '            <div layout="row" flex layout-align="start center">\n' +
    '                <div layout="column" class="enl-labelled-text-container" style=\'width:100%\'>\n' +
    '                    <label class="enl-floating-label">{{\'CONFIGURATION_KEY\' | translate}}</label>\n' +
    '                    <span>{{ detailsCtrl.curEnum.parent }}:{{ detailsCtrl.curEnum.val }} </span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/ops/control-unit/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/control-unit/details/info-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/control-unit/details/info-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Manufacturer * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'MANUFACTURER\' | translate}}</label>\n' +
    '                <md-select required name="manufacturer" ng-disabled="enlMode==\'edit\'" class="md-accent" ng-model="enlEnum.manufacturer"\n' +
    '                           ng-click="infoForm.manufacturer.$setDirty()">\n' +
    '                    <md-option ng-repeat="manufacturer in options.manufacturers track by $index" value="{{manufacturer.val}}">\n' +
    '                        {{manufacturer.names | localize}}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '                <div ng-messages="infoForm.manufacturer.$error" role="alert">\n' +
    '                    <div ng-message="required">{{\'MANUFACTURER_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <!-- Part Number * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'PART_NUMBER\' | translate }}</label>\n' +
    '                <input required type="text" name="val" ng-disabled="enlMode==\'edit\'" ng-model="enlEnum.val" enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.val.$error" role="alert" md-auto-hide="false" ng-show="infoForm.val.$touched && infoForm.val.$invalid">\n' +
    '                    <div ng-message="required">{{\'PART_NUMBER_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="ascii">{{\'ASCII_ONLY_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Default Display Name -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '                <input required type="text" name="dname" ng-model="enlEnum.dname" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.dname.$error" role="alert" md-auto-hide="false" ng-show="infoForm.dname.$touched && infoForm.dname.$invalid">\n' +
    '                    <div ng-message="required">{{\'DEFAULT_DISPLAY_NAME_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <!-- Default Description -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '                <input type="text" name="ddescription" ng-model="enlEnum.ddescription" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.ddescription.$error" role="alert">\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '\n' +
    '            <!-- Number of Control Channels * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'NUMBER_OF_CONTROL_CHANNELS\' | translate}}</label>\n' +
    '                <input type="number" required name="channels" ng-model="enlEnum.channels" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '                <div ng-messages="infoForm.channels.$error" role="alert" md-auto-hide="false" ng-show="infoForm.channels.$touched && infoForm.channels.$invalid">\n' +
    '                    <div ng-message="required">{{\'NUMBER_OF_CONTROL_CHANNELS_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Supports Soft Metering -->\n' +
    '            <md-checkbox ng-model="enlEnum.metering">\n' +
    '                {{ \'SUPPORT_METERING\' | translate }}\n' +
    '            </md-checkbox>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '    <p enl-required-field-note></p>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/control-unit/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/control-unit/details/info.html',
    '<div ng-controller="ControlUnitInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '        <enl-control-unit-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                enl-enum="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                options="infoCtrl.options"\n' +
    '                enl-mode=\'edit\'>\n' +
    '        </enl-control-unit-form>\n' +
    '\n' +
    '        <enl-control-unit-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                enl-enum="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                options="infoCtrl.options"\n' +
    '                enl-mode=\'edit\'>\n' +
    '        </enl-control-unit-form-view-only>\n' +
    '\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/control-unit/list/control-unit-list.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/control-unit/list/control-unit-list.tpl.html',
    '<enl-data-table table-title="{{ listCtrl.tableTitle | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.addEnum" delete-fn="listCtrl.deleteEnums(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '        <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '            <thead md-head md-order="listCtrl.query.order">\n' +
    '            <tr md-row>\n' +
    '                <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '                <th md-column md-order-by="manufacturer"><span>{{\'MANUFACTURER\' | translate }}</span></th>\n' +
    '                <th md-column md-order-by="val"><span>{{\'PART_NUMBER\' | translate }}</span></th>\n' +
    '                <th md-column md-order-by="channels"><span>{{\'NUMBER_OF_CONTROL_CHANNELS\' | translate }}</span></th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody md-body>\n' +
    '            <tr md-row md-select="enum"\n' +
    '                ng-repeat="enum in (filteredItems = (items | filterBy: [\'name\', \'manufacturer\', \'val\', \'channels\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '                <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.onItemClick(enum)">{{enum.name}}</a></span> </td>\n' +
    '                <td md-cell><span>{{enum.manufacturer}}</span></td>\n' +
    '                <td md-cell><span>{{enum.val}}</span> </td>\n' +
    '                <td md-cell><span>{{enum.channels}}</span> </td>\n' +
    '            </tr>\n' +
    '            <tr md-row ng-if="!filteredItems.length">\n' +
    '                <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </md-table-container>\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{items.length}}"></md-table-pagination>\n' +
    '</enl-data-table>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/control-unit/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/control-unit/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-control-unit-table\n' +
    '            items="listCtrl.enums"\n' +
    '            options="listCtrl.options"\n' +
    '            on-item-click="listCtrl.viewEnumDetails"\n' +
    '            add-fn="listCtrl.createEnum"\n' +
    '            delete-fn="listCtrl.showDeleteConfirm">\n' +
    '    </enl-control-unit-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/device_type/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/device_type/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="min-height:680px;padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{createCtrl.kind | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-device-type-info-form\n' +
    '        obj="createCtrl.enumObj"\n' +
    '        form="createCtrl.infoForm">\n' +
    '      </enl-device-type-info-form>\n' +
    '      <div layout="row">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/device_type/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/device_type/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/ops/device_type/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/device_type/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/device_type/details/info.html',
    '<div ng-controller="DeviceTypeInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '        <enl-device-type-info-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                obj="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                enl-mode="edit">\n' +
    '        </enl-device-type-info-form>\n' +
    '        <enl-device-type-info-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                obj="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                enl-mode="edit">\n' +
    '        </enl-device-type-info-form-view-only>\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/device_type/directive/device_type-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/device_type/directive/device_type-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'DEVICE_TYPENAME\' | translate }}</label>\n' +
    '                <input required type="text" name="type" ng-model="obj.val" ng-disabled="enlMode==\'edit\'" enl-alpha-numeric-id enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.type.$error" role="alert" md-auto-hide="false" ng-show="infoForm.type.$touched && infoForm.type.$invalid">\n' +
    '                    <div ng-message="required">{{\'TYPE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="validid">{{\'VALID_ALPHANUMERIC_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="ascii">{{\'ASCII_ONLY_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Default Display Name * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '                <input required type="text" name="name" ng-model="obj.names[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.name.$error" role="alert" md-auto-hide="false" ng-show="infoForm.name.$touched && infoForm.name.$invalid">\n' +
    '                    <div ng-message="required">{{\'DEFAULT_DISPLAY_NAME_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <!-- Default Description -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '                <input type="text" name="description" ng-model="obj.descriptions[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.description.$error" role="alert">\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <md-input-container style="width:100%">\n' +
    '                <label enl-required-field>{{\'DEVICE_CATE\' | translate}}</label>\n' +
    '                <md-select required name="model" class="md-accent" ng-model="obj.device"  style="width: 100%;" ng-click="infoForm.model.$setDirty()">\n' +
    '                    <md-option ng-repeat="device in infoCtrl.devices track by $index" value="{{ device }}">\n' +
    '                        {{ device | translate }}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '                <div ng-messages="infoForm.model.$error" role="alert">\n' +
    '                    <div ng-message="required">{{\'MODEL_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- SVG XMl * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'SVG_XML\' | translate }}</label>\n' +
    '                <textarea required md-no-autogrow name="svg" ng-model="obj.svg" style="height:350px" enl-svg-parser></textarea>\n' +
    '                <div ng-messages="infoForm.svg.$error" role="alert" md-auto-hide="false" ng-show="infoForm.svg.$touched && infoForm.svg.$invalid">\n' +
    '                    <div ng-message="required">{{\'SVG_XML_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="xmlerr">{{\'SVG_XML_PARSING_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="werr">{{\'SVG_XML_WIDTH_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="herr">{{\'SVG_XML_HEIGHT_ERROR\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <enl-svg-viewer svgstring="obj.svg" devicestyle="\'device\'" overlay="\'device\'" svgprops="infoCtrl.svgProps">\n' +
    '                </enl-svg-viewer>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <p enl-required-field-note></p>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/device_type/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/device_type/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ \'DEVICE_TYPE_TITLE\' | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="val"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="name"><span>{{\'DEVICE_TYPENAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="manu"><span>{{\'DEVICE_CATE\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (listCtrl.enums | filterBy: [\'val\', \'name\', \'manu\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{ enum.name }}</a></span> </td>\n' +
    '          <td md-cell><span>{{ enum.val }}</span> </td>\n' +
    '          <td md-cell><span>{{ enum.device | translate }}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/driver/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/driver/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content layout-padding>\n' +
    '  <md-steppers md-dynamic-height md-stretch-steppers="always" md-selected="createCtrl.selectedStep" md-busy="createCtrl.showBusyText">\n' +
    '    <md-step ng-repeat="step in createCtrl.stepData track by $index" label="{{step.label}}" md-complete="step.completed" ng-disabled="createCtrl.stepProgress < $index+1">\n' +
    '      <md-content ng-include="step.templateUrl"> </md-content>\n' +
    '    </md-step>\n' +
    '  </md-steppers>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/driver/create/info_step.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/driver/create/info_step.html',
    '<div ng-controller="CreateDriverInfoCtrl as infoCtrl" style="padding: 0.8rem;">\n' +
    '  <p>{{ \'NEW_DRIVER_INFO_HELP_TEXT\' | translate }}</p>\n' +
    '  <div>\n' +
    '    <enl-regulator-info-form\n' +
    '      enl-enum="infoCtrl.newDriver"\n' +
    '      enl-mode="\'create\'"\n' +
    '      manufacturers="infoCtrl.manufacturers"\n' +
    '      electrical-interfaces="infoCtrl.electricalInterfaces"\n' +
    '      form="infoCtrl.infoForm">\n' +
    '    </enl-regulator-info-form>\n' +
    '  </div>\n' +
    '  <md-step-actions layout="row">\n' +
    '    <div flex layout="row" layout-align="end top">\n' +
    '        <md-button class="md-primary" ng-click="createCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '        <md-button class="md-accent md-raised" ng-disabled="infoCtrl.infoForm.$invalid" ng-click="infoCtrl.nextStep()">{{\'NEXT_STEP\' | translate }}</md-button>\n' +
    '    </div>\n' +
    '  </md-step-actions>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/driver/create/lamp_configs_step.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/driver/create/lamp_configs_step.html',
    '<div ng-controller="CreateDriverLampConfigsCtrl as configsCtrl" style="padding: 0.8rem;">\n' +
    '  <div>\n' +
    '    <p ng-hide="configsCtrl.mode === \'form\'">{{ \'NEW_DRIVER_LAMP_CONFIGS_HELP_TEXT\' | translate }}</p>\n' +
    '    <enl-lamp-config-widget\n' +
    '      configurations="configsCtrl.configurations"\n' +
    '      lamps="configsCtrl.lamps"\n' +
    '      enl-on-view-change="configsCtrl.onViewChange"\n' +
    '      options="configsCtrl.options">\n' +
    '    </enl-lamp-config-widget>\n' +
    '  </div>\n' +
    '  <md-step-actions layout="row" ng-hide="configsCtrl.mode === \'form\'">\n' +
    '    <div flex layout="row" layout-align="end top">\n' +
    '      <md-button class="md-primary" ng-click="createCtrl.goPreviousStep(step)">{{\'PREVIOUS_STEP\' | translate}}</md-button>\n' +
    '      <span flex></span>\n' +
    '      <md-button class="md-primary" ng-click="createCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '      <md-button class="md-accent md-raised" ng-click="configsCtrl.finish()">{{\'FINISH\' | translate }}</md-button>\n' +
    '    </div>\n' +
    '  </md-step-actions>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/driver/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/driver/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>{{detailsCtrl.curDriver.val}}</h3>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="enl-vertical-separator"></div>\n' +
    '      <div layout="row" flex layout-align="start center">\n' +
    '        <div layout="column" class="enl-labelled-text-container" style=\'width:100%\'>\n' +
    '          <label class="enl-floating-label">{{\'CONFIGURATION_KEY\' | translate}}</label>\n' +
    '          <span>{{ detailsCtrl.curDriver.parent }}:{{ detailsCtrl.curDriver.val }} </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'EDIT\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon style="color: white;">edit</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'VIEW\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'DELETE\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon>delete</md-icon>\n' +
    '      </md-button>\n' +
    '    </div>\n' +
    '    <!-- Tabs -->\n' +
    '    <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '      <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '        <ng-include src="\'app-container/components/global-settings/ops/driver/details/info.html\'"></ng-include>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'LAMP_CONFIGURATIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectLampConfigsTab()">\n' +
    '        <ng-include src="\'app-container/components/global-settings/ops/driver/details/lamp-configs.html\'"></ng-include>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '        <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.names"\n' +
    '          title-table="\'Name\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '          type="\'names\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir>\n' +
    '\n' +
    '        <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.names"\n' +
    '          title-table="\'Name\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '          type="\'names\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir-view-only>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '        <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.descriptions"\n' +
    '          title-table="\'Descriptions\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '          type="\'descriptions\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir>\n' +
    '\n' +
    '        <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.descriptions"\n' +
    '          title-table="\'Descriptions\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '          type="\'descriptions\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir-view-only>\n' +
    '      </md-tab>\n' +
    '    </md-tabs>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/driver/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/driver/details/info.html',
    '<div ng-controller="DriverInfoCtrl as infoCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '    <enl-regulator-info-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '      enl-enum="infoCtrl.curDriver"\n' +
    '      manufacturers="infoCtrl.manufacturers"\n' +
    '      electrical-interfaces="infoCtrl.electricalInterfaces"\n' +
    '      form="infoCtrl.infoForm">\n' +
    '    </enl-regulator-info-form>\n' +
    '\n' +
    '    <enl-regulator-info-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '      enl-enum="infoCtrl.curDriver"\n' +
    '      manufacturers="infoCtrl.manufacturers"\n' +
    '      electrical-interfaces="infoCtrl.electricalInterfaces"\n' +
    '      form="infoCtrl.infoForm">\n' +
    '    </enl-regulator-info-form-view-only>\n' +
    '    <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '      <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '        <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '        <md-icon style="color: white;">check</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '        <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '        <md-icon style="color: white;">refresh</md-icon>\n' +
    '      </md-button>\n' +
    '      <div ng-show="infoCtrl.hasAsync">\n' +
    '        <circle-spinner></circle-spinner>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/driver/details/lamp-configs.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/driver/details/lamp-configs.html',
    '<div ng-controller="DriverLampConfigsCtrl as configsCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!configsCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="configsCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '    <enl-lamp-config-widget ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '      configurations="configsCtrl.configurations"\n' +
    '      lamps="configsCtrl.lamps"\n' +
    '      enl-on-change="configsCtrl.onConfigChange"\n' +
    '      options="configsCtrl.options">\n' +
    '    </enl-lamp-config-widget>\n' +
    '\n' +
    '    <enl-lamp-config-widget-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '      configurations="configsCtrl.configurations"\n' +
    '      lamps="configsCtrl.lamps"\n' +
    '      enl-on-change="configsCtrl.onConfigChange"\n' +
    '      options="configsCtrl.options">\n' +
    '    </enl-lamp-config-widget-view-only>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/driver/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/driver/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-regulator-data-table\n' +
    '    items="listCtrl.enums"\n' +
    '    options="listCtrl.options"\n' +
    '    kind=\'driver\'>\n' +
    '  </enl-regulator-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lamp/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lamp/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{createCtrl.kind | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-lamp-info-form\n' +
    '          obj="createCtrl.enumObj"\n' +
    '          form="createCtrl.infoForm"\n' +
    '          enl-mode="create">\n' +
    '      </enl-lamp-info-form>\n' +
    '      <div layout="row">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lamp/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lamp/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="enl-vertical-separator"></div>\n' +
    '      <div layout="row" flex layout-align="start center">\n' +
    '        <div layout="column" class="enl-labelled-text-container" style=\'width:100%\'>\n' +
    '          <label class="enl-floating-label">{{\'CONFIGURATION_KEY\' | translate}}</label>\n' +
    '          <span>{{ detailsCtrl.curEnum.parent }}:{{ detailsCtrl.curEnum.val }} </span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'EDIT\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon style="color: white;">edit</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'VIEW\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'DELETE\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        <md-icon>delete</md-icon>\n' +
    '      </md-button>\n' +
    '    </div>\n' +
    '    <!-- Tabs -->\n' +
    '    <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '      <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '        <ng-include src="\'app-container/components/global-settings/ops/lamp/details/info.html\'"></ng-include>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '        <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.names"\n' +
    '          title-table="\'Name\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '          type="\'names\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir>\n' +
    '\n' +
    '        <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.names"\n' +
    '          title-table="\'Name\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '          type="\'names\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir-view-only>\n' +
    '      </md-tab>\n' +
    '      <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '        <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.descriptions"\n' +
    '          title-table="\'Descriptions\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '          type="\'descriptions\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir>\n' +
    '\n' +
    '        <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '          options="detailsCtrl.curEnum.descriptions"\n' +
    '          title-table="\'Descriptions\'"\n' +
    '          title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '          type="\'descriptions\'"\n' +
    '          save-fn="detailsCtrl.saveFn"\n' +
    '        ></enl-language-handler-dir-view-only>\n' +
    '      </md-tab>\n' +
    '    </md-tabs>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lamp/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lamp/details/info.html',
    '<div ng-controller="LampInfoCtrl as infoCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '    <enl-lamp-info-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '        obj="infoCtrl.enumObj"\n' +
    '        form="infoCtrl.infoForm">\n' +
    '    </enl-lamp-info-form>\n' +
    '\n' +
    '    <enl-lamp-info-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '        obj="infoCtrl.enumObj"\n' +
    '        form="infoCtrl.infoForm">\n' +
    '    </enl-lamp-info-form-view-only>\n' +
    '    <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '      <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '        <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '        <md-icon style="color: white;">check</md-icon>\n' +
    '      </md-button>\n' +
    '      <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '        <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '        <md-icon style="color: white;">refresh</md-icon>\n' +
    '      </md-button>\n' +
    '      <div ng-show="infoCtrl.hasAsync">\n' +
    '        <circle-spinner></circle-spinner>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lamp/directive/lamp-form.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lamp/directive/lamp-form.template.html',
    '<form name="lampInfoForm">\n' +
    '  <div class="enl-form-container">\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'MANUFACTURER\' | translate}}</label>\n' +
    '        <md-select class="md-accent" required name="parent" ng-disabled="enlMode!==\'create\'" ng-model="obj.parent">\n' +
    '          <md-option ng-repeat="manufacturer in lampFormCtrl.manufacturers" value="{{manufacturer.val}}">\n' +
    '            {{manufacturer.val}}\n' +
    '          </md-option>\n' +
    '        </md-select>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'PART_NUMBER\' | translate}}</label>\n' +
    '        <input required type="text" name="pnum" ng-model="obj.val" ng-disabled="enlMode!==\'create\'" enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '        <div ng-messages="lampInfoForm.pnum.$error" role="alert" md-auto-hide="false" multiple ng-show="lampInfoForm.pnum.$touched && lampInfoForm.pnum.$invalid">\n' +
    '          <div ng-message="required">{{\'PART_NUMBER_REQUIRED_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="ascii">{{\'ASCII_ONLY_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '        <input required type="text" name="name" ng-model="obj.names[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '        <div ng-messages="lampInfoForm.name.$error" role="alert" md-auto-hide="false" ng-show="infoForm.name.$touched && infoForm.name.$invalid">\n' +
    '          <div ng-message="required">{{\'DEFAULT_DISPLAY_NAME_REQUIRED_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'DESCRIPTION\' | translate}}</label>\n' +
    '        <input type="text" name="description" ng-model="obj.descriptions[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '        <div ng-messages="lampInfoForm.description.$error" role="alert">\n' +
    '          <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'TYPE\' | translate}}</label>\n' +
    '        <md-select class="md-accent" required name="type" ng-model="obj.type">\n' +
    '          <md-option ng-repeat="ltype in lampFormCtrl.lamp_types" value="{{ltype.val}}">\n' +
    '            {{ltype.val}}\n' +
    '          </md-option>\n' +
    '        </md-select>\n' +
    '        <div ng-messages="lampInfoForm.type.$error" role="alert">\n' +
    '          <div ng-message="required">{{\'TYPE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container>\n' +
    '        <md-checkbox ng-model="obj.led" aria-label="LED">\n' +
    '          LED\n' +
    '        </md-checkbox>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_INIT_LUMENS\' | translate}}</label>\n' +
    '        <input type="number" name="initial_lumens" ng-model="obj.initial_lumens" ng-pattern="$root.REGEX_LIB.INTEGER" ng-max="$root.NUMBER_LIMITS.MAX_LONG" ng-min="1">\n' +
    '        <div ng-messages="lampInfoForm.initial_lumens.$error" role="alert" md-auto-hide="false" ng-show="lampInfoForm.initial_lumens.$touched && lampInfoForm.initial_lumens.$invalid">\n' +
    '          <div ng-message="number">{{\'NUMBER_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="pattern">{{\'INTEGER_NOT_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '          <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_DESIGN_LUMENS\' | translate}}</label>\n' +
    '        <input type="number" name="design_lumens" ng-model="obj.design_lumens" ng-pattern="$root.REGEX_LIB.INTEGER" ng-max="$root.NUMBER_LIMITS.MAX_LONG" ng-min="1">\n' +
    '        <div ng-messages="lampInfoForm.design_lumens.$error" role="alert" md-auto-hide="false" ng-show="lampInfoForm.design_lumens.$touched && lampInfoForm.design_lumens.$invalid">\n' +
    '          <div ng-message="number">{{\'NUMBER_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="pattern">{{\'INTEGER_NOT_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '          <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'LAMP_WATT\' | translate}}</label>\n' +
    '        <input required type="number" name="wattage" ng-model="obj.wattage" ng-min="1.0">\n' +
    '        <div ng-messages="lampInfoForm.wattage.$error" role="alert" md-auto-hide="false" ng-show="lampInfoForm.wattage.$touched && lampInfoForm.wattage.$invalid">\n' +
    '          <div ng-message="number">{{\'NUMBER_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '          <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_INST_REG\' | translate}}</label>\n' +
    '        <input type="number" name="life_ins_start" ng-model="obj.life_ins_start" ng-pattern="$root.REGEX_LIB.INTEGER" ng-max="$root.NUMBER_LIMITS.MAX_LONG" ng-min="1">\n' +
    '        <div ng-messages="lampInfoForm.life_ins_start.$error" role="alert" md-auto-hide="false" ng-show="lampInfoForm.life_ins_start.$touched && lampInfoForm.life_ins_start.$invalid">\n' +
    '          <div ng-message="number">{{\'NUMBER_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="pattern">{{\'INTEGER_NOT_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '          <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_PROG_REG\' | translate}}</label>\n' +
    '        <input type="number" name="life_prog_start" ng-model="obj.life_prog_start" ng-pattern="$root.REGEX_LIB.INTEGER" ng-max="$root.NUMBER_LIMITS.MAX_LONG" ng-min="1">\n' +
    '        <div ng-messages="lampInfoForm.life_prog_start.$error" role="alert" md-auto-hide="false" ng-show="lampInfoForm.life_prog_start.$touched && lampInfoForm.life_prog_start.$invalid">\n' +
    '          <div ng-message="number">{{\'NUMBER_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="pattern">{{\'INTEGER_NOT_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '          <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_COLOR_IDX\' | translate}}</label>\n' +
    '        <input type="number" name="cri" ng-model="obj.cri" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '        <div ng-messages="lampInfoForm.cri.$error" role="alert" md-auto-hide="false" ng-show="lampInfoForm.cri.$touched && lampInfoForm.cri.$invalid">\n' +
    '          <div ng-message="number">{{\'NUMBER_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="pattern">{{\'INTEGER_NOT_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '          <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_COLOR_TEMP\' | translate}}</label>\n' +
    '        <input type="number" name="color_temp" ng-model="obj.color_temp" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '        <div ng-messages="lampInfoForm.color_temp.$error" role="alert" md-auto-hide="false" ng-show="lampInfoForm.color_temp.$touched && lampInfoForm.color_temp.$invalid">\n' +
    '          <div ng-message="number">{{\'NUMBER_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="pattern">{{\'INTEGER_NOT_VALID_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '          <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'DIAMETER\' | translate}}</label>\n' +
    '        <input type="number" name="diameter_width" ng-model="obj.diameter.width">\n' +
    '        <div ng-messages="lampInfoForm.diameter_width.$error" role="alert">\n' +
    '          <div ng-message="number">{{\'NUMBER_VALID_ERROR\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-primary" flex>\n' +
    '        <label>{{\'UNIT_NAME\' | translate}}</label>\n' +
    '        <md-select class="md-primary" name="diameter_units" ng-model="obj.diameter.units">\n' +
    '          <md-option  ng-repeat="unit in lampFormCtrl.units track by $index" value="{{unit}}">\n' +
    '            {{unit | translate}}\n' +
    '          </md-option>\n' +
    '          <!--\n' +
    '          <div ng-messages="lampInfoForm.diameter_units.$error" role="alert">\n' +
    '            <div ng-message="required">{{\'UNIT_REQUIRED_ERROR\' | translate}}</div>\n' +
    '          </div>\n' +
    '          -->\n' +
    '        </md-select>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LENGTH\' | translate}}</label>\n' +
    '        <input type="number" name="length_width" ng-model="obj.length.width">\n' +
    '        <div ng-messages="lampInfoForm.length_width.$error" role="alert">\n' +
    '          <div ng-message="number">{{\'NUMBER_VALID_ERROR\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-primary" flex>\n' +
    '        <label>{{\'UNIT_NAME\' | translate}}</label>\n' +
    '        <md-select class="md-primary" name="length_units" ng-model="obj.length.units">\n' +
    '          <md-option ng-repeat="unit in lampFormCtrl.units" value="{{unit}}">\n' +
    '            {{unit | translate }}\n' +
    '          </md-option>\n' +
    '        </md-select>\n' +
    '        <!--\n' +
    '        <div ng-messages="lampInfoForm.length_units.$error" role="alert">\n' +
    '          <div ng-message="required">{{\'UNIT_REQUIRED_ERROR\' | translate}}</div>\n' +
    '        </div>\n' +
    '        -->\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <p enl-required-field-note></p>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lamp/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lamp/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ listCtrl.enumType | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="val"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="name"><span>{{\'PART_NUMBER\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="manu"><span>{{\'MANUFACTURER\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="description"><span>{{\'DESCRIPTION\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (listCtrl.enums | filterBy: [\'val\', \'name\', \'manu\', \'description\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{enum.val}}</a></span> </td>\n' +
    '          <td md-cell><span>{{enum.name}}</span> </td>\n' +
    '          <td md-cell><span>{{enum.parent}}</span> </td>\n' +
    '          <td md-cell><span>{{enum.description}}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/gang-box/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/gang-box/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>Create New {{ \'gang_box\' | translate}}</h3>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div>\n' +
    '            <enl-gang-box-form\n' +
    '                    enl-enum="createCtrl.newEnum"\n' +
    '                    form="createCtrl.infoForm"\n' +
    '                    options="createCtrl.options"\n' +
    '                    kind="gang_box">\n' +
    '            </enl-gang-box-form>\n' +
    '\n' +
    '            <div layout="row">\n' +
    '                <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '                    <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">check</md-icon>\n' +
    '                </md-button>\n' +
    '                <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '                    <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">close</md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/gang-box/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/gang-box/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/ops/gang-box/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/gang-box/details/info-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/gang-box/details/info-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Model * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'MODEL\' | translate}}</label>\n' +
    '                <input required type="text" name="model" ng-model="enlEnum.val" ng-disabled="enlMode==\'edit\'" enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.model.$error" role="alert" md-auto-hide="false" ng-show="infoForm.model.$touched && infoForm.model.$invalid">\n' +
    '                    <div ng-message="required">{{\'MODEL_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="ascii">{{\'ASCII_ONLY_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Default Display Name * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '                <input required type="text" name="dname" ng-model="enlEnum.dname" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.dname.$error" role="alert" md-auto-hide="false" ng-show="infoForm.dname.$touched && infoForm.dname.$invalid">\n' +
    '                    <div ng-message="required">{{\'DEFAULT_DISPLAY_NAME_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <!-- Default Description -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '                <input type="text" name="ddescription" ng-model="enlEnum.ddescription" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.ddescription.$error" role="alert">\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '          <!-- width -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '              <label enl-required-field>{{ \'WIDTH\' | translate }}</label>\n' +
    '              <input type="number" ng-model="enlEnum.width.width" name="widthvalue" required ng-pattern="/^\\d+$/">\n' +
    '              <div ng-messages="infoForm.widthvalue.$error" role="alert">\n' +
    '                <div ng-message="required">{{\'REQUIRED_ERROR\' | translate}}</div>\n' +
    '                <div ng-message="pattern">{{\'WIDTH_INVALID\' | translate}}</div>\n' +
    '              </div>\n' +
    '          </md-input-container>\n' +
    '          <!-- <md-input-container class="md-block md-accent" flex>\n' +
    '              <label enl-required-field>{{ \'UNITS\' | translate }}</label>\n' +
    '              <md-select name="widthunit" ng-model="enlEnum.width.units" class="md-accent" required>\n' +
    '                  <md-option ng-repeat="unit in enumInfoCtrl.units" value="{{unit}}">\n' +
    '                      {{unit}}\n' +
    '                  </md-option>\n' +
    '              </md-select>\n' +
    '              <div ng-messages="floorForm.floorplan_unit.$error" role="alert">\n' +
    '                  <div ng-message="required">{{ \'UNIT_REQUIRED_ERROR\' | translate }}</div>\n' +
    '              </div>\n' +
    '          </md-input-container> -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{ \'HEIGHT\' | translate }}</label>\n' +
    '            <input type="number" ng-model="enlEnum.height.width" name="heightvalue" ng-pattern="/^\\d+$/" required>\n' +
    '            <div ng-messages="infoForm.heightvalue.$error" role="alert">\n' +
    '              <div ng-message="required">{{\'REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="pattern">{{\'HEIGHT_INVALID\' | translate}}</div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{ \'UNITS\' | translate }}</label>\n' +
    '            <md-select name="widthunit" ng-model="enlEnum.height.units" md-on-close="enlEnum.width.units = enlEnum.height.units" ng-change="enlEnum.width.units = enlEnum.height.units" class="md-accent" required>\n' +
    '              <md-option ng-repeat="unit in enumInfoCtrl.units" value="{{unit}}">\n' +
    '                {{unit}}\n' +
    '              </md-option>\n' +
    '            </md-select>\n' +
    '            <div ng-messages="floorForm.floorplan_unit.$error" role="alert">\n' +
    '              <div ng-message="required">{{ \'UNIT_REQUIRED_ERROR\' | translate }}</div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '        </div>\n' +
    '          <!-- <div layout="row" class="enl-form-field-container">\n' +
    '              <! height\n' +
    '          </div> -->\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Max Number of Outlets -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label ng-if="kind === \'gang_box\'">{{ \'MAX_NUMBER_OF_OUTLETS\' | translate }}</label>\n' +
    '                <label ng-if="kind === \'light_enclosure\'">{{ \'MAX_NUMBER_OF_LAMPS\' | translate }}</label>\n' +
    '                <input type="number" name="maxoutlets" ng-model="enlEnum.max_outlets" ng-pattern=/^\\d+$/  ng-if="kind === \'gang_box\'" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '                <input type="number" name="maxlamps" ng-model="enlEnum.max_lamps" ng-pattern=/^\\d+$/  ng-if="kind === \'light_enclosure\'" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '                <div ng-messages="infoForm.maxoutlets.$error" role="alert" ng-if="kind === \'gang_box\'" md-auto-hide="false" ng-show="infoForm.maxoutlets.$touched && infoForm.maxoutlets.$invalid">\n' +
    '                    <div ng-message="pattern">{{\'MAX_OUTLETS_INVALID_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '                    <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '                </div>\n' +
    '                <div ng-messages="infoForm.maxlamps.$error" role="alert" ng-if="kind === \'light_enclosure\'" md-auto-hide="false" ng-show="infoForm.maxlamps.$touched && infoForm.maxlamps.$invalid">\n' +
    '                    <div ng-message="pattern">{{\'MAX_LAMPS_INVALID_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '                    <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </div>\n' +
    '        <div layout="row" layout-align="start" class="enl-form-field-container">\n' +
    '            <div layout="column" flex>\n' +
    '                <!-- SVG XMl * -->\n' +
    '                <md-input-container class="md-block md-accent" style="margin-bottom: 0;">\n' +
    '                    <label enl-required-field>{{\'SVG_XML\' | translate }}</label>\n' +
    '                    <textarea md-no-autogrow required name="svg" ng-model="enlEnum.svg" style="height:260px" enl-svg-parser></textarea>\n' +
    '                    <div ng-messages="infoForm.svg.$error" role="alert" md-auto-hide="false" ng-show="infoForm.svg.$touched && infoForm.svg.$invalid">\n' +
    '                        <div ng-message="required">{{\'SVG_XML_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                        <div ng-message="xmlerr">{{\'SVG_XML_PARSING_ERROR\' | translate}}</div>\n' +
    '                        <div ng-message="werr">{{\'SVG_XML_WIDTH_ERROR\' | translate}}</div>\n' +
    '                        <div ng-message="herr">{{\'SVG_XML_HEIGHT_ERROR\' | translate}}</div>\n' +
    '                    </div>\n' +
    '                </md-input-container>\n' +
    '                <div>\n' +
    '                    <md-button ng-click="enumInfoCtrl.autogenerateSVG()" ng-disabled="enumInfoCtrl.isAutogenerateDisabled()">{{ \'AUTO_GENERATE_SVG\' | translate}}</md-button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <enl-svg-viewer svgstring="enlEnum.svg" devicestyle="\'enclosure\'" overlay="\'enclosure\'" svgprops="enumInfoCtrl.svgProps">\n' +
    '                </enl-svg-viewer>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <p enl-required-field-note></p>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/gang-box/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/gang-box/details/info.html',
    '<div ng-controller="GangBoxInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '        <enl-gang-box-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                enl-enum="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                options="infoCtrl.options"\n' +
    '                kind="gang_box"\n' +
    '                enl-mode="edit">\n' +
    '        </enl-gang-box-form>\n' +
    '\n' +
    '        <enl-gang-box-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode && infoCtrl.isLoaded"\n' +
    '                enl-enum="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                options="infoCtrl.options"\n' +
    '                kind="gang_box"\n' +
    '                enl-mode="edit">\n' +
    '        </enl-gang-box-form-view-only>\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/gang-box/list/gang-box-list.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/gang-box/list/gang-box-list.tpl.html',
    '<enl-data-table table-title="{{ listCtrl.tableTitle | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.addEnum" delete-fn="listCtrl.deleteEnums(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '        <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '            <thead md-head md-order="listCtrl.query.order">\n' +
    '            <tr md-row>\n' +
    '                <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '                <th md-column md-order-by="val"><span>{{\'MODEL\' | translate }}</span></th>\n' +
    '                <th md-column md-order-by="description"><span>{{\'DESCRIPTION\' | translate }}</span></th>\n' +
    '\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody md-body>\n' +
    '            <tr md-row md-select="enum"\n' +
    '                ng-repeat="enum in (filteredItems = (items | filterBy: [\'name\', \'val\', \'description\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '                <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.onItemClick(enum)">{{enum.name}}</a></span> </td>\n' +
    '                <td md-cell><span>{{enum.val}}</span></td>\n' +
    '                <td md-cell><span>{{enum.description}}</span> </td>\n' +
    '            </tr>\n' +
    '            <tr md-row ng-if="!filteredItems.length">\n' +
    '                <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </md-table-container>\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{items.length}}"></md-table-pagination>\n' +
    '</enl-data-table>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/gang-box/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/gang-box/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-gang-box-table\n' +
    '            items="listCtrl.enums"\n' +
    '            options="listCtrl.options"\n' +
    '            on-item-click="listCtrl.viewEnumDetails"\n' +
    '            add-fn="listCtrl.createEnum"\n' +
    '            delete-fn="listCtrl.showDeleteConfirm">\n' +
    '    </enl-gang-box-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lampcfg/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lampcfg/create/create.html',
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content id="address_type">\n' +
    '  <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{\'lampcfg\' | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <md-steppers md-dynamic-height md-stretch-steppers="always" md-selected="createCtrl.selectedStep" md-busy="createCtrl.showBusyText">\n' +
    '        <md-step label="{{\'LAMP_CONFIG_CREATE\' | translate}}" md-complete="createCtrl.step1.completed" ng-disabled="createCtrl.step1.disabled">\n' +
    '          <enl-lamp-config-dir\n' +
    '            enlenum="createCtrl.enumObj"\n' +
    '            form="createCtrl.infoForm"\n' +
    '            enlmode="\'create\'"\n' +
    '            manufacturers="createCtrl.manufacturers">\n' +
    '          </enl-lamp-config-dir>\n' +
    '          <md-step-actions layout="row">\n' +
    '            <div flex layout="row" layout-align="end top">\n' +
    '                <md-button class="md-primary" ng-click="createCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '                <md-button class="md-accent md-raised" ng-disabled="createCtrl.infoForm.$invalid" ng-click="createCtrl.nextStep()">{{\'NEXT_STEP\' | translate }}</md-button>\n' +
    '            </div>\n' +
    '          </md-step-actions>\n' +
    '        </md-step>\n' +
    '        <md-step label="{{\'LAMP_CONFIG_CREATE_2\' | translate}}" md-complete="createCtrl.step2.completed" ng-disabled="createCtrl.step2.disabled">\n' +
    '          <ng-form name="createCtrl.wattageForm">\n' +
    '            <div class="enl-form-container">\n' +
    '              <div layout="row" class="enl-form-field-container">\n' +
    '                <md-input-container class="md-block md-accent" flex>\n' +
    '                  <label enl-required-field>{{\'LINE_VOLTAGE\' | translate}}</label>\n' +
    '                  <input required type="number" name="voltage" ng-model="createCtrl.curConfig.voltage" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '                  <div ng-messages="createCtrl.wattageForm.voltage.$error" role="alert" md-auto-hide="false" ng-show="createCtrl.wattageForm.voltage.$touched && createCtrl.wattageForm.voltage.$invalid">\n' +
    '                    <div ng-message="required">{{\'LINE_VOLTAGE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="min">{{\'LINE_VOLTAGE_MIN_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="pattern">{{\'INPUT_TYPE_INTEGER_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '                  </div>\n' +
    '                </md-input-container>\n' +
    '                <md-input-container class="md-block md-accent" flex>\n' +
    '                  <label enl-required-field>{{\'MAX_INPUT_POWER\' | translate}}</label>\n' +
    '                  <input required type="number" name="max_input_power" ng-model="createCtrl.curConfig.maxpower" ng-min="1.0">\n' +
    '                  <div ng-messages="createCtrl.wattageForm.max_input_power.$error" role="alert" md-auto-hide="false" ng-show="createCtrl.wattageForm.max_input_power.$touched && createCtrl.wattageForm.max_input_power.$invalid">\n' +
    '                    <div ng-message="required">{{\'MAX_INPUT_POWER_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '                  </div>\n' +
    '                </md-input-container>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <div ng-if="!createCtrl.enumObj.metering">\n' +
    '              <h5>{{ \'METERING_WATTAGES\' | translate }}</h5>\n' +
    '              <md-divider></md-divider>\n' +
    '              <div style="padding: 0.8rem; padding-bottom: 1rem;">\n' +
    '                <enl-wattage-widget\n' +
    '                  entries="createCtrl.curConfig.wattages"\n' +
    '                  enl-on-change="createCtrl.onWattagesChange">\n' +
    '                </enl-wattage-widget>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '            <p enl-required-field-note></p>\n' +
    '          </ng-form>\n' +
    '          <md-step-actions layout="row">\n' +
    '            <div flex layout="row" layout-align="end top">\n' +
    '              <md-button class="md-primary" ng-click="createCtrl.goPreviousStep()">{{\'PREVIOUS_STEP\' | translate}}</md-button>\n' +
    '              <span flex></span>\n' +
    '              <md-button class="md-primary" ng-click="createCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '              <md-button class="md-accent md-raised" ng-disabled="createCtrl.wattageForm.$invalid" ng-click="createCtrl.save()">{{\'FINISH\' | translate }}</md-button>\n' +
    '            </div>\n' +
    '          </md-step-actions>\n' +
    '        </md-step>\n' +
    '      </md-steppers>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lampcfg/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lampcfg/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '              <ng-include src="\'app-container/components/global-settings/ops/lampcfg/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'WATTAGES\' | translate }}" md-on-select="detailsCtrl.onSelectWattageTab()">\n' +
    '              <!-- to check with conrad -->\n' +
    '              <ng-include ng-show="detailsCtrl.globalsettingseditmode" src="\'app-container/components/global-settings/ops/lampcfg/details/wattage.html\'"></ng-include>\n' +
    '              <ng-include ng-show="!detailsCtrl.globalsettingseditmode" src="\'app-container/components/global-settings/ops/lampcfg/details/view-only/wattage.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lampcfg/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lampcfg/details/info.html',
    '<div ng-controller="LampConfigInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '      <enl-lamp-config-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '        enlenum="infoCtrl.enumObj"\n' +
    '        form="infoCtrl.infoForm"\n' +
    '        enlmode="\'edit\'"\n' +
    '        manufacturers="infoCtrl.manufacturers">\n' +
    '      </enl-lamp-config-dir>\n' +
    '\n' +
    '      <enl-lamp-config-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '        enlenum="infoCtrl.enumObj"\n' +
    '        form="infoCtrl.infoForm"\n' +
    '        enlmode="\'edit\'"\n' +
    '        manufacturers="infoCtrl.manufacturers">\n' +
    '      </enl-lamp-config-dir-view-only>\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lampcfg/details/wattage.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lampcfg/details/wattage.html',
    '<div ng-controller="LampConfigWattageCtrl as lampCfgWattageCtrl" ng-switch="lampCfgWattageCtrl.mode">\n' +
    '  <!-- LIST MODE -->\n' +
    '  <div ng-switch-when="list">\n' +
    '    <md-button class="md-raised md-accent" style="margin-top: 1.5rem;" ng-click="lampCfgWattageCtrl.doAdd();">\n' +
    '      <md-tooltip md-direction="bottom">\n' +
    '        {{\'ADD_NEW\' | translate}}\n' +
    '      </md-tooltip>\n' +
    '      {{\'ADD_NEW\' | translate}}\n' +
    '    </md-button>\n' +
    '    <md-list style="padding: 0px 8px;">\n' +
    '      <enl-list-item ng-repeat="wattage in lampCfgWattageCtrl.enumObj.wattageMassaged track by $index"\n' +
    '        avatar-icon="\'lightbulb_outline\'"\n' +
    '        del-fn="lampCfgWattageCtrl.showDeleteConfirm(wattage, $index)"\n' +
    '        edit-fn="lampCfgWattageCtrl.doEdit(wattage.wattage, wattage.percentage, $index)">\n' +
    '        <div layout="row" layout-align="center center">\n' +
    '          <div flex>\n' +
    '            <div layout="column" layout-align="center start">\n' +
    '              <div flex>\n' +
    '                <h4>{{wattage.percentage}}V {{\'CONFIGURATION\' | translate}}</h4>\n' +
    '              </div>\n' +
    '              <div flex>\n' +
    '                <h4>{{wattage.wattage["100.0"]}}W {{\'MAX_INPUT_POWER_NO_W\' | translate}}</h4>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div flex style="margin-top: 10px;">\n' +
    '            <enl-chart flex ng-if="!lampCfgWattageCtrl.enumObj.metering" style="max-height: 100px;float: left;"\n' +
    '            entries-arr="wattage.chartedValues"\n' +
    '            ylabel="\'wattage\'"\n' +
    '            yvaluefrom="\'wattage\'"\n' +
    '            xlabel="\'percentage\'"\n' +
    '            xvaluefrom="\'percentage\'"\n' +
    '            widthpx="200"\n' +
    '            heightpx="130"\n' +
    '            size="\'small\'"\n' +
    '            ></enl-chart>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <md-divider ng-if="!$last"></md-divider>\n' +
    '      </enl-list-item>\n' +
    '    </md-list>\n' +
    '  </div>\n' +
    '  <!-- FORM MODE -->\n' +
    '  <div ng-switch-when="form">\n' +
    '    <ng-form name="lampCfgWattageCtrl.wattageForm">\n' +
    '      <h4>{{lampCfgWattageCtrl.curConfigTitle}}</h4>\n' +
    '      <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'LINE_VOLTAGE\' | translate}}</label>\n' +
    '            <input required type="number" name="voltage" ng-model="lampCfgWattageCtrl.curConfig.voltage" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '            <div ng-messages="lampCfgWattageCtrl.wattageForm.voltage.$error" role="alert" md-auto-hide="false" ng-show="lampCfgWattageCtrl.wattageForm.voltage.$touched && lampCfgWattageCtrl.wattageForm.voltage.$invalid">\n' +
    '              <div ng-message="required">{{\'LINE_VOLTAGE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="min">{{\'LINE_VOLTAGE_MIN_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="pattern">{{\'INPUT_TYPE_INTEGER_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'MAX_INPUT_POWER\' | translate}}</label>\n' +
    '            <input required type="number" name="max_input_power" ng-model="lampCfgWattageCtrl.curConfig.maxpower" ng-min="1.0">\n' +
    '            <div ng-messages="lampCfgWattageCtrl.wattageForm.max_input_power.$error" role="alert" md-auto-hide="false" ng-show="lampCfgWattageCtrl.wattageForm.max_input_power.$touched && lampCfgWattageCtrl.wattageForm.max_input_power.$invalid">\n' +
    '              <div ng-message="required">{{\'MAX_INPUT_POWER_REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div ng-if="!lampCfgWattageCtrl.enumObj.metering">\n' +
    '        <h5>{{ \'METERING_WATTAGES\' | translate }}</h5>\n' +
    '        <md-divider></md-divider>\n' +
    '        <div style="padding: 0.8rem; padding-bottom: 1rem;">\n' +
    '          <enl-wattage-widget\n' +
    '            entries="lampCfgWattageCtrl.curConfig.wattages"\n' +
    '            enl-on-change="lampCfgWattageCtrl.onWattagesChange">\n' +
    '          </enl-wattage-widget>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <p enl-required-field-note></p>\n' +
    '      <div layout="row" style="margin-left: -0.8rem;">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="lampCfgWattageCtrl.save()" ng-disabled="lampCfgWattageCtrl.wattageForm.$invalid || lampCfgWattageCtrl.wattageForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="lampCfgWattageCtrl.showCancelConfirm($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">clear</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </ng-form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lampcfg/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lampcfg/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ listCtrl.enumType | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="parent"><span>{{\'MANUFACTURER\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="val"><span>{{\'PART_NUMBER\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="description"><span>{{\'DESCRIPTION\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in listCtrl.enums | filterBy: [\'name\', \'parent\', \'val\', \'description\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit">\n' +
    '            <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{enum.name}}</a></span> </td>\n' +
    '          <td md-cell><span>{{enum.parent}}</span> </td>\n' +
    '          <td md-cell><span>{{enum.val}}</span> </td>\n' +
    '          <td md-cell><span>{{enum.description}}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!listCtrl.enums.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/light-enclosure/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/light-enclosure/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>Create New {{\'light_enclosure\' | translate}}</h3>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div>\n' +
    '            <enl-gang-box-form\n' +
    '                    enl-enum="createCtrl.newEnum"\n' +
    '                    form="createCtrl.infoForm"\n' +
    '                    options="createCtrl.options"\n' +
    '                    kind="light_enclosure">\n' +
    '            </enl-gang-box-form>\n' +
    '\n' +
    '            <div layout="row">\n' +
    '                <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '                    <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">check</md-icon>\n' +
    '                </md-button>\n' +
    '                <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '                    <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">close</md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/light-enclosure/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/light-enclosure/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-gang-box-table\n' +
    '            items="listCtrl.enums"\n' +
    '            options="listCtrl.options"\n' +
    '            on-item-click="listCtrl.viewEnumDetails"\n' +
    '            add-fn="listCtrl.createEnum"\n' +
    '            delete-fn="listCtrl.showDeleteConfirm">\n' +
    '    </enl-gang-box-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/light-enclosure/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/light-enclosure/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/ops/light-enclosure/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/light-enclosure/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/light-enclosure/details/info.html',
    '<div ng-controller="LightEnclosureInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '      <enl-gang-box-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '              enl-enum="infoCtrl.enumObj"\n' +
    '              form="infoCtrl.infoForm"\n' +
    '              options="infoCtrl.options"\n' +
    '              kind="light_enclosure"\n' +
    '              enl-mode="edit">\n' +
    '      </enl-gang-box-form>\n' +
    '      \n' +
    '      <enl-gang-box-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode && infoCtrl.isLoaded"\n' +
    '      enl-enum="infoCtrl.enumObj"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      options="infoCtrl.options"\n' +
    '      kind="light_enclosure"\n' +
    '      enl-mode="edit">\n' +
    '    </enl-gang-box-form-view-only>\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/luminaire/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/luminaire/create/create.html',
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content id="address_type">\n' +
    '  <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{\'luminaire\' | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <md-steppers md-dynamic-height md-stretch-steppers="always" md-selected="createCtrl.selectedStep" md-busy="createCtrl.showBusyText">\n' +
    '        <md-step label="{{\'INFORMATION\' | translate}}" md-complete="createCtrl.steps.step1.completed" ng-disabled="createCtrl.steps.step1.disabled">\n' +
    '          <enl-luminaire-type-dir\n' +
    '            enlenum="createCtrl.enumObj"\n' +
    '            form="createCtrl.informationForm"\n' +
    '            manufacturers="createCtrl.manufacturers"\n' +
    '            enlmode="\'create\'">\n' +
    '          </enl-luminaire-type-dir>\n' +
    '          <md-step-actions layout="row">\n' +
    '            <div flex layout="row" layout-align="end top">\n' +
    '                <md-button class="md-primary" ng-click="createCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '                <md-button class="md-accent md-raised" ng-disabled="createCtrl.informationForm.$invalid" ng-click="createCtrl.nextStep();">{{\'NEXT_STEP\' | translate }}</md-button>\n' +
    '            </div>\n' +
    '          </md-step-actions>\n' +
    '        </md-step>\n' +
    '        <md-step label="{{\'LAMP_CONFIGURATIONS\' | translate}}" md-complete="createCtrl.steps.step2.completed" ng-disabled="createCtrl.selectedStep < 1">\n' +
    '          <!--<div layout="column" ng-if="createCtrl.lampcfg_mode == \'list\'">\n' +
    '            <div flex>\n' +
    '              <md-button class="md-raised md-accent" ng-click="createCtrl.editView();">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                  {{\'ADD_NEW\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                {{\'ADD_NEW\' | translate}}\n' +
    '              </md-button>\n' +
    '              <md-list style="padding: 0px 8px;">\n' +
    '                <enl-list-item ng-repeat="(key, lampcfg) in createCtrl.enumObj.lamp_cfgs track by $index"\n' +
    '                  avatar-icon="\'lightbulb_outline\'"\n' +
    '                  del-fn="createCtrl.showDeleteConfirm(key)"\n' +
    '                  edit-fn="createCtrl.doEdit(lampcfg, key)">\n' +
    '                  <h4>{{key}}</h4>\n' +
    '                  <h4>{{lampcfg}}</h4>\n' +
    '                  <md-divider ng-if="!$last"></md-divider>\n' +
    '                </enl-list-item>\n' +
    '              </md-list>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '          <enl-luminaire-lampcfg-dir ng-if="createCtrl.lampcfg_mode == \'edit\'"\n' +
    '            form="createCtrl.lampConfigForm"\n' +
    '            enlenum="createCtrl.enumObj"\n' +
    '            lampcfgtypes="createCtrl.lampcfgtypes"\n' +
    '            partnumbers="createCtrl.lampcfg"\n' +
    '            add-fn="createCtrl.addLampCfg"\n' +
    '            cancel-fn="createCtrl.cancelLampCfg">\n' +
    '          </enl-luminaire-lampcfg-dir>\n' +
    '          <md-step-actions layout="row">\n' +
    '            <div flex layout="row" layout-align="end top">\n' +
    '              <md-button class="md-primary" ng-click="createCtrl.previousStep();">{{\'PREVIOUS_STEP\' | translate}}</md-button>\n' +
    '              <span flex></span>\n' +
    '              <md-button class="md-primary" ng-click="createCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '              <md-button class="md-accent md-raised" ng-disabled="createCtrl.lampConfigForm.$invalid || createCtrl.compareConfigs() != \'true\'" ng-click="createCtrl.nextStep();">{{\'NEXT_STEP\' | translate }}</md-button>\n' +
    '            </div>\n' +
    '          </md-step-actions>-->\n' +
    '          <enl-luminaire-sensors-dir\n' +
    '            enlenum="createCtrl.enumObj"\n' +
    '            enlmode="\'create\'"\n' +
    '            sensorform="createCtrl.sensorsForm"\n' +
    '            manufacturers="createCtrl.manufacturers">\n' +
    '            <!-- productcodes="createCtrl.productCodes" -->\n' +
    '          </enl-luminaire-sensors-dir>\n' +
    '          <md-step-actions layout="row" class="col-sm-12" style="margin: 3rem 0;">\n' +
    '            <div flex layout="row" layout-align="end top">\n' +
    '              <md-button class="md-primary" ng-click="createCtrl.previousStep();">{{\'PREVIOUS_STEP\' | translate}}</md-button>\n' +
    '              <span flex></span>\n' +
    '              <md-button class="md-primary" ng-click="createCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '              <md-button class="md-accent md-raised" ng-disabled="createCtrl.backupSensor.length == 0 || createCtrl.sensorsForm.$invalid || createCtrl.sensorform.$pristine" ng-click="createCtrl.save();">{{\'FINISH\' | translate }}</md-button>\n' +
    '            </div>\n' +
    '          </md-step-actions>\n' +
    '        </md-step>\n' +
    '        <!--<md-step label="{{\'SENSORS\' | translate}}" md-complete="createCtrl.steps.step3.completed" ng-disabled="createCtrl.selectedStep < 2">\n' +
    '          <enl-luminaire-sensors-dir\n' +
    '            enlenum="createCtrl.enumObj"\n' +
    '            enlmode="\'create\'"\n' +
    '            sensorform="createCtrl.sensorsForm"\n' +
    '            manufacturers="createCtrl.manufacturers"\n' +
    '            productcodes="createCtrl.productCodes">\n' +
    '          </enl-luminaire-sensors-dir>\n' +
    '\n' +
    '          <md-step-actions layout="row" class="col-sm-12" style="margin: 3rem 0;">\n' +
    '            <div flex layout="row" layout-align="end top">\n' +
    '              <md-button class="md-primary" ng-click="createCtrl.previousStep();">{{\'PREVIOUS_STEP\' | translate}}</md-button>\n' +
    '              <span flex></span>\n' +
    '              <md-button class="md-primary" ng-click="createCtrl.showConfirmCancel($event)">{{\'CANCEL_STEP\' | translate }}</md-button>\n' +
    '              <md-button class="md-accent md-raised" ng-disabled="createCtrl.backupSensor.length == 0 || createCtrl.sensorsForm.$invalid || createCtrl.sensorform.$pristine" ng-click="createCtrl.save();">{{\'FINISH\' | translate }}</md-button>\n' +
    '            </div>\n' +
    '          </md-step-actions>\n' +
    '        </md-step>-->\n' +
    '      </md-steppers>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/luminaire/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/luminaire/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '              <ng-include src="\'app-container/components/global-settings/ops/luminaire/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'LAMP_CONFIGURATIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectLampCfgTab()">\n' +
    '              <!--<ng-include src="\'app-container/components/global-settings/ops/luminaire/details/lampcfg.html\'"></ng-include>-->\n' +
    '              <ng-include src="\'app-container/components/global-settings/ops/luminaire/details/lamp_tab.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/luminaire/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/luminaire/details/info.html',
    '<div ng-controller="LuminaireInfoCtrl as infoCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '    <enl-luminaire-type-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '      enlenum="infoCtrl.enumObj"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      manufacturers="infoCtrl.manufacturers"\n' +
    '      enlmode="\'edit\'">\n' +
    '    </enl-luminaire-type-dir>\n' +
    '\n' +
    '    <enl-luminaire-type-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode && infoCtrl.isLoaded"\n' +
    '      enlenum="infoCtrl.enumObj"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      manufacturers="infoCtrl.manufacturers"\n' +
    '      enlmode="\'edit\'">\n' +
    '    </enl-luminaire-type-dir-view-only>\n' +
    '    <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '            <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '            <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">refresh</md-icon>\n' +
    '        </md-button>\n' +
    '        <div ng-show="infoCtrl.hasAsync">\n' +
    '            <circle-spinner></circle-spinner>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/luminaire/details/lamp_tab.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/luminaire/details/lamp_tab.html',
    '<div ng-controller="LuminaireSensorsCtrl as sensorInfoCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!sensorInfoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="sensorInfoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '    <enl-luminaire-sensors-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '      enlenum="sensorInfoCtrl.enumObj"\n' +
    '      sensorform="sensorInfoCtrl.infoForm"\n' +
    '      manufacturers="sensorInfoCtrl.manufacturers"\n' +
    '      save-fn="sensorInfoCtrl.sensorSaveFn">\n' +
    '      <!-- productcodes="sensorInfoCtrl.productCodes" -->\n' +
    '    </enl-luminaire-sensors-dir>\n' +
    '\n' +
    '    <enl-luminaire-sensors-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '      enlenum="sensorInfoCtrl.enumObj"\n' +
    '      sensorform="sensorInfoCtrl.infoForm"\n' +
    '      manufacturers="sensorInfoCtrl.manufacturers"\n' +
    '      save-fn="sensorInfoCtrl.sensorSaveFn">\n' +
    '      <!-- productcodes="sensorInfoCtrl.productCodes" -->\n' +
    '    </enl-luminaire-sensors-dir-view-only>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/luminaire/details/lampcfg.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/luminaire/details/lampcfg.html',
    '<div ng-controller="LuminaireLampcfgCtrl as lampInfoCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!lampInfoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="lampInfoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '    <div layout="column" ng-if="lampInfoCtrl.lampcfg_mode == \'list\'">\n' +
    '      <div flex>\n' +
    '        <md-button class="md-raised md-accent" ng-click="lampInfoCtrl.editView();">\n' +
    '          <md-tooltip md-direction="bottom">\n' +
    '            {{\'ADD_NEW\' | translate}}\n' +
    '          </md-tooltip>\n' +
    '          {{\'ADD_NEW\' | translate}}\n' +
    '        </md-button>\n' +
    '        <md-list style="padding: 0px 8px;">\n' +
    '          <enl-list-item ng-repeat="(key, lampcfg) in lampInfoCtrl.enumObj.lamp_cfgs track by $index"\n' +
    '            avatar-icon="\'lightbulb_outline\'"\n' +
    '            del-fn="lampInfoCtrl.showDeleteConfirm(key)"\n' +
    '            edit-fn="lampInfoCtrl.doEdit(lampcfg, key)">\n' +
    '            <h4>{{key}}</h4>\n' +
    '            <h4>{{lampcfg}}</h4>\n' +
    '            <md-divider ng-if="!$last"></md-divider>\n' +
    '          </enl-list-item>\n' +
    '        </md-list>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <enl-luminaire-lampcfg-dir ng-if="lampInfoCtrl.lampcfg_mode == \'edit\'"\n' +
    '      form="lampInfoCtrl.infoForm"\n' +
    '      enlenum="lampInfoCtrl.lampmodel"\n' +
    '      enlmode="lampInfoCtrl.mode"\n' +
    '      lampcfgtypes="lampInfoCtrl.lampcfgtypes"\n' +
    '      partnumbers="lampInfoCtrl.lampcfg"\n' +
    '      add-fn="lampInfoCtrl.addLampCfg"\n' +
    '      cancel-fn="lampInfoCtrl.cancelLampCfg">\n' +
    '    </enl-luminaire-lampcfg-dir>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/luminaire/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/luminaire/list/list.html',
    '<!--<div id="breadcrumb" class="well" ncy-breadcrumb></div>-->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ listCtrl.enumType | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="parent"><span>{{\'MANUFACTURER\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="val"><span>{{\'PART_NUMBER\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="description"><span>{{\'DESCRIPTION\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (listCtrl.enums | filterBy:[\'name\', \'parent\', \'val\', \'description\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '            <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{enum.name}}</a></span> </td>\n' +
    '          <td md-cell><span>{{enum.parent}}</span> </td>\n' +
    '          <td md-cell><span>{{enum.val}}</span> </td>\n' +
    '          <td md-cell><span>{{enum.description}}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>Create New {{\'product_code\' | translate}}</h3>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div>\n' +
    '            <enl-product-code-form\n' +
    '                    enl-enum="createCtrl.newEnum"\n' +
    '                    form="createCtrl.infoForm"\n' +
    '                    options="createCtrl.options">\n' +
    '            </enl-product-code-form>\n' +
    '\n' +
    '            <div layout="row">\n' +
    '                <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '                    <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">check</md-icon>\n' +
    '                </md-button>\n' +
    '                <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '                    <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">close</md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/details/cert.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/details/cert.html',
    '<div ng-controller="ProductCodeCertCtrl as certCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!certCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="certCtrl.isLoaded" style="padding: 1rem 0.8rem;">\n' +
    '        <p translate="NO_CERT_TO_DISPLAY_TEXT" ng-if="certCtrl.enumObj.certs.length === 0"></p>\n' +
    '        <p translate="REQUEST_CERT_HELP_TEXT" ng-if="certCtrl.enumObj.certs.length !== 0"></p>\n' +
    '        <md-list style="padding: 0px 0px; margin: 0px 0px;">\n' +
    '            <div ng-repeat="cert in certCtrl.enumObj.certs" tabindex="0">\n' +
    '                <enl-list-item  class="enl-cert-list-item" item="cert" avatar-icon="\'description\'" no-edit="true"  no-del="true" download="true" download-fn="certCtrl.downloadCert($event, cert, certCtrl.enumObj)">\n' +
    '                    <h4>{{ \'SERIAL_NUMBER\' | translate }}:&nbsp;{{ cert.serial }}</h4>\n' +
    '                    <h4>{{\'INITIATED_BY\' | translate }} {{ cert.signedUserName | translate }}&nbsp;{{ \'ON\' | translate }}\n' +
    '                        {{ cert.signed_on | date: \'medium\'}}</h4>\n' +
    '                </enl-list-item>\n' +
    '            </div>\n' +
    '        </md-list>\n' +
    '\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/details/change-state-dialog.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/details/change-state-dialog.tpl.html',
    '<md-dialog flex="30" aria-label="{{ \'CHANGE_STATE\' | translate}}">\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>{{ \'CHANGE_STATE\' | translate}}</h2>\n' +
    '            <span flex></span>\n' +
    '            <md-button class="md-icon-button" ng-click="ctrl.cancel()">\n' +
    '                <i class="material-icons">clear</i>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content layout="column" selected="selectedIndex" style="max-width:800px;max-height:610px;">\n' +
    '        <md-content class="md-padding">\n' +
    '            <form name="stateTransition.form" ng-submit="$event.preventDefault()">\n' +
    '                <div layout="column" class="center center">\n' +
    '                    <md-input-container>\n' +
    '                        <label enl-required-field>{{ "STATE" | translate }}</label>\n' +
    '                        <md-select  name="state" class="md-accent" ng-model="ctrl.curPCode.state"  style="min-width: 200px;" ng-click="stateTransition.form.state.$setDirty()">\n' +
    '                            <md-option ng-repeat="state in ctrl.pcodeStates track by $index" value="{{ state }}">\n' +
    '                                {{ state | translate }}\n' +
    '                            </md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '                <div class="left bottom">\n' +
    '                    <p style="margin-top: 1rem;" enl-required-field-note></p>\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </md-content>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <md-button class="md-mini md-fab md-accent" aria-label="save"\n' +
    '                   ng-click="ctrl.changeState(ctrl.curPCode)">\n' +
    '            <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-mini md-fab md-primary" aria-label="cancel" ng-click="ctrl.cancel()">\n' +
    '            <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">clear</md-icon>\n' +
    '        </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div class="enl-vertical-separator"></div>\n' +
    '            <div layout="row" flex layout-align="start center">\n' +
    '                <div layout="column" class="enl-labelled-text-container" ng-focus-model focus-model="detailsCtrl.stateFocus" style=\'width:50%\'>\n' +
    '                    <label class="enl-floating-label">{{\'STATE\' | translate}}</label>\n' +
    '                    <span>{{ detailsCtrl.curEnum.state | translate }}</span>\n' +
    '                </div>\n' +
    '                <div layout="column" class="enl-labelled-text-container" style=\'width:50%\'>\n' +
    '                    <label class="enl-floating-label">{{\'CONFIGURATION_KEY\' | translate}}</label>\n' +
    '                    <span>{{ detailsCtrl.curEnum.parent }}:{{ detailsCtrl.curEnum.val }} </span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{\'REQUEST_CERTIFICATE\' | translate}}" ng-click="detailsCtrl.requestCert($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'REQUEST_CERTIFICATE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon style="color: #FFFFFF;">library_books</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent" ng-disabled="detailsCtrl.curEnum.state == \'MANUFACTURING\'" aria-label="{{\'PCODE_CHANGE_STATE\' | translate}}" ng-click="detailsCtrl.changeStatus($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'PCODE_CHANGE_STATE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon style="color: #FFFFFF;">exit_to_app</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/ops/product-code/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'CERTIFICATES\' | translate }}" md-on-select="detailsCtrl.onSelectCertTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/ops/product-code/details/cert.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'ROLES\' | translate }}" md-on-select="detailsCtrl.onSelectRolesTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/ops/product-code/details/roles.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'FIRMWARES\' | translate }}" md-on-select="detailsCtrl.onSelectFirmwaresTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/ops/product-code/details/firmware.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/details/firmware.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/details/firmware.html',
    '<div ng-controller="PCodeFirmwareCtrl as firmwareTabCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!firmwareTabCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="firmwareTabCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-firmware-dir firmware-list="firmwareTabCtrl.firmwareList"\n' +
    '      add-fn="firmwareTabCtrl.addFirmware"\n' +
    '      edit-fn="firmwareTabCtrl.editFirmware"\n' +
    '      download-fn="firmwareTabCtrl.downloadFirmware"\n' +
    '      delete-fn="firmwareTabCtrl.deleteFirmwareVersion"></enl-firmware-dir>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/details/info-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/details/info-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Manufacturer * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'MANUFACTURER\' | translate}}</label>\n' +
    '                <md-select required name="manufacturer" ng-disabled="enlMode==\'edit\'" class="md-accent" ng-model="enlEnum.manufacturer"\n' +
    '                           ng-click="infoForm.manufacturer.$setDirty()">\n' +
    '                    <md-option ng-repeat="manufacturer in options.manufacturers track by $index"\n' +
    '                               value="{{manufacturer.val}}">\n' +
    '                        {{manufacturer.names | localize}}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '                <div ng-messages="infoForm.manufacturer.$error" role="alert">\n' +
    '                    <div ng-message="required">{{\'MANUFACTURER_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <!-- Code * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'PRODUCT_CODE\' | translate }}</label>\n' +
    '                <input required type="text" name="val" ng-disabled="enlMode==\'edit\'" ng-model="enlEnum.val" enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.val.$error" role="alert" md-auto-hide="false"\n' +
    '                     ng-show="infoForm.val.$touched">\n' +
    '                    <div ng-message="required">{{\'PRODUCT_CODE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="ascii">{{\'ASCII_ONLY_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Default Display Name -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '                <input required type="text" name="dname" ng-model="enlEnum.dname" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.dname.$error" role="alert" md-auto-hide="false" ng-show="infoForm.dname.$touched && infoForm.dname.$invalid">\n' +
    '                    <div ng-message="required">{{\'DEFAULT_DISPLAY_NAME_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <!-- Default Description -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '                <input type="text" name="ddescription" ng-model="enlEnum.ddescription" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.ddescription.$error" role="alert">\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Type * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'TYPE\' | translate}}</label>\n' +
    '                <md-select required name="type" class="md-accent" ng-model="enlEnum.obj"\n' +
    '                           ng-click="infoForm.type.$setDirty()">\n' +
    '                    <md-option ng-repeat="type in options.objTypes track by $index" value="{{type}}">\n' +
    '                        {{type | translate}}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '                <div ng-messages="infoForm.type.$error" role="alert">\n' +
    '                    <div ng-message="required">{{\'TYPE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container" ng-if="enlEnum.obj !== undefined && enlEnum.obj.toUpperCase() !== \'EM\'">\n' +
    '            <!-- Range * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'RANGE\' | translate }}</label>\n' +
    '                <input required type="number" name="range" ng-model="enlEnum.radio.width" ng-min="0.0">\n' +
    '                <div ng-messages="infoForm.range.$error" role="alert" md-auto-hide="false"\n' +
    '                     ng-show="infoForm.val.$touched">\n' +
    '                    <div ng-message="required">{{\'RANGE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="min">{{\'ZERO_MIN_REQUIRED\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <!-- Units * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'UNITS\' | translate}}</label>\n' +
    '                <md-select required name="units" class="md-accent" ng-model="enlEnum.radio.units"\n' +
    '                           ng-click="infoForm.units.$setDirty()">\n' +
    '                    <md-option ng-repeat="unit in options.units track by $index" value="{{ unit }}">\n' +
    '                        {{ unit | translate}}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '                <div ng-messages="infoForm.units.$error" role="alert">\n' +
    '                    <div ng-message="required">{{\'UNITS_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '\n' +
    '        <div layout="row" class="enl-form-field-container" ng-if="enlEnum.obj.toUpperCase() === \'SENSOR\'">\n' +
    '            <!-- Number of Control Channels * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'NUMBER_OF_CONTROL_CHANNELS\' | translate }}</label>\n' +
    '                <input required type="number" name="channels" ng-model="enlEnum.channels" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '                <div ng-messages="infoForm.channels.$error" role="alert" md-auto-hide="false"\n' +
    '                     ng-show="infoForm.channels.$touched">\n' +
    '                    <div ng-message="required">{{\'CONTROL_CHANNELS_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="pattern">{{\'INTEGER_NOT_VALID_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '                    <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <p enl-required-field-note></p>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/details/info.html',
    '<div ng-controller="ProductCodeInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '        <enl-product-code-form\n' +
    '                enl-enum="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                options="infoCtrl.options"\n' +
    '                enl-mode=\'edit\' ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '        </enl-product-code-form>\n' +
    '\n' +
    '        <enl-product-code-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                enl-enum="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                options="infoCtrl.options"\n' +
    '                enl-mode=\'edit\'>\n' +
    '        </enl-product-code-form-view-only>\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/details/req-cert-dialog.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/details/req-cert-dialog.tpl.html',
    '<md-dialog flex="30" aria-label="{{ \'REQUEST_CERTIFICATE\' | translate}}">\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>{{ \'REQUEST_CERTIFICATE\' | translate}}</h2>\n' +
    '            <span flex></span>\n' +
    '            <md-button class="md-icon-button" ng-click="ctrl.cancel()">\n' +
    '                <i class="material-icons">clear</i>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content layout="column" selected="selectedIndex" style="max-width:800px;max-height:610px;">\n' +
    '        <md-content class="md-padding">\n' +
    '            <form name="reqCert.form" ng-submit="$event.preventDefault()">\n' +
    '                <p><small style="opacity: 0.54;">You will need to generate a CSR with the Common Name(CN):\n' +
    '                    </small>\n' +
    '                </p>\n' +
    '                <p><small style="opacity: 0.54;">\n' +
    '                        {{ ctrl.curPCode.parent}}:{{ ctrl.curPCode.val}}\n' +
    '                </small></p>\n' +
    '                <p>\n' +
    '                    <small style="opacity: 0.54;">Please paste the generated CSR\n' +
    '                        below</small>\n' +
    '                </p>\n' +
    '                <div layout="column" class="center center">\n' +
    '                    <md-input-container>\n' +
    '                        <label enl-required-field>{{ "CSR" | translate }}</label>\n' +
    '                        <textarea name="csr" ng-model="ctrl.newCert" rows="10"\n' +
    '                                  required\n' +
    '                                  ng-pattern="/^-----BEGIN\\sCERTIFICATE\\sREQUEST-----(.*)-----END\\sCERTIFICATE\\sREQUEST-----$/"></textarea>\n' +
    '                        <div ng-messages="reqCert.form.csr.$error" role="alert" md-auto-hide="false"\n' +
    '                             ng-show="reqCert.form.csr.$touched">\n' +
    '                            <div ng-message="required">{{ \'CSR_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                            <div ng-message="pattern">{{ \'CSR_INVALID_ERROR\' | translate}}</div>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '                <div class="left bottom">\n' +
    '                    <p style="margin-top: 1rem;" enl-required-field-note></p>\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </md-content>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <md-button class="md-mini md-fab md-accent" aria-label="save"\n' +
    '                   ng-click="ctrl.requestCert(ctrl.curPCode)"\n' +
    '                   ng-disabled="reqCert.form.$invalid || reqCert.form.$pristine">\n' +
    '            <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-mini md-fab md-primary" aria-label="cancel" ng-click="ctrl.cancel()">\n' +
    '            <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">clear</md-icon>\n' +
    '        </md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/details/roles.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/details/roles.html',
    '<div ng-controller="PCodeRolesCtrl as rolesCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!rolesCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="rolesCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '        <enl-roles-widget ng-if="detailsCtrl.globalsettingseditmode" roles="rolesCtrl.enumObj.roles.roles"></enl-roles-widget>\n' +
    '        <enl-roles-widget-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode" roles="rolesCtrl.enumObj.roles.roles"></enl-roles-widget-view-only>\n' +
    '     </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-product-code\n' +
    '            items="listCtrl.enums"\n' +
    '            options="listCtrl.options"\n' +
    '            on-item-click="listCtrl.viewEnumDetails"\n' +
    '            add-fn="listCtrl.createEnum"\n' +
    '            delete-fn="listCtrl.showDeleteConfirm">\n' +
    '    </enl-product-code>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/list/pcode-list.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/list/pcode-list.tpl.html',
    '<enl-data-table table-title="{{ listCtrl.tableTitle | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.addEnum" delete-fn="listCtrl.deleteEnums(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '        <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '            <thead md-head md-order="listCtrl.query.order">\n' +
    '            <tr md-row>\n' +
    '                <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '                <th md-column md-order-by="parent"><span>{{\'MANUFACTURER\' | translate }}</span></th>\n' +
    '                <th md-column md-order-by="val"><span>{{\'PRODUCT_CODE\' | translate }}</span></th>\n' +
    '                <th md-column md-order-by="obj"><span>{{\'TYPE\' | translate }}</span></th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody md-body>\n' +
    '            <tr md-row md-select="enum"\n' +
    '                ng-repeat="enum in (filteredItems = (items | filterBy: [\'name\', \'parent\', \'val\', \'obj\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '                <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.onItemClick(enum)">{{enum.name}}</a></span> </td>\n' +
    '                <td md-cell><span>{{enum.parent}}</span></td>\n' +
    '                <td md-cell><span>{{enum.val}}</span> </td>\n' +
    '                <td md-cell><span>{{enum.obj | translate}}</span> </td>\n' +
    '            </tr>\n' +
    '            <tr md-row ng-if="!filteredItems.length">\n' +
    '                <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </md-table-container>\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{items.length}}"></md-table-pagination>\n' +
    '</enl-data-table>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/list/regulator-list-table.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/list/regulator-list-table.template.html',
    '<enl-data-table table-title="{{listDirCtrl.tableTitle | translate}}" selected="listDirCtrl.selected" filter-text="listDirCtrl.filter.search" add-fn="listDirCtrl.addEnum" delete-fn="listDirCtrl.showDeleteConfirm(listDirCtrl.selected, $event)">\n' +
    '  <md-table-container>\n' +
    '    <table md-table md-row-select ng-model="listDirCtrl.selected">\n' +
    '      <thead md-head md-order="listDirCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="manufacturer"><span>{{\'MANUFACTURER\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="part_number"><span>{{\'PART_NUMBER\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="channels"><span>{{\'NUMBER_OF_CONTROL_CHANNELS\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '      </thead>\n' +
    '      <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (items | filterBy: [\'name\', \'manufacturer\', \'part_number\', \'channels\']: listDirCtrl.filter.search | orderBy: listDirCtrl.query.order | limitTo: listDirCtrl.query.limit : (listDirCtrl.query.page -1) * listDirCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ui-sref="^.details({enumEid: enum.eid})">{{enum.name}}</a></span> </td>\n' +
    '          <td md-cell>{{enum.manufacturer}}</td>\n' +
    '          <td md-cell>{{enum.part_number}}</td>\n' +
    '          <td md-cell>{{enum.channels}}</td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '      </tbody>\n' +
    '    </table>\n' +
    '  </md-table-container>\n' +
    '\n' +
    '  <md-table-pagination md-label="{{listDirCtrl.query.label}}" md-limit="listDirCtrl.query.limit" md-page="listDirCtrl.query.page" md-total="{{items.length}}"></md-table-pagination>\n' +
    '</enl-data-table>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/regulator-info-form.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/regulator-info-form.template.html',
    '<form name="infoForm">\n' +
    '  <div class="enl-form-container">\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <!-- Manufacturer -->\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'MANUFACTURER\' | translate}}</label>\n' +
    '        <md-select required name="manufacturer" ng-disabled="enlMode != \'create\' " class="md-accent" ng-model="enlEnum.manufacturer" ng-click="infoForm.manufacturer.$setDirty()">\n' +
    '          <md-option ng-repeat="manufacturer in manufacturers track by $index" value="{{manufacturer.val}}">\n' +
    '              {{manufacturer.names | localize}}\n' +
    '          </md-option>\n' +
    '        </md-select>\n' +
    '        <div ng-messages="infoForm.manufacturer.$error" role="alert">\n' +
    '          <div ng-message="required">{{\'MANUFACTURER_REQUIRED_ERROR\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '\n' +
    '      <!-- Part Number -->\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{ \'PART_NUMBER\' | translate }}</label>\n' +
    '        <input required type="text" ng-disabled="enlMode != \'create\' " name="part_number" ng-model="enlEnum.part_number" enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '        <div ng-messages="infoForm.part_number.$error" class="enl-ng-messages" role="alert" md-auto-hide="false" ng-show="infoForm.part_number.$touched && infoForm.part_number.$invalid">\n' +
    '          <div ng-message="required">{{\'PART_NUMBER_REQUIRED_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="ascii">{{\'ASCII_ONLY_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <!-- Default Display Name -->\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '        <input required type="text" name="dname" ng-model="enlEnum.dname" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '        <div ng-messages="infoForm.dname.$error" role="alert" md-auto-hide="false" ng-show="infoForm.dname.$touched && infoForm.dname.$invalid">\n' +
    '          <div ng-message="required">{{\'DEFAULT_DISPLAY_NAME_REQUIRED_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '\n' +
    '      <!-- Default Description -->\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '        <input type="text" name="ddescription" ng-model="enlEnum.ddescription" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '        <div ng-messages="infoForm.ddescription.$error" role="alert">\n' +
    '          <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '\n' +
    '      <!-- Number of Control Channels * -->\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'NUMBER_OF_CONTROL_CHANNELS\' | translate}}</label>\n' +
    '        <input required type="number" name="channels" ng-model="enlEnum.channels" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '        <div ng-messages="infoForm.channels.$error" role="alert" md-auto-hide="false" ng-show="infoForm.channels.$touched && infoForm.channels.$invalid">\n' +
    '          <div ng-message="required">{{\'NUMBER_OF_CONTROL_CHANNELS_REQUIRED_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="pattern">{{\'INPUT_TYPE_INTEGER_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="min">{{\'NUMBER_OF_CONTROL_CHANNELS_MIN_ERROR\' | translate}}</div>\n' +
    '          <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '        </div>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <md-checkbox ng-model="enlEnum.metering" aria-label="{{\'SUPPORT_METERING\' | translate}}">\n' +
    '      {{\'SUPPORT_METERING\' | translate}}\n' +
    '    </md-checkbox>\n' +
    '  </div>\n' +
    '  <p enl-required-field-note></p>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/regulator-lamp-configs.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/regulator-lamp-configs.template.html',
    '<div ng-switch="lampConfigCtrl.mode">\n' +
    '  <!-- LIST MODE -->\n' +
    '  <div ng-switch-when="list">\n' +
    '    <md-button class="md-raised md-accent" ng-click="lampConfigCtrl.doAdd()">\n' +
    '      <md-tooltip md-direction="bottom">\n' +
    '        {{\'ADD_NEW\' | translate}}\n' +
    '      </md-tooltip>\n' +
    '      {{\'ADD_NEW\' | translate}}\n' +
    '    </md-button>\n' +
    '    <md-list style="padding: 0px 8px;">\n' +
    '      <enl-list-item ng-repeat="config in configurations track by $index"\n' +
    '        avatar-icon="\'lightbulb_outline\'"\n' +
    '        del-fn="lampConfigCtrl.showDeleteConfirm(config, $index)"\n' +
    '        edit-fn="lampConfigCtrl.doEdit(config, $index)">\n' +
    '        <h4>{{config.num}} {{config.lamp_manufacturer}} {{config.lamp_part_number}}</h4>\n' +
    '        <h4>{{config.voltage}}V, {{config.channels}} <span translate="{{lampConfigCtrl.getChannelKey(config.channels)}}"></h4>\n' +
    '        <h4 ng-if="config.color">{{\'SUPPORTS_COLOR\' | translate}}</h4>\n' +
    '        <h4 ng-if="!config.color">{{\'DOES_NOT_SUPPORTS_COLOR\' | translate}}</h4>\n' +
    '        <md-divider ng-if="!$last"></md-divider>\n' +
    '      </enl-list-item>\n' +
    '    </md-list>\n' +
    '  </div>\n' +
    '  <!-- FORM MODE -->\n' +
    '  <div ng-switch-when="form">\n' +
    '    <ng-form name="lampConfigCtrl.configForm">\n' +
    '      <h4>{{lampConfigCtrl.curConfigTitle}}</h4>\n' +
    '      <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '          <!-- # Lamps -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'NUMBER_OF_LAMPS\' | translate}}</label>\n' +
    '            <input required type="number" name="num_lamps" ng-model="lampConfigCtrl.curConfig.num" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '            <div ng-messages="lampConfigCtrl.configForm.num_lamps.$error" role="alert" md-auto-hide="false" ng-show="lampConfigCtrl.configForm.num_lamps.$touched && lampConfigCtrl.configForm.num_lamps.$invalid">\n' +
    '              <div ng-message="required">{{\'DEFAULT_DISPLAY_NAME_REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="pattern">{{\'INPUT_TYPE_INTEGER_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="min">{{\'NUMBER_OF_LAMPS_MIN_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '\n' +
    '          <!-- Lamp -->\n' +
    '          <md-autocomplete\n' +
    '              class="enl-md-autocomplete"\n' +
    '              required="true"\n' +
    '              md-input-name="lamp"\n' +
    '              md-search-text="lampConfigCtrl.searchText"\n' +
    '              md-selected-item="lampConfigCtrl.curConfig.lamp"\n' +
    '              md-search-text="lampConfigCtrl.searchText"\n' +
    '              md-min-length="0"\n' +
    '              md-items="lamp in lampConfigCtrl.querySearch(lampConfigCtrl.searchText)"\n' +
    '              md-item-text="lamp.display"\n' +
    '              ng-attr-md-floating-label="{{\'LAMP\' | translate}} *" flex>\n' +
    '              <md-item-template>\n' +
    '                  <span md-highlight-text="lampConfigCtrl.searchText" md-highlight-flags="^i">{{ lamp.display }}</span>\n' +
    '              </md-item-template>\n' +
    '              <md-not-found>\n' +
    '                  {{ \'AUTOCOMPLETE_NO_MATCHES\' | translate }}\n' +
    '              </md-not-found>\n' +
    '              <div ng-messages="lampConfigCtrl.configForm.lamp.$error" role="alert" ng-if="lampConfigCtrl.configForm.lamp.$touched">\n' +
    '                  <div ng-message="required">{{ \'LAMP_REQUIRED_ERROR\' | translate }}</div>\n' +
    '              </div>\n' +
    '          </md-autocomplete>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '          <!-- Line Voltage -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'LINE_VOLTAGE\' | translate}}</label>\n' +
    '            <input required type="number" name="voltage" ng-model="lampConfigCtrl.curConfig.voltage" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '            <div ng-messages="lampConfigCtrl.configForm.voltage.$error" role="alert" md-auto-hide="false" ng-show="lampConfigCtrl.configForm.voltage.$touched && lampConfigCtrl.configForm.voltage.$invalid">\n' +
    '              <div ng-message="required">{{\'LINE_VOLTAGE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="min">{{\'LINE_VOLTAGE_MIN_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="pattern">{{\'INPUT_TYPE_INTEGER_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '\n' +
    '          <!-- Number of Control Channels -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'NUMBER_OF_CONTROL_CHANNELS\' | translate}}</label>\n' +
    '            <input required type="number" name="channels" ng-model="lampConfigCtrl.curConfig.channels" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '            <div ng-messages="lampConfigCtrl.configForm.channels.$error" role="alert" md-auto-hide="false" ng-show="lampConfigCtrl.configForm.channels.$touched && lampConfigCtrl.configForm.channels.$invalid">\n' +
    '              <div ng-message="required">{{\'NUMBER_OF_CONTROL_CHANNELS_REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="pattern">{{\'INPUT_TYPE_INTEGER_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="min">{{\'NUMBER_OF_CONTROL_CHANNELS_MIN_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="max">{{\'VALUE_TOO_LARGE\' | translate}}</div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '\n' +
    '          <!-- Factor -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'FACTOR\' | translate}}</label>\n' +
    '            <input required type="number" name="factor" ng-model="lampConfigCtrl.curConfig.factor" ng-pattern="/(^0\\.[0-9]*[1-9]+|^1$)/"/>\n' +
    '            <div ng-messages="lampConfigCtrl.configForm.factor.$error" role="alert" md-auto-hide="false" ng-show="lampConfigCtrl.configForm.factor.$touched && lampConfigCtrl.configForm.factor.$invalid">\n' +
    '              <div ng-message="required">{{\'FACTOR_REQUIRED\' | translate}}</div>\n' +
    '              <div ng-message="pattern">{{\'FACTOR_WRONG_NUMBER\' | translate}}</div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '\n' +
    '          <!-- Maximum Input Power -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'MAX_INPUT_POWER\' | translate}}</label>\n' +
    '            <input required type="number" name="max_input_power" ng-model="lampConfigCtrl.curConfig.x_maxPower" ng-min="1.0">\n' +
    '            <div ng-messages="lampConfigCtrl.configForm.max_input_power.$error" role="alert" md-auto-hide="false" ng-show="lampConfigCtrl.configForm.max_input_power.$touched && lampConfigCtrl.configForm.max_input_power.$invalid">\n' +
    '              <div ng-message="required">{{\'MAX_INPUT_POWER_REQUIRED_ERROR\' | translate}}</div>\n' +
    '              <div ng-message="min">{{\'ONE_MIN_REQUIRED\' | translate}}</div>\n' +
    '            </div>\n' +
    '          </md-input-container>\n' +
    '        </div>\n' +
    '\n' +
    '        <md-checkbox ng-model="lampConfigCtrl.curConfig.color" aria-label="{{\'SUPPORTS_COLOR\' | translate}}">\n' +
    '          {{\'SUPPORTS_COLOR\' | translate}}\n' +
    '        </md-checkbox>\n' +
    '      </div>\n' +
    '      <div ng-if="!options.noWattages">\n' +
    '        <h5>{{ \'METERING_WATTAGES\' | translate }}</h5>\n' +
    '        <md-divider></md-divider>\n' +
    '        <div style="padding: 0.8rem; padding-bottom: 1rem;">\n' +
    '          <enl-wattage-widget\n' +
    '            entries="lampConfigCtrl.curConfig.wattages"\n' +
    '            enl-on-change="lampConfigCtrl.onWattagesChange">\n' +
    '          </enl-wattage-widget>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <p enl-required-field-note></p>\n' +
    '      <div layout="row" style="margin-left: -0.8rem;">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="lampConfigCtrl.save()" ng-disabled="lampConfigCtrl.configForm.$invalid || lampConfigCtrl.configForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="lampConfigCtrl.showCancelConfirm($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">clear</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </ng-form>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/wattage-entry-editor.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/wattage-entry-editor.template.html',
    '<div class="enl-editor">\n' +
    '  <ng-form name="wattageForm">\n' +
    '    <div class="enl-form-container">\n' +
    '      <div layout="row" class="enl-form-field-container">\n' +
    '        <!-- % Line Voltage -->\n' +
    '        <md-input-container class="md-block md-accent" flex>\n' +
    '          <label enl-required-field>{{\'PERCENTAGE_LINE_VOLTAGE\' | translate}}</label>\n' +
    '          <input required type="number" name="percentage" ng-model="entry.percentage" ng-disabled="options.disablePercentage" max="100" enl-restrict enl-restrictions="restrictions">\n' +
    '          <div ng-messages="wattageForm.percentage.$error" role="alert" md-auto-hide="false" ng-show="wattageForm.percentage.$touched && wattageForm.percentage.$invalid">\n' +
    '            <div ng-message="required">{{\'PERCENTAGE_LINE_VOLTAGE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '            <div ng-message="max">{{\'PERC_LINE_VOLTAGE_MAX_ERROR\' | translate}}</div>\n' +
    '            <div ng-message="conflict">{{\'PERC_LINE_VOLTAGE_CONFLICT_ERROR\' | translate}}</div>\n' +
    '          </div>\n' +
    '        </md-input-container>\n' +
    '        <!-- Wattage -->\n' +
    '        <md-input-container class="md-block md-accent" flex>\n' +
    '          <label enl-required-field>{{\'WATTAGE\' | translate}}</label>\n' +
    '          <input required type="number" name="wattage" ng-model="entry.wattage">\n' +
    '          <div ng-messages="wattageForm.wattage.$error" role="alert">\n' +
    '            <div ng-message="required">{{\'WATTAGE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '          </div>\n' +
    '        </md-input-container>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div layout="row">\n' +
    '      <md-button class="md-accent" ng-disabled="wattageForm.$invalid || wattageForm.$pristine" ng-click="editorCtrl.save()">{{\'SAVE\' | translate}}</md-button>\n' +
    '      <md-button class="md-prmary" ng-click="editorCtrl.cancel()">{{\'CANCEL\' | translate}}</md-button>\n' +
    '      <span flex></span>\n' +
    '    </div>\n' +
    '  </ng-form>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/wattage-entry.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/wattage-entry.template.html',
    '<div class="enl-wattage-entry">\n' +
    '  <div ng-switch="entryCtrl.mode">\n' +
    '    <div ng-switch-when="readonly">\n' +
    '      <div layout="row">\n' +
    '        <div class="labelled-container">\n' +
    '          <label class="floating-label" ng-if="!options.hideLabels">{{ \'PERCENTAGE_LINE_VOLTAGE\' | translate }}</label>\n' +
    '          <div class="labelled-content">\n' +
    '            <span>{{ entry.percentage }}</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="labelled-container">\n' +
    '          <label class="floating-label" ng-if="!options.hideLabels">{{ \'WATTAGE\' | translate }}</label>\n' +
    '          <div class="labelled-content">\n' +
    '            <span>{{ entry.wattage }}</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div flex></div>\n' +
    '        <div style="margin: auto;">\n' +
    '          <md-button class="md-icon-button md-accent" ng-if="!options.noEdit" aria-label="{{\'EDIT\' | translate }}" ng-click="entryCtrl.doEdit()" ng-disabled="options.disableEdit">\n' +
    '            <md-tooltip>{{\'EDIT\' | translate }}</md-tooltip>\n' +
    '            <md-icon>mode_edit</md-icon>\n' +
    '          </md-button>\n' +
    '        </div>\n' +
    '        <div style="margin: auto;">\n' +
    '          <md-button class="md-icon-button md-accent" ng-if="!options.noDel" aria-label="{{\'DELETE\' | translate }}" ng-click="entryCtrl.showDeleteConfirm($event)" ng-disabled="options.disableDelete">\n' +
    '            <md-tooltip>{{\'DELETE\' | translate }}</md-tooltip>\n' +
    '            <md-icon>delete</md-icon>\n' +
    '          </md-button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div ng-switch-when="edit">\n' +
    '      <enl-wattage-entry-editor\n' +
    '        restrictions="entryCtrl.restrictions"\n' +
    '        entry="entryCtrl.editEntry"\n' +
    '        on-cancel="entryCtrl.mode = \'readonly\'"\n' +
    '        on-save="entryCtrl.onSave"\n' +
    '        options="options"></enl-wattage-entry-editor>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/wattage-widget.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/wattage-widget.template.html',
    '<div class="enl-wattage-widget">\n' +
    '  <div layout="row">\n' +
    '    <div flex="50">\n' +
    '      <enl-wattage-entry ng-repeat="entry in entries track by $index"\n' +
    '      entry="entry"\n' +
    '      restrictions="wattagesCtrl.entryPercentages"\n' +
    '      index="$index"\n' +
    '      enl-on-change="wattagesCtrl.onEntryChange"\n' +
    '      del-fn="wattagesCtrl.doDelete"\n' +
    '      options="entry.options">\n' +
    '      </enl-wattage-entry>\n' +
    '    </div>\n' +
    '    <div flex="50">\n' +
    '      <enl-chart\n' +
    '        entries-arr="entries"\n' +
    '        ylabel="\'Wattages\'"\n' +
    '        yvaluefrom="\'wattage\'"\n' +
    '        xlabel="\'Percentages\'"\n' +
    '        xvaluefrom="\'percentage\'"\n' +
    '        ></enl-chart>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-switch="wattagesCtrl.mode">\n' +
    '    <div ng-switch-when="button">\n' +
    '      <md-button class="md-raised md-accent" ng-click="wattagesCtrl.showEditor()">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'ADD_NEW\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        {{\'ADD_NEW\' | translate}}\n' +
    '      </md-button>\n' +
    '    </div>\n' +
    '    <div ng-switch-when="editor" style="width: 50%;">\n' +
    '      <enl-wattage-entry-editor\n' +
    '        restrictions="wattagesCtrl.entryPercentages"\n' +
    '        entry="wattagesCtrl.newEntry"\n' +
    '        on-save="wattagesCtrl.createNewEntry"\n' +
    '        on-cancel="wattagesCtrl.cancelCreate">\n' +
    '      </enl-wattage-entry-editor>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/reason/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/reason/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>Create New {{\'reason\' | translate}}</h3>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div>\n' +
    '            <enl-reason-info-form\n' +
    '                    enl-enum="createCtrl.newEnum"\n' +
    '                    form="createCtrl.infoForm"\n' +
    '                    options="createCtrl.options">\n' +
    '            </enl-reason-info-form>\n' +
    '\n' +
    '            <div layout="row">\n' +
    '                <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '                    <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">check</md-icon>\n' +
    '                </md-button>\n' +
    '                <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '                    <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '                    <md-icon style="color: white;">close</md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/reason/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/reason/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/ops/reason/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/reason/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/reason/details/info.html',
    '<div ng-controller="ReasonInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '        <enl-reason-info-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                enl-enum="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                options="infoCtrl.options"\n' +
    '                enl-mode="edit">\n' +
    '        </enl-reason-info-form>\n' +
    '\n' +
    '        <enl-reason-info-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                enl-enum="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm"\n' +
    '                options="infoCtrl.options"\n' +
    '                enl-mode="edit">\n' +
    '        </enl-reason-info-form-view-only>\n' +
    '\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/reason/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/reason/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '    <enl-reason-table\n' +
    '            items="listCtrl.enums"\n' +
    '            options="listCtrl.options"\n' +
    '            on-item-click="listCtrl.viewEnumDetails"\n' +
    '            add-fn="listCtrl.createEnum"\n' +
    '            delete-fn="listCtrl.showDeleteConfirm">\n' +
    '    </enl-reason-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/reason/list/reason-list.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/reason/list/reason-list.tpl.html',
    '<enl-data-table table-title="{{ listCtrl.tableTitle | translate }}" selected="listCtrl.selected"\n' +
    '                filter-text="listCtrl.filter.search" add-fn="listCtrl.addEnum"\n' +
    '                delete-fn="listCtrl.deleteEnums(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '        <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '            <thead md-head md-order="listCtrl.query.order">\n' +
    '            <tr md-row>\n' +
    '                <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '                <th md-column md-order-by="val"><span>{{\'CODE\' | translate }}</span></th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody md-body>\n' +
    '            <tr md-row md-select="enum"\n' +
    '                ng-repeat="enum in (filteredItems = (items | filterBy: [\'name\', \'val\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '                <td md-cell><span><a style="text-decoration: underline;cursor:pointer;"\n' +
    '                                     ng-click="listCtrl.onItemClick(enum)">{{enum.name}}</a></span></td>\n' +
    '                <td md-cell><span>{{enum.val}}</span></td></tr>\n' +
    '            <tr md-row ng-if="!filteredItems.length">\n' +
    '                <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </md-table-container>\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page"\n' +
    '                         md-total="{{items.length}}"></md-table-pagination>\n' +
    '</enl-data-table>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/address_type/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/address_type/create/create.html',
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content id="address_type">\n' +
    '  <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{createCtrl.kind | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-address-type-dir enlenum="createCtrl.enumObj" form="createCtrl.infoForm" enlmode="\'create\'">\n' +
    '      </enl-address-type-dir>\n' +
    '      <div layout="row">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/address_type/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/address_type/details/details.html',
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/system/address_type/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/address_type/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/address_type/details/info.html',
    '<div ng-controller="AddressTypeInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;" id="address_type">\n' +
    '      <enl-address-type-dir ng-if="detailsCtrl.globalsettingseditmode" enlenum="infoCtrl.enumObj" form="infoCtrl.infoForm" enlmode="\'edit\'"></enl-address-type-dir>\n' +
    '      <enl-address-type-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode" enlenum="infoCtrl.enumObj" form="infoCtrl.infoForm" enlmode="\'edit\'"></enl-address-type-dir-view-only>\n' +
    '      <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '          <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '              <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '              <md-icon style="color: white;">check</md-icon>\n' +
    '          </md-button>\n' +
    '          <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '              <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '              <md-icon style="color: white;">refresh</md-icon>\n' +
    '          </md-button>\n' +
    '          <div ng-show="infoCtrl.hasAsync">\n' +
    '              <circle-spinner></circle-spinner>\n' +
    '          </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/address_type/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/address_type/list/list.html',
    '<!--<div id="breadcrumb" class="well" ncy-breadcrumb></div>-->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ listCtrl.enumType | translate}}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="val"><span>{{\'TYPE\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="description"><span>{{\'DEFAULT_DESCRIPTION\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (listCtrl.enums | filterBy:[\'name\', \'val\', \'description\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{ enum.name }}</a></span> </td>\n' +
    '          <td md-cell><span>{{ enum.val }}</span> </td>\n' +
    '          <td md-cell><span>{{ enum.description | translate }}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/layer_type/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/layer_type/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{createCtrl.kind | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-layer-type-dir\n' +
    '        enlenum="createCtrl.newEnum"\n' +
    '        enlmode="\'create\'"\n' +
    '        form="createCtrl.infoForm"\n' +
    '        hidecomputed="true"\n' +
    '        disablecomputed="true"\n' +
    '      ></enl-layer-type-dir>\n' +
    '      <div layout="row">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/layer_type/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/layer_type/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/system/layer_type/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/layer_type/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/layer_type/details/info.html',
    '<div ng-controller="LayerTypeInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '      <enl-layer-type-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '        enlenum="infoCtrl.enumObj"\n' +
    '        enlmode="\'edit\'"\n' +
    '        form="infoCtrl.infoForm"\n' +
    '        hidecomputed="false"\n' +
    '        disablecomputed="true"\n' +
    '      ></enl-layer-type-dir>\n' +
    '\n' +
    '      <enl-layer-type-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '        enlenum="infoCtrl.enumObj"\n' +
    '        enlmode="\'edit\'"\n' +
    '        form="infoCtrl.infoForm"\n' +
    '        hidecomputed="false"\n' +
    '        disablecomputed="true"\n' +
    '      ></enl-layer-type-dir-view-only>\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/layer_type/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/layer_type/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ listCtrl.enumType | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="val"><span>{{\'TYPE\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="description"><span>{{\'DEFAULT_DESCRIPTION\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (listCtrl.enums | filterBy: [\'name\', \'val\', \'description\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{ enum.name }}</a></span> </td>\n' +
    '          <td md-cell><span>{{ enum.val }}</span> </td>\n' +
    '          <td md-cell><span>{{ enum.description | translate }}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/app_type/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/app_type/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{createCtrl.kind | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-app-type-dir\n' +
    '        enlenum="createCtrl.newEnum"\n' +
    '        form="createCtrl.infoForm"\n' +
    '        enlmode="\'create\'"\n' +
    '      >\n' +
    '      </enl-app-type-dir>\n' +
    '      <div layout="row">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/app_type/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/app_type/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/system/app_type/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/app_type/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/app_type/details/info.html',
    '<div ng-controller="AppTypeInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '      <enl-app-type-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '        enlenum="infoCtrl.enumObj"\n' +
    '        form="infoCtrl.infoForm"\n' +
    '        enlmode="\'edit\'">\n' +
    '      </enl-app-type-dir>\n' +
    '\n' +
    '      <enl-app-type-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '        enlenum="infoCtrl.enumObj"\n' +
    '        form="infoCtrl.infoForm"\n' +
    '        enlmode="\'edit\'">\n' +
    '      </enl-app-type-dir-view-only>\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/app_type/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/app_type/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ listCtrl.enumType | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="val"><span>{{\'TYPE\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="description"><span>{{\'DEFAULT_DESCRIPTION\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (listCtrl.enums | filterBy: [\'name\', \'val\', \'description\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{ enum.name }}</a></span> </td>\n' +
    '          <td md-cell><span>{{ enum.val }}</span> </td>\n' +
    '          <td md-cell><span>{{ enum.description | translate }}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/facility_type/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/facility_type/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>{{\'CREATE_NEW\' | translate}} {{createCtrl.kind | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-facility-type-dir\n' +
    '        hasfloors="false"\n' +
    '        form="createCtrl.infoForm"\n' +
    '        enlenum="createCtrl.newEnum"\n' +
    '        handleselectionchange="createCtrl.handleSelectionChange"\n' +
    '        updatemulti="createCtrl.checkToUpdateMultiSelect()"\n' +
    '        enlmode="\'create\'">\n' +
    '      </enl-facility-type-dir>\n' +
    '      <div layout="row">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/facility_type/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/facility_type/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/system/facility_type/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/facility_type/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/facility_type/details/info.html',
    '<div ng-controller="FacilityTypeInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '        <enl-facility-type-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '          hasfloors="infoCtrl.enumObj.floors"\n' +
    '          form="infoCtrl.infoForm"\n' +
    '          enlenum="infoCtrl.enumObj"\n' +
    '          handleselectionchange="infoCtrl.handleSelectionChange"\n' +
    '          enlmode="\'edit\'">\n' +
    '        </enl-facility-type-dir>\n' +
    '\n' +
    '        <enl-facility-type-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '          hasfloors="infoCtrl.enumObj.floors"\n' +
    '          form="infoCtrl.infoForm"\n' +
    '          enlenum="infoCtrl.enumObj"\n' +
    '          handleselectionchange="infoCtrl.handleSelectionChange"\n' +
    '          enlmode="\'edit\'">\n' +
    '        </enl-facility-type-dir-view-only>\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/facility_type/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/facility_type/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ listCtrl.enumType | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="val"><span>{{\'TYPE\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="description"><span>{{\'DEFAULT_DESCRIPTION\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (listCtrl.enums | filterBy: [\'name\', \'val\', \'description\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{ enum.name }}</a></span> </td>\n' +
    '          <td md-cell><span>{{ enum.val }}</span> </td>\n' +
    '          <td md-cell><span>{{ enum.description | translate }}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/phone_type/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/phone_type/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="min-height:680px;padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{createCtrl.kind | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-phone-type-info-form\n' +
    '        obj="createCtrl.enumObj"\n' +
    '        form="createCtrl.infoForm">\n' +
    '      </enl-phone-type-info-form>\n' +
    '      <div layout="row">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/phone_type/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/phone_type/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/system/phone_type/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/phone_type/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/phone_type/details/info.html',
    '<div ng-controller="PhoneTypeInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '        <enl-phone-type-info-form ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                obj="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm">\n' +
    '        </enl-phone-type-info-form>\n' +
    '\n' +
    '        <enl-phone-type-info-form-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                obj="infoCtrl.enumObj"\n' +
    '                form="infoCtrl.infoForm">\n' +
    '        </enl-phone-type-info-form-view-only>\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/phone_type/directive/phone_type-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/phone_type/directive/phone_type-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'DEVICE_TYPENAME\' | translate }}</label>\n' +
    '                <input required type="text" name="type" ng-disabled="editMode" ng-model="obj.val" enl-alpha-numeric-id\n' +
    '                       enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.type.$error" role="alert" md-auto-hide="false"\n' +
    '                     ng-show="infoForm.type.$touched && infoForm.type.$invalid">\n' +
    '                    <div ng-message="required">{{\'TYPE_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="validid">{{\'VALID_ALPHANUMERIC_ERROR\' | translate}}\n' +
    '                        <div ng-message="ascii">{{\'ASCII_ONLY_ERROR\' | translate}}</div>\n' +
    '                        <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Default Display Name * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '                <input required type="text" name="name" ng-model="obj.names[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.name.$error" role="alert" md-auto-hide="false" ng-show="infoForm.name.$touched && infoForm.name.$invalid">\n' +
    '                    <div ng-message="required">{{\'DEFAULT_DISPLAY_NAME_REQUIRED_ERROR\' | translate}}</div>\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <!-- Default Description -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '                <input type="text" name="description" ng-model="obj.descriptions[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '                <div ng-messages="infoForm.description.$error" role="alert">\n' +
    '                    <div ng-message="maxlength"><span translate="MAXLENGTH_ERROR" translate-values="{ chars: $root.MAXLENGTH.TEXT }"></span></div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <fieldset class="enl-reset-all enl-material-fieldset">\n' +
    '                <legend enl-required-field class="enl-reset-all enl-material-fieldset-legend">{{\'OBJECT_ASSOCIATION\' |\n' +
    '                    translate}}\n' +
    '                </legend>\n' +
    '                <div layout="column" layout-wrap flex style="padding:10px 20px">\n' +
    '                    <md-checkbox ng-model="facility_cb" ng-disabled="facility_disable&&editMode"\n' +
    '                                 aria-label="FACILITY_ASSOCIATION CHECKBOX">\n' +
    '                        {{\'FACILITY\' | translate}}\n' +
    '                    </md-checkbox>\n' +
    '                    <md-checkbox ng-model="person_cb" ng-disabled="person_disable&&editMode"\n' +
    '                                 aria-label="PERSON_ASSOCIATION CHECKBOX">\n' +
    '                        {{\'PERSON\' | translate}}\n' +
    '                    </md-checkbox>\n' +
    '                </div>\n' +
    '            </fieldset>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <p enl-required-field-note></p>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/phone_type/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/phone_type/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ \'PHONE_TYPE_TITLE\' | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="val"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="name"><span>{{\'TYPE\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="manu"><span>{{\'DESCRIPTION\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (listCtrl.enums | filterBy: [\'val\', \'name\', \'manu\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{ enum.name }}</a></span> </td>\n' +
    '          <td md-cell><span>{{ enum.val }}</span> </td>\n' +
    '          <td md-cell><span>{{ enum.device | translate }}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/role/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/role/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{createCtrl.kind | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-role-dir\n' +
    '        enlenum="createCtrl.newEnum"\n' +
    '        form="createCtrl.infoForm"\n' +
    '        enlmode="\'create\'"\n' +
    '        on-change-callback="createCtrl.onChangeCallback"\n' +
    '      ></enl-role-dir>\n' +
    '      <div layout="row">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/role/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/role/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/system/role/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/role/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/role/details/info.html',
    '<div ng-controller="RoleTypeInfoCtrl as infoCtrl">\n' +
    '  <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '  <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '    <enl-role-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '      enlenum="infoCtrl.enumObj"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      enlmode="\'edit\'"\n' +
    '      on-change-callback="infoCtrl.onChangeCallback"\n' +
    '      counter="infoCtrl.virtualCounter"\n' +
    '    ></enl-role-dir>\n' +
    '\n' +
    '    <enl-role-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '      enlenum="infoCtrl.enumObj"\n' +
    '      form="infoCtrl.infoForm"\n' +
    '      enlmode="\'edit\'"\n' +
    '      on-change-callback="infoCtrl.onChangeCallback"\n' +
    '      counter="infoCtrl.virtualCounter"\n' +
    '    ></enl-role-dir-view-only>\n' +
    '    <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '            <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '            <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '            <md-icon style="color: white;">refresh</md-icon>\n' +
    '        </md-button>\n' +
    '        <div ng-show="infoCtrl.hasAsync">\n' +
    '            <circle-spinner></circle-spinner>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/role/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/role/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ listCtrl.enumType | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="val"><span>{{\'TYPE\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="description"><span>{{\'DEFAULT_DESCRIPTION\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (listCtrl.enums | filterBy: [\'name\', \'val\', \'description\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{ enum.name }}</a></span> </td>\n' +
    '          <td md-cell><span>{{ enum.val }}</span> </td>\n' +
    '          <td md-cell><span>{{ enum.description | translate }}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/task_type/create/create.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/task_type/create/create.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '  <div style="padding: 0 2rem;" ng-if="createCtrl.isLoaded">\n' +
    '    <div layout="row" flex>\n' +
    '      <div>\n' +
    '        <h3>Create New {{createCtrl.kind | translate}}</h3>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div>\n' +
    '      <enl-task-type-dir\n' +
    '        enlenum="createCtrl.newEnum"\n' +
    '        enlmode="\'create\'"\n' +
    '        form="createCtrl.infoForm"\n' +
    '        maskdisabled="true"\n' +
    '      ></enl-task-type-dir>\n' +
    '      <div layout="row">\n' +
    '        <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="createCtrl.save()" ng-disabled="createCtrl.infoForm.$invalid || createCtrl.infoForm.$pristine">\n' +
    '          <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">check</md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button class="md-fab md-mini md-primary" aria-label="{{ \'CANCEL\' | translate }}" ng-click="createCtrl.showConfirmCancel($event)">\n' +
    '          <md-tooltip>{{\'CANCEL\' | translate }}</md-tooltip>\n' +
    '          <md-icon style="color: white;">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/task_type/details/details.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/task_type/details/details.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<md-content>\n' +
    '    <div style="padding: 0 2rem;" ng-if="detailsCtrl.isLoaded">\n' +
    '        <div layout="row" flex>\n' +
    '            <div>\n' +
    '                <h3>{{detailsCtrl.enumVal}}</h3>\n' +
    '            </div>\n' +
    '            <div flex></div>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'EDIT\' | translate}}" ng-if="!detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.globalsettingseditmode = true;">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'EDIT\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">edit</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-accent md-raised" aria-label="{{\'VIEW\' | translate}}" ng-if="detailsCtrl.globalsettingseditmode" ng-click="detailsCtrl.checkViewOnly($event);">\n' +
    '              <md-tooltip md-direction="bottom">\n' +
    '                {{\'VIEW\' | translate}}\n' +
    '              </md-tooltip>\n' +
    '              <md-icon style="color: white;">remove_red_eye</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{\'DELETE\' | translate}}" ng-click="detailsCtrl.showConfirmDelete($event)">\n' +
    '                <md-tooltip md-direction="bottom">\n' +
    '                    {{\'DELETE\' | translate}}\n' +
    '                </md-tooltip>\n' +
    '                <md-icon>delete</md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <!-- Tabs -->\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="detailsCtrl.tabIndex">\n' +
    '            <md-tab label="{{ \'INFORMATION\' | translate }}" md-on-select="detailsCtrl.onSelectInfoTab()">\n' +
    '                <ng-include src="\'app-container/components/global-settings/system/task_type/details/info.html\'"></ng-include>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'NAMES_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectNamesTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.names"\n' +
    '                title-table="\'Name\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_NAME"\'\n' +
    '                type="\'names\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '            <md-tab label="{{ \'DESCRIPTIONS_TAB\' | translate }}" md-on-select="detailsCtrl.onSelectDescriptionsTab()">\n' +
    '              <enl-language-handler-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir>\n' +
    '\n' +
    '              <enl-language-handler-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '                options="detailsCtrl.curEnum.descriptions"\n' +
    '                title-table="\'Descriptions\'"\n' +
    '                title-disclaimer=\'"LOCALIZE_DISC_DESC"\'\n' +
    '                type="\'descriptions\'"\n' +
    '                save-fn="detailsCtrl.saveFn"\n' +
    '              ></enl-language-handler-dir-view-only>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/task_type/details/info.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/task_type/details/info.html',
    '<div ng-controller="TaskTypeInfoCtrl as infoCtrl">\n' +
    '    <div layout="row" layout-align="center center" style="height:70%;" ng-if="!infoCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '    <div ng-if="infoCtrl.isLoaded" style="padding: 1.6rem 0.8rem;">\n' +
    '      <enl-task-type-dir ng-if="detailsCtrl.globalsettingseditmode"\n' +
    '        enlenum="infoCtrl.enumObj"\n' +
    '        enlmode="\'edit\'"\n' +
    '        form="infoCtrl.infoForm"\n' +
    '        maskdisabled="true"\n' +
    '      ></enl-task-type-dir>\n' +
    '\n' +
    '      <enl-task-type-dir-view-only view-only ng-if="!detailsCtrl.globalsettingseditmode"\n' +
    '        enlenum="infoCtrl.enumObj"\n' +
    '        enlmode="\'edit\'"\n' +
    '        form="infoCtrl.infoForm"\n' +
    '        maskdisabled="true"\n' +
    '      ></enl-task-type-dir-view-only>\n' +
    '        <div layout="row" layout-align="start center" ng-if="detailsCtrl.globalsettingseditmode">\n' +
    '            <md-button class="md-fab md-mini md-accent" aria-label="{{ \'SAVE\' | translate }}" ng-click="infoCtrl.save()" ng-disabled="infoCtrl.infoForm.$invalid || infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{ \'SAVE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">check</md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-fab md-mini md-primary" aria-label="{{ \'RESTORE\' | translate }}" ng-click="infoCtrl.restore()" ng-disabled="infoCtrl.infoForm.$pristine">\n' +
    '                <md-tooltip>{{\'RESTORE\' | translate }}</md-tooltip>\n' +
    '                <md-icon style="color: white;">refresh</md-icon>\n' +
    '            </md-button>\n' +
    '            <div ng-show="infoCtrl.hasAsync">\n' +
    '                <circle-spinner></circle-spinner>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/task_type/list/list.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/task_type/list/list.html',
    '<!-- <div id="breadcrumb" class="well" ncy-breadcrumb></div> -->\n' +
    '<tt-breadcrumb></tt-breadcrumb>\n' +
    '<div layout="row" layout-align="center center" style="height:70%;" ng-if="!listCtrl.isLoaded"><wave-spinner></wave-spinner></div>\n' +
    '<md-content ng-if="listCtrl.isLoaded" style="padding: 1.6rem;">\n' +
    '  <enl-data-table table-title="{{ listCtrl.enumType | translate }}" selected="listCtrl.selected" filter-text="listCtrl.filter.search" add-fn="listCtrl.createFn" delete-fn="listCtrl.deleteFn(listCtrl.selected, $event)">\n' +
    '    <md-table-container>\n' +
    '      <table md-table md-row-select ng-model="listCtrl.selected" md-progress="promise">\n' +
    '        <thead md-head md-order="listCtrl.query.order">\n' +
    '        <tr md-row>\n' +
    '          <th md-column md-order-by="name"><span>{{\'DISPLAY_NAME\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="val"><span>{{\'TYPE\' | translate }}</span></th>\n' +
    '          <th md-column md-order-by="org_type"><span>{{\'ORGANIZATION_TYPE\' | translate }}</span></th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody md-body>\n' +
    '        <tr md-row md-select="enum"\n' +
    '            ng-repeat="enum in (filteredItems = (listCtrl.enums | filterBy: [\'name\', \'val\', \'org_type\']: listCtrl.filter.search | orderBy: listCtrl.query.order | limitTo: listCtrl.query.limit : (listCtrl.query.page -1) * listCtrl.query.limit))">\n' +
    '          <td md-cell><span><a style="text-decoration: underline;cursor:pointer;" ng-click="listCtrl.viewEnumDetails(enum)">{{ enum.name }}</a></span> </td>\n' +
    '          <td md-cell><span>{{ enum.val }}</span> </td>\n' +
    '          <td md-cell><span>{{ enum.org_type }}</span> </td>\n' +
    '        </tr>\n' +
    '        <tr md-row ng-if="!filteredItems.length">\n' +
    '          <td md-cell colspan="100%"><span class="no-items">{{ \'NO_ENTRIES_TO_DISPLAY\' | translate}}</span></td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '      </table>\n' +
    '    </md-table-container>\n' +
    '\n' +
    '    <md-table-pagination md-label="listCtrl.query.label" md-limit="listCtrl.query.limit" md-page="listCtrl.query.page" md-total="{{listCtrl.enums.length}}"></md-table-pagination>\n' +
    '  </enl-data-table>\n' +
    '</md-content>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/control-unit/details/view-only/info-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/control-unit/details/view-only/info-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Manufacturer * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'MANUFACTURER\' | translate}}</label>\n' +
    '                <md-select required name="manufacturer" ng-disabled="true" class="md-accent" ng-model="enlEnum.manufacturer"\n' +
    '                           ng-click="infoForm.manufacturer.$setDirty()">\n' +
    '                    <md-option ng-repeat="manufacturer in options.manufacturers track by $index" value="{{manufacturer.val}}">\n' +
    '                        {{manufacturer.names | localize}}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </md-input-container>\n' +
    '            <!-- Part Number * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'PART_NUMBER\' | translate }}</label>\n' +
    '                <input required type="text" name="val" ng-disabled="true" ng-model="enlEnum.val" enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Default Display Name -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '                <input required type="text" name="dname" ng-model="enlEnum.dname" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '            <!-- Default Description -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '                <input type="text" name="ddescription" ng-model="enlEnum.ddescription" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '\n' +
    '            <!-- Number of Control Channels * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'NUMBER_OF_CONTROL_CHANNELS\' | translate}}</label>\n' +
    '                <input type="number" required name="channels" ng-model="enlEnum.channels" ng-max="$root.NUMBER_LIMITS.MAX_INT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Supports Soft Metering -->\n' +
    '            <md-checkbox ng-model="enlEnum.metering" ng-disabled="true">\n' +
    '                {{ \'SUPPORT_METERING\' | translate }}\n' +
    '            </md-checkbox>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/device_type/directive/view-only/device_type-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/device_type/directive/view-only/device_type-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'DEVICE_TYPENAME\' | translate }}</label>\n' +
    '                <input required type="text" name="type" ng-model="obj.val" ng-disabled="true" enl-alpha-numeric-id enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Default Display Name * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '                <input required type="text" name="name" ng-model="obj.names[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '            <!-- Default Description -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '                <input type="text" name="description" ng-model="obj.descriptions[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <md-input-container style="width:100%">\n' +
    '                <label enl-required-field>{{\'DEVICE_CATE\' | translate}}</label>\n' +
    '                <md-select required name="model" class="md-accent" ng-model="obj.device"  style="width: 100%;" ng-disabled="true">\n' +
    '                    <md-option ng-repeat="device in infoCtrl.devices track by $index" value="{{ device }}">\n' +
    '                        {{ device | translate }}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- SVG XMl * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'SVG_XML\' | translate }}</label>\n' +
    '                <textarea required md-no-autogrow name="svg" class="svg-text" ng-model="obj.svg" style="height:350px" enl-svg-parser></textarea>\n' +
    '            </md-input-container>\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <enl-svg-viewer-view-only view-only svgstring="obj.svg" ng-if="obj.svg" devicestyle="\'device\'" overlay="\'device\'" svgprops="infoCtrl.svgProps">\n' +
    '              </enl-svg-viewer-view-only>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lamp/directive/view-only/lamp-form.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lamp/directive/view-only/lamp-form.template.html',
    '<form name="lampInfoForm">\n' +
    '  <div class="enl-form-container">\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'MANUFACTURER\' | translate}}</label>\n' +
    '        <md-select class="md-accent" required name="parent" ng-disabled="true" ng-model="obj.parent">\n' +
    '          <md-option ng-repeat="manufacturer in lampFormCtrl.manufacturers" value="{{manufacturer.val}}">\n' +
    '            {{manufacturer.val}}\n' +
    '          </md-option>\n' +
    '        </md-select>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'PART_NUMBER\' | translate}}</label>\n' +
    '        <input required type="text" name="pnum" ng-model="obj.val" ng-disabled="true" enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '        <input required type="text" name="name" ng-model="obj.names[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'DESCRIPTION\' | translate}}</label>\n' +
    '        <input type="text" name="description" ng-model="obj.descriptions[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'TYPE\' | translate}}</label>\n' +
    '        <md-select class="md-accent" required name="type" ng-model="obj.type" ng-disabled="true">\n' +
    '          <md-option ng-repeat="ltype in lampFormCtrl.lamp_types" value="{{ltype.val}}">\n' +
    '            {{ltype.val}}\n' +
    '          </md-option>\n' +
    '        </md-select>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container>\n' +
    '        <md-checkbox ng-model="obj.led" aria-label="LED" ng-disabled="true">\n' +
    '          LED\n' +
    '        </md-checkbox>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_INIT_LUMENS\' | translate}}</label>\n' +
    '        <input type="number" name="initial_lumens" ng-model="obj.initial_lumens" ng-pattern="$root.REGEX_LIB.INTEGER" ng-max="$root.NUMBER_LIMITS.MAX_LONG" ng-min="1" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_DESIGN_LUMENS\' | translate}}</label>\n' +
    '        <input type="number" name="design_lumens" ng-model="obj.design_lumens" ng-pattern="$root.REGEX_LIB.INTEGER" ng-max="$root.NUMBER_LIMITS.MAX_LONG" ng-min="1" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'LAMP_WATT\' | translate}}</label>\n' +
    '        <input required type="number" name="wattage" ng-model="obj.wattage" ng-min="1.0" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_INST_REG\' | translate}}</label>\n' +
    '        <input type="number" name="life_ins_start" ng-model="obj.life_ins_start" ng-pattern="$root.REGEX_LIB.INTEGER" ng-max="$root.NUMBER_LIMITS.MAX_LONG" ng-min="1" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_PROG_REG\' | translate}}</label>\n' +
    '        <input type="number" name="life_prog_start" ng-model="obj.life_prog_start" ng-pattern="$root.REGEX_LIB.INTEGER" ng-max="$root.NUMBER_LIMITS.MAX_LONG" ng-min="1" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_COLOR_IDX\' | translate}}</label>\n' +
    '        <input type="number" name="cri" ng-model="obj.cri" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LAMP_COLOR_TEMP\' | translate}}</label>\n' +
    '        <input type="number" name="color_temp" ng-model="obj.color_temp" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'DIAMETER\' | translate}}</label>\n' +
    '        <input type="number" name="diameter_width" ng-model="obj.diameter.width" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-primary" flex>\n' +
    '        <label>{{\'UNIT_NAME\' | translate}}</label>\n' +
    '        <md-select class="md-primary" name="diameter_units" ng-model="obj.diameter.units" ng-disabled="true">\n' +
    '          <md-option  ng-repeat="unit in lampFormCtrl.units track by $index" value="{{unit}}">\n' +
    '            {{unit | translate}}\n' +
    '          </md-option>\n' +
    '        </md-select>\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'LENGTH\' | translate}}</label>\n' +
    '        <input type="number" name="length_width" ng-model="obj.length.width" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '      <md-input-container class="md-block md-primary" flex>\n' +
    '        <label>{{\'UNIT_NAME\' | translate}}</label>\n' +
    '        <md-select class="md-primary" name="length_units" ng-model="obj.length.units" ng-disabled="true">\n' +
    '          <md-option ng-repeat="unit in lampFormCtrl.units" value="{{unit}}">\n' +
    '            {{unit | translate }}\n' +
    '          </md-option>\n' +
    '        </md-select>\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/gang-box/details/view-only/info-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/gang-box/details/view-only/info-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Model * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'MODEL\' | translate}}</label>\n' +
    '                <input required type="text" name="model" ng-model="enlEnum.val" ng-disabled="true" enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Default Display Name * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '                <input required type="text" name="dname" ng-model="enlEnum.dname" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '            <!-- Default Description -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '                <input type="text" name="ddescription" ng-model="enlEnum.ddescription" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '          <!-- width -->\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '              <label enl-required-field>{{ \'WIDTH\' | translate }}</label>\n' +
    '              <input type="number" ng-model="enlEnum.width.width" name="widthvalue" required ng-pattern="/^\\d+$/" ng-disabled="true">\n' +
    '          </md-input-container>\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{ \'HEIGHT\' | translate }}</label>\n' +
    '            <input type="number" ng-model="enlEnum.height.width" name="heightvalue" ng-pattern="/^\\d+$/" required ng-disabled="true">\n' +
    '          </md-input-container>\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{ \'UNITS\' | translate }}</label>\n' +
    '            <md-select name="widthunit" ng-model="enlEnum.height.units" ng-disabled="true" class="md-accent" required>\n' +
    '              <md-option ng-repeat="unit in enumInfoCtrl.units" value="{{unit}}">\n' +
    '                {{unit}}\n' +
    '              </md-option>\n' +
    '            </md-select>\n' +
    '          </md-input-container>\n' +
    '        </div>\n' +
    '          <!-- <div layout="row" class="enl-form-field-container">\n' +
    '              <! height\n' +
    '          </div> -->\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Max Number of Outlets -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label ng-if="kind === \'gang_box\'">{{ \'MAX_NUMBER_OF_OUTLETS\' | translate }}</label>\n' +
    '                <label ng-if="kind === \'light_enclosure\'">{{ \'MAX_NUMBER_OF_LAMPS\' | translate }}</label>\n' +
    '                <input type="number" name="maxoutlets" ng-model="enlEnum.max_outlets" ng-pattern=/^\\d+$/  ng-if="kind === \'gang_box\'" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT" ng-disabled="true">\n' +
    '                <input type="number" name="maxlamps" ng-model="enlEnum.max_lamps" ng-pattern=/^\\d+$/  ng-if="kind === \'light_enclosure\'" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </div>\n' +
    '        <div layout="row" layout-align="start" class="enl-form-field-container">\n' +
    '            <div layout="column" flex>\n' +
    '                <!-- SVG XMl * -->\n' +
    '                <md-input-container class="md-block md-accent" style="margin-bottom: 0;">\n' +
    '                    <label enl-required-field>{{\'SVG_XML\' | translate }}</label>\n' +
    '                    <textarea md-no-autogrow required name="svg" class="svg-text" ng-model="enlEnum.svg" style="height:260px" enl-svg-parser></textarea>\n' +
    '                </md-input-container>\n' +
    '                <div>\n' +
    '                    <md-button ng-click="enumInfoCtrl.autogenerateSVG()" ng-disabled="true">{{ \'AUTO_GENERATE_SVG\' | translate}}</md-button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <enl-svg-viewer-view-only svgstring="enlEnum.svg" devicestyle="\'enclosure\'" overlay="\'enclosure\'" svgprops="enumInfoCtrl.svgProps"></enl-svg-viewer-view-only>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/lampcfg/details/view-only/wattage.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/lampcfg/details/view-only/wattage.html',
    '<div ng-controller="LampConfigWattageCtrl as lampCfgWattageCtrl">\n' +
    '  <!-- LIST MODE -->\n' +
    '  <div>\n' +
    '    <md-list style="padding: 0px 8px;">\n' +
    '      <enl-list-item ng-repeat="wattage in lampCfgWattageCtrl.enumObj.wattageMassaged track by $index"\n' +
    '        avatar-icon="\'lightbulb_outline\'"\n' +
    '        no-del="true"\n' +
    '        no-edit="true">\n' +
    '        <div layout="row" layout-align="center center">\n' +
    '          <div flex>\n' +
    '            <div layout="column" layout-align="center start">\n' +
    '              <div flex>\n' +
    '                <h4>{{wattage.percentage}}V {{\'CONFIGURATION\' | translate}}</h4>\n' +
    '              </div>\n' +
    '              <div flex>\n' +
    '                <h4>{{wattage.wattage["100.0"]}}W {{\'MAX_INPUT_POWER_NO_W\' | translate}}</h4>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div flex style="margin-top: 10px;">\n' +
    '            <enl-chart flex ng-if="!lampCfgWattageCtrl.enumObj.metering" style="max-height: 100px;float: left;"\n' +
    '            entries-arr="wattage.chartedValues"\n' +
    '            ylabel="\'wattage\'"\n' +
    '            yvaluefrom="\'wattage\'"\n' +
    '            xlabel="\'percentage\'"\n' +
    '            xvaluefrom="\'percentage\'"\n' +
    '            widthpx="200"\n' +
    '            heightpx="130"\n' +
    '            size="\'small\'"\n' +
    '            ></enl-chart>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <md-divider ng-if="!$last"></md-divider>\n' +
    '      </enl-list-item>\n' +
    '    </md-list>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/product-code/details/view-only/info-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/product-code/details/view-only/info-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Manufacturer * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'MANUFACTURER\' | translate}}</label>\n' +
    '                <md-select required name="manufacturer" ng-disabled="true" class="md-accent" ng-model="enlEnum.manufacturer"\n' +
    '                           ng-click="infoForm.manufacturer.$setDirty()">\n' +
    '                    <md-option ng-repeat="manufacturer in options.manufacturers track by $index"\n' +
    '                               value="{{manufacturer.val}}">\n' +
    '                        {{manufacturer.names | localize}}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </md-input-container>\n' +
    '            <!-- Code * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'PRODUCT_CODE\' | translate }}</label>\n' +
    '                <input required type="text" name="val" ng-disabled="true" ng-model="enlEnum.val" enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Default Display Name -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '                <input required type="text" name="dname" ng-model="enlEnum.dname" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '            <!-- Default Description -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '                <input type="text" name="ddescription" ng-model="enlEnum.ddescription" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Type * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'TYPE\' | translate}}</label>\n' +
    '                <md-select required name="type" class="md-accent" ng-model="enlEnum.obj" ng-disabled="true">\n' +
    '                    <md-option ng-repeat="type in options.objTypes track by $index" value="{{type}}">\n' +
    '                        {{type | translate}}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container" ng-if="enlEnum.obj !== undefined && enlEnum.obj.toUpperCase() !== \'EM\'">\n' +
    '            <!-- Range * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'RANGE\' | translate }}</label>\n' +
    '                <input required type="number" name="range" ng-model="enlEnum.radio.width" ng-min="0.0" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '            <!-- Units * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'UNITS\' | translate}}</label>\n' +
    '                <md-select required name="units" class="md-accent" ng-model="enlEnum.radio.units" ng-disabled="true">\n' +
    '                    <md-option ng-repeat="unit in options.units track by $index" value="{{ unit }}">\n' +
    '                        {{ unit | translate}}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '\n' +
    '        <div layout="row" class="enl-form-field-container" ng-if="enlEnum.obj.toUpperCase() === \'SENSOR\'">\n' +
    '            <!-- Number of Control Channels * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'NUMBER_OF_CONTROL_CHANNELS\' | translate }}</label>\n' +
    '                <input required type="number" name="channels" ng-model="enlEnum.channels" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/chart/chart.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/chart/chart.template.html',
    '<div id="chart" class="chart">\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/view-only/regulator-info-form.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/view-only/regulator-info-form.template.html',
    '<form name="infoForm">\n' +
    '  <div class="enl-form-container">\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <!-- Manufacturer -->\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'MANUFACTURER\' | translate}}</label>\n' +
    '        <md-select required name="manufacturer" ng-disabled="true" class="md-accent" ng-model="enlEnum.manufacturer">\n' +
    '          <md-option ng-repeat="manufacturer in manufacturers track by $index" value="{{manufacturer.val}}">\n' +
    '              {{manufacturer.names | localize}}\n' +
    '          </md-option>\n' +
    '        </md-select>\n' +
    '      </md-input-container>\n' +
    '\n' +
    '      <!-- Part Number -->\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{ \'PART_NUMBER\' | translate }}</label>\n' +
    '        <input required type="text" ng-disabled="true" name="part_number" ng-model="enlEnum.part_number" enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '      <!-- Default Display Name -->\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '        <input required type="text" name="dname" ng-model="enlEnum.dname" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '\n' +
    '      <!-- Default Description -->\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '        <input type="text" name="ddescription" ng-model="enlEnum.ddescription" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <div layout="row" class="enl-form-field-container">\n' +
    '\n' +
    '      <!-- Number of Control Channels * -->\n' +
    '      <md-input-container class="md-block md-accent" flex>\n' +
    '        <label enl-required-field>{{\'NUMBER_OF_CONTROL_CHANNELS\' | translate}}</label>\n' +
    '        <input required type="number" name="channels" ng-model="enlEnum.channels" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT" ng-disabled="true">\n' +
    '      </md-input-container>\n' +
    '    </div>\n' +
    '    <md-checkbox ng-model="enlEnum.metering" aria-label="{{\'SUPPORT_METERING\' | translate}}" ng-disabled="true">\n' +
    '      {{\'SUPPORT_METERING\' | translate}}\n' +
    '    </md-checkbox>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/view-only/regulator-lamp-configs.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/view-only/regulator-lamp-configs.template.html',
    '<div ng-switch="lampConfigCtrl.mode">\n' +
    '  <!-- LIST MODE -->\n' +
    '  <div ng-switch-when="list">\n' +
    '    <md-list style="padding: 0px 8px;">\n' +
    '      <enl-list-item ng-repeat="config in configurations track by $index"\n' +
    '        avatar-icon="\'lightbulb_outline\'"\n' +
    '        no-edit="true"\n' +
    '        no-del="true">\n' +
    '        <h4>{{config.num}} {{config.lamp_manufacturer}} {{config.lamp_part_number}}</h4>\n' +
    '        <h4>{{config.voltage}}V, {{config.channels}} <span translate="{{lampConfigCtrl.getChannelKey(config.channels)}}"></h4>\n' +
    '        <h4 ng-if="config.color">{{\'SUPPORTS_COLOR\' | translate}}</h4>\n' +
    '        <h4 ng-if="!config.color">{{\'DOES_NOT_SUPPORTS_COLOR\' | translate}}</h4>\n' +
    '        <md-divider ng-if="!$last"></md-divider>\n' +
    '      </enl-list-item>\n' +
    '    </md-list>\n' +
    '  </div>\n' +
    '  <!-- FORM MODE -->\n' +
    '  <!-- <div ng-switch-when="form">\n' +
    '    <ng-form name="lampConfigCtrl.configForm">\n' +
    '      <h4>{{lampConfigCtrl.curConfigTitle}}</h4>\n' +
    '      <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '          <!-- # Lamps -\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'NUMBER_OF_LAMPS\' | translate}}</label>\n' +
    '            <input required type="number" name="num_lamps" ng-model="lampConfigCtrl.curConfig.num" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '          </md-input-container>\n' +
    '\n' +
    '          <!-- Lamp -\n' +
    '          <md-autocomplete\n' +
    '              class="enl-md-autocomplete"\n' +
    '              md-input-name="lamp"\n' +
    '              md-search-text="lampConfigCtrl.searchText"\n' +
    '              md-selected-item="lampConfigCtrl.curConfig.lamp"\n' +
    '              md-search-text="lampConfigCtrl.searchText"\n' +
    '              md-min-length="0"\n' +
    '              md-items="lamp in lampConfigCtrl.querySearch(lampConfigCtrl.searchText)"\n' +
    '              md-item-text="lamp.display"\n' +
    '              ng-attr-md-floating-label="{{\'LAMP\' | translate}}" flex>\n' +
    '              <md-item-template>\n' +
    '                  <span md-highlight-text="lampConfigCtrl.searchText" md-highlight-flags="^i">{{ lamp.display }}</span>\n' +
    '              </md-item-template>\n' +
    '              <md-not-found>\n' +
    '                  {{ \'AUTOCOMPLETE_NO_MATCHES\' | translate }}\n' +
    '              </md-not-found>\n' +
    '          </md-autocomplete>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '          <!-- Line Voltage -\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'LINE_VOLTAGE\' | translate}}</label>\n' +
    '            <input required type="number" name="voltage" ng-model="lampConfigCtrl.curConfig.voltage" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '          </md-input-container>\n' +
    '\n' +
    '          <!-- Number of Control Channels -\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'NUMBER_OF_CONTROL_CHANNELS\' | translate}}</label>\n' +
    '            <input required type="number" name="channels" ng-model="lampConfigCtrl.curConfig.channels" ng-pattern="$root.REGEX_LIB.INTEGER" ng-min="1" ng-max="$root.NUMBER_LIMITS.MAX_INT">\n' +
    '          </md-input-container>\n' +
    '\n' +
    '          <!-- Factor -\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'FACTOR\' | translate}}</label>\n' +
    '            <input required type="number" name="factor" ng-model="lampConfigCtrl.curConfig.factor" ng-pattern="/(^0\\.[0-9]*[1-9]+|^1$)/"/>\n' +
    '          </md-input-container>\n' +
    '\n' +
    '          <!-- Maximum Input Power -\n' +
    '          <md-input-container class="md-block md-accent" flex>\n' +
    '            <label enl-required-field>{{\'MAX_INPUT_POWER\' | translate}}</label>\n' +
    '            <input required type="number" name="max_input_power" ng-model="lampConfigCtrl.curConfig.x_maxPower" ng-min="1.0">\n' +
    '          </md-input-container>\n' +
    '        </div>\n' +
    '\n' +
    '        <md-checkbox ng-model="lampConfigCtrl.curConfig.color" aria-label="{{\'SUPPORTS_COLOR\' | translate}}">\n' +
    '          {{\'SUPPORTS_COLOR\' | translate}}\n' +
    '        </md-checkbox>\n' +
    '      </div>\n' +
    '      <div ng-if="!options.noWattages">\n' +
    '        <h5>{{ \'METERING_WATTAGES\' | translate }}</h5>\n' +
    '        <md-divider></md-divider>\n' +
    '        <div style="padding: 0.8rem; padding-bottom: 1rem;">\n' +
    '          <enl-wattage-widget-view-only\n' +
    '            entries="lampConfigCtrl.curConfig.wattages"\n' +
    '            enl-on-change="lampConfigCtrl.onWattagesChange">\n' +
    '          </enl-wattage-widget-view-only>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </ng-form>\n' +
    '  </div> -->\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/view-only/wattage-entry-editor.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/view-only/wattage-entry-editor.template.html',
    '<div class="enl-editor">\n' +
    '  <ng-form name="wattageForm">\n' +
    '    <div class="enl-form-container">\n' +
    '      <div layout="row" class="enl-form-field-container">\n' +
    '        <!-- % Line Voltage -->\n' +
    '        <md-input-container class="md-block md-accent" flex>\n' +
    '          <label enl-required-field>{{\'PERCENTAGE_LINE_VOLTAGE\' | translate}}</label>\n' +
    '          <input required type="number" name="percentage" ng-model="entry.percentage" ng-disabled="true" max="100" enl-restrict enl-restrictions="restrictions">\n' +
    '        </md-input-container>\n' +
    '        <!-- Wattage -->\n' +
    '        <md-input-container class="md-block md-accent" flex>\n' +
    '          <label enl-required-field>{{\'WATTAGE\' | translate}}</label>\n' +
    '          <input required type="number" name="wattage" ng-model="entry.wattage" ng-disabled="true">\n' +
    '        </md-input-container>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <!-- <div layout="row">\n' +
    '      <md-button class="md-accent" ng-disabled="wattageForm.$invalid || wattageForm.$pristine" ng-click="editorCtrl.save()">{{\'SAVE\' | translate}}</md-button>\n' +
    '      <md-button class="md-prmary" ng-click="editorCtrl.cancel()">{{\'CANCEL\' | translate}}</md-button>\n' +
    '      <span flex></span>\n' +
    '    </div> -->\n' +
    '  </ng-form>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/view-only/wattage-entry.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/view-only/wattage-entry.template.html',
    '<div class="enl-wattage-entry">\n' +
    '  <div ng-switch="entryCtrl.mode">\n' +
    '    <div ng-switch-when="readonly">\n' +
    '      <div layout="row">\n' +
    '        <div class="labelled-container">\n' +
    '          <label class="floating-label" ng-if="!options.hideLabels">{{ \'PERCENTAGE_LINE_VOLTAGE\' | translate }}</label>\n' +
    '          <div class="labelled-content">\n' +
    '            <span>{{ entry.percentage }}</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="labelled-container">\n' +
    '          <label class="floating-label" ng-if="!options.hideLabels">{{ \'WATTAGE\' | translate }}</label>\n' +
    '          <div class="labelled-content">\n' +
    '            <span>{{ entry.wattage }}</span>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div flex></div>\n' +
    '        <!-- <div style="margin: auto;">\n' +
    '          <md-button class="md-icon-button md-accent" ng-if="!options.noEdit" aria-label="{{\'EDIT\' | translate }}" ng-click="entryCtrl.doEdit()" ng-disabled="options.disableEdit">\n' +
    '            <md-tooltip>{{\'EDIT\' | translate }}</md-tooltip>\n' +
    '            <md-icon>mode_edit</md-icon>\n' +
    '          </md-button>\n' +
    '        </div>\n' +
    '        <div style="margin: auto;">\n' +
    '          <md-button class="md-icon-button md-accent" ng-if="!options.noDel" aria-label="{{\'DELETE\' | translate }}" ng-click="entryCtrl.showDeleteConfirm($event)" ng-disabled="options.disableDelete">\n' +
    '            <md-tooltip>{{\'DELETE\' | translate }}</md-tooltip>\n' +
    '            <md-icon>delete</md-icon>\n' +
    '          </md-button>\n' +
    '        </div> -->\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div ng-switch-when="edit">\n' +
    '      <enl-wattage-entry-editor-view-only\n' +
    '        restrictions="entryCtrl.restrictions"\n' +
    '        entry="entryCtrl.editEntry"\n' +
    '        on-cancel="entryCtrl.mode = \'readonly\'"\n' +
    '        on-save="entryCtrl.onSave"\n' +
    '        options="options"></enl-wattage-entry-editor-view-only>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/ops/regulator/details/view-only/wattage-widget.template.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/ops/regulator/details/view-only/wattage-widget.template.html',
    '<div class="enl-wattage-widget">\n' +
    '  <div layout="row">\n' +
    '    <div flex="50">\n' +
    '      <enl-wattage-entry-view-only ng-repeat="entry in entries track by $index"\n' +
    '      entry="entry"\n' +
    '      restrictions="wattagesCtrl.entryPercentages"\n' +
    '      index="$index"\n' +
    '      enl-on-change="wattagesCtrl.onEntryChange"\n' +
    '      del-fn="wattagesCtrl.doDelete"\n' +
    '      options="entry.options">\n' +
    '    </enl-wattage-entry-view-only>\n' +
    '    </div>\n' +
    '    <div flex="50">\n' +
    '      <enl-chart-view-only\n' +
    '        entries-arr="entries"\n' +
    '        ylabel="\'Wattages\'"\n' +
    '        yvaluefrom="\'wattage\'"\n' +
    '        xlabel="\'Percentages\'"\n' +
    '        xvaluefrom="\'percentage\'"\n' +
    '        ></enl-chart-view-only>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div ng-switch="wattagesCtrl.mode">\n' +
    '    <!-- <div ng-switch-when="button">\n' +
    '      <md-button class="md-raised md-accent" ng-click="wattagesCtrl.showEditor()">\n' +
    '        <md-tooltip md-direction="bottom">\n' +
    '          {{\'ADD_NEW\' | translate}}\n' +
    '        </md-tooltip>\n' +
    '        {{\'ADD_NEW\' | translate}}\n' +
    '      </md-button>\n' +
    '    </div> -->\n' +
    '    <div ng-switch-when="editor" style="width: 50%;">\n' +
    '      <enl-wattage-entry-editor-view-only\n' +
    '        restrictions="wattagesCtrl.entryPercentages"\n' +
    '        entry="wattagesCtrl.newEntry"\n' +
    '        on-save="wattagesCtrl.createNewEntry"\n' +
    '        on-cancel="wattagesCtrl.cancelCreate">\n' +
    '      </enl-wattage-entry-editor-view-only>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
})();

// Source: components/global-settings/system/phone_type/directive/view-only/phone_type-form.tpl.js
(function(module) {
try {
  module = angular.module('enl-container-templates');
} catch (e) {
  module = angular.module('enl-container-templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app-container/components/global-settings/system/phone_type/directive/view-only/phone_type-form.tpl.html',
    '<form name="infoForm">\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{ \'DEVICE_TYPENAME\' | translate }}</label>\n' +
    '                <input required type="text" name="type" ng-disabled="true" ng-model="obj.val" enl-alpha-numeric-id\n' +
    '                       enl-ascii-only ng-maxlength="$root.MAXLENGTH.TEXT">\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <!-- Default Display Name * -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label enl-required-field>{{\'DEFAULT_DISPLAY_NAME\' | translate}}</label>\n' +
    '                <input required type="text" name="name" ng-model="obj.names[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '            <!-- Default Description -->\n' +
    '            <md-input-container class="md-block md-accent" flex>\n' +
    '                <label>{{\'DEFAULT_DESCRIPTION\' | translate}}</label>\n' +
    '                <input type="text" name="description" ng-model="obj.descriptions[\'\']" ng-maxlength="$root.MAXLENGTH.TEXT" ng-disabled="true">\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="enl-form-container">\n' +
    '        <div layout="row" class="enl-form-field-container">\n' +
    '            <fieldset class="enl-reset-all enl-material-fieldset">\n' +
    '                <legend enl-required-field class="enl-reset-all enl-material-fieldset-legend">{{\'OBJECT_ASSOCIATION\' | translate}}</legend>\n' +
    '                <div layout="column" layout-wrap flex style="padding:10px 20px">\n' +
    '                    <md-checkbox ng-model="facility_cb" ng-disabled="true"\n' +
    '                                 aria-label="FACILITY_ASSOCIATION CHECKBOX">\n' +
    '                        {{\'FACILITY\' | translate}}\n' +
    '                    </md-checkbox>\n' +
    '                    <md-checkbox ng-model="person_cb" ng-disabled="true"\n' +
    '                                 aria-label="PERSON_ASSOCIATION CHECKBOX">\n' +
    '                        {{\'PERSON\' | translate}}\n' +
    '                    </md-checkbox>\n' +
    '                </div>\n' +
    '            </fieldset>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);
})();

})(window, document);
