/* @ngInject */
module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      obj : '=',
      form : '='
    },
    templateUrl: 'app-container/components/global-settings/system/phone_type/directive/phone_type-form.tpl.html',
    controller: 'PhoneTypeInfoDirCtrl as infoCtrl'
  };
};
