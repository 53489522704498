module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      entriesArr: '=',
      xlabel: '=',
      xvaluefrom: '=',
      ylabel: '=',
      yvaluefrom: '=',
      tooltip: '=',
      widthpx: '=',
      heightpx: '=',
      size: '='
    },
    templateUrl: 'app-container/components/global-settings/ops/regulator/details/chart/chart.template.html',
    controller: 'RegulatorChartCtrl as chartCtrl',
    link: function (scope, elem) {
      scope.chart = elem;
    }
  };
};
