/* @ngInject */
module.exports = function ($scope, $translate, ConfirmDialogSvc, FileSaver, Blob, $filter, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIEnumsSvc) {

  var vm = this;

  //============= FUNCTIONS ============//
  vm.downloadCert = downloadCert;
  vm.signed_for = undefined;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops product_code cert.ctrl: activate');

    $scope.$on('PRODUCT_CODE_CERT_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });

  }

  function init(enumObj) {
    console.log('ops product_code cert.ctrl: init', enumObj);
    vm.enumObj = enumObj;
    // sys_user check
    angular.forEach(vm.enumObj.certs, function(cert) {
      if(_.isEmpty(cert.references)) {
        cert.signedUserName = 'SYSTEM_USER';
      } else {
        cert.signedUserName = cert.references.signed_for.name;
      }
    });
    vm.isLoaded = true;
  }


  function downloadCert(ev, cert, enumObj) {
    console.log('opt product_code.cert.ctrl: downloadCert - ', cert);
    console.log('opt product_code.cert.ctrl: downloadCert - ', enumObj);
    var data = new Blob([cert.cert], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(data, enumObj.val + '_' + cert.serial +'.pem');
  }


};
