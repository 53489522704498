/* @ngInject */
module.exports = function ($scope, $q, $stateParams, $translate, EnlightedAPISvc, BannerSvc, $rootScope, $state, ConfirmDialogSvc, EnlightedAPIErrorSvc) {
  var

  vm = this,

  I18N_KEYS = [
    'CONFIRM_CANCEL_DIALOG',
    'CONFIRM_CANCEL',
    'CONFIRM_CANCEL_CREATE_MSG'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.newEnum  = undefined;
  vm.baseObj  = {};
  vm.infoForm = {};
  vm.isLoaded = false;
  vm.kind     = undefined;
  vm.options  = {};

  //============= FUNCTIONS ============//
  vm.save              = save;
  vm.showConfirmCancel = showConfirmCancel;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('system basic create.ctrl: activate ', $stateParams);

    $scope.enumType = vm.kind = "app_type";

    vm.newEnum = {};

    var promises = [];

    angular.forEach(I18N_KEYS, function (key) {
      promises.push($translate(key));
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    $q.all(promises).then(function (translations) {
      angular.forEach(translations, function (translate, index) {
        DICTIONARY[I18N_KEYS[index]] = translate;
      });

      getAppReleaseOptions().then(function (resp) {
        vm.newEnum.drelease = resp.drelease;
      });
    });

    $translate('ENUM_VAL_DISPLAY_' + vm.kind.toUpperCase())
      .then(function (translation) {
        vm.options.valDisplay = translation;
        vm.isLoaded = true;
      });
  }

  function getAppReleaseOptions() {
    var deferred = $q.defer();
    EnlightedAPI.app_release.getAll().then(function (resp) {
      var enumDisplay = {};
      var releaseArr = [];

      angular.forEach(resp.enums, function (en, index) {
        var obj = {};
        angular.forEach(en.names, function (name, index) {
          obj[index] = name;
        });
        obj.value = en.val;
        releaseArr.push(obj);
      });

      enumDisplay.drelease = releaseArr;
      enumDisplay.drelease[0].selected = true;

      deferred.resolve(enumDisplay);
    }, function (err) {

    });
    return deferred.promise;
  }

  function save() {
    console.log(vm.newEnum);

    vm.baseObj = updateAPIEnum(vm.baseObj, vm.newEnum);
    vm.baseObj.kind = vm.kind;

    console.log('basic-create.ctrl: save -', vm.baseObj);

    EnlightedAPISvc[vm.kind].create(vm.baseObj)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('CREATE_SUCCESS', { val: vm.newEnum.val }),
          el: '#content'
        });

        $state.go('^.list', {enumId: vm.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_FAILURE', { val: vm.newEnum.val }), error),
          el: '#content'
        });
      });
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        $state.go('^.list');
      });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.dname;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.ddescription || "";
    apiEnumObj.version = enumObj.dversion;
    apiEnumObj.release = enumObj.dreleaseOPT;
    apiEnumObj.ui_url = enumObj.durl;

    return apiEnumObj;
  }
};
