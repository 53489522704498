/* @ngInject */
module.exports = function ($http, $q, $mdToast, $translate, $window) {

  var

  isStorageAvailable,

  ENL_APPS_UPDATE_STATUS = 'enlAppsUpdateStatus';

  return {
    checkForUpdate : checkForUpdate,
    appNeedUpdate  : appNeedUpdate,
    needsUpdate    : needsUpdate,
    updateApp      : updateApp
  };

  function appNeedUpdate(appval) {
    var lsBeenUpdated, parsedStatusObj;

    if (localStorageAvailable()) {
      lsBeenUpdated = $window.localStorage.getItem(ENL_APPS_UPDATE_STATUS);
      if (lsBeenUpdated && lsBeenUpdated.length > 0) {
        parsedStatusObj = JSON.parse(lsBeenUpdated);
        return (appval in parsedStatusObj && parsedStatusObj[appval] === false);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function checkForUpdate(apps) {
    needsUpdate()
      .then(function (doesNeed) {
        if (doesNeed) {
          $mdToast.show({
            controller: 'UpdateToastCtrl',
            templateUrl: 'app-container/components/base/update-toast.tpl.html',
            hideDelay: false,
            highlightAction: true
          })
            .then(function () {
                $window.location.reload(true);

                // Mark all apps in localStorage as need update
                populateAppsUpdateStatus(apps);
            });
        }
      });
  }

  function needsUpdate() {
    var deferred = $q.defer(),

    queryParams = {
      nocache: (new Date()).getTime()
    };
    // fetch config.js with cache busting
    $http.get('/js/version.json', { params: queryParams})
      .then(function (configRsp) {
        console.log('update.svc: checkForUpdate - version is ' + configRsp.data.version);
        // compare version against enl.config.version
        deferred.resolve(configRsp.data.version !== $window.enl.config.version);
      }, function (error) {
        deferred.resolve(false);
      });

    return deferred.promise;
  }

  function populateAppsUpdateStatus(apps) {
    if (localStorageAvailable()) {
      var lsBeenUpdated = {};
      angular.forEach(apps, function (app) {
        lsBeenUpdated[app.val] = false;
      });
      $window.localStorage.setItem(ENL_APPS_UPDATE_STATUS, JSON.stringify(lsBeenUpdated));
    }
  }

  function localStorageAvailable() {
    if (isStorageAvailable === undefined) {
      try {
        var storage = $window.localStorage,
          x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        isStorageAvailable = true;
        return true;
      }
      catch(e) {
        isStorageAvailable = false;
        return false;
      }
    } else {
      return isStorageAvailable;
    }
  }

  function updateApp(appval, hasUpdated) {
    var lsBeenUpdated, parsedStatusObj;

    if (localStorageAvailable()) {
      lsBeenUpdated = $window.localStorage.getItem(ENL_APPS_UPDATE_STATUS);
      if (lsBeenUpdated && lsBeenUpdated.length > 0) {
        parsedStatusObj = JSON.parse(lsBeenUpdated);
        if (appval in parsedStatusObj) {
          parsedStatusObj[appval] = hasUpdated;
          $window.localStorage.setItem(ENL_APPS_UPDATE_STATUS, JSON.stringify(parsedStatusObj));
        }
      }
    }
  }
};
