/* @ngInject */
module.exports = function ($scope, $filter, EnlightedAPIEnumsSvc, $q, EnlightedAPISvc, BannerSvc, $rootScope, $translate) {
  var vm = this,
      isInit = false,
      I18N_KEYS = [],
      DICTIONARY = {};

  //============= MODELS ============//
  // View Model
  vm.curDongle = undefined;
  vm.lastUser = undefined;
  vm.lastUserName = '';
  vm.tasks = [];

  // API Model
  vm.infoForm = {};
  vm.isLoaded = false;

  //============= FUNCTIONS ============//
  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('dongle-info.ctrl: activate');

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });
    });

    $scope.$on('DONGLES_INFORMATION_TAB_SELECTED', function (evt, args) {
      init(args.dongle);
    });
  }

  function init(dongle) {
    console.log('dongle-info.ctrl: init - ', dongle);

    if (isInit) {
      return;
    }

    vm.curDongle = dongle;
    getInfo(vm.curDongle);

    /*
    getUserInfo(vm.curDongle.last_user)
      .then(getOrgInfo(), handleGetUserErr())
       .then(getTasksInfo(), handleGetOrgInfo())
       .then(getJobsInfo(), handleGetTaskErr())
       .then(handleSuccess(), handleErr());

    */


  }

  function getUserInfo(userGid) {
    console.log(userGid);
    if (userGid) {
      return EnlightedAPISvc.User.get(userGid, false, true);
    }
    return $q.when({});
  }

  function getOrgInfo() {
    return function (userRsp) {
      console.log(userRsp);
      vm.lastUserName =  userRsp.obj.name;
      var orgGid = userRsp.obj.org;
      return EnlightedAPISvc.Organization.get(orgGid, false, true);
    };
  }

  //Jing added, everything is preloaded
  function getInfo(dogl) {
    vm.lastUserName = "";
    
    //for last user preload
    if(dogl.references && dogl.references.last_user){
      vm.lastUserName = dogl.references.last_user.name;
    }
    //for task preload
    if(dogl.references && dogl.references.tasks){
      angular.forEach(dogl.references.tasks,function(task) {
        console.log(task);
        vm.tasks.push({
            name: task.name,
            job: task.job
          });
      });
    }

    //for job preload


    if(dogl.references && dogl.references.org_owner){
      vm.orgonwer = dogl.references.org_owner.name;
    }

    vm.isLoaded = true;
    isInit = true;

  }



  function getTasksInfo() {
     return function(orgRsp) {
       vm.lastUserName += ' (';
       vm.lastUserName += orgRsp.obj.name;
       vm.lastUserName += ')';
       var promises = vm.curDongle.tasks.map(function(task) {
                            return EnlightedAPISvc.Task.get(task, false, true);
                        });
       return $q.all(promises);
     };
  }

  function getJobsInfo() {
    return function(tasksRsp) {
      angular.forEach(tasksRsp, function(task) {
          vm.tasks.push({
            name: task.obj.name,
            job: ''
          });
        });

        var promises = tasksRsp.map(function(task) {
          return EnlightedAPISvc.Job.get(task.obj.job, false, true);
        });
      return $q.all(promises);
    };
  }

  function handleSuccess() {
    return function(jobsRsp) {
      for (var i = 0; i < vm.tasks.length; i++) {
        vm.tasks[i].job = jobsRsp[i].obj.name;
      }
      vm.isLoaded = true;
      isInit = true;
    };
  }

  function handleGetUserErr() {
    return function(error) {
      return $q.reject(error);
    };
  }

  function handleGetOrgInfo() {
    return function(error) {
      return $q.reject(error);
    };
  }

  function handleGetTaskErr() {
    return function(error) {
      return $q.reject(error);
    };
  }

  function handleErr() {
    return function(error) {
      return $q.reject(error);
    };
  }

};
