module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      enlEnum              : '=',
      manufacturers        : '=',
      electricalInterfaces : '=',
      form                 : '=',
      enlMode              : '='
    },
    templateUrl: 'app-container/components/global-settings/ops/regulator/details/regulator-info-form.template.html',
    controller: 'RegulatorDetailsDirCtrl as regDetailsCtrl'
  };
};
