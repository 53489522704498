/* @ngInject */
module.exports = function ($scope, $translate, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, ViewOnly) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.origEnumObj   = undefined;
  vm.enumObj       = undefined;
  vm.hasAsync      = false;
  vm.infoForm      = {};
  vm.isLoaded      = false;
  vm.wrapper       = {};

  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;
  vm.onChangeCallback  = onChangeCallback;

  activate();

  // this callback is for the accordion switch change event (triggers after switch)
  function onChangeCallback(item, val) {
    if (typeof vm.enumObj.groups[val] === "undefined") {
      vm.enumObj.groups[val] = [];
    }
    var i = vm.enumObj.groups[val].indexOf(item.id);
    if (i == -1 && item.value) {
      vm.enumObj.groups[val].push(item.id);
    } else if (item.value === false) {
      vm.enumObj.groups[val].splice(i, 1);
    } else {
      console.warn("SOMETHING WENT WRONG HERE");
    }
    //console.log(vm.enumObj.groups);
  }

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('system role info.ctrl: activate ');

    $scope.$on('ROLE_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('system role info.ctrl: init', enumObj);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    vm.apiEnum = enumObj;

    // transform to have layer_type obj model
    vm.enumObj = createEnumDisplay(enumObj);
    getPermissionGroup(vm.enumObj).then(function (list) {
      vm.enumObj.list = list;
      // copy original object to retain information for restore
      vm.origEnumObj = angular.copy(vm.enumObj);
      vm.isLoaded = true;
    });
  }

  function getPermissionGroup(enumObj) {
    var deferred = $q.defer();
    EnlightedAPI.permission_group.getAll().then(function (permission_group) {
      // this list will have the accordion items
      var list = [];
      var hasProperties = Object.keys(enumObj.groups).length;
      angular.forEach(permission_group.enums, function (group) {
        // create a group object for the main list, with an empty list inside
        var groupObject = {
          title: group.names,
          contract: true,
          allSelected: 'none',
          val: group.val,
          list: []
        };
        // iterate permissions
        angular.forEach(group.permissions, function (perm) {
          var permission = {};
          permission.descriptions = perm.descriptions;
          permission.id = perm.id;
          permission.value = false;
          // check if role objects has coincidence with permissions and set them to true
          if (hasProperties > 0) {
            angular.forEach(enumObj.groups, function (groupArr, groupType) {
              if (groupType === groupObject.val) {
                angular.forEach(groupArr, function (item) {
                  if (item === permission.id) {
                    permission.value = true;
                  }
                });
              }
            });
          }
          groupObject.list.push(permission);
        });
        // add groupObject to list
        list.push(groupObject);
      });
      deferred.resolve(list);
    }, function (error) {
      console.warn(error);
    });
    return deferred.promise;
  }

  function createEnumDisplay(enumObj) {
    return {
        val: enumObj.val,
        dname: (enumObj.names) ? enumObj.names[''] : undefined,
        ddescription: (enumObj.descriptions) ? enumObj.descriptions[''] : undefined,
        groups: enumObj.groups,
        eid: enumObj.eid
    };
  }

  function restore() {
    console.log("RESTORE vm.enumObj WITH vm.origEnumObj ", "OBJECT MODEL: ", vm.enumObj, "ORIGINAL OBJECT:", vm.origEnumObj);
    vm.enumObj = angular.copy(vm.origEnumObj);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    vm.hasAsync = true;
    vm.apiEnum = updateAPIEnum(vm.apiEnum, vm.enumObj);

    console.log("DATA SENT:", vm.apiEnum);

    EnlightedAPISvc.layer_type.update(vm.apiEnum)
      .then(function (rsp) {
        console.log("RESPONSE AFTER SAVE", rsp.enum);
        vm.apiEnum = rsp.enum;
        getPermissionGroup(vm.apiEnum).then(function (list) {
          vm.enumObj = createEnumDisplay(vm.apiEnum);
          vm.enumObj.list = list;
          updateLists();
          // copy original object to retain information for restore
          vm.origEnumObj = angular.copy(vm.enumObj);
          vm.hasAsync = false;
          vm.infoForm.$setPristine();

          BannerSvc.show({
            type: 'success',
            scope: $rootScope,
            autoClose: true,
            content: $translate.instant('UPDATE_SUCCESS', { val: vm.enumObj.val }),
            el: '#content'
          });
       });
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.origEnumObj.val }), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  function updateAPIEnum(apiEnum, enumObj) {
    apiEnum.val = enumObj.val;
    apiEnum.names = apiEnum.names || {};
    apiEnum.names[''] = enumObj.dname;
    apiEnum.descriptions = apiEnum.descriptions || {};
    apiEnum.descriptions[''] = enumObj.ddescription || "";
    apiEnum.groups = enumObj.groups;
    apiEnum.eid = enumObj.eid;

    return apiEnum;
  }

  function updateLists() {
    angular.forEach(vm.enumObj.list, function (items) {
      checkItems(items, undefined, undefined);
    });
  }

  function checkItems(items, item, val) {
    var allFalse = true;
    var allTrue = true;
    angular.forEach(items.list, function (permission) {
      if (permission.value) {
        allFalse = false;
      } else if (!(permission.value)) {
        allTrue = false;
      }
    });

    if (allFalse === false && allTrue === false) {
      items.allSelected = 'some';
    } else if (allFalse === false && allTrue) {
      items.allSelected = 'all';
    } else if (allFalse && allTrue === false) {
      items.allSelected = 'none';
    }
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("role form");
    } else {
      ViewOnly.delete("role form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("role form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("role form");
  });
};
