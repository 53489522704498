/* @ngInject */
module.exports = function ($stateParams, $scope, $state, $rootScope, ConfirmDialogSvc, EnlightedAPISvc, $filter, BannerSvc, $translate, EnlightedAPIErrorSvc, ViewOnly) {

  var vm = this,
    idpId;

  //============= MODELS ============//
  vm.curIdp = undefined;
  vm.isLoaded = false;
  vm.originalIdp = undefined;
  vm.idpTitleName = '';
  vm.tabIndex = 0;
  vm.breadcrumbLabel = undefined;
  //============= FUNCTIONS ============//
  vm.onSelectInfoTab = onSelectInfoTab;
  vm.onSelectNotesTab = onSelectNotesTab;
  vm.showConfirmDelete = showConfirmDelete;
  vm.checkViewOnly = checkViewOnly;

  activiate();

  //============= FN DEFINITIONS ============//
  function checkViewOnly(ev) {
    if (ViewOnly.oneDirty()) {
      var dirtyNames = ViewOnly.getDirtyNames();
      var opts = {
        ariaLabel: $translate.instant('CONFIRM_CHANGES_LOSE_ARIA_LABEL'),
        title: $translate.instant('CONFIRM_CHANGES_LOSE'),
        message: $translate.instant('CHANGES_LOSE', {names: dirtyNames.join(" and ")})
      };

      ConfirmDialogSvc.show(ev, opts).then(function () {
        // deleteOrg();
        vm.globalsettingseditmode = false;
        $scope.$broadcast("RESET_FORM_VIEW_ONLY");
      });
    } else {
      vm.globalsettingseditmode = false;
    }
  }
  function activiate() {
    console.log('idp-details.ctrl: activiate');
    init();
  }

  function init() {
    console.log('idp-details.ctrl: initialized');
    idpId = $stateParams.idpId;
    console.log('current idp id' + idpId);

    EnlightedAPISvc.IdentityProvider.get(idpId, false, true).then(function (idpRsp) {
      vm.originalIdp = idpRsp.obj;
      vm.curIdp = angular.copy(idpRsp.obj);
      vm.idpName = vm.curIdp.name;
      vm.isLoaded = true;

      $scope.breadcrumbLabel = vm.breadcrumbLabel = vm.curIdp.name;

    });

  }

  function broadcastSelection(tabname) {
    $scope.$broadcast('IDPS_' + tabname + '_TAB_SELECTED', {idp: vm.curIdp});
  }

  function deleteIdp() {
    EnlightedAPISvc.IdentityProvider.delete(vm.curIdp.gid)
      .then(function() {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('DELETE_IDP_SUCCESS', { idpname: vm.idpName }),
          el: '#content'
        });
        $state.go('idps.list');
      }, function(error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('DELETE_IDP_FAILURE'), error),
          el: '#content'
        });
      });

  }

  function onSelectInfoTab() {
    broadcastSelection('INFORMATION');
  }

  function onSelectNotesTab() {
    var notesObject = angular.copy(vm.curIdp);
    console.log("notesObject");
    console.log(notesObject);

    $scope.$broadcast('NOTES_TAB_SELECTED', {object: notesObject});
  }

  function showConfirmDelete(ev) {
    var

      objectType = $translate.instant('IDP_OBJECT_TYPE'),

      opts = {
        ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
        title: $translate.instant('CONFIRM_DELETE'),
        message: $translate.instant('CONFIRM_DELETE_MESSAGE', {objectType: objectType})
      };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        deleteIdp();
      });
  }
};
