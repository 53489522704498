/* @ngInject */
module.exports = function ($scope, $translate, $q, ConfirmDialogSvc, EnlightedAPISvc, $state) {
  var

  vm = this,

  I18N_KEYS = [
    'DT_PAGE',
    'DT_ROWS_PER_PAGE',
    'DT_OF',
    'CONFIRM_DELETE_MESSAGE_LIST',
    'CONFIRM_DELETE_MESSAGE_LIST_PLURAL',
    'CONFIRM_DELETE',
    'CONFIRM_DELETE_ARIA_LABEL'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.filter     = {};
  vm.query      = {};
  vm.selected   = [];
  vm.tableTitle = undefined;

  //============= FUNCTIONS ============//
  vm.addEnum           = addEnum;
  vm.showDeleteConfirm = showDeleteConfirm;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    var promises = [];

    vm.query = {
      order: 'name',
      limit: 15,
      page: 1
    };

    vm.tableTitle = $scope.options.title;

    angular.forEach(I18N_KEYS, function (key) {
      promises.push($translate(key));
    });

    $q.all(promises)
      .then(function (translations) {
        angular.forEach(translations, function (translation, index) {
          DICTIONARY[I18N_KEYS[index]] = translation;
        });

        return $q.when();
      })
      .finally(function () {
        vm.query.label = {
          page: DICTIONARY.DT_PAGE,
          rowsPerPage: DICTIONARY.DT_ROWS_PER_PAGE,
          of: DICTIONARY.DT_OF
        };
      });
  }

  function addEnum() {
    $state.go('^.create');
  }

  function deleteEnums(selected) {
    console.log('control-unit-list.ctrl: deleteEnums - ', selected);

    var defer = $q.defer(), promises = [];

    angular.forEach(selected, function (enumObj) {
      promises.push(EnlightedAPISvc[$scope.kind].delete(enumObj.val));
    });

    $q.all(promises).then(function () {
        // remove deleted users from users list
        vm.enums = _.difference(vm.enums, selected);

        BannerSvc.show({
          scope: $rootScope,
          type: 'success',
          autoClose: true,
          content: $translate.instant('DELETE_SUCCESS_PLURAL'),
          el: '#content'
        });

        return $q.when();
      }, function () {
        BannerSvc.show({
          scope: $rootScope,
          type: 'error',
          autoClose: false,
          content: $translate.instant('DELETE_FAILURE_PLURAL'),
          el: '#content'
        });

        return $q.when();
      })
      .finally(function () {
        defer.resolve();
      });

    return defer.promise;
  }

  function showDeleteConfirm(selected, ev) {
    var

    opts = {
      ariaLabel: DICTIONARY.CONFIRM_DELETE_ARIA_LABEL,
      title: DICTIONARY.CONFIRM_DELETE,
      message: (selected.length > 1) ?
        DICTIONARY.CONFIRM_DELETE_MESSAGE_LIST_PLURAL :
        DICTIONARY.CONFIRM_DELETE_MESSAGE_LIST
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        return deleteEnums(selected);
      });
  }
};
