/* @ngInject */
module.exports = function ($scope, $mdToast) {
  $scope.close = function () {
    $mdToast.cancel();
  };

  $scope.reload = function () {
    $mdToast.hide();
  };
};
