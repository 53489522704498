/* @ngInject */
module.exports = function($scope, $state, $sce, $stateParams) {
  var

  vm = this;

  //============= MODELS ============//

  //============= FUNCTIONS ============//
  vm.getMiniUrl = getMiniUrl;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('dashboard.ctrl: activate - stateParams', $stateParams);
    $scope.headerOptions.showOrgDropdown = true;
    $scope.headerOptions.showAppsDropdown = true;
    $scope.menus = [];
    $scope.currentApp.title = '';

    if ($scope.init) {
      init();
    }

    $scope.$watch(function () {
      return $scope.init;
    }, function (newVal, oldVal) {
      if (newVal) {
        init();
      }
    });
  }

  function init() {
    console.log('dashboard.ctrl: init');

    $scope.$emit('REFRESH_ORG_LIST');

    $scope.$on('ORG_LIST_REFRESHED', function () {
      $scope.setCurrentOrg(($stateParams) ? $stateParams.orgId : undefined);
    });
  }
  function getMiniUrl(app) {
    return (app.val === 'project_mgmt' || app.val === 'facility_mgmt' || app.val === 'org_mgmt') ? $sce.trustAsResourceUrl(app.ui_url + '/mini.html') : $sce.trustAsResourceUrl(app.ui_url);
  }
};
