/* @ngInject */
module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      obj : '=',
      form : '=',
      enlMode : '@'
    },
    templateUrl: 'app-container/components/global-settings/ops/device_type/directive/view-only/device_type-form.tpl.html',
    controller: 'DeviceTypeInfoDirCtrl as infoCtrl',
    link: function (scope, element) {
      var checksvgtext = setInterval(function () {
        var svgtext = element.find(".svg-text");
        // console.log("LINK: ", element, svgtext, svgtext[0].value.length);
        if (svgtext.length && svgtext[0].value.length) {
          svgtext.attr("disabled", true);
          clearInterval(checksvgtext);
        }
      }, 1500);
    }
  };
};
