module.exports = (function () {
  'use strict';

  angular.module('enl-my-account', [
    'ngSanitize',
    'pascalprecht.translate',
    'ngMaterial',
    'ngMessages',
    'ngAnimate',
    'ui.router',
    'ngEnlighted',
    'enl-app-components',
    'angular-sortable-view'])

    .controller('MyAccountDetailsCtrl', require('./my-account-details.ctrl'))
    .controller('MyAccountInfoCtrl', require('./my-account-info.ctrl'))
    .controller('MyAccountContactCtrl', require('./my-account-contact-info.ctrl'))
  ;
})();
