/* @ngInject */
module.exports = function ($stateParams, $scope, $state, $rootScope, ConfirmDialogSvc, EnlightedAPISvc, $filter, BannerSvc, $translate, $mdDialog, $q, EnlightedAPIErrorSvc) {

  var vm = this,
    mdeviceId;

  //============= MODELS ============//
  vm.curmdevice = undefined;
  vm.formObj = {};
  vm.isLoaded = false;
  vm.infoForm = {};
  vm.originalmdevice = undefined;
  vm.mdeviceTitleName = '';
  vm.breadcrumbLabel = undefined;
  $scope.mdeviceId = undefined;
  vm.manufacturers = [];
  vm.filteredManufacturers = [];
  vm.product_codes = [];
  vm.lastSignon = undefined;
  vm.setProductCodesDropdown = setProductCodesDropdown;

  //============= FUNCTIONS ============//
  vm.showConfirmDelete = showConfirmDelete;
  vm.deletemdevice = deletemdevice;
  vm.changeStatus = changeStatus;
  vm.save = save;
  vm.restore = restore;
  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('mdevice-detail.ctrl: activiate');


    var promises = [
      EnlightedAPISvc.manufacturer.getAll(),
      EnlightedAPISvc.product_code.getAll()
    ];

    $q.all(promises).then(function(res){

      jQuery.each(res[0].enums,function(idx1){
        vm.manufacturers.push(res[0].enums[idx1]);
        vm.manufacturers[vm.manufacturers.length-1].children_ui = [];


        jQuery.each(res[1].enums,function(idx2){
          if(vm.manufacturers[vm.manufacturers.length-1].val === res[1].enums[idx2].parent ){
            vm.manufacturers[vm.manufacturers.length-1].children_ui.push(res[1].enums[idx2]);
          }
        });
        
      });

      //filtering to the orgs that have product codes
      jQuery.each(vm.manufacturers,function(idx){
        if(vm.manufacturers[idx].children_ui.length !== 0){
          vm.filteredManufacturers.push(vm.manufacturers[idx]);
        }
      });


      setProductCodesDropdown();
    });
    init();
  }


  function setProductCodesDropdown(){
    var temp =  _.find(vm.filteredManufacturers, function(obj){
      return obj.val == vm.formObj.manufacturer;
    });
    console.log(temp);
    if(temp){
      vm.product_codes = temp.children_ui;
    }else{
      vm.product_codes = [];
    }
  }

  function init() {
    console.log('mdevice-detail.ctrl: initialized');
    mdeviceId = $stateParams.mdeviceId;
    $scope.mdeviceId = $stateParams.mdeviceId;
    console.log('current mdevice id' + mdeviceId);

    EnlightedAPISvc.ManufacturedDevice.get(mdeviceId, false, false).then(function (mdeviceRsp) {
      console.log(mdeviceRsp.obj);

      if(mdeviceRsp.obj.signed_on){

        vm.lastSignon = new Date(mdeviceRsp.obj.signed_on).toLocaleDateString('en-US', {  
          month : 'short',
          day : 'numeric',
          year : 'numeric'
        });

      }

      
      vm.originalmdevice = mdeviceRsp.obj;

      vm.curmdevice = angular.copy(mdeviceRsp.obj);

      
      applyMdeviceForm(vm.curmdevice);
      applyHeader();

      vm.isLoaded = true;


    });
  }


  function applyMdeviceForm(target){
    var temp = target.mac.pcode.split(':');
    vm.formObj.manufacturer = temp[0];
    vm.formObj.pcode = temp[1];
    vm.formObj.macaddr = $filter('formatMAC')(vm.curmdevice.mac.address);
  }

  function applyHeader(){
    vm.mdeviceTitleName = vm.curmdevice.mac.pcode + ' ' + $filter('formatMAC')(vm.curmdevice.mac.address);
    vm.mdeviceName = vm.mdeviceTitleName;
    vm.breadcrumbLabel = vm.mdeviceTitleName;
  }


  function restore() {
    vm.curmdevice = angular.copy(vm.originalmdevice);
    
    applyMdeviceForm(vm.originalmdevice);
    vm.infoForm.$setPristine();


    
    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: $translate.instant('RESTORE_SUCCESS', { val: vm.curmdevice.val }),
      el: '#content'
    });
  }


  function formApplyToObj(){
    vm.curmdevice.mac.pcode = vm.formObj.manufacturer + vm.curmdevice.mac.pcode.substring(vm.curmdevice.mac.pcode.indexOf(':'));
    vm.curmdevice.mac.pcode = vm.curmdevice.mac.pcode.substring(0,vm.curmdevice.mac.pcode.indexOf(':')+1) + vm.formObj.pcode;
    vm.curmdevice.mac.address = vm.formObj.macaddr.replace(/[:]+/g, '');
  }

  function save() {
    formApplyToObj();
    console.log('mdevice detail.ctrl: save - ',  vm.curmdevice);
    vm.hasAsync = true;

    
    EnlightedAPISvc.ManufacturedDevice.update(vm.curmdevice)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.curmdevice.val }),
          el: '#content'
        });
        
        
        vm.curmdevice = rsp.obj;
        vm.originalmdevice = angular.copy(vm.curmdevice);
        applyHeader();
        console.log(rsp);



        vm.infoForm.$setPristine();

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: $translate.instant('UPDATE_FAILURE', { val: vm.originalmdevice.val }),
          el: '#content'
        });

        vm.hasAsync = false;
      });
      
    }

  function parseDate(str_date) {
    return new Date(Date.parse(str_date));
  }


  function broadcastSelection(tabname) {
    $scope.$broadcast('MDEVICE_' + tabname + '_TAB_SELECTED', {mdevice: vm.curmdevice});
  }

  function deletemdevice() {
    console.log('deleting mdevice');

    EnlightedAPISvc.ManufacturedDevice.delete(vm.curmdevice.gid)
      .then(function() {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('DELETE_MDEVICE_SUCCESS', { mdevicename: vm.mdeviceName }),
          el:'#content'
        });
        $state.go('settings.mdevice.list');
      }, function() {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('DELETE_MDEVICE_FAILURE'), error),
          el:'#content'
        });
      });
  }

  function onSelectInfoTab() {
    broadcastSelection('INFORMATION');
  }

  function onSelectNotesTab() {
    var notesObject = angular.copy(vm.curmdevice);
    $scope.$broadcast('NOTES_TAB_SELECTED', {object: notesObject});
  }

  function showConfirmDelete(ev) {
    var

      objectType = $translate.instant('MDEVICE_OBJECT_TYPE'),

      opts = {
        ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
        title: $translate.instant('CONFIRM_DELETE'),
        message: $translate.instant('CONFIRM_DELETE_MESSAGE', {objectType: objectType})
      };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        deletemdevice();
      });
  }

  function changeStatus(event) {
    $mdDialog.show({
      locals: {
        // pass mdevice vm to md-dialog
        mdeviceVm: vm.curmdevice
      },
      clickOutsideToClose: true,
      controller: 'MdeviceChstatsCtrl',
      controllerAs: 'ctrl',
      focusOnOpen: false,
      targetEvent: event,
      templateUrl: 'app-container/components/global-settings/mdevice/chstats/change-state-dialog.tpl.html'
    }).then(function () {
      init();
    });
  }

};
