/* @ngInject */
module.exports = function ($scope, $q, $translate, EnlightedAPISvc, EnlightedAPIEnumsSvc, ConfirmDialogSvc, BannerSvc, $rootScope, $filter,$state) {
  var vm = this,
    orgGid,
    I18N_KEYS = [
      'MDEVICES',
      'DT_PAGE',
      'DT_ROWS_PER_PAGE',
      'DT_OF',
      'MDEVICE_DELETE_SUCCESS_PLURAL',
      'MDEVICE_DELETE_FAILED_PLURAL',
      'CONFIRM_DELETE',
      'CONFIRM_DELETE_ARIA_LABEL',
      'MDEVICE_OBJECT_TYPE_PLURAL',
      'MDEVICE_OBJECT_TYPE'
    ],
    DICTIONARY = {};

  //============= MODELS ============//
  vm.isLoaded = false;
  vm.filter = {};
  vm.query = {};
  vm.selected = [];
  vm.md_grid = [];

  //============= FUNCTIONS ============//
  vm.createMdevices = createMdevices;
  vm.deletemdevices = deletemdevices;
  vm.showDeleteConfirm = showDeleteConfirm;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('manufactured_devices.list.ctrl: activate');

    init();
  }

  $scope.$watch(function(){
    return vm.filter.search;
  },function(){
    console.log(vm.filter.search);
  });

  function init() {
    console.log('manufactured_devices-list.ctrl: init');
    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });
    });

    orgGid = $rootScope.orgId;

    vm.query = {
      order: 'kind',
      limit: 15,
      page: 1,
      label: {
        page: DICTIONARY.DT_PAGE,
        rowsPerPage: DICTIONARY.DT_ROWS_PER_PAGE,
        of: DICTIONARY.DT_OF
      }
    };

    var promises = [];
    promises.push(EnlightedAPIEnumsSvc.getEnum('product_code'));
    promises.push(EnlightedAPIEnumsSvc.getEnum('manufacturer'));
    promises.push(EnlightedAPISvc.ManufacturedDevice.getAll());
    
    $q.all(promises).then(function(resolutions) {
      var productCodes = _.keyBy(resolutions[0], function(productCode) {
        return productCode.val;
      });
      var manufacturers = _.keyBy(resolutions[1], function(manufacturer) {
        return manufacturer.val;
      });

      handleGetmdevicesSucc(resolutions[2], productCodes, manufacturers);
    }, handleGetmdevicesErr());
  }

  function handleGetmdevicesSucc(manufacturedDevices, productCodes, manufacturers) {
      vm.md_grid = [];

      angular.forEach(manufacturedDevices.objs, function (mdevice) {
        var productCode = productCodes[mdevice.mac.pcode.split(":").pop()];
        var manufacturer = manufacturers[productCode.parent];
        var temp = {};
        temp.gid = mdevice.gid;
        temp.macaddress = $filter('formatMAC')(mdevice.mac.address);
        temp.manu = manufacturer.names;
        temp.pcode = productCode.names;
        temp.serial = mdevice.serial;
        temp.state = mdevice.state;
        vm.md_grid.push(temp);
      });

      vm.isLoaded = true;
  }

  function handleGetmdevicesErr() {
    return function (err) {
      return $q.reject(err);
    };
  }

  function createMdevices() {
    $state.go('settings.mdevice.create');
  }

  function deletemdevices(selected) {
    var promises = [];
    angular.forEach(selected, function (mdevice) {
      promises.push(EnlightedAPISvc.ManufacturedDevice.delete(mdevice.gid));
    });

    $q.all(promises).then(function () {
      // remove deleted mdevices form mdevice lsit
      vm.md_grid = _.difference(vm.md_grid, selected);
      BannerSvc.show({
        scope: $rootScope,
        type: 'success',
        autoClose: true,
        content: $translate.instant('DELETE_SUCCESS_PLURAL', {objects: DICTIONARY.IDP_OBJECT_TYPE_PLURAL})
      });
      vm.selected = [];
    }, function () {
      BannerSvc.show({
        scope: $rootScope,
        type: 'error',
        autoClose: false,
        content: $translate.instant('DELETE_FAILED_PLURAL', {objects: DICTIONARY.IDP_OBJECT_TYPE_PLURAL})
      });
      vm.selected = [];
    });
  }

  function showDeleteConfirm(selected, ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_DELETE_ARIA_LABEL,
      title: DICTIONARY.CONFIRM_DELETE,
      message: (selected.length > 1) ?
        $translate.instant('CONFIRM_DELETE_MESSAGE_LIST_PLURAL') :
        $translate.instant('CONFIRM_DELETE_MESSAGE_LIST')
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        vm.deletemdevices(vm.selected);
      });
  }

};