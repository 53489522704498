var AngledNavbarDirectiveController = require('./angled-navbar.directive.ctrl');

/* @ngInject */
module.exports = function() {
  return {
    restrict: 'AE',
    scope: {
      menus: '=',
      user: '=',
      apps: '=',
      orgs: '=',
      currentOrg: '=',
      showTitle: '=',
      navfn: '&',
      changeOrg: '&',
      openApp: '&',
      showOpts: '=',
      locale: '='
    },
    templateUrl: 'app-container/components/header/navbar.html',
    controller: AngledNavbarDirectiveController,
    controllerAs: 'navbarCtrl'
  };
};
