/* @ngInject */
module.exports = function ($scope, $translate, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, ConfirmDialogSvc) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.origEnumObj   = undefined;
  vm.enumObj       = undefined;
  vm.hasAsync      = false;
  vm.infoForm      = {};
  vm.isLoaded      = false;
  vm.wrapper       = {};
  vm.manufacturers = [];
  vm.lampcfg_mode = "list";

  //============= FUNCTIONS ============//
  vm.sensorSaveFn = sensorSaveFn;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops luminaire info.ctrl: activate ');
    $scope.$on('LUMINAIRE_LAMP_CFG_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
      console.log(args.enumObj.sensorArray);
      $scope.$broadcast("RERENDER_SENSORS");
    });
  }

  function init(enumObj) {
    console.log('ops luminaire info.ctrl: init', enumObj);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    getManufacturers();
    getSensorProductCodes();

    enumObj.sensorArray = undefined;
    enumObj.sensor = undefined;

    vm.apiEnum = enumObj;
    // transform to have app_type obj model
    vm.enumObj = enumObj;
    vm.enumObj.sensorArray = angular.copy(enumObj.sensors);

    console.log(vm.enumObj);
    // copy original object to retain information for restore
    vm.origEnumObj = angular.copy(vm.enumObj);
    vm.isLoaded = true;
  }

  function sensorSaveFn(sensor, index) {
    vm.apiEnum = updateAPIEnum(vm.apiEnum, vm.enumObj);
    vm.isLoaded = false;

    console.log("DATA SENT:", vm.apiEnum);

    EnlightedAPISvc.luminaire.update(vm.apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        console.log("RESPONSE AFTER SAVE", rsp.enum, vm.apiEnum);
        vm.apiEnum = rsp.enum;
        // transform to have app_type obj model
        vm.enumObj = rsp.enum;
        vm.enumObj.sensorArray = vm.enumObj.sensors;
        // copy original object to retain information for restore
        vm.origEnumObj = angular.copy(vm.enumObj);

        console.log(vm.enumObj.sensorArray, vm.enumObj.sensors);

        vm.isLoaded = true;
        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.origEnumObj.val }), error),
          el: '#content'
        });


        // vm.enumObj.sensorArray.splice(index, 1);
        // vm.enumObj = angular.copy(vm.origEnumObj);
        $scope.$broadcast("LAMP_SENSOR_RESTORE");

        $scope.$emit("getSensor", {data: vm.origEnumObj.sensors});

        console.log(vm.enumObj.sensorArray, vm.enumObj.sensors);

        vm.isLoaded = true;
        vm.hasAsync = false;
      });
  }

  function getManufacturers() {
    EnlightedAPI.manufacturer.getAll().then(function (resp) {
      vm.manufacturers = [];
      angular.forEach(resp.enums, function (e) {
        vm.manufacturers.push(e);
      });
    });
  }

  function getSensorProductCodes() {
    // EnlightedAPI.product_code.getAll().then(function (resp) {
    //   vm.productCodes = resp.enums.filter(function (pCode, index) {
    //     return pCode.obj === "sensor";
    //   });
    // });
  }

  function updateAPIEnum(apiEnum, enumObj) {
    apiEnum.eid = enumObj.eid;
    apiEnum.val = enumObj.val;
    apiEnum.sensors = angular.copy(enumObj.sensorArray);

    return apiEnum;
  }
};
