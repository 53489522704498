/* @ngInject */
module.exports = function ($scope, $q, $stateParams, $translate, EnlightedAPISvc, BannerSvc, $rootScope, $state, ConfirmDialogSvc, EnlightedAPIErrorSvc) {
  var

  vm = this,

  I18N_KEYS = [
    'CONFIRM_CANCEL_DIALOG',
    'CONFIRM_CANCEL',
    'CONFIRM_CANCEL_CREATE_MSG'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.newEnum  = undefined;
  vm.baseObj  = {};
  vm.infoForm = {};
  vm.isLoaded = false;
  vm.kind     = undefined;
  vm.options  = {};

  //============= FUNCTIONS ============//
  vm.save              = save;
  vm.showConfirmCancel = showConfirmCancel;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('system task_type create.ctrl: activate ', $stateParams);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    $scope.enumType = vm.kind = "task_type";

    createOrgTypeDisplay().then(function (resp) {
      vm.newEnum = {};
      vm.newEnum.org_types = resp.org_types;
      vm.newEnum.org_type_opt = resp.org_type_opt;
    });

    $translate('ENUM_VAL_DISPLAY_' + vm.kind.toUpperCase())
      .then(function (translation) {
        vm.options.valDisplay = translation;
        vm.isLoaded = true;
      });
  }

  function createOrgTypeDisplay() {
    var deferred = $q.defer();

    EnlightedAPI.organization_type.getAll().then(function (resp) {
      var organizations = resp.enums;
      var org_types = [];
      angular.forEach(organizations, function (org, index) {
        org_types.push({
          value: org.val,
          names: org.names,
          selected: false
        });
      });

      deferred.resolve({
        org_types: org_types,
        org_type_opt: undefined
      });
    });

    return deferred.promise;
  }

  function save() {
    console.log(vm.newEnum);

    vm.baseObj = updateAPIEnum(vm.baseObj, vm.newEnum);
    vm.baseObj.kind = vm.kind;

    console.log('basic-create.ctrl: save -', vm.baseObj);

    EnlightedAPISvc[vm.kind].create(vm.baseObj)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('CREATE_SUCCESS', { val: vm.newEnum.val }),
          el: '#content'
        });

        $state.go('^.list', {enumId: vm.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_FAILURE', { val: vm.newEnum.val }), error),
          el: '#content'
        });
      });
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        $state.go('^.list');
      });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.dname;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.ddescription || "";
    apiEnumObj.org_type = enumObj.org_type_opt;
    apiEnumObj.permissions_mask = enumObj.permissions_mask;

    return apiEnumObj;
  }
};
