module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      configurations  : '=',
      lamps           : '=',
      enlOnChange     : '&',
      delFn           : '&',
      enlOnViewChange : '&',
      options         : '='
    },
    templateUrl: 'app-container/components/global-settings/ops/regulator/details/view-only/regulator-lamp-configs.template.html',
    controller: 'LampConfigsDirCtrl as lampConfigCtrl'
  };
};
