/* @ngInject */
module.exports = function($scope, $state, $stateParams, EnlightedPermissionsSvc, $filter, ConfirmDialogSvc, $timeout, EnlightedAPISvc, $q, BannerSvc, $rootScope, ContainerNavigationSvc, EnlightedAPIErrorSvc) {
  var vm = this,

  uuid = require('node-uuid'),

  newOrgId,

  I18N_KEYS = [
    'INFORMATION',
    'APPLICATIONS',
    'ORGANIZATIONS',
    'IDENTITY_PROVIDER',
    'ADMINISTRATOR',
    'CREATE_ORGANIZATION_TITLE',
    'CONFIRM_CANCEL_CREATE_ORG_MESSAGE',
    'CONFIRM_CANCEL',
    'CONFIRM_CANCEL_DIALOG',
    'CREATE_ORG_FAILURE_MSG',
    'CREATE_ORG_FAILURE_ORG_ALREADY_EXISTS',
    'CREATE_ORG_FAILURE_IDP_DOMAIN_ALREADY_EXISTS',
    'CREATE_ORG_FAILURE_PERSON_EMAIL_ALREADY_EXISTS'
  ],

  DICTIONARY = {},

  WIZARD_STEPS = {
    INFORMATION       : 0,
    APPLICATIONS      : 1,
    ORGANIZATIONS     : 2,
    IDENTITY_PROVIDER : 3,
    ADMINISTRATOR     : 4
  };

  //============= MODELS ============//
  vm.createdAdminRsp = undefined;
  vm.createdIdpRsp   = undefined;
  vm.createdOrgId    = undefined;
  vm.createdOrgRsp   = undefined;
  vm.selectedStep    = 0;
  vm.sessionRsp      = undefined;
  vm.showSuccess     = false;
  vm.stepProgress    = 1;
  vm.maxStep         = 5;
  vm.showBusyText    = false;
  vm.stepData        = [];

  //============= FUNCTIONS ============//
  vm.finishWizard      = finishWizard;
  vm.goPreviousStep    = goPreviousStep;
  vm.goToOrg           = goToOrg;
  vm.initWizard        = initWizard;
  vm.showConfirmCancel = showConfirmCancel;
  vm.submitCurrentStep = submitCurrentStep;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('create-org.ctrl: activated -', $stateParams);

    getMessages();

    watchSelectedStep();

    initWizard();

    if (!EnlightedPermissionsSvc.hasCreateOrgPermission($scope.user)) {
      ContainerNavigationSvc.goToDashboard($scope.currentOrg.gid);
    }

    // Change the header
    $scope.currentApp.title = DICTIONARY.CREATE_ORGANIZATION_TITLE;
    $scope.headerOptions.showOrgDropdown = false;
    $scope.headerOptions.showAppsDropdown = false;
  }

  function watchSelectedStep() {
    $scope.$watch(function() {
      return vm.selectedStep;
    }, function(newVal, oldVal) {
      var broadcastEvt = 'CREATE_ORG_ACTIVATE_ORG';

      if (newVal === WIZARD_STEPS.IDENTITY_PROVIDER) {
        broadcastEvt = 'CREATE_ORG_ACTIVATE_IDP';
      } else if (newVal === WIZARD_STEPS.ADMINISTRATOR) {
        broadcastEvt = 'CREATE_ORG_ACTIVATE_ADMIN';
      } else if (newVal === WIZARD_STEPS.APPLICATIONS) {
        broadcastEvt = 'CREATE_ORG_ACTIVATE_APPLICATIONS';
      } else if (newVal === WIZARD_STEPS.ORGANIZATIONS) {
        broadcastEvt = 'CREATE_ORG_ACTIVATE_ORGANIZATIONS';
      }

      $scope.$broadcast(broadcastEvt);
    });
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_ORG_MESSAGE
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function() {
        ContainerNavigationSvc.goToDashboard($scope.currentOrg.gid, $scope.selectedLanguage.val);
      });
  }

  function getMessages() {
    angular.forEach(I18N_KEYS, function(key) {
      DICTIONARY[key] = $filter('translate')(key);
    });
  }

  function submitCurrentStep(step) {
    step.completed = true;
    if (vm.selectedStep >= vm.maxStep) {
      return;
    }
    //do not increment vm.stepProgress when submitting from previously completed step
    if (vm.selectedStep === vm.stepProgress - 1) {
      vm.stepProgress = vm.stepProgress + 1;
    }

    $timeout(function() {
      vm.selectedStep = vm.selectedStep + 1;
    }, 0);
  }

  function goPreviousStep(step) {
    vm.selectedStep = vm.selectedStep - 1;
  }

  function finishWizard(onlyOrg) {

    console.log('create-org.ctrl: finish wizard!', vm.stepData);

    // create uuid v1 transaction id for the 3 API calls
    var tid = uuid.v1(),
      // combine steps 1-4 into one org object for create org API
      newOrg = mergeOrgStepData(vm.stepData.slice(0, 4)),
      newOrgGid;

    newOrg.kind = 'organization';

    if (!onlyOrg) {
      // make create org api call
      createOrg(newOrg, tid)
        .then(createIdP(vm.stepData[3].data.idp, tid), handleCreateOrgErr())
        .then(getUserSession(), handleCreateIdPErr())
        .then(createAdministrator(vm.stepData[4].data.user, tid, newOrg.apps), handleGetSessionErr())
        .then(addOrgContact(tid), handleCreateAdminErr())
        .then(showSuccess(), showError());
    } else {
      createOrg(newOrg, tid)
        .then(function (newOrgRsp) {
          var newOrg = newOrgRsp.obj;
          vm.createdOrgRsp = newOrgRsp;
          vm.createdOrgId = newOrg.gid;
          vm.showSuccess = true;

          $rootScope.$broadcast('REFRESH_ORG_LIST');
        }, showError());
    }
  }

  function createIdP(newIdp, tid) {
    return function(newOrgRsp) {
      var newOrg = newOrgRsp.obj;
      vm.createdOrgRsp = newOrgRsp;
      vm.createdOrgId = newOrg.gid;

      if (_.isEmpty(newIdp) || newIdp.type === '') {
        console.log("No idp info at this time. Skip idp step");
        return $q.when({});
      } else {
        newIdp.org_owner = vm.createdOrgId;
        newIdp.kind = newIdp.type;
        newIdp.windows_only = true;
        return EnlightedAPISvc.IdentityProvider.create(newIdp, tid);
      }
    };
  }

  function handleCreateOrgErr() {
    return function(error) {
      console.log('create-org.ctrl: failed to create org ', error);
      return $q.reject(error);
    };
  }

  function getUserSession() {
    return function(newIdpRsp) {
      vm.createdIdpRsp = newIdpRsp;
      console.log('create-org.ctrl: new idp created successfully or idp creating skipped');
      return EnlightedAPISvc.Session.getSession();
    };
  }

  function handleCreateIdPErr() {
    return function(error) {
      console.log('create-org.ctrl: failure[idp] ', error);
      return $q.reject(error);
    };
  }

  function createAdministrator(newPerson, tid, apps) {
    return function(sessionRsp) {
      vm.sessionRsp = sessionRsp;
      console.log('create-org.ctrl: received session user');
      var
        stepDataPerson = vm.stepData[4].data.user,
        newPerson = {
          kind: 'person',
          emails: [stepDataPerson.email],
          org: vm.createdOrgRsp.obj.id,
          firstname: stepDataPerson.fname,
          lastname: stepDataPerson.lname,
          roles: vm.stepData[4].data.user.roles || angular.copy(sessionRsp.roles),
          addresses: [{
            type: 'work',
            country: stepDataPerson.country
          }],
          phones: [],
          apps: apps
        };
      console.log('create-org.ctrl: creating new person ', newPerson, tid);
      return EnlightedAPISvc.User.create(newPerson, tid);
    };
  }

  function handleGetSessionErr() {
    return function(error) {
      console.log('create-org.ctrl: get session failure', error);
      return $q.reject(error);
    };
  }

  function addOrgContact(tid) {
    return function(newPersonRsp) {
      vm.createdAdminRsp = newPersonRsp;
      console.log('create-org.ctrl: admin user created successfully');

      var updateOrg = vm.createdOrgRsp.obj;
      updateOrg.contacts = updateOrg.contacts || [];
      updateOrg.contacts.push({
        type: 'administrator',
        user: newPersonRsp.obj.gid
      });

      return EnlightedAPISvc.Organization.update(updateOrg, tid);
    };
  }

  function handleCreateAdminErr() {
    return function(error) {
      console.log('create-org.ctrl: failure[person] ', error);
      return $q.reject(error);
    };
  }

  function showSuccess() {
    return function() {
      console.log('create-org.ctrl: new admin added as contact of org successfully');
      vm.showSuccess = true;

      $rootScope.$broadcast('REFRESH_ORG_LIST');
    };
  }

  function showError() {
    return function(error) {
      var errMsg;

      if (error && error.details) {
        if (error.details.kind === 'organization') {
          if (error.details.attribute && error.details.attribute.name === 'name' &&
            error.code && error.code.message === 'REFERENCE_ALREADY_EXIST') {
            errMsg = DICTIONARY.CREATE_ORG_FAILURE_ORG_ALREADY_EXISTS;
          }
        } else if (error.details.kind === 'alias' || error.details.kind === 'saml') {
          if (error.details.attribute && error.details.attribute.name === 'domain' &&
            error.code && error.code.message === 'REFERENCE_ALREADY_EXIST') {
            errMsg = DICTIONARY.CREATE_ORG_FAILURE_IDP_DOMAIN_ALREADY_EXISTS;
          }
        } else if (error.details.kind === 'person') {
          if (error.details.attribute && error.details.attribute.name === 'emails' &&
            error.code && error.code.message === 'REFERENCE_ALREADY_EXIST') {
            errMsg = DICTIONARY.CREATE_ORG_FAILURE_PERSON_EMAIL_ALREADY_EXISTS;
          }
        }
      }

      if (vm.createdOrgId) {
        console.log('create-org.ctrl: finishWizard - deleting org because encountered an error');
        EnlightedAPISvc.Organization.delete(vm.createdOrgId)
          .catch(function(delErr) {
            console.log('create-org-ctrl: finishWizard - failed to delete org!');
          });
      }

      BannerSvc.show({
        type: 'error',
        scope: $rootScope,
        autoClose: false,
        content: EnlightedAPIErrorSvc.getErrorMsg(errMsg || DICTIONARY.CREATE_ORG_FAILURE_MSG, error),
        el: '#content'
      });
    };
  }

  function createOrg(org, tid) {
    console.log('create-org.ctrl: creating new org ', org, tid);
    return EnlightedAPISvc.Organization.create(org, tid);
  }

  function initWizard() {
    vm.selectedStep = 0;
    vm.stepProgress = 1;
    vm.stepData = [{
      step: WIZARD_STEPS.INFORMATION,
      label: DICTIONARY.INFORMATION,
      completed: false,
      optional: false,
      data: {
        org: {
          types: []
        }
      },
      templateUrl: 'app-container/components/create-org/organization/organization_step.html'
    }, {
      step: WIZARD_STEPS.APPLICATIONS,
      label: DICTIONARY.APPLICATIONS,
      completed: false,
      optional: false,
      data: {
        org: {
          apps: []
        }
      },
      templateUrl: 'app-container/components/create-org/organization/applications_step.html'
    }, {
      step: WIZARD_STEPS.ORGANIZATIONS,
      label: DICTIONARY.ORGANIZATIONS,
      completed: false,
      optional: false,
      data: {
        org: {
          orgs: {}
        }
      },
      templateUrl: 'app-container/components/create-org/organization/organization_types_step.html'
    }, {
      step: WIZARD_STEPS.IDENTITY_PROVIDER,
      label: DICTIONARY.IDENTITY_PROVIDER,
      completed: false,
      optional: false,
      data: {
        idp: {},
        org: {
          thirdparty_idps: false
        }
      },
      templateUrl: 'app-container/components/create-org/idp/idp_step.html'
    }, {
      step: WIZARD_STEPS.ADMINISTRATOR,
      label: DICTIONARY.ADMINISTRATOR,
      completed: false,
      optional: false,
      data: {
        user: {}
      },
      templateUrl: 'app-container/components/create-org/administrator/administrator_step.html'
    }];

    vm.showSuccess = false;
  }

  function mergeOrgStepData(orgDataList) {
    var newOrg = {};

    angular.forEach(orgDataList, function(orgdata) {
      angular.extend(newOrg, orgdata.data.org);
    });

    return newOrg;
  }

  function goToOrg() {
    ContainerNavigationSvc.goToApp(vm.createdOrgId, $scope.selectedLanguage.val, ContainerNavigationSvc.APPS.ORG_MGMT);
    $scope.headerOptions.showOrgDropdown = true;
    $scope.headerOptions.showAppsDropdown = true;
  }
};
