/* @ngInject */
module.exports = function ($scope) {
  var

  vm = this;

  //============= MODELS ============//
  var chart, columns, entriesArray;

  //============= FUNCTIONS ============//
  var xlabelWatch = $scope.$watch(function () { return $scope.xlabel; }, function (newVal, oldVal) {
    if (typeof newVal !== "undefined") {
      activate();
      xlabelWatch();
    }
  }, true);

  var yAxisValues = ($scope.entriesArr.length === 0 ? undefined : []);

  function calcYValues(arr, prop) {
    var min = getMin(arr, prop),
    max = getMax(arr, prop),
    lengthonevalue = arr[0][prop],
    middle = parseInt((min + max) / 2);
    if (arr.length === 1) {
      yAxisValues = [0, lengthonevalue, lengthonevalue * 2];
    } else if (arr.length >= 2) {
      yAxisValues = [min, middle, max];
    } else {
      yAxisValues = [min, max];
    }
    //console.log(yAxisValues, min, max, middle);
  }

  function getMin(arr, prop) {
    var lowest = Number.POSITIVE_INFINITY;
    var tmp;
    for (var i=arr.length-1; i>=0; i--) {
        tmp = arr[i][prop];
        if (tmp < lowest) lowest = tmp;
    }
    return lowest;
  }

  function getMax(arr, prop) {
    var highest = Number.NEGATIVE_INFINITY;
    var tmp;
    for (var i=arr.length-1; i>=0; i--) {
        tmp = arr[i][prop];
        if (tmp > highest) highest = tmp;
    }
    return highest;
  }

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log("CHART START");
    entriesArray = $scope.$watch(function () { return $scope.entriesArr; }, function (newVal, oldVal) {
      if (typeof newVal !== "undefined" && newVal.length > 0) {
        calcYValues(newVal, $scope.yvaluefrom);
      }
      //console.warn(yAxisValues, newVal, $scope.yvaluefrom);
      var subcol1 = [];
      var subcol2 = [];
      angular.forEach(newVal, function (metering, index) {
        subcol1.push(metering[$scope.yvaluefrom]);
        subcol2.push(metering[$scope.xvaluefrom]);
      });

      subcol1.unshift($scope.ylabel);
      subcol2.unshift($scope.xlabel);
      columns = [
        subcol1, subcol2
      ];

      chart.load({
        columns: columns
      });
    }, true);

    // initialize chart
    var xlabel = $scope.xlabel;
    var ylabel = $scope.ylabel;

    var colors = {};
    colors[xlabel] = 'rgb(121,192,82)';
    colors[ylabel] = 'rgb(121,192,82)';
    if (typeof $scope.entriesArr !== "undefined" && $scope.entriesArr.length > 0) {
      calcYValues($scope.entriesArr, $scope.yvaluefrom);
    }
    chart = c3.generate({
        bindto: ($scope.chart.children()[0]),
        size: {
          width: $scope.widthpx || undefined,
          height: $scope.heightpx || undefined
        },
        tooltip: {
            show: false
        },
        padding: {
          right: 3
        },
        data: {
            x: xlabel,
            columns: [
              [$scope.xlabel, 0],
              [$scope.ylabel, 0]
            ],
            // set enlighted default colors
            colors: colors,
            // show labels
            labels: true,
            // dont show legend
            legend: {
                show: false
            }
        },
        axis: {
          // x and y labels
          x: {
              label: {
                  text: $scope.xlabel,
                  position: 'outer-center'
              }
          },
          y: {
              label: {
                  text: $scope.ylabel,
                  position: 'outer-middle'
              },
              tick: {
                values: yAxisValues
              }
          }
        }
    });
  }

  $scope.$on("$destroy", function () {
    if (typeof entriesArray === "function") {
      console.log("DESTROY CHART SCOPE, UNDO WATCH ");
      entriesArray();
    }
  });
};
