/* @ngInject */
module.exports = function ($scope, $stateParams, $q, $translate, EnlightedAPISvc, BannerSvc, $rootScope, $state, ConfirmDialogSvc, EnlightedAPIErrorSvc) {
  var

    vm = this,

    I18N_KEYS = [
      'CONFIRM_CANCEL_DIALOG',
      'CONFIRM_CANCEL',
      'CONFIRM_CANCEL_CREATE_MSG'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.newEnum  = undefined;
  vm.infoForm = {};
  vm.isLoaded = false;
  vm.options  = {};
  vm.kind = undefined;
  //============= FUNCTIONS ============//
  vm.save              = save;
  vm.showConfirmCancel = showConfirmCancel;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops control-unit create.ctrl: activate ', $stateParams);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    $scope.enumType = vm.kind = 'control_unit';

    var promises = [];
    promises.push(EnlightedAPISvc.manufacturer.getAll());


    $q.all(promises).then(function (resolutions) {
      vm.options.manufacturers = angular.copy(resolutions[0].enums);
      vm.isLoaded = true;
    });
  }

  function save() {
    var apiEnum = {};

    apiEnum = updateAPIEnum(apiEnum, vm.newEnum);


    console.log('Control Unit-create.ctrl: save -', apiEnum);

    EnlightedAPISvc[vm.kind].create(apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('CREATE_SUCCESS', { val: vm.newEnum.val }),
          el: '#content'
        });

        $state.go('^.list', {enumId: vm.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('CREATE_FAILURE', { val: vm.newEnum.val }), error),
          el: '#content'
        });
      });
  }

  function showConfirmCancel(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_CANCEL_DIALOG,
      title: DICTIONARY.CONFIRM_CANCEL,
      message: DICTIONARY.CONFIRM_CANCEL_CREATE_MSG
    };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        $state.go('^.list');
      });
  }

  function updateAPIEnum(apiEnumObj, enumObj) {
    apiEnumObj.val = enumObj.val;
    apiEnumObj.names = apiEnumObj.names || {};
    apiEnumObj.names[''] = enumObj.dname;
    apiEnumObj.descriptions = apiEnumObj.descriptions || {};
    apiEnumObj.descriptions[''] = enumObj.ddescription;
    apiEnumObj.parent = enumObj.manufacturer;
    apiEnumObj.channels = enumObj.channels;
    apiEnumObj.metering = enumObj.metering;
    apiEnumObj.kind = vm.kind;

    return apiEnumObj;
  }
};
