/* @ngInject */
module.exports = function ($scope, EnlightedAPISvc, EnlightedAPIEnumsSvc, BannerSvc, $rootScope, $translate, EnlightedAPIErrorSvc) {
  var

  vm = this,

  orgId,

  I18N_KEYS = [
    'EMAIL_OBJECT_TYPE',
    'PHONE_NUMBER_OBJECT_TYPE'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.apiUser     = undefined;
  vm.cachedUser  = undefined;
  vm.emailMode   = 'list';
  vm.isLoaded    = false;
  vm.phone_types = [];

  //============= FUNCTIONS ============//
  vm.emailsChanged = emailsChanged;
  vm.phonesChanged = phonesChanged;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('user-contact-info.ctrl: activate');

    $scope.$on('USERS_CONTACT_INFO_TAB_SELECTED', function (evt, args) {
      init(args.user);
    });
  }

  function init(user) {
    var promises = [];

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    console.log('user-contact-info.ctrl: init - ', user);

    orgId = $rootScope.orgId;
    vm.apiUser = angular.copy(user);
    vm.cachedUser = angular.copy(user);

    EnlightedAPIEnumsSvc.getEnum('phone_type')
      .then(function (enums) {
        vm.phone_types = filterByPhoneKind(enums);
        vm.isLoaded = true;
      });
  }

  function determineEmailError(error, event, affectedEmail) {
    var msg, domain;

    if (error) {
      if (error.code && error.details) {
        if ((error.code.message === 'REFERENCE_NOT_FOUND' ||
          error.code.message === 'INVALID_ATTRIBUTE') &&
          error.details.kind === 'person' &&
          error.details.attribute && error.details.attribute.name === 'emails') {
          domain = (affectedEmail) ? affectedEmail.substr(affectedEmail.indexOf('@') + 1) : affectedEmail;
          msg = $translate.instant('EMAIL_DOMAIN_NOT_SUPPORTED', { domain: domain });
        }
      }
    }

    return msg;
  }

  function emailsChanged(newEmails, event, affectedEmail) {
    var msg;

    console.log('user-contact-info.ctrl: emailsChanged - ', newEmails, event);

    vm.apiUser.emails = newEmails;

    EnlightedAPISvc.User.update(vm.apiUser)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: getSuccessMsg(event, DICTIONARY.EMAIL_OBJECT_TYPE)
        });

        angular.extend(vm.apiUser, rsp.obj);
        vm.cachedUser = angular.copy(vm.apiUser);
      }, function (error) {
        msg = determineEmailError(error, event, affectedEmail);

        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(msg || getFailureMsg(event, DICTIONARY.EMAIL_OBJECT_TYPE), error)
        });

        vm.apiUser = angular.copy(vm.cachedUser);
      });
  }

  function filterByPhoneKind(phoneTypes) {
    return _.filter(phoneTypes, function (phoneType) {
      return _.includes(phoneType.kinds, 'person');
    });
  }

  function getFailureMsg(event, objType) {
    return getMsg(event, 'FAILURE', objType);
  }

  function getMsg(event, successOrFailure, objType) {
    var msg, keySuffix;

    if (!(event && successOrFailure && objType)) {
      return;
    }

    keySuffix = successOrFailure.toUpperCase();

    switch(event) {
      case 'add':
        msg = $translate.instant('OBJECT_ADD_' + keySuffix, { object: objType });
        break;
      case 'delete':
        msg = $translate.instant('OBJECT_DELETE_' + keySuffix, { object: objType });
        break;
      case 'reorder':
        msg = $translate.instant('OBJECT_REORDER_' + keySuffix, { object: objType });
        break;
      case 'edit':
        msg = $translate.instant('OBJECT_EDIT_' + keySuffix, { object: objType });
        break;
      default:
        msg = $translate.instant('OBJECT_EDIT_' + keySuffix, { object: objType });
    }

    return msg;
  }

  function getSuccessMsg(event, objType) {
    return getMsg(event, 'SUCCESS', objType);
  }

  function phonesChanged(newPhones, event, affectedPhone) {
    var msg;

    console.log('user-contact-info.ctrl: phonesChanged - ', newPhones, event, affectedPhone);

    vm.apiUser.phones = newPhones;

    EnlightedAPISvc.User.update(vm.apiUser)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: getSuccessMsg(event, DICTIONARY.PHONE_NUMBER_OBJECT_TYPE)
        });

        angular.extend(vm.apiUser, rsp.obj);
        vm.cachedUser = angular.copy(vm.apiUser);
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(msg || getFailureMsg(event, DICTIONARY.PHONE_NUMBER_OBJECT_TYPE), error)
        });

        vm.apiUser = angular.copy(vm.cachedUser);
      });
  }
};
