/**
 * The list of 3rd party libraries required by main.js.
 * @type {Array}
 */
module.exports = [
  'jquery',
  'bootstrap',
  'angular',
  'lodash',
  'd3',
  'c3',
  'node-uuid',
  '../../bower_components/angular-ui-router/release/angular-ui-router',
  '../../bower_components/angular-bootstrap/ui-bootstrap-tpls',
  '../../bower_components/angular-sanitize/angular-sanitize',
  '../../bower_components/angular-translate/angular-translate',
  '../../bower_components/angular-translate-loader-static-files/angular-translate-loader-static-files',
  '../../bower_components/angular-animate/angular-animate',
  '../../bower_components/angular-aria/angular-aria',
  '../../bower_components/angular-messages/angular-messages',
  '../../bower_components/angular-material/angular-material',
  '../../bower_components/angular-sortable-view/src/angular-sortable-view',
  '../../bower_components/angular-material-data-table/dist/md-data-table',
  '../../bower_components/angular-breadcrumb/release/angular-breadcrumb',
  '../../bower_components/angular-file-saver/dist/angular-file-saver.bundle',
  '../../thirdparty_components/enl-angular-components/enl-angular-components',
  '../../thirdparty_components/enl-application-components/enl-app-components.min'
];
