/* @ngInject */
module.exports = function ($scope, $translate, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, ViewOnly) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.origEnumObj = undefined;
  vm.enumObj       = undefined;
  vm.hasAsync      = false;
  vm.infoForm      = {};
  vm.isLoaded      = false;
  vm.wrapper       = {};

  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('system layer-type info.ctrl: activate ');

    $scope.$on('LAYER_TYPE_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('system layer-type info.ctrl: init', enumObj);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    vm.apiEnum = enumObj;
    // transform to have layer_type obj model
    vm.enumObj = createEnumDisplay(enumObj);
    // copy original object to retain information for restore
    vm.origEnumObj = angular.copy(vm.enumObj);
    vm.isLoaded = true;

  }

  function createEnumDisplay(enumObj) {
    return {
        val: enumObj.val,
        dname: (enumObj.names) ? enumObj.names[''] : undefined,
        ddescription: (enumObj.descriptions) ? enumObj.descriptions[''] : undefined,
        computed: enumObj.computed,
        eid: enumObj.eid
    };
  }

  function restore() {
    console.log("RESTORE vm.enumObj WITH vm.origEnumObj ", "OBJECT MODEL: ", vm.enumObj, "ORIGINAL OBJECT:", vm.origEnumObj);
    vm.enumObj = angular.copy(vm.origEnumObj);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    console.log('layer-type-info.ctrl: save - ', vm.enumObj);

    vm.hasAsync = true;
    vm.apiEnum = updateAPIEnum(vm.apiEnum, vm.enumObj);

    console.log("DATA SENT:", vm.apiEnum);

    EnlightedAPISvc.layer_type.update(vm.apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        console.log("RESPONSE AFTER SAVE", rsp.enum, vm.apiEnum);
        vm.apiEnum = rsp.enum;
        vm.enumObj = createEnumDisplay(rsp.enum);
        // copy original object to retain information for restore
        vm.origEnumObj = angular.copy(vm.enumObj);

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.origEnumObj.val }), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  function updateAPIEnum(apiEnum, enumObj) {
    apiEnum.val = enumObj.val;
    apiEnum.names[''] = enumObj.dname;
    apiEnum.descriptions = apiEnum.descriptions || {};
    apiEnum.descriptions[''] = enumObj.ddescription || "";
    apiEnum.computed = enumObj.computed;
    apiEnum.eid = enumObj.eid;

    return apiEnum;
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("layer type form");
    } else {
      ViewOnly.delete("layer type form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("layer type form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("layer type form");
  });
};
