module.exports = function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      enlRestrictions : '='
    },
    link: function(scope, elem, attrs, ctrl) {
      ctrl.$validators.conflict = function (modelValue, viewValue) {
        var isValid = true;

        angular.forEach(scope.enlRestrictions, function (r) {
          if (r === parseFloat(viewValue)) {
            isValid = false;
          }
        });

        return isValid;
      };
    }
  };
};
