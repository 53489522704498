/* @ngInject */
module.exports = function ($scope, $translate, BannerSvc, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, ViewOnly) {
  var

  vm = this,

  I18N_KEYS = [
    'RESTORE_SUCCESS',
    'UPDATE_SUCCESS',
    'UPDATE_FAILURE'
  ],

  DICTIONARY = {};

  //============= MODELS ============//
  vm.origEnumObj = undefined;
  vm.enumObj       = undefined;
  vm.hasAsync      = false;
  vm.infoForm      = {};
  vm.isLoaded      = false;


  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('ops lamp info.ctrl: activate ');

    $scope.$on('ENUM_LAMP_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('ops lamp info.ctrl: init', enumObj);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    vm.enumObj = enumObj;

    vm.origEnumObj = angular.copy(vm.enumObj);

    vm.isLoaded = true;
  }


  function restore() {
    vm.enumObj = angular.copy(vm.origEnumObj);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    console.log('lamp-info.ctrl: save - ', vm.enumObj);

    vm.hasAsync = true;

    EnlightedAPISvc[vm.enumObj.kind].update(vm.enumObj)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        vm.enumObj = rsp.enum;
        vm.origEnumObj = angular.copy(vm.enumObj);

        vm.infoForm.$setPristine();

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.origEnumObj.val }), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("lamp information form");
    } else {
      ViewOnly.delete("lamp information form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("lamp information form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("lamp information form");
  });
};
