module.exports = {
  "code": {
    "val": 200007,
    "message": "OBJECTS_RETRIEVED"
  },
  "kind": "organization",
  "objs": [{
    "modified_user": "AANhcGkABmdsb2JhbBOBQAAd0hGygICAgICAgIATgUAAHdIRsoCAgICAgICA",
    "trans_id": "13814000-1dd2-11b2-8080-808080808080",
    "id": "bc76cfd0-d740-11e5-8080-808080808080",
    "name": "ATT",
    "modified": 1455910874445,
    "deleted": false,
    "types": ["customer", "fmanager"],
    "apps": ["device_mgmt", "facility_mgmt", "lightning_mgmt", "project_mgmt", "sysadmin", "test"],
    "orgs": {
      "commissioner": ["AAxvcmdhbml6YXRpb24ABmdsb2JhbLx29uDXQBHlgICAgICAgIA"],
      "pmanager": ["AAxvcmdhbml6YXRpb24ABmdsb2JhbLaImADYPBHkgICAgICAgIA"]
    },
    "contacts": [],
    "primary_facility": null,
    "facilities": ["AAhmYWNpbGl0eQAGZ2xvYmFsvHbP0NdAEeWAgICAgICAgLx4CFDXQBHlgICAgICAgIA"],
    "groups": [],
    "roles": {},
    "thirdparty_idps": true,
    "files": {},
    "kind": "organization",
    "creation": 1455910874445,
    "gid": "AAxvcmdhbml6YXRpb24ABmdsb2JhbLx2z9DXQBHlgICAgICAgIA"
  }, {
    "modified_user": "AANhcGkABmdsb2JhbBOBQAAd0hGygICAgICAgIATgUAAHdIRsoCAgICAgICA",
    "trans_id": "13814000-1dd2-11b2-8080-808080808080",
    "id": "b6889800-d83c-11e4-8080-808080808080",
    "name": "Enlighted Inc.",
    "modified": 1427871600000,
    "deleted": false,
    "types": ["fmanager", "pmanager"],
    "apps": ["device_mgmt", "facility_mgmt", "lightning_mgmt", "project_mgmt", "sysadmin", "test"],
    "orgs": {},
    "contacts": [],
    "primary_facility": "AAhmYWNpbGl0eQACdXO2iJgA2DwR5ICAgICAgICANcXYANqYEeSAgICAgICAgA",
    "facilities": [],
    "groups": [],
    "roles": {},
    "locale": "en_US",
    "thirdparty_idps": true,
    "files": {},
    "kind": "organization",
    "creation": 1427871600000,
    "gid": "AAxvcmdhbml6YXRpb24ABmdsb2JhbLaImADYPBHkgICAgICAgIA"
  }, {
    "modified_user": "AANhcGkABmdsb2JhbBOBQAAd0hGygICAgICAgIATgUAAHdIRsoCAgICAgICA",
    "trans_id": "13814000-1dd2-11b2-8080-808080808080",
    "id": "bc76f6e0-d740-11e5-8080-808080808080",
    "name": "ABC Lighting",
    "modified": 1455910874446,
    "deleted": false,
    "types": ["commissioner"],
    "apps": ["device_mgmt", "facility_mgmt", "lightning_mgmt", "project_mgmt", "sysadmin", "test"],
    "orgs": {},
    "contacts": [],
    "primary_facility": null,
    "facilities": [],
    "groups": [],
    "roles": {},
    "thirdparty_idps": true,
    "files": {},
    "kind": "organization",
    "creation": 1455910874446,
    "gid": "AAxvcmdhbml6YXRpb24ABmdsb2JhbLx29uDXQBHlgICAgICAgIA"
  }]
};
