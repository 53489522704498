var DashboardController = require('../components/dashboard/dashboard.ctrl');
var AppViewController = require('../components/appview/appview.ctrl');

/* @ngInject */
module.exports = function($stateProvider, $urlRouterProvider, $translateProvider, $mdThemingProvider, $compileProvider, IS_PRODUCTION, $rootScopeProvider, $locationProvider) {

  $rootScopeProvider.digestTtl(20);

  $locationProvider.hashPrefix('');

  $mdThemingProvider.definePalette('enlighted-grey', {
    '50': '#d5d8da',
    '100': '#acb1b7',
    '200': '#8e959d',
    '300': '#69727a',
    '400': '#5b6269',
    '500': '#4d5359',
    '600': '#3f4449',
    '700': '#313438',
    '800': '#222528',
    '900': '#141617',
    'A100': '#d5d8da',
    'A200': '#acb1b7',
    'A400': '#5b6269',
    'A700': '#313438',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 A100 A200'
  });

  $mdThemingProvider.definePalette('enlighted-green', {
    '50': '#fefffe',
    '100': '#d3eac6',
    '200': '#b3db9d',
    '300': '#8ac868',
    '400': '#79c052',
    '500': '#69b241',
    '600': '#5c9c39',
    '700': '#4f8531',
    '800': '#416f28',
    '900': '#345820',
    'A100': '#fefffe',
    'A200': '#d3eac6',
    'A400': '#79c052',
    'A700': '#4f8531',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 300 400 500 A100 A200 A400'
  });

  $mdThemingProvider.theme('default')
    .primaryPalette('enlighted-grey')
    .accentPalette('enlighted-green', {
      'default': 'A400'
    });

  $translateProvider.useStaticFilesLoader({
    prefix: 'languages/locale-',
    suffix: '.json'
  });

  $compileProvider.debugInfoEnabled(!IS_PRODUCTION);

  // $translateProvider.useMessageFormatInterpolation();
  $translateProvider.preferredLanguage('en');
  $translateProvider.fallbackLanguage('en');
  $translateProvider.useSanitizeValueStrategy('escape');
  //
  // For any unmatched url, redirect to /state1
  $urlRouterProvider.otherwise("/dashboard");
  //
  // Now set up the states
  $stateProvider
    .state('dashboard', {
      url: "/dashboard?orgId&locale",
      views: {
        header: {
          templateUrl: "app-container/components/header/header.html"
        },
        content: {
          templateUrl: "app-container/components/dashboard/dashboard.html",
          controller: DashboardController,
          controllerAs: "dashboardCtrl"
        },
        footer: {
          templateUrl: "app-container/components/footer/footer.html"
        }
      }
    })
    .state('appview', {
      url: "/apps/:appId?orgId&locale",
      params: {
        opts: {}
      },
      views: {
        header: {
          templateUrl: "app-container/components/header/header.html"
        },
        content: {
          templateUrl: "app-container/components/appview/appview.html",
          controller: AppViewController,
          controllerAs: 'appviewCtrl'
        },
        footer: {
          templateUrl: "app-container/components/footer/footer.html"
        }
      }
    })
    // Commented out the UI route for the marketing page that describes
    // the different enlighted apps available
    // .state('apps', {
    //   url: '/apps?locale',
    //   views: {
    //     header: {
    //       templateUrl: "app-container/components/header/header.html"
    //     },
    //     content: {
    //       templateUrl: "app-container/components/apps/apps.html",
    //       controller: function($scope) {}
    //     },
    //     footer: {
    //       templateUrl: "app-container/components/footer/footer.html"
    //     }
    //   }
    // })
    .state('create-org', {
      url: '/create?locale',
      views: {
        header: {
          templateUrl: "app-container/components/header/header.html"
        },
        content: {
          templateUrl: "app-container/components/create-org/wizard.html",
          controller: require('../components/create-org/create-org.ctrl'),
          controllerAs: 'coBaseCtrl'
        },
        footer: {
          templateUrl: "app-container/components/footer/footer.html"
        }
      }
    })
    .state('settings', {
      url: "/settings?locale",
      views: {
        header: {
          templateUrl: "app-container/components/header/header.html"
        },
        content: {
          templateUrl: "app-container/components/global-settings/index.html",
          controller: require('../components/global-settings/settings.ctrl'),
          controllerAs: 'settingsCtrl'
        },
        footer: {
          templateUrl: "app-container/components/footer/footer.html"
        }
      },
      ncyBreadcrumb: {
        skip: true
      }
    })
    //============  SETTINGS: SYSTEM ROUTES ==============//
    .state('settings.system', {
      abstract: true,
      url: "/system",
      template: "<ui-view/>"
    })
    .state('settings.system.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/system/list/list.html",
      controller: "SystemListCtrl as sysCtrl",
      ncyBreadcrumb : {
        label: 'System Configurations'
      },
      tt : {
        label: 'System Configurations'
      }
    })
    //============  SETTINGS: SYSTEM FACILITY TYPE ROUTES ==============//
    .state('settings.system.facility_type', {
      abstract: true,
      url: "/facility_type",
      template: "<ui-view/>"
    })
    .state('settings.system.facility_type.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/system/facility_type/list/list.html",
      controller: "FacilityTypeListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'facility_type',
        parent: 'settings.system.list'
      },
      tt: {
        label: '{{"facility_type" | translate}}',
        parent: 'settings.system.list'
      }
    })
    .state('settings.system.facility_type.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/system/facility_type/create/create.html",
      controller: "FacilityTypeCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.facility_type.list'
      },
      tt: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.facility_type.list'
      }
    })
    .state('settings.system.facility_type.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/system/facility_type/details/details.html",
      controller: "FacilityTypeDetailCtrl as detailsCtrl",
      params: {
        enumObj: null,
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.system.facility_type.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.system.facility_type.list'
      }
    })
    //============  SETTINGS: SYSTEM LAYER TYPE ROUTES ==============//
    .state('settings.system.layer_type', {
      abstract: true,
      url: "/layer_type",
      template: "<ui-view/>"
    })
    .state('settings.system.layer_type.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/system/layer_type/list/list.html",
      controller: "LayerTypeListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'layer_type',
        parent: 'settings.system.list'
      },
      tt: {
        label: '{{"layer_type" | translate}}',
        parent: 'settings.system.list'
      }
    })
    .state('settings.system.layer_type.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/system/layer_type/create/create.html",
      controller: "LayerTypeCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.layer_type.list'
      },
      tt: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.layer_type.list'
      }
    })
    .state('settings.system.layer_type.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/system/layer_type/details/details.html",
      controller: "LayerTypeDetailCtrl as detailsCtrl",
      params: {
        enumObj: null,
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.system.layer_type.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.system.layer_type.list'
      }
    })
    //============  SETTINGS: SYSTEM ADDRESS TYPE ROUTES ==============//
    .state('settings.system.address_type', {
      abstract: true,
      url: "/address_type",
      template: "<ui-view/>"
    })
    .state('settings.system.address_type.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/system/address_type/list/list.html",
      controller: "AddressTypeListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'address_type',
        parent: 'settings.system.list'
      },
      tt: {
        label: '{{"address_type" | translate}}',
        parent: 'settings.system.list'
      }
    })
    .state('settings.system.address_type.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/system/address_type/create/create.html",
      controller: "AddressTypeCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.address_type.list'
      },
      tt: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.address_type.list'
      }
    })
    .state('settings.system.address_type.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/system/address_type/details/details.html",
      controller: "AddressTypeDetailCtrl as detailsCtrl",
      params: {
        enumObj: null,
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.system.address_type.list'
      },
      tt: {
        label: '{{breadcrumbLocalized | localize: $root.global_language}}',
        parent: 'settings.system.address_type.list'
      }
    })
    //============  SETTINGS: SYSTEM APP TYPE ROUTES ==============//
    .state('settings.system.app_type', {
      abstract: true,
      url: "/app_type",
      template: "<ui-view/>"
    })
    .state('settings.system.app_type.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/system/app_type/list/list.html",
      controller: "AppTypeListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'app_type',
        parent: 'settings.system.list'
      },
      tt: {
        label: '{{"app_type" | translate}}',
        parent: 'settings.system.list'
      }
    })
    // NOT YET TO BE IMPLEMENTED SINCE IT WILL REQUIRE SPECIAL IMPLEMENTATION
    .state('settings.system.app_type.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/system/app_type/create/create.html",
      controller: "AppTypeCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.app_type.list'
      },
      tt: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.app_type.list'
      }
    })
    .state('settings.system.app_type.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/system/app_type/details/details.html",
      controller: "AppTypeDetailCtrl as detailsCtrl",
      params: {
        enumObj: null,
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.system.app_type.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.system.app_type.list'
      }
    })
    //============  SETTINGS: SYSTEM ROLE ROUTES ==============//
    .state('settings.system.role', {
      abstract: true,
      url: "/role",
      template: "<ui-view/>"
    })
    .state('settings.system.role.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/system/role/list/list.html",
      controller: "RoleTypeListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'role',
        parent: 'settings.system.list'
      },
      tt: {
        label: '{{"role" | translate}}',
        parent: 'settings.system.list'
      }
    })
    .state('settings.system.role.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/system/role/create/create.html",
      controller: "RoleTypeCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.role.list'
      },
      tt: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.role.list'
      }
    })
    .state('settings.system.role.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/system/role/details/details.html",
      controller: "RoleTypeDetailCtrl as detailsCtrl",
      params: {
        enumObj: null,
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.system.role.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.system.role.list'
      }
    })

    //============  SETTINGS: SYSTEM TASK TYPE ROUTES ==============//
    .state('settings.system.task_type', {
      abstract: true,
      url: "/task_type",
      template: "<ui-view/>"
    })
    .state('settings.system.task_type.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/system/task_type/list/list.html",
      controller: "TaskTypeListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'Task Type',
        parent: 'settings.system.list'
      },
      tt: {
        label: '{{"task_type" | translate}}',
        parent: 'settings.system.list'
      }
    })
    .state('settings.system.task_type.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/system/task_type/create/create.html",
      controller: "TaskTypeCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.task_type.list'
      },
      tt: {
        label: '{{"CREATE_NEW" | translate}}',
        parent: 'settings.system.task_type.list'
      }
    })
    .state('settings.system.task_type.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/system/task_type/details/details.html",
      controller: "TaskTypeDetailCtrl as detailsCtrl",
      params: {
        enumObj: null,
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.system.task_type.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.system.task_type.list'
      }
    })

    .state('settings.system.enum', {
      abstract: true,
      url: "/{enumId}",
      template: "<ui-view/>"
    })
    .state('settings.system.enum.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/basic/list/list.html",
      controller: "BasicListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: '{{enumType}}',
        parent: 'settings.system.list'
      },
      tt: {
        label: '{{enumType | translate}}',
        parent: 'settings.system.list'
      }
    })
    .state('settings.system.enum.details', {
      url: "/details/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/basic/details/details.html",
      controller: "BasicDetailsCtrl as detailsCtrl",
      params: {
        enumObj: null
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.system.enum.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.system.enum.list'
      }
    })
    .state('settings.system.enum.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/ops/basic/create/create.html",
      controller: "BasicCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New',
        parent: 'settings.system.enum.list'
      },
      tt: {
        label: 'Create New',
        parent: 'settings.system.enum.list'
      }
    })

    //phone type
    .state('settings.system.phone_type', {
      abstract: true,
      url: "/phone_type",
      template: "<ui-view/>"
    })
    .state('settings.system.phone_type.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/system/phone_type/list/list.html",
      controller: "PhoneTypeListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'phone_type',
        parent: 'settings.system.list'
      },
      tt: {
        label: '{{"phone_type" | translate}}',
        parent: 'settings.system.list'
      }
    })
    .state('settings.system.phone_type.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/system/phone_type/create/create.html",
      controller: "PhoneTypeCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New',
        parent: 'settings.system.phone_type.list'
      },
      tt: {
        label: 'Create New',
        parent: 'settings.system.phone_type.list'
      }
    })
    .state('settings.system.phone_type.details', {
        url: "/{enumEid}",
        templateUrl: "app-container/components/global-settings/system/phone_type/details/details.html",
        controller: "PhoneTypeDetailCtrl as detailsCtrl",
        params: {
            enumObj: null,
        },
        ncyBreadcrumb: {
            label: '{{enumVal}}',
            parent: 'settings.system.phone_type.list'
        },
        tt: {
            label: '{{enumVal}}',
            parent: 'settings.system.phone_type.list'
        }
    })
    //end phone type




    //============  SETTINGS: OPS ROUTES ==============//
    .state('settings.ops', {
      abstract: true,
      url: "/ops",
      template: "<ui-view/>"
    })


    //lamp
    .state('settings.ops.lamp', {
      abstract: true,
      url: "/lamp",
      template: "<ui-view/>"
    })
    .state('settings.ops.lamp.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/lamp/list/list.html",
      controller: "LampListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'lamp',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{"lamp" | translate}}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.lamp.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/ops/lamp/create/create.html",
      controller: "LampCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New Lamp Device',
        parent: 'settings.ops.lamp.list'
      },
      tt: {
        label: 'Create New Lamp Device',
        parent: 'settings.ops.lamp.list'
      }
    })
    .state('settings.ops.lamp.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/lamp/details/details.html",
      controller: "LampDetailCtrl as detailsCtrl",
      params: {
        enumObj: null
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.lamp.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.lamp.list'
      }
    })
    //end lamp

    //lampcfg
    .state('settings.ops.lampcfg', {
      abstract: true,
      url: "/lampcfg",
      template: "<ui-view/>"
    })
    .state('settings.ops.lampcfg.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/lampcfg/list/list.html",
      controller: "LampConfigListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'lamp config',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{"lampcfg" | translate}}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.lampcfg.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/ops/lampcfg/create/create.html",
      controller: "LampConfigCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New Lamp Config',
        parent: 'settings.ops.lampcfg.list'
      },
      tt: {
        label: 'Create New Lamp Config',
        parent: 'settings.ops.lampcfg.list'
      }
    })
    .state('settings.ops.lampcfg.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/lampcfg/details/details.html",
      controller: "LampConfigDetailCtrl as detailsCtrl",
      params: {
        enumObj: null
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.lampcfg.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.lampcfg.list'
      }
    })
    //end lampcfg

    //luminaire
    .state('settings.ops.luminaire', {
      abstract: true,
      url: "/luminaire",
      template: "<ui-view/>"
    })
    .state('settings.ops.luminaire.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/luminaire/list/list.html",
      controller: "LuminaireListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'luminaire',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{"luminaire" | translate}}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.luminaire.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/ops/luminaire/create/create.html",
      controller: "LuminaireCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New Luminaire',
        parent: 'settings.ops.luminaire.list'
      },
      tt: {
        label: 'Create New Luminaire',
        parent: 'settings.ops.luminaire.list'
      }
    })
    .state('settings.ops.luminaire.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/luminaire/details/details.html",
      controller: "LuminaireDetailCtrl as detailsCtrl",
      params: {
        enumObj: null
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.luminaire.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.luminaire.list'
      }
    })
    //end luminaire

    //device type
    .state('settings.ops.device_type', {
      abstract: true,
      url: "/device_type",
      template: "<ui-view/>"
    })
    .state('settings.ops.device_type.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/device_type/list/list.html",
      controller: "DeviceTypeListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'device_type',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{"device_type" | translate}}',
        parent: 'settings.ops.list'
      }
    })

    .state('settings.ops.device_type.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/ops/device_type/create/create.html",
      controller: "DeviceTypeCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New',
        parent: 'settings.ops.device_type.list'
      },
      tt: {
        label: 'Create New',
        parent: 'settings.ops.device_type.list'
      }
    })

    .state('settings.ops.device_type.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/device_type/details/details.html",
      controller: "DeviceTypeDetailCtrl as detailsCtrl",
      params: {
        enumObj: null,
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.device_type.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.device_type.list'
      }
    })
    //end device type

    //----------- driver --------------
    .state('settings.ops.driver', {
      abstract: true,
      url: "/driver",
      template: "<ui-view>"
    })
    .state('settings.ops.driver.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/driver/list/list.html",
      controller: "DriverListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'driver',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{"driver" | translate}}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.driver.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/ops/driver/create/create.html",
      controller: "CreateDriverCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New',
        parent: 'settings.ops.driver.list'
      },
      tt: {
        label: 'Create New',
        parent: 'settings.ops.driver.list'
      }
    })
    .state('settings.ops.driver.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/driver/details/details.html",
      controller: "DriverDetailsCtrl as detailsCtrl",
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.driver.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.driver.list'
      }
    })
    //----------- end driver --------------

    //----------- ballast --------------
    .state('settings.ops.ballast', {
      abstract: true,
      url: "/ballast",
      template: "<ui-view>"
    })
    .state('settings.ops.ballast.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/ballast/list/list.html",
      controller: "BallastListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: 'ballast',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{"ballast" | translate}}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.ballast.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/ops/ballast/create/create.html",
      controller: "CreateBallastCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New',
        parent: 'settings.ops.ballast.list'
      },
      tt: {
        label: 'Create New',
        parent: 'settings.ops.ballast.list'
      }
    })
    .state('settings.ops.ballast.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/ballast/details/details.html",
      controller: "BallastDetailsCtrl as detailsCtrl",
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.ballast.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.ballast.list'
      }
    })
    //----------- end ballast --------------

    //----------- control-unit --------------
    .state('settings.ops.control-unit', {
      abstract: true,
      url: "/control_unit",
      template: "<ui-view/>"
    })
    .state('settings.ops.control-unit.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/control-unit/list/list.html",
      controller: "ControlUnitListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: '{{ enumType}}',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{ enumType | translate}}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.control-unit.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/ops/control-unit/create/create.html",
      controller: "ControlUnitCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New',
        parent: 'settings.ops.control-unit.list'
      },
      tt: {
        label: 'Create New',
        parent: 'settings.ops.control-unit.list'
      }
    })
    .state('settings.ops.control-unit.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/control-unit/details/details.html",
      controller: "ControlUnitDetailsCtrl as detailsCtrl",
      params: {
        enumObj: null,
        enumId: null,
        enumEid: null
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.control-unit.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.control-unit.list'
      }
    })
    //----------- end control-unit --------------

    //----------- gang-box --------------
    .state('settings.ops.gang-box', {
      abstract: true,
      url: "/gang_box",
      template: "<ui-view/>"
    })
    .state('settings.ops.gang-box.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/gang-box/list/list.html",
      controller: "GangBoxListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: '{{ enumType}}',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{ enumType | translate}}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.gang-box.create', {
    url: "/create",
    templateUrl: "app-container/components/global-settings/ops/gang-box/create/create.html",
    controller: "GangBoxCreateCtrl as createCtrl",
    ncyBreadcrumb: {
      label: 'Create New',
      parent: 'settings.ops.gang-box.list'
    },
    tt: {
      label: 'Create New',
      parent: 'settings.ops.gang-box.list'
    }
  })
    .state('settings.ops.gang-box.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/gang-box/details/details.html",
      controller: "GangBoxDetailsCtrl as detailsCtrl",
      params: {
        enumObj: null,
        enumId: null,
        enumEid: null
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.gang-box.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.gang-box.list'
      }
    })
    //----------- end gang-box --------------

    //----------- light-enclosure --------------
    .state('settings.ops.light-enclosure', {
      abstract: true,
      url: "/light_enclosure",
      template: "<ui-view/>"
    })
    .state('settings.ops.light-enclosure.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/light-enclosure/list/list.html",
      controller: "LightEnclosureListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: '{{ enumType}}',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{ enumType | translate }}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.light-enclosure.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/ops/light-enclosure/create/create.html",
      controller: "LightEnclosureCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New',
        parent: 'settings.ops.light-enclosure.list'
      },
      tt: {
        label: 'Create New',
        parent: 'settings.ops.light-enclosure.list'
      }
    })
    .state('settings.ops.light-enclosure.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/light-enclosure/details/details.html",
      controller: "LightEnclosureDetailsCtrl as detailsCtrl",
      params: {
        enumObj: null,
        enumId: null,
        enumEid: null
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.light-enclosure.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.light-enclosure.list'
      }
    })
    //----------- end light-enclosure  --------------

    //----------- reason --------------
    .state('settings.ops.reason', {
      abstract: true,
      url: "/reason",
      template: "<ui-view/>"
    })
    .state('settings.ops.reason.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/reason/list/list.html",
      controller: "ReasonListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: '{{ enumType}}',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{ enumType | translate }}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.reason.create', {
        url: "/create",
        templateUrl: "app-container/components/global-settings/ops/reason/create/create.html",
        controller: "ReasonCreateCtrl as createCtrl",
        ncyBreadcrumb: {
            label: 'Create New',
            parent: 'settings.ops.reason.list'
        },
        tt: {
            label: 'Create New',
            parent: 'settings.ops.reason.list'
        }
    })
    .state('settings.ops.reason.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/reason/details/details.html",
      controller: "ReasonDetailsCtrl as detailsCtrl",
      params: {
        enumObj: null,
        enumId: null,
        enumEid: null
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.reason.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.reason.list'
      }
    })
    //----------- end reason  --------------
    //----------- contact_type --------------
    .state('settings.ops.contact-type', {
      abstract: true,
      url: "/contact_type",
      template: "<ui-view/>"
    })
    .state('settings.ops.contact-type.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/contact-type/list/list.html",
      controller: "ContactTypeListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: '{{ enumType}}',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{ enumType | translate }}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.contact-type.create', {
        url: "/create",
        templateUrl: "app-container/components/global-settings/ops/contact-type/create/create.html",
        controller: "ContactTypeCreateCtrl as createCtrl",
        ncyBreadcrumb: {
            label: 'Create New',
            parent: 'settings.ops.contact-type.list'
        },
        tt: {
            label: 'Create New',
            parent: 'settings.ops.contact-type.list'
        }
    })
    .state('settings.ops.contact-type.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/contact-type/details/details.html",
      controller: "ContactTypeDetailsCtrl as detailsCtrl",
      params: {
        enumObj: null,
        enumId: null,
        enumEid: null
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.contact-type.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.contact-type.list'
      }
    })
    //----------- end contact_type  --------------

    //----------- product_code --------------
    .state('settings.ops.product-code', {
      abstract: true,
      url: "/product_code",
      template: "<ui-view/>"
    })
    .state('settings.ops.product-code.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/product-code/list/list.html",
      controller: "ProductCodeListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: '{{ enumType}}',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{ enumType | translate }}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.product-code.create', {
        url: "/create",
        templateUrl: "app-container/components/global-settings/ops/product-code/create/create.html",
        controller: "ProductCodeCreateCtrl as createCtrl",
        ncyBreadcrumb: {
            label: 'Create New',
            parent: 'settings.ops.product-code.list'
        },
        tt: {
            label: 'Create New',
            parent: 'settings.ops.product-code.list'
        }
    })
    .state('settings.ops.product-code.details', {
      url: "/{enumEid}",
      templateUrl: "app-container/components/global-settings/ops/product-code/details/details.html",
      controller: "ProductCodeDetailsCtrl as detailsCtrl",
      params: {
        enumObj: null,
        enumId: null,
        enumEid: null
      },
      ncyBreadcrumb: {
        label: '{{enumVal}}',
        parent: 'settings.ops.product-code.list'
      },
      tt: {
        label: '{{enumVal}}',
        parent: 'settings.ops.product-code.list'
      }
    })
    //----------- end product_code  --------------
    .state('settings.ops.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/list/list.html",
      controller: "OpsListCtrl as opsCtrl",
      ncyBreadcrumb: {
        label: 'Operations Configurations'
      },
      tt: {
        label: 'Operations Configurations'
      }
    })
    .state('settings.ops.enum', {
      abstract: true,
      url: "/{enumId}",
      template: "<ui-view/>"
    })
    .state('settings.ops.enum.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/ops/basic/list/list.html",
      controller: "BasicListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: '{{enumType}}',
        parent: 'settings.ops.list'
      },
      tt: {
        label: '{{enumType | translate}}',
        parent: 'settings.ops.list'
      }
    })
    .state('settings.ops.enum.create', {
        url: "/create",
        templateUrl: "app-container/components/global-settings/ops/basic/create/create.html",
        controller: "BasicCreateCtrl as createCtrl",
        ncyBreadcrumb: {
            label: 'Create New',
            parent: 'settings.ops.enum.list'
        },
        tt: {
            label: 'Create New',
            parent: 'settings.ops.enum.list'
        }
    })
    .state('settings.ops.enum.details', {
        url: "/{enumEid}",
        templateUrl: "app-container/components/global-settings/ops/basic/details/details.html",
        controller: "BasicDetailsCtrl as detailsCtrl",
        params: {
            enumObj: null
        },
        ncyBreadcrumb: {
            label: '{{enumVal}}',
            parent: 'settings.ops.enum.list'
        },
        tt: {
            label: '{{enumVal}}',
            parent: 'settings.ops.enum.list'
        }
    })


    //----------- idps --------------
    .state('settings.idps', {
      abstract: true,
      url: "",
      template: "<ui-view/>"
    })
    .state('settings.idps.list', {
      url: "/idps",
      templateUrl: "app-container/components/global-settings/idp/list/list.html",
      controller: "IdpListCtrl as idpListCtrl",
      ncyBreadcrumb: {
        label: 'Identity Providers'
      },
      tt: {
        label: 'Identity Providers'
      }
    })
    .state('settings.idps.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/idp/create/create-idp.tpl.html",
      controller: "IdpCreateCtrl as newIdpCtrl",
      ncyBreadcrumb: {
        label: 'Create New',
        parent: 'settings.idps.list'
      },
      tt: {
        label: 'Create New',
        parent: 'settings.idps.list'
      }
    })
    .state('settings.idps.idp', {
      url: "/idp/{idpId}",
      templateUrl: "app-container/components/global-settings/idp/details/details.html",
      controller: "IdpDetailsCtrl as idpDetailsCtrl",
      ncyBreadcrumb: {
        label: '{{ idpDetailsCtrl.breadcrumbLabel }}',
        parent: 'settings.idps.list'
      },
      tt: {
        label: '{{ idpDetailsCtrl.breadcrumbLabel }}',
        parent: 'settings.idps.list'
      }
    })
    //----------- end idps --------------

    //----------- dongles --------------
    .state('settings.dongles', {
      abstract: true,
      url: "/dongles",
      template: "<ui-view/>"
    })
    .state('settings.dongles.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/dongle/list/list.html",
      controller: "DoglListCtrl as doglCtrl",
      ncyBreadcrumb: {
        label: "Dongles"
      },
      tt: {
        label: "Dongles"
      }
    })
    .state('settings.dongles.detail', {
      url: "/{dongleId}",
      templateUrl: "app-container/components/global-settings/dongle/detail/detail.html",
      controller: "DoglDetailCtrl as doglCtrl",
      ncyBreadcrumb: {
        label: '{{doglCtrl.breadcrumbLabel}}',
        parent: 'settings.dongles.list'
      },
      tt: {
        label: '{{doglCtrl.breadcrumbLabel}}',
        parent: 'settings.dongles.list'
      }
    })
    //----------- end dongles --------------

    //------------ manufactured devices -------
    .state('settings.mdevice', {
      abstract: true,
      url: "/mdevice",
      template: "<ui-view/>"
    })
    .state('settings.mdevice.list', {
      url: "",
      templateUrl: "app-container/components/global-settings/mdevice/list/list.html",
      controller: "MdeviceListCtrl as listCtrl",
      ncyBreadcrumb: {
        label: "Manufactured Devices"
      }
    })
    .state('settings.mdevice.create', {
      url: "/create",
      templateUrl: "app-container/components/global-settings/mdevice/create/create-mdevice.tpl.html",
      controller: "MdeviceCreateCtrl as createCtrl",
      ncyBreadcrumb: {
        label: 'Create New',
        parent: 'settings.mdevice.list'
      }
    })

    .state('settings.mdevice.detail', {
      url: "/{mdeviceId}",
      templateUrl: "app-container/components/global-settings/mdevice/detail/detail.html",
      controller: "MdeviceDetailCtrl as mdeviceCtrl",
      ncyBreadcrumb: {
        label: '{{mdeviceCtrl.breadcrumbLabel}}',
        parent: 'settings.mdevice.list'
      }
    })

    //----------end manufactured devices -------

    .state('myaccount', {
      url: '/account',
      views: {
        header: {
          templateUrl: "app-container/components/header/header.html"
        },
        content: {
          templateUrl: "app-container/components/my-account/account.html",
          controller: 'MyAccountDetailsCtrl as detailsCtrl'
        },
        footer: {
          templateUrl: "app-container/components/footer/footer.html"
        }
      },
      ncyBreadcrumb: {
        skip: true
      }
    })
    ;
};
