/* @ngInject */
module.exports = function ($scope, $translate, BannerSvc, $q, $rootScope, EnlightedAPISvc, EnlightedAPIErrorSvc, ViewOnly) {
  var

    vm = this,

    I18N_KEYS = [
      'RESTORE_SUCCESS',
      'UPDATE_SUCCESS',
      'UPDATE_FAILURE'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.origEnumObj = undefined;
  vm.enumObj       = undefined;
  vm.hasAsync      = false;
  vm.infoForm      = {};
  vm.isLoaded      = false;
  vm.wrapper       = {};

  //============= FUNCTIONS ============//
  vm.restore = restore;
  vm.save    = save;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('system task-type info.ctrl: activate ');

    $scope.$on('TASK_TYPE_INFO_TAB_SELECTED', function (evt, args) {
      init(args.enumObj);
    });
  }

  function init(enumObj) {
    console.log('system task-type info.ctrl: init', enumObj);

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) { DICTIONARY[key] = translation; });
    });

    vm.apiEnum = enumObj;
    // transform to have task_type obj model
    createEnumDisplay(enumObj).then(function (display) {
      vm.enumObj = display;
      // copy original object to retain information for restore
      vm.origEnumObj = angular.copy(vm.enumObj);
      vm.isLoaded = true;
    });

  }

  function createEnumDisplay(enumObj) {
    var deferred = $q.defer();

    EnlightedAPI.organization_type.getAll().then(function (resp) {
      var organizations = resp.enums;
      var org_types = [];
      angular.forEach(organizations, function (org, index) {
        org_types.push({
          value: org.val,
          names: org.names,
          selected: (org.val == enumObj.org_type ? true : false)
        });
      });

      deferred.resolve({
        val: enumObj.val,
        dname: (enumObj.names) ? enumObj.names[''] : undefined,
        ddescription: (enumObj.descriptions) ? enumObj.descriptions[''] : undefined,
        org_types: org_types,
        org_type_opt: undefined,
        permissions_mask: undefined,
        eid: enumObj.eid
      });
    });

    return deferred.promise;
  }

  function restore() {
    console.log("RESTORE vm.enumObj WITH vm.origEnumObj ", "OBJECT MODEL: ", vm.enumObj, "ORIGINAL OBJECT:", vm.origEnumObj);
    vm.enumObj = angular.copy(vm.origEnumObj);
    vm.infoForm.$setPristine();

    BannerSvc.show({
      type: 'info',
      scope: $rootScope,
      autoClose: true,
      content: DICTIONARY.RESTORE_SUCCESS,
      el: '#content'
    });
  }

  function save() {
    console.log('task-type-info.ctrl: save - ', vm.enumObj);

    vm.hasAsync = true;
    vm.apiEnum = updateAPIEnum(vm.apiEnum, vm.enumObj);

    console.log("DATA SENT:", vm.apiEnum);

    EnlightedAPISvc.task_type.update(vm.apiEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.enumObj.val }),
          el: '#content'
        });

        console.log("RESPONSE AFTER SAVE", rsp.enum, vm.apiEnum);
        vm.apiEnum = rsp.enum;
        createEnumDisplay(rsp.enum).then(function (display) {
          vm.enumObj = display;
          // copy original object to retain information for restore
          vm.origEnumObj = angular.copy(vm.enumObj);
        });

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.origEnumObj.val }), error),
          el: '#content'
        });

        vm.hasAsync = false;
      });
  }

  function updateAPIEnum(apiEnum, enumObj) {
    apiEnum.val = enumObj.val;
    apiEnum.names[''] = enumObj.dname;
    apiEnum.descriptions = apiEnum.descriptions || {};
    apiEnum.descriptions[''] = enumObj.ddescription || "";
    apiEnum.org_type = enumObj.org_type_opt;
    apiEnum.permissions_mask = enumObj.permissions_mask;
    apiEnum.eid = enumObj.eid;

    return apiEnum;
  }

  $scope.$watch(function () {
    return vm.infoForm.$dirty;
  }, function (newval, oldval) {
    if (newval) {
      ViewOnly.set("task type form");
    } else {
      ViewOnly.delete("task type form");
    }
  });

  $scope.$on("RESET_FORM_VIEW_ONLY", function (evt, data) {
    if (ViewOnly.exists("task type form")) {
      restore();
    }
  });

  $scope.$on("$destroy", function () {
    ViewOnly.delete("task type form");
  });
};
