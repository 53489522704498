/* @ngInject */
module.exports = function($mdThemingProvider) {
  $mdThemingProvider.definePalette('enlighted-grey', {
    '50': '#d5d8da',
    '100': '#acb1b7',
    '200': '#8e959d',
    '300': '#69727a',
    '400': '#5b6269',
    '500': '#4d5359',
    '600': '#3f4449',
    '700': '#313438',
    '800': '#222528',
    '900': '#141617',
    'A100': '#d5d8da',
    'A200': '#acb1b7',
    'A400': '#5b6269',
    'A700': '#313438',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 A100 A200'
  });

  $mdThemingProvider.definePalette('enlighted-green', {
    '50': '#fefffe',
    '100': '#d3eac6',
    '200': '#b3db9d',
    '300': '#8ac868',
    '400': '#79c052',
    '500': '#69b241',
    '600': '#5c9c39',
    '700': '#4f8531',
    '800': '#416f28',
    '900': '#345820',
    'A100': '#fefffe',
    'A200': '#d3eac6',
    'A400': '#79c052',
    'A700': '#4f8531',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 300 400 500 A100 A200 A400'
  });

  $mdThemingProvider.theme('default')
    .primaryPalette('enlighted-grey')
    .accentPalette('enlighted-green', {
      'default': 'A400'
    });
};
