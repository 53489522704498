/* @ngInject */
module.exports = function ($scope, EnlightedAPIEnumsSvc, $translate, $q, $stateParams, localizeFilter, $state, ConfirmDialogSvc, BannerSvc, EnlightedAPISvc, $rootScope) {
  var vm = this,
  I18N_KEYS = [
    'DT_PAGE',
    'DT_ROWS_PER_PAGE',
    'DT_OF',
    'CONFIRM_DELETE_MESSAGE_LIST',
    'CONFIRM_DELETE_MESSAGE_LIST_PLURAL',
    'CONFIRM_DELETE',
    'CONFIRM_DELETE_ARIA_LABEL'
  ],
  DICTIONARY = {};

  //============= MODELS ============//
  vm.isLoaded = false;
  vm.enumType = 'luminaire';
  vm.enums    = [];
  vm.filter     = {};
  vm.query      = {};
  vm.selected   = [];

  //============= FUNCTIONS ============//
  vm.createFn = createFn;
  vm.deleteFn = deleteFn;
  vm.viewEnumDetails = viewEnumDetails;

  //============= FN DEFINITIONS ============//
  activate();

  function activate() {
    var promises = [];

    vm.query = {
      order: 'name',
      limit: 15,
      page: 1
    };

    console.log('luminaire-list.ctrl: activate');

    $scope.enumType = vm.enumType;

    angular.forEach(I18N_KEYS, function (key) {
      promises.push($translate(key));
    });

    $q.all(promises).then(function (translations) {
      angular.forEach(translations, function (translation, index) {
        DICTIONARY[I18N_KEYS[index]] = translation;
      });
      return $q.when();
    }).finally(function () {
      console.log(vm);
      EnlightedAPISvc[vm.enumType].getAll().then(function (enumRsp) {
        var displayEnums = [];

        angular.forEach(enumRsp.enums, function (enumItem) {
          displayEnums.push({
            val : enumItem.val,
            parent: enumItem.parent,
            name: localizeFilter(enumItem.names),
            description: localizeFilter(enumItem.descriptions),
            eid : enumItem.eid
          });
        });

        vm.enums = displayEnums;
        vm.isLoaded = true;
      });
    });
  }

  function createFn() {
    $state.go('^.create', {enumId: vm.enumType});
  }

  function deleteFn(selected, evt) {
    showDeleteConfirm(selected, evt).then(function () {
      vm.selected = [];
    });
  }

  function deleteEnums(selected) {
    console.log('luminaire-list.ctrl: deleteEnums - ', selected);

    var defer = $q.defer(),
        promises = [];

    angular.forEach(selected, function (enumObj) {
      promises.push(EnlightedAPISvc[vm.enumType].delete(enumObj.val));
    });

    $q.all(promises).then(function () {
      // remove deleted users from users list
      vm.enums = _.difference(vm.enums, selected);
      BannerSvc.show({
        scope: $rootScope,
        type: 'success',
        autoClose: true,
        content: $translate.instant('DELETE_SUCCESS_PLURAL'),
        el: '#content'
      });

      return $q.when();
    }, function () {

      BannerSvc.show({
        scope: $rootScope,
        type: 'error',
        autoClose: false,
        content: $translate.instant('DELETE_FAILURE_PLURAL'),
        el: '#content'
      });

      return $q.when();
    })
    .finally(function () {
      defer.resolve();
    });

    return defer.promise;
  }

  function showDeleteConfirm(selected, ev) {
    var deferred = $q.defer(),
      opts = {
        ariaLabel: DICTIONARY.CONFIRM_DELETE_ARIA_LABEL,
        title: DICTIONARY.CONFIRM_DELETE,
        message: (selected.length > 1) ?
          DICTIONARY.CONFIRM_DELETE_MESSAGE_LIST_PLURAL :
          DICTIONARY.CONFIRM_DELETE_MESSAGE_LIST
      };

    ConfirmDialogSvc.show(ev, opts).then(function () {
      return deleteEnums(selected);
    }).finally(function () {
      deferred.resolve();
    });

    return deferred.promise;
  }

  function viewEnumDetails(enumObj) {
    console.log('luminaire-list.ctrl: viewEnumDetails', enumObj);
    $state.go('^.details', {enumId: vm.enumType, enumEid: enumObj.eid, enumObj: enumObj});
  }
};
