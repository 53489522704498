/* @ngInject */
module.exports = function ($scope, EnlightedAPIEnumsSvc, $translate, $q, localizeFilter, $state, ConfirmDialogSvc, BannerSvc, EnlightedAPISvc, $rootScope) {
  var

    vm = this,

    I18N_KEYS = [
      'DT_PAGE',
      'DT_ROWS_PER_PAGE',
      'DT_OF',
      'CONFIRM_DELETE_MESSAGE_LIST',
      'CONFIRM_DELETE_MESSAGE_LIST_PLURAL',
      'CONFIRM_DELETE',
      'CONFIRM_DELETE_ARIA_LABEL'
    ],

    DICTIONARY = {};

  //============= MODELS ============//
  vm.isLoaded = false;
  vm.enumType = undefined;
  vm.enums    = [];
  vm.options  = {};

  //============= FUNCTIONS ============//
  vm.createEnum = createEnum;
  vm.viewEnumDetails = viewEnumDetails;
  vm.showDeleteConfirm = showDeleteConfirm;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    var promises = [];

    console.log('control unit list.ctrl: activate');

    vm.enumType = 'control_unit';
    $scope.enumType = 'control_unit';

    angular.forEach(I18N_KEYS, function (key) {
      promises.push($translate(key));
    });

    $q.all(promises)
      .then(function (translations) {
        angular.forEach(translations, function (translation, index) {
          DICTIONARY[I18N_KEYS[index]] = translation;
        });

        return $q.when();
      })
      .finally(function () {
        EnlightedAPISvc[vm.enumType].getAll()
          .then(function (enumRsp) {
            var displayEnums = [];
            angular.forEach(enumRsp.enums, function (enumItem) {
              displayEnums.push({
                val : enumItem.val,
                name: localizeFilter(enumItem.names),
                manufacturer: enumItem.parent,
                eid : enumItem.eid,
                channels: enumItem.channels
              });
            });
            vm.enums = displayEnums;
            var promises = vm.enums.map(function(enumItem) {
                return EnlightedAPISvc.manufacturer.get(enumItem.manufacturer);
            });
            return $q.all(promises);
          }).then(function(results) {
          angular.forEach(vm.enums, function(value, key) {
            value.manufacturer = localizeFilter(results[key].enum.names);
          });
          
          vm.options.title = vm.enumType;
          vm.isLoaded = true;
        });
      });
  }

  function createEnum() {
    console.log('control-unit-list.ctrl: createEnum');
    $state.go('^.create', {enumId: vm.enumType});
  }

  function deleteEnums(selected) {
    console.log('control-unit-list.ctrl: deleteEnums - ', selected);

    var defer = $q.defer(), promises = [];

    angular.forEach(selected, function (enumObj) {
      promises.push(EnlightedAPISvc[vm.enumType].delete(enumObj.eid));
    });

    $q.all(promises).then(function () {
        // remove deleted items from item list
        vm.enums = _.difference(vm.enums, selected);

        BannerSvc.show({
          scope: $rootScope,
          type: 'success',
          autoClose: true,
          content: $translate.instant('DELETE_SUCCESS_PLURAL'),
          el: '#content'
        });

        return $q.when();
      }, function () {
        BannerSvc.show({
          scope: $rootScope,
          type: 'error',
          autoClose: false,
          content: $translate.instant('DELETE_FAILURE_PLURAL'),
          el: '#content'
        });

        return $q.when();
      })
      .finally(function () {
        defer.resolve();
      });

    return defer.promise;
  }

  function showDeleteConfirm(selected, ev) {
    var

      deferred = $q.defer(),

      opts = {
        ariaLabel: DICTIONARY.CONFIRM_DELETE_ARIA_LABEL,
        title: DICTIONARY.CONFIRM_DELETE,
        message: (selected.length > 1) ?
          DICTIONARY.CONFIRM_DELETE_MESSAGE_LIST_PLURAL :
          DICTIONARY.CONFIRM_DELETE_MESSAGE_LIST
      };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        return deleteEnums(selected);
      })
      .finally(function () {
        deferred.resolve();
      });

    return deferred.promise;
  }

  function viewEnumDetails(enumObj) {
    console.log('control-unit-list.ctrl: viewEnumDetails', enumObj);
    $state.go('^.details', {enumId: vm.enumType, enumEid: enumObj.eid, enumObj: enumObj});
  }
};
