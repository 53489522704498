/* @ngInject */
module.exports = function (pcodeVm, $mdDialog, $scope, $q, $translate, EnlightedAPISvc, EnlightedAPIEnumsSvc, ConfirmDialogSvc, BannerSvc, $rootScope, EnlightedAPIErrorSvc) {

  var vm = this,
    I18N_KEYS = [
      'REQUEST_CERTIFICATE_SUCCESS',
      'REQUEST_CERTIFICATE_FAILURE'
    ],
    DICTIONARY = {};

  //============= MODELS ============//
  vm.pcodeStates = EnlightedAPIEnumsSvc.getDoglStates();
  vm.selectedState = undefined;
  vm.newCert = '';
  //============= FUNCTIONS ============//
  vm.cancel = $mdDialog.cancel;
  vm.curPCode = pcodeVm;
  vm.requestCert = requestCert;
  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('request-cert.ctrl: activate');
    console.log(vm.curPCode);
    init();
  }

  function init() {
    console.log('request-cert.ctrl: initialized');

    angular.forEach(I18N_KEYS, function (key) {
      $translate(key).then(function (translation) {
        DICTIONARY[key] = translation;
      });
    });

  }

  function requestCert(pcodeAPIModel) {

    console.log('request-cert.ctrl: save - ', vm.newCert);
   
    EnlightedAPISvc.product_code.registerByEID(pcodeAPIModel.eid, vm.newCert)
      .then(function (reqCertRsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: DICTIONARY.REQUEST_CERTIFICATE_SUCCESS
        });
        $mdDialog.hide();

      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg(DICTIONARY.REQUEST_CERTIFICATE_FAILURE, error)
        });
        $mdDialog.cancel();
      });
  }
};
