/* @ngInject */
module.exports = function ($scope, $q, $state, $stateParams, EnlightedAPISvc, localizeFilter, ConfirmDialogSvc, $translate, BannerSvc, $rootScope, EnlightedAPIErrorSvc, ViewOnly) {
  var vm = this;

  //============ MODLES ============//
  vm.curEnum = undefined;
  vm.enumVal = undefined;
  vm.isLoaded = false;
  vm.tabIndex = 0;

  //============ FUNCTIONS ============//
  vm.onSelectDescriptionsTab = undefined;
  vm.onSelectInfoTab = onSelectInfoTab;
  vm.onSelectNamesTab = undefined;
  vm.showConfirmDelete = showConfirmDelete;
  vm.saveFn = saveFn;
  vm.checkViewOnly           = checkViewOnly;

  activate();

  //============= FN DEFINITIONS ============//
  function checkViewOnly(ev) {
    if (ViewOnly.oneDirty()) {
      var dirtyNames = ViewOnly.getDirtyNames();
      var opts = {
        ariaLabel: $translate.instant('CONFIRM_CHANGES_LOSE_ARIA_LABEL'),
        title: $translate.instant('CONFIRM_CHANGES_LOSE'),
        message: $translate.instant('CHANGES_LOSE', {names: dirtyNames.join(" and ")})
      };

      ConfirmDialogSvc.show(ev, opts).then(function () {
        // deleteOrg();
        vm.globalsettingseditmode = false;
        $scope.$broadcast("RESET_FORM_VIEW_ONLY");
      });
    } else {
      vm.globalsettingseditmode = false;
    }
  }

  function activate() {
    console.log('ops reason details.ctrl: activate ', $stateParams);

    $scope.enumType = $stateParams.enumId || "reason";

    if (EnlightedAPISvc.hasOwnProperty($scope.enumType)) {
      EnlightedAPISvc[$scope.enumType].getById($stateParams.enumEid)
        .then(function (enumRsp) {
          vm.curEnum = enumRsp.enum;
          $scope.enumVal = vm.enumVal = vm.curEnum.val;
          vm.isLoaded = true;

        }, function (err) {
          // TODO: what to show in case of error?
          vm.isLoaded = true;
        });
    }
  }

  function saveFn() {
    var deferred = $q.defer();
    console.log(vm.curEnum);
    EnlightedAPISvc.reason.update(vm.curEnum)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('UPDATE_SUCCESS', { val: vm.curEnum.val }),
          el: '#content'
        });

        console.log("RESPONSE AFTER SAVE", rsp.enum, vm.curEnum);
        vm.curEnum = rsp.enum;

        deferred.resolve("success");

        vm.hasAsync = false;
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('UPDATE_FAILURE', { val: vm.curEnum.val }), error),
          el: '#content'
        });

        deferred.resolve("error");

        vm.hasAsync = false;
      });

      return deferred.promise;
  }

  function deleteEnum() {
    EnlightedAPISvc[vm.curEnum.kind].deleteById(vm.curEnum.eid)
      .then(function (rsp) {
        BannerSvc.show({
          type: 'success',
          scope: $rootScope,
          autoClose: true,
          content: $translate.instant('DELETE_SUCCESS', {val: vm.curEnum.val}),
          el: '#content'
        });
        $state.go('^.list', {enumId: vm.curEnum.kind});
      }, function (error) {
        BannerSvc.show({
          type: 'error',
          scope: $rootScope,
          autoClose: false,
          content: EnlightedAPIErrorSvc.getErrorMsg($translate.instant('DELETE_FAILURE', {val: vm.curEnum.val}), error),
          el: '#content'
        });
      });
  }

  function onSelectInfoTab() {
    $scope.$broadcast('REASON_INFO_TAB_SELECTED', {enumObj: vm.curEnum});
  }

  function showConfirmDelete(ev) {
    var

      opts = {
        ariaLabel: $translate.instant('CONFIRM_DELETE_ARIA_LABEL'),
        title: $translate.instant('CONFIRM_DELETE'),
        message: $translate.instant('CONFIRM_DELETE_MESSAGE', {objName: localizeFilter(vm.curEnum.names)})
      };

    ConfirmDialogSvc.show(ev, opts)
      .then(function () {
        deleteEnum();
      });
  }
};
