/* @ngInject */
module.exports = function ($scope, EnlightedAPIEnumsSvc, $q, $filter, ConfirmDialogSvc) {
  var vm = this,

  I18N_KEYS = [
    'CONFIRM_ALLOW_3RDPARTY_IDP',
    'CONFIRM_DIALOG'
  ],

  DICTIONARY = {},

  isInit = false;

  //============= MODELS ============//
  vm.locales               = [];
  vm.orgInfoForm           = {};
  vm.orgInfoOpts           = { hideHQ: true, hideIdP: true };
  vm.orgtypes              = [];
  vm.orgTypesSelectedCount = 0;
  vm.hasTypeProxy          = undefined;

  //============= FUNCTIONS ============//
  vm.showConfirmDialog      = showConfirmDialog;
  vm.toggleOrgTypeSelection = toggleOrgTypeSelection;

  activate();

  //============= FN DEFINITIONS ============//
  function activate() {
    console.log('organization_step.ctrl: activate');

    $scope.$on('CREATE_ORG_ACTIVATE_ORG', function () {
      init();
    });

    if ($scope.coBaseCtrl.selectedStep === 0) {
      init();
    }
  }

  function init() {
    console.log('organization_step.ctrl: init');

    angular.forEach(I18N_KEYS, function (key) {
      DICTIONARY[key] = $filter('translate')(key);
    });

    if (isInit) {
      return;
    }

    isInit = true;

    var promises = [];

    watchSelectedOrgTypesCount();

    promises.push(EnlightedAPIEnumsSvc.getEnum('locale'));
    promises.push(EnlightedAPIEnumsSvc.getEnum('organization_type'));

    $q.all(promises).then(function (resolutions) {
      vm.locales = resolutions[0];
      vm.orgtypes = massageOrgTypes(resolutions[1]);
    });
  }

  function watchSelectedOrgTypesCount() {
    $scope.$watch(function () {
      return vm.orgTypesSelectedCount;
    }, function (newVal, oldVal) {
      if (newVal === 0 && oldVal > 0) {
        vm.hasTypeProxy = undefined;
        if ($scope.orgForm && $scope.orgForm.typeProxy) {
          $scope.orgForm.typeProxy.$setDirty();
        }
      } else {
        vm.hasTypeProxy = newVal;
      }
    });
  }

  function showConfirmDialog(ev) {
    var opts = {
      ariaLabel: DICTIONARY.CONFIRM_DIALOG,
      title: DICTIONARY.CONFIRM_DIALOG,
      message: DICTIONARY.CONFIRM_ALLOW_3RDPARTY_IDP
    };

    ConfirmDialogSvc.show(ev, opts)
      .catch(function () {
        $scope.step.data.org.thirdparty_idps = false;
      });
  }

  function toggleOrgTypeSelection(orgtype) {
    // orgtype.selected = !orgtype.selected;

    if (orgtype.selected) {
      // if selected, add to org type list
      $scope.coBaseCtrl.stepData[0].data.org.types.push(orgtype.id);
    } else {
      // else remove from org type list
      _.remove($scope.coBaseCtrl.stepData[0].data.org.types, function (n) {
        return n === orgtype.id;
      });
    }

    vm.orgTypesSelectedCount = countSelectedOrgTypes(vm.orgtypes);
  }

  function countSelectedOrgTypes(orgtypes) {
    var count = 0;
    angular.forEach(orgtypes, function (orgtype) {
      if (orgtype.selected) {
        count++;
      }
    });

    return count;
  }

  function massageOrgTypes(orgTypesEnum) {
    var massagedTypes = [], type;

    angular.forEach(orgTypesEnum, function (orgtype) {
      type = {
          id: orgtype.val,
          display: $filter('localize')(orgtype.names),
          selected: false,
          locked: false
      };

      massagedTypes.push(type);
    });

    return massagedTypes;
  }


};
